import React, { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router";

import { DigitalAssetType } from "../../shared";

import {
  Button,
  CardList,
  Container,
  Link,
  PageHeader,
} from "../../components";
import {
  ChatMessageButton,
  CompanyActivityLogWidget,
  CompanyCommentCreateForm,
  CompanyCommentLogWidget,
  DigitalAssetPreviewCard,
  EducationDetailSection,
  ExperienceDetailSection,
} from "..";
import { getProfileById, getResumeById } from "../../store";
import { date } from "../../utils";
import {
  useAppDispatch,
  useAppSelector,
  useEmployee,
  useLoading,
} from "../../hooks";
import { ROUTER } from "../../routes";

export const UserResumeDetailContainer = () => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const { userId } = useParams();
  const [loading, setLoading] = useLoading();

  const { company } = useEmployee();

  const {
    profile: { data: profile },
  } = useAppSelector((state) => state.profile);
  const {
    resume: { data: resume },
  } = useAppSelector((state) => state.resume);

  const digitalAssets = resume?.digitalAssets || [];
  const certificateAssets = digitalAssets?.filter(
    (asset) => asset.type === DigitalAssetType.Certificate
  );
  const photoAssets = digitalAssets?.filter(
    (asset) => asset.type === DigitalAssetType.Photo
  );

  const companyId = company?.id;

  const { profileId } = resume || {};
  const { firstName, lastName } = profile || {};

  useEffect(() => {
    (async () => {
      if (!userId) return;

      setLoading(true);

      // Checking User
      const user = userId ? await dispatch(getProfileById(userId)) : null;

      if (!user?.id || !user?.resumeId) {
        return setLoading(false);
      }

      // Checking Resume
      const resume = await dispatch(getResumeById(user?.resumeId));
      if (!resume?.id) {
        return setLoading(false);
      }

      setLoading(false);
    })();
  }, [userId]);

  return (
    <Container className="w-full flex flex-col py-[15px]" loading={loading}>
      <div className="flex flex-row justify-between mb-[30px]">
        <PageHeader
          exceptions={[
            { from: ROUTER.USER_DIGITAL_ASSETS(userId), to: ROUTER.HOME },
          ]}
        >
          {firstName || ""} {lastName || ""}
        </PageHeader>
        {/* <div className="flex flex-row justify-start items-center gap-x-[10px]">
          <Button color="secondary" variant="outlined">
            Share
          </Button>
          <ChatMessageButton userId={profileId} />
        </div> */}
      </div>
      <div className="flex flex-col gap-y-[50px]">
        {resume?.covers?.length >= 1 && (
          <CardList className="w-full flex flex-col gap-y-[20px]">
            {resume?.covers?.map((cover) => (
              <div key={cover?.id} className="w-full flex flex-col">
                <h3 className="text-white text-md font-semibold mb-[10px]">
                  {cover?.title}
                </h3>
                <p className="text-base text-secondary font-medium">
                  {cover?.details}
                </p>
              </div>
            ))}
          </CardList>
        )}

        {resume?.experiences?.length >= 1 && (
          <div className="flex flex-col">
            <h2 className="text-white text-lg font-semibold">Employer Data</h2>
            <CardList className="mt-[25px] gap-y-[25px]">
              {resume?.experiences?.map((experience) => (
                <ExperienceDetailSection key={experience.id} {...experience} />
              ))}
            </CardList>
          </div>
        )}

        {resume?.educations?.length >= 1 && (
          <div className="flex flex-col">
            <h2 className="text-white text-lg font-semibold">
              Education/Certifications
            </h2>
            <CardList className="flex flex-col gap-y-[25px] mt-[25px]">
              {resume?.educations?.map((education) => (
                <EducationDetailSection key={education?.id} {...education} />
              ))}
            </CardList>
          </div>
        )}

        {!!resume?.digitalAssets?.length && (
          <div className="flex flex-col">
            <div className="flex flex-row items-center justify-between">
              <h2 className="text-white text-lg font-semibold">
                <Link to={ROUTER.USER_DIGITAL_ASSETS(userId)}>
                  Digital Portfolio
                </Link>
              </h2>
            </div>
            <div className="flex flex-col gap-[30px] mt-[15px]">
              {!!certificateAssets?.length && (
                <div className="flex flex-col">
                  <h3 className="text-base text-secondary font-semibold">
                    Certificates
                  </h3>
                  <div className="grid gap-[2px] mt-[15px] xxs:grid-cols-3 sm:grid-cols-4 md:grid-cols-4">
                    {certificateAssets?.map((digitalAsset) => (
                      <DigitalAssetPreviewCard
                        key={digitalAsset?.id}
                        to={ROUTER.USER_DIGITAL_ASSET_DETAIL(
                          digitalAsset?.createdById,
                          digitalAsset?.id
                        )}
                        {...digitalAsset}
                      />
                    ))}
                  </div>
                </div>
              )}

              {!!photoAssets?.length && (
                <div className="flex flex-col">
                  <h3 className="text-base text-secondary font-semibold">
                    Photos
                  </h3>
                  <div className="grid gap-[2px] mt-[15px] xxs:grid-cols-3 sm:grid-cols-4 md:grid-cols-4">
                    {photoAssets?.map((digitalAsset) => (
                      <DigitalAssetPreviewCard
                        key={digitalAsset?.id}
                        to={ROUTER.USER_DIGITAL_ASSET_DETAIL(
                          digitalAsset?.createdById,
                          digitalAsset?.id
                        )}
                        {...digitalAsset}
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        <Container className="flex flex-col md:hidden">
          <div className="flex flex-row justify-between items-center">
            <h2 className="text-white text-lg font-semibold">Activity</h2>
          </div>
          <div className="mt-[20px]">
            <CompanyActivityLogWidget
              companyId={companyId}
              where={{ userId }}
            />
          </div>
        </Container>

        <Container className="flex flex-col md:hidden">
          <div className="flex flex-row justify-between items-center">
            <h2 className="text-white text-lg font-semibold">Company Notes</h2>
            <Button>Add Note</Button>
          </div>
          <div className="mt-[20px]">
            <CompanyCommentLogWidget companyId={companyId} where={{ userId }} />
          </div>

          <div className="mt-[20px]">
            <CompanyCommentCreateForm
              inputClassName="bg-primaryLight"
              {...{ userId, companyId }}
            />
          </div>
        </Container>
      </div>
    </Container>
  );
};
