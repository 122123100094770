import React, { useEffect, useState } from "react";

import { Button, PageContainerSwitch } from "../../components";
import { AdminUserContainer, AdminBusinessContainer } from "./../../features";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { countBusinessVerifications, countVerifications } from "../../store";
import { VerificationStatus } from "../../shared";

export const AdminHomeContainer = () => {
  const dispatch = useAppDispatch();

  const {
    verifications: { results: verificationCount },
  } = useAppSelector((state) => state.verification);

  const {
    companyVerifications: { results: businessVerificationCount },
  } = useAppSelector((state) => state.company);

  const tabs = [
    {
      label: `Users (${verificationCount || 0})`,
      component: AdminUserContainer,
      onRight: (
        <div>
          <Button color="primary" variant="outlined">
            Invite Member
          </Button>
        </div>
      ),
    },
    {
      label: `Businesses (${businessVerificationCount || 0})`,
      component: AdminBusinessContainer,
      onRight: (
        <div>
          <Button color="primary" variant="outlined">
            Invite Member
          </Button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    dispatch(
      countVerifications({
        where: { status: VerificationStatus.Pending, companyId: null },
      })
    );

    dispatch(
      countBusinessVerifications({
        where: { status: VerificationStatus.Pending },
      })
    );
  }, []);
  return <PageContainerSwitch tabs={tabs} />;
};
