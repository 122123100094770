import React from "react";

import {
  Action,
  IResumeCover,
  IResumeCoverFindOptions,
} from "../../../../shared";
import { ActionMenu } from "../../../../components";
import { IResumeSectionCardProps } from "../../../../features";

interface IResumeCoverCardProps extends IResumeSectionCardProps, IResumeCover {}

export const ResumeCoverCard: React.FC<IResumeCoverCardProps> = ({
  title,
  details,
  isDraft,
  actions,
  onEdit,
  onDelete,
}) => (
  <div className="w-full">
    <div className="w-full flex flex-row justify-between items-center mb-2">
      <div className="w-full flex flex-row justify-between items-center break-words">
        <h3 className="flex flex-row justify-start items-center basis-10/12 gap-x-[10px] text-base text-white font-semibold leading-none">
          <span className="break-words">{title}</span>
          {isDraft && <span className="text-sm text-secondary">(Draft)</span>}
        </h3>{" "}
        <div className="basis-2/12 flex flex-row justify-end">
          {!!actions?.length ? (
            <ActionMenu
              actions={[Action.Edit, Action.Delete]}
              {...{ onEdit, onDelete }}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
    <div className="mt-[15px] overflow-hidden break-words text-secondary text-base font-medium">
      <p>{details}</p>
    </div>
  </div>
);

ResumeCoverCard.defaultProps = {
  isDraft: false,
  isVerified: false,
};
