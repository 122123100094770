import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { useFormik } from "formik";
import * as Yup from "yup";

import { FiCheckCircle as CheckIcon } from "react-icons/fi";

import { ROUTER } from "../../../routes";
import {
  Checkbox,
  Form,
  TextField,
  Button,
  FormSection,
  Container,
} from "../../../components";
import { useAppDispatch, useLoading, useToast } from "../../../hooks";
import { checkUserToken, confirmPasswordReset } from "../../../store";
import {
  NotificationStatus,
  NotificationType,
  NotificationPriority,
} from "./../../../shared";

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Password must consist of 8 characters minimum.")
    .required("New Password is a required field"),
  passwordConfirmation: Yup.string()
    .required("Confirm Password is a required field")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

export const PasswordResetConfirm = () => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const location = useLocation();
  const query = new URLSearchParams(location.search) || {};

  const token = query.get("token") || null;

  const { toast } = useToast();
  const [loading, setLoading] = useLoading();

  const [isTokenVerified, setTokenVerified] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const { values, errors, touched, setFieldError, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        password: "",
        passwordConfirmation: "",
      },
      validationSchema,
      onSubmit: async (values) => {
        const data = {
          ...values,
          token,
        };

        setLoading(true);

        const updated = await dispatch(confirmPasswordReset(data));
        if (!updated) {
          toast({
            status: NotificationStatus.Error,
            message: "Password Not Updated!",
          });
          return setLoading(false);
        }

        setLoading(false);
        setSubmitted(true);

        // redirect(ROUTER.PASSWORD_RESET_CONFIRM);
      },
    });

  useEffect(
    () =>
      // @ts-ignore
      (async () => {
        if (!token) return;

        setLoading(true);

        const verifiedToken = await dispatch(checkUserToken(token));
        if (!verifiedToken) {
          setLoading(false);
          setTokenVerified(false);
          return;
        }

        setLoading(false);
        setTokenVerified(true);
      })(),

    [token]
  );

  return (
    <Container
      loading={loading}
      loadingClassName="flex justify-center items-center"
    >
      {isTokenVerified ? (
        <div className="w-full flex flex-col text-white">
          {submitted ? (
            <PasswordResetConfirmSuccess />
          ) : (
            <>
              <div className="w-full flex flex-col items-center">
                <h1 className="text-2xl font-semibold mb-[20px]">
                  Set New Password
                </h1>
                <span>Plese set and confirm your new password below.</span>
              </div>
              <FormSection>
                <Form onSubmit={handleSubmit} className="mt-[15px] text-black">
                  <div className="w-full flex flex-col justify-start items-start gap-y-[10px]">
                    <div className="w-full flex flex-col gap-y-[10px]">
                      <TextField
                        id="password"
                        label="New Password"
                        type="password"
                        className="!bg-white !text-black"
                        value={values?.password}
                        errorMessage={
                          touched?.password ? errors?.password : null
                        }
                        onChange={handleChange}
                      />
                      <TextField
                        id="passwordConfirmation"
                        label="Repeat Password"
                        type="password"
                        className="!bg-white !text-black"
                        value={values?.passwordConfirmation}
                        errorMessage={
                          touched?.passwordConfirmation
                            ? errors?.passwordConfirmation
                            : null
                        }
                        onChange={handleChange}
                      />
                    </div>

                    <div className="w-full">
                      <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        className="w-full min-h-[55px] font-medium bg-blue-500 text-blue-50"
                        loading={loading}
                      >
                        Update Password
                      </Button>
                    </div>
                  </div>
                </Form>
              </FormSection>
            </>
          )}
        </div>
      ) : (
        <div className="w-full flex flex-col items-center">
          <h1 className="text-2xl font-semibold mb-[20px]">
            Oops. Something went wrong.
          </h1>
          <span>
            You're using an invalid token! Please request a new token.
          </span>
          <div className="mt-[30px]">
            <Button
              type="submit"
              color="secondary"
              variant="outlined"
              className="min-w-[150px]"
              to={ROUTER.PASSWORD_RESET_EMAIL}
            >
              Request A New Token
            </Button>
          </div>
        </div>
      )}
    </Container>
  );
};

export const PasswordResetConfirmSuccess = () => (
  <div className="w-full flex flex-col items-center">
    <div className="w-full flex flex-col items-center">
      <div className="flex flex-row justify-center items-center mb-[10px] h-[100px]">
        <CheckIcon size={72} className="text-success" />
      </div>
      <h1 className="text-2xl font-semibold mb-[20px]">Password Updated!</h1>
      <span className="text-center">
        You've just successfully updated your password! Welcome back to
        Legitbook! 🥳
      </span>
    </div>

    <div className="mt-[30px]">
      <Button
        type="submit"
        color="secondary"
        variant="outlined"
        className="min-w-[150px]"
        to={ROUTER.LOGIN}
      >
        Go to Login
      </Button>
    </div>
  </div>
);
