import React from "react";

import { FormikContextType, FormikProvider } from "formik";

interface IFormProps extends React.FormHTMLAttributes<HTMLFormElement> {
  loading?: boolean;
  formik?: FormikContextType<any> | null;
  autoComplete?: "on" | "off";
}

export const Form: React.FC<IFormProps> = ({
  method,
  formik,
  children,
  className,
  loading,
  hidden,
  autoComplete,
  onSubmit,
}) => {
  const handleSubmit = (e, ...args) => {
    e.preventDefault();

    if (!onSubmit) return;

    onSubmit(e);
  };

  return formik ? (
    <FormikProvider value={formik}>
      <form
        method={method}
        className={`${className} relative w-full`}
        {...{ hidden, autoComplete }}
        onSubmit={handleSubmit}
      >
        {children}
      </form>
    </FormikProvider>
  ) : (
    <form
      method={method}
      className={`${className} relative w-full`}
      {...{ hidden, autoComplete }}
      onSubmit={handleSubmit}
    >
      {children}
    </form>
  );
};

Form.defaultProps = {
  method: "POST",
  loading: false,
  formik: null,
  hidden: false,
  autoComplete: "off",
  onSubmit: () => {},
};
