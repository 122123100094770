import React from "react";

import { CircularProgress } from "@mui/material";

interface ICircularProgressLoaderProps {
  value: number;
  size?: number;
  className?: string;
  hideCounter?: boolean;
}

export const CircularProgressLoader: React.FC<ICircularProgressLoaderProps> = ({
  value,
  size,
  className,
  hideCounter,
  ...props
}) => (
  <div
    className={`${className} relative flex flex-row justify-center items-center`}
    style={{ width: size, height: size }}
  >
    {!hideCounter && (
      <span className="absolute text-xxs text-inherit font-medium">
        {`${value || 0}%`}
      </span>
    )}
    <CircularProgress
      variant="determinate"
      value={value}
      className="w-full h-full text-inherit z-20"
      classes={{
        svg: "stroke-[2px]",
      }}
      //   classes={{
      //     colorPrimary: "text-secondary",
      //     colorSecondary: "text-red-500",
      //     circle: "text-red-900 stroke-red-200",
      //     svg: "text-red-200 stroke-red-200",
      //   }}
      thickness={2}
      {...props}
    />
    <div className="absolute border-[3px] border-secondary brightness-50 rounded-full w-full h-full z-10"></div>
  </div>
);

CircularProgressLoader.defaultProps = {
  className: "",
  size: 28,
  hideCounter: false,
};
