import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

interface ApiQueryParams {
  page?: number;
}

interface UseQueryParamsHook<T> {
  isQueryParams: boolean;
  queryParams: T;
  queryString: string;
  setQueryParams: (params: T | any) => void;
  setQueryParam: (key: string, value: string | number | boolean) => void;
  clearQueryParams: () => void;
}

export const useQueryParams = <T = any>(): UseQueryParamsHook<T> => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [queryParams, setQueryParams] = useState<T | any>({});
  const [queryString, setQueryString] = useState<string>("");

  const set = (params: T | any) => {
    setSearchParams({ ...queryParams, ...params });
  };

  const setQueryParam = (key: string, value: string | number | boolean) => {
    set({ ...queryParams, [key]: value });
  };

  const clear = () => {
    setSearchParams({});
  };

  const isQueryParams = !!Array.from(searchParams.keys())?.length;

  useEffect(() => {
    let queryParams = {};
    let queryRawParams: string[] = [];

    let separator = ";";

    let keys = Array.from(searchParams.keys());
    let values = Array.from(searchParams.values());

    const isNumber = (i: string) => !isNaN(Number(i));
    const isBoolean = (i: string) => ["true", "false"]?.some((x) => x === i);
    const isArray = (i: string) => i.startsWith("[]");

    for (const key of keys) {
      let i = keys.indexOf(key);
      let value = values[i];

      let result: string | number | boolean | string[] = value;

      if (isNumber(value)) {
        result = parseInt(value);
      }

      if (isBoolean(value)) {
        result = value === "true" ? true : false;
      }

      if (isArray(value)) {
        result = value?.replace("[]", "")?.split(separator) || [];
      }

      queryParams[key] = result;
      queryRawParams.push(`${key}=${value}`);
    }

    setQueryParams(queryParams);
    setQueryString(queryRawParams.join("&"));
  }, [searchParams]);

  return {
    isQueryParams,
    queryParams,
    queryString,
    setQueryParams: set,
    setQueryParam,
    clearQueryParams: clear,
  };
};
