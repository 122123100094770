import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";

import { useFormik } from "formik";
import * as Yup from "yup";

import { MdClose as CloseIcon } from "react-icons/md";

import {
  DigitalAssetType,
  ModalType,
  NotificationStatus,
} from "../../../shared";

import { useAppDispatch, useLoading, useModal, useToast } from "../../../hooks";
import { createDigitalAsset } from "../../../store";

import {
  Button,
  Container,
  Form,
  Modal,
  FileDragDropPicker,
  Loader,
  TextField,
  BasicSelect,
  IconButton,
  CircularProgressLoader,
  UploadProgressLoader,
} from "../../../components";
import { ROUTER } from "../../../routes";
import { FormValidator, toBase64 } from "../../../utils";
import { DIGITAL_ASSET_TYPES } from "../../../constants";

interface IDigitalPortfolioUploadForm {
  type?: DigitalAssetType;
  name?: string;
  description?: string;
  file?: string;
}

const validationSchema = Yup.object().shape({
  type: Yup.string().required("Type is a required field"),
  name: Yup.string()
    .max(500, "Exceeded maximum character length of 500")
    .required("Title is a required field"),
  description: Yup.string()
    .max(3000, "Exceeded maximum character length of 3000")
    .notRequired(),
});

export const DigitalAssetUploadModal = () => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const { toast } = useToast();
  const { isOpen, closeModal } = useModal({
    type: ModalType.DIGITAL_PORTFOLIO_UPLOAD,
  });

  const [loading, setLoading] = useLoading();
  const [processing, setProcessing] = useLoading();
  const [progress, setProgress] = useState(0);
  const [progressLabel, setProgressLabel] = useState<string>();

  const [file, setFile] = useState<File | null>(null);
  const [preview, setPreview] = useState<string | null>(null);
  const [assetTypeDefined, setAssetTypeDefined] = useState(false);

  const form = useFormik<IDigitalPortfolioUploadForm>({
    initialValues: { type: DigitalAssetType.Photo, name: "", description: "" },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      // Preparing data
      const form = new FormData();

      for (const key of Object.keys(values)) {
        if (!key) continue;

        form.append(key, values[key]);
      }

      if (file) {
        form.append("file", file);
      }

      setLoading(true);
      setProgressLabel("Uploading the file..");

      // Creating the digital asset
      const digitalAsset = await dispatch(
        createDigitalAsset(form, handleUploadProgress)
      );
      if (!digitalAsset?.id) {
        setLoading(false);
        closeModal();

        toast({
          status: NotificationStatus.Error,
          message: `Digital asset hasn't been upload to gallery.`,
        });

        return;
      }

      setLoading(false);
      closeModal();

      toast({
        status: NotificationStatus.Success,
        message: `Digital asset has been successfully uploaded to gallery.`,
      });
    },
  });

  const { values, setFieldValue, handleChange, handleSubmit } = form;

  const validator = new FormValidator(form);

  const handleFileChange = (file?: File) => {
    if (!file) return;

    setFile(file);
  };

  const handleFilePreview = async (file?: File) => {
    if (!file) return;

    // Image
    if (file?.type?.includes("image")) {
      const buffer = await toBase64(file);
      const preview = buffer?.toString();

      if (!preview) return;

      setPreview(preview);

      return;
    }

    // Video
    if (file?.type?.includes("video")) {
      setPreview(null);
      setAssetTypeDefined(true);
      setFieldValue("type", "video", true);

      return;
    }
  };

  const handleUploadProgress = (e) => {
    const { loaded, total } = e;

    const percent = Math.round((loaded * 100) / total);

    setProgress(() => percent);
  };

  const handleReset = () => {
    setFile(null);
    setPreview(null);
    setAssetTypeDefined(false);
  };

  useEffect(() => {
    if (!processing) return;

    setProgressLabel("Processing the file..");
  }, [processing]);

  useEffect(() => {
    if (progress >= 100 || false) {
      setProcessing(true);
    }
  }, [progress]);

  useEffect(() => {
    if (!file) return;

    handleFilePreview(file);
  }, [file]);

  return (
    <Modal
      title="Upload Digital Asset"
      description="Please select a digital asset you want to upload."
      className="!w-[480px]"
      open={isOpen}
      onClose={closeModal}
    >
      {loading ? (
        <UploadProgressLoader value={progress} label={progressLabel} />
      ) : (
        <Container>
          {!file ? (
            <Form onSubmit={handleSubmit}>
              <FileDragDropPicker
                className="h-[240px]"
                onChange={(files) => handleFileChange(files?.[0])}
              />
            </Form>
          ) : (
            <div className="flex flex-col">
              <div className="flex flex-row flex-wrap gap-x-[5px] gap-y-[5px] mt-[5px]">
                {!!preview ? (
                  <div className="relative flex flex-row justify-center items-start w-full h-auto aspect-video rounded-lg overflow-hidden border-[2px] border-primaryMid">
                    <img
                      className="absolute w-full h-auto select-none pointer-events-none"
                      src={preview}
                      alt="Preview"
                    />
                  </div>
                ) : file ? (
                  <div className="w-full h-[50px] flex flex-row justify-between items-center rounded-lg bg-primaryMid p-[15px] box-border">
                    <span>{file.name}</span>
                    <IconButton
                      icon={() => <CloseIcon size={16} />}
                      className="transition-all w-[20px] h-[20px] bg-primaryMid rounded-full p-[3px] box-border hover:bg-primaryDark"
                      onClick={handleReset}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>

              <div className="flex flex-col gap-y-[10px] mt-[20px]">
                {!assetTypeDefined && (
                  <BasicSelect
                    id="type"
                    label="Type"
                    className="!bg-primaryDark !text-white"
                    controlClassName="basis-1/2"
                    labelClassName="text-secondary"
                    options={DIGITAL_ASSET_TYPES}
                    value={values?.type}
                    setValue={(value) => setFieldValue("type", value, true)}
                    errorMessage={validator.isInputValid("type")}
                    required
                  />
                )}
                <TextField
                  id="name"
                  label="Title"
                  type="text"
                  variant="filled"
                  className="!bg-primaryDark !text-white"
                  controlClassName="basis-1/2"
                  labelClassName="text-secondary"
                  inputClassName="text-inherit"
                  required
                  value={values?.name}
                  errorMessage={validator.isInputValid("name")}
                  onChange={handleChange}
                />
                <TextField
                  id="description"
                  label="Description"
                  type="text"
                  variant="filled"
                  className="!bg-primaryDark !text-white"
                  controlClassName="basis-1/2"
                  labelClassName="text-secondary"
                  inputClassName="text-inherit"
                  minRows={2}
                  maxRows={5}
                  multiline
                  value={values?.description}
                  errorMessage={validator.isInputValid("description")}
                  onChange={handleChange}
                />
              </div>

              <div className="flex flex-row justify-end items-center gap-x-[10px] mt-[20px]">
                <Button
                  color="secondary"
                  disabled={loading}
                  onClick={handleReset}
                >
                  Change
                </Button>
                <Button
                  color="success"
                  className="min-w-[150px]"
                  loading={loading}
                  onClick={() => handleSubmit()}
                >
                  Upload
                </Button>
              </div>
            </div>
          )}
        </Container>
      )}
    </Modal>
  );
};
