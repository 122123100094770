import React from "react";

import { lazyload } from "../../utils";
import { MainExtendedLayout } from "../../layouts";

const { UserLicensesSoldContainer, LicenseSidebar } = lazyload(
  () => import("../../features")
);

export const UserLicensesSoldPage = () => (
  <MainExtendedLayout leftSidebar={LicenseSidebar}>
    <UserLicensesSoldContainer />
  </MainExtendedLayout>
);
