import React, { useEffect } from "react";

import { VerificationStatus } from "../../shared";
import { useAppDispatch, useAppSelector, useEmployee } from "../../hooks";
import { CardList } from "../../components";
import { UserVerificationCard } from "..";
import { getVerificationRequests } from "../../store";

export const BusinessVerificationListContainer = () => {
  const dispatch = useAppDispatch();

  const {
    verifications: { data, isLoading },
  } = useAppSelector((state) => state.verification);
  const {
    account: { data: account },
  } = useAppSelector((state) => state.account);

  const { company, checkEmployment } = useEmployee();

  const companyId = company?.id;

  useEffect(() => {
    if (!companyId) return;

    dispatch(
      getVerificationRequests({
        where: { companyId, status: VerificationStatus.Pending },
      })
    );
  }, [companyId]);

  return (
    <CardList loading={isLoading}>
      {!!data?.length ? (
        data?.map((request) => (
          <UserVerificationCard key={request?.id} {...request} />
        ))
      ) : (
        <span>No verifications found.</span>
      )}
    </CardList>
  );
};
