import React from "react";
import { useNavigate } from "react-router";

import { useFormik } from "formik";
import * as Yup from "yup";

import {
  ModalType,
  NotificationStatus,
  NotificationType,
  NotificationPriority,
  IProfile,
} from "../../../shared";

import {
  useAppDispatch,
  useLoading,
  useModal,
  useAccount,
  useToast,
  useAppSelector,
} from "../../../hooks";
import {
  createInterview,
  removeChat,
  removeJob,
  setInterviewToJobApplication,
} from "../../../store";

import {
  Avatar,
  BasicDatePicker,
  BasicTimePicker,
  Button,
  Form,
  FormSection,
  Modal,
  TextField,
} from "../../../components";
import { date, FormValidator } from "../../../utils";

interface IInterviewCreateForm {
  title?: string;
  startDate?: Date;
  startTime?: Date;
  finishDate?: Date;
  finishTime?: Date;
  startedAt?: Date;
  finishedAt?: Date;
}

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .max(100, "Exceeded maximum character length of 100")
    .required("Job Title is a required field"),
  startDate: Yup.date()
    .nullable()
    .typeError("Invalid date format")
    .min(new Date(), "Start Date can't be the past")
    .required("Date is a required field"),
  startTime: Yup.date()
    .nullable()
    .typeError("Invalid date format")
    .max(Yup.ref("finishTime"), "Start can't be more than End Time")
    .required("Start Time is a required field"),
  finishTime: Yup.date()
    .nullable()
    .typeError("Invalid date format")
    .min(Yup.ref("startTime"), "End Time can't be less than Start Time")
    .required("End Time is a required field"),
});

export const InterviewScheduleModal = () => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const { modal, isOpen, closeModal } = useModal({
    type: ModalType.INTERVIEW_SCHEDULE_MODAL,
  });

  const { toast } = useToast();
  const [loading, setLoading] = useLoading();
  const { activeProfile } = useAccount();

  const {
    profile: { data: profile },
  } = useAppSelector((state) => state.profile);

  const {
    props: { startedAt, resume, jobApplication },
  } = modal;

  const hostProfile = activeProfile;
  const canditateProfile =
    resume?.profile || jobApplication?.createdBy || profile;
  const candidateId = canditateProfile?.id;
  const jobApplicationId = jobApplication?.id;

  const form = useFormik<IInterviewCreateForm>({
    initialValues: {
      title: "",
      startDate: startedAt || new Date(),
      startTime: new Date(),
      finishTime: date(startedAt || new Date())
        .add(1, "h")
        .toDate(),
    },
    validationSchema,
    onSubmit: async (values) => {
      const { title } = values;

      // Parsing Time
      const startedAt = values?.startDate
        ? new Date(values.startDate)
        : new Date();

      const finishedAt = values?.startDate
        ? new Date(values.startDate)
        : new Date();

      const startTime = values?.startTime
        ? new Date(values.startTime.toString())
        : new Date();

      const finishTime = values?.finishTime
        ? new Date(values.finishTime.toString())
        : new Date();

      startedAt.setHours(startTime.getHours());
      startedAt.setMinutes(startTime.getMinutes());

      finishedAt.setHours(finishTime.getHours());
      finishedAt.setMinutes(finishTime.getMinutes());

      const data = {
        title,
        startedAt,
        finishedAt,
        candidateId,
        jobApplicationId,
      };

      setLoading(true);

      // Creating Interview
      const interview = await dispatch(createInterview(data));
      if (!interview?.id) {
        handleCancel();

        toast({
          status: NotificationStatus.Error,
          message: "Interview Not Scheduled!",
        });

        return setLoading(false);
      }

      // Setting Interview to Job Application
      dispatch(setInterviewToJobApplication(interview));

      closeModal();
      setLoading(false);
      resetForm();

      toast({
        status: NotificationStatus.Success,
        message: "Interview Scheduled!",
      });
    },
  });

  const validator = new FormValidator<IInterviewCreateForm>(form);

  const {
    values,
    errors,
    resetForm,
    setFieldValue,
    handleChange,
    handleSubmit,
  } = form;

  const handleClose = () => {
    closeModal();
  };

  const handleCancel = () => {
    handleClose();
    resetForm();
  };

  return (
    <Modal
      title="Schedule Interview"
      description="Schedule interview by filling up the information below."
      open={isOpen}
      onClose={handleClose}
    >
      <Form onSubmit={handleSubmit}>
        <div className="w-full flex flex-col text-white gap-y-[10px]">
          <div className="flex flex-row justify-start gap-x-[10px] gap-y-[10px] md:flex-row xxs:flex-col">
            <TextField
              id="title"
              label="Job Title"
              type="text"
              variant="filled"
              className="!bg-primaryDark !text-white"
              labelClassName="text-secondary"
              inputClassName="text-inherit"
              value={values?.title}
              errorMessage={validator.isInputValid("title")}
              autoFocus
              onChange={handleChange}
            />
          </div>

          <div className="flex text-white h-auto gap-x-[10px] gap-y-[10px] md:flex-row xxs:flex-col">
            <BasicDatePicker
              id="startDate"
              label="Date"
              value={values?.startDate}
              errorMessage={validator.isInputValid("startDate")}
              className="!bg-primaryDark !text-white"
              disablePast
              onChange={(value) => setFieldValue("startDate", value, true)}
            />
            <div className="flex flex-row gap-[10px]">
              <div className="xs:basis-full md:basis-6/12">
                <BasicTimePicker
                  id="startTime"
                  label="Start Time"
                  value={values?.startTime}
                  errorMessage={validator.isInputValid("startTime")}
                  className="!bg-primaryDark !text-white"
                  onChange={(value) => setFieldValue("startTime", value, true)}
                />
              </div>
              <div className="xs:basis-full md:basis-6/12">
                <BasicTimePicker
                  id="finishTime"
                  label="End Time"
                  value={values?.finishTime}
                  errorMessage={validator.isInputValid("finishTime")}
                  className="!bg-primaryDark !text-white"
                  onChange={(value) => setFieldValue("finishTime", value, true)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="mt-[30px]">
          <span className="text-secondary">Participants</span>
          <div className="flex flex-col gap-[10px] mt-[10px]">
            {[
              { ...hostProfile, role: "host" },
              { ...canditateProfile, role: "candidate" },
            ]?.map((participant) => (
              <div className="flex flex-row justify-between items-center gap-[10px] w-full bg-primaryDark rounded-lg p-[10px] ">
                <div className="flex flex-row gap-[8px]">
                  <Avatar size={40} src={participant?.avatar} />
                  <div className="flex flex-col justify-center">
                    <span className="text-white text-xs font-semibold capitalize">
                      {participant?.firstName} {participant?.lastName}
                    </span>
                    <span className="text-secondary text-xs font-medium capitalize">
                      {participant?.role}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="flex flex-row justify-end items-center mt-[15px] gap-x-[10px] gap-y-[10px] md:flex-row xxs:flex-col-reverse">
          <Button
            color="secondary"
            variant="outlined"
            className="md:w-auto xxs:w-full"
            onClick={handleCancel}
          >
            Discard
          </Button>
          <Button
            type="submit"
            color="success"
            variant="outlined"
            className="min-w-[125px] md:w-auto xxs:w-full"
            loading={loading}
          >
            Schedule
          </Button>
        </div>
      </Form>
    </Modal>
  );
};
