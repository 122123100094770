import React, { useEffect } from "react";
import { redirect, useNavigate } from "react-router";

import { ModalType, NotificationStatus } from "../../../shared";
import {
  useAppDispatch,
  useAppSelector,
  useLoading,
  useModal,
  useToast,
  useURL,
} from "../../../hooks";
import { ROUTER } from "../../../routes";
import {
  removeDigitalAssetsFromResume,
  getDigitalAssetById,
} from "../../../store";
import {
  Button,
  Container,
  ImageResponsiveContainer,
  Modal,
} from "../../../components";

export const ResumeDigitalAssetPreviewModal = () => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const { modal, isOpen, closeModal } = useModal({
    type: ModalType.RESUME_DIGITAL_ASSET_PREVIEW,
  });

  const [loading, setLoading] = useLoading();
  const { toast } = useToast();

  const {
    digitalAsset: { data: digitalAsset, isLoading: digitalAssetLoading },
  } = useAppSelector((state) => state.digitalPortfolio);

  const { props } = modal;
  const { id, resumeId } = props || {};
  const { name, description, src, previewSrc, createdById } =
    digitalAsset || {};

  const handleDetails = () => {
    if (!id || !createdById) return;

    redirect(ROUTER.USER_DIGITAL_ASSET_DETAIL(createdById, id));
    closeModal();
  };

  useEffect(() => {
    if (!id) return;

    dispatch(getDigitalAssetById(id));
  }, [id]);

  return (
    <Modal title={name} open={isOpen} className="!w-full">
      <Container loading={digitalAssetLoading}>
        <div className="text-sm text-secondary">
          <ImageResponsiveContainer src={src} />
          <div className="flex flex-row justify-end items-center mt-[20px]">
            <div className="flex flex-row">
              <Button
                variant="outlined"
                color="secondary"
                className="min-w-[150px]"
                onClick={handleDetails}
              >
                Details
              </Button>
            </div>
          </div>
        </div>
      </Container>
    </Modal>
  );
};
