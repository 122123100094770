import React from "react";

import { ModalType, NotificationStatus } from "../../../shared";

import { Button, Container, Modal } from "../../../components";
import { useAppDispatch, useLoading, useModal, useToast } from "../../../hooks";
import { removePostById } from "../../../store";

export const PostCreateModal = () => {
  const dispatch = useAppDispatch();

  const { modal, isOpen, closeModal } = useModal({
    type: ModalType.POST_CREATE,
  });

  const { toast } = useToast();
  const [loading, setLoading] = useLoading();

  const { props } = modal;
  const { id, onCreate } = props || {};

  const handleSubmit = async () => {};

  return (
    <Modal title="Create Post" open={isOpen} onClose={closeModal}>
      <Container></Container>
    </Modal>
  );
};
