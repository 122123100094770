import React, { useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { FiUpload as UploadIcon, FiUser as UserIcon } from "react-icons/fi";

import { Logo, IconButton, Form, Container } from "../../../components";

import { ROUTER } from "../../../routes";
import { checkPassport, scanPassport } from "../../../store";
import {
  useAppDispatch,
  useLoading,
  useModal,
  useToast,
  useURL,
} from "../../../hooks";
import { RegisterContext } from "../../../context";
import {
  NotificationStatus,
  NotificationType,
  NotificationPriority,
  ModalType,
} from "../../../shared";

export const RegisterStart = () => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const { registerState, setRegisterState } = useContext(RegisterContext);

  const [loading, setLoading] = useLoading();
  const [URL, setURL] = useURL();

  const { toast } = useToast();
  const { setModal } = useModal();

  const filePickerRef = useRef<HTMLInputElement>(null);

  const handleAvailableCountriesModal = () => {
    setModal({ type: ModalType.FAQ_AVAILABLE_COUNTRIES });
  };

  const handleTermsDetailsModal = () => {
    setModal({ type: ModalType.TERMS_AND_CONDITIONS });
  };

  const handleFilePicker = () => {
    if (!filePickerRef.current) return;

    // @ts-ignore
    filePickerRef.current.value = null;
    filePickerRef.current?.click();
  };

  const handleUploadPassport = async (e) => {
    const file = e.target?.files?.[0];
    if (!file) return;

    setLoading(true);

    const form = new FormData();

    form.append("document", file);

    // Scanning Passport
    const passport = await dispatch(scanPassport(form));
    if (passport.error) {
      setLoading(false);

      const { message } = passport.error;

      return toast({ status: NotificationStatus.Error, message });
    }

    // Checking Passport
    const checkedPassport = await dispatch(checkPassport(passport.data));
    if (checkedPassport.error) {
      setLoading(false);

      const { message } = checkedPassport.error;

      return toast({ status: NotificationStatus.Error, message });
    }

    setLoading(false);
    setRegisterState({ ...registerState, passport: passport.data });
    setURL({ path: ROUTER.REGISTER_PASSPORT_DATA });
  };

  return (
    <Container className="w-full min-h-[250px] py-[50px]">
      <div className="w-full max-w-[640px] text-white">
        <div className="flex flex-col items-center w-full text-center mb-[50px] text-2xl font-medium">
          <Logo to={ROUTER.ROOT} className="text-2xl" />
          <div className="max-w-[400px] mt-[35px] text-sm">
            <span className="font-thin">
              To register, please upload a photo of any government form of ID or
              passport from up to{" "}
              <span
                className="transition-all cursor-pointer font-medium hover:text-white"
                onClick={handleAvailableCountriesModal}
              >
                170 supported countries
              </span>
              . Alternatively, if you already have an account, use the login
              button.
            </span>
          </div>
        </div>

        <div className="flex flex-row justify-center items-center gap-[15px]">
          <input
            ref={filePickerRef}
            id="upload"
            name="passport"
            type="file"
            accept=".jpg, .jpeg, .png, .heic"
            hidden
            onChange={handleUploadPassport}
          />
          <IconButton
            icon={UploadIcon}
            iconProps={{ size: 24 }}
            className="w-[125px] h-[125px] text-secondary bg-primaryMid rounded-full hover:bg-primaryBlue hover:text-white"
            disabledClassName="hover:bg-primaryMid hover:text-secondary"
            loading={loading}
            onClick={handleFilePicker}
          />
          <IconButton
            to={ROUTER.LOGIN}
            icon={UserIcon}
            iconProps={{ size: 24 }}
            className="flex flex-row justify-center items-center w-[125px] h-[125px] text-secondary bg-primaryMid rounded-full hover:bg-primaryBlue hover:text-white"
          />
        </div>

        <div className="w-full flex flex-row justify-center mt-[75px]">
          <div className="w-full max-w-[280px] flex flex-row justify-center text-secondary text-sm font-thin">
            <p className="text-center">
              By registering for Legitbook you agree
              <br /> to our{" "}
              <span
                className="transition-all cursor-pointer font-medium hover:text-white"
                onClick={handleTermsDetailsModal}
              >
                terms and conditions
              </span>
            </p>
          </div>
        </div>
      </div>
    </Container>
  );
};
