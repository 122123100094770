import React from "react";

import {
  MdVideocam as VideoCameraIcon,
  MdVideocamOff as VideoCameraOffIcon,
  MdOutlineMic as MicIcon,
  MdOutlineMicOff as MicOffIcon,
  MdOutlineMonitor as MonitorIcon,
} from "react-icons/md";

import { ISidebar } from "@types";

import {
  SidebarSection,
  Sidebar,
  SidebarContent,
  Button,
  Avatar,
  IconButton,
} from "../../../components";
import { ROUTER } from "../../../routes";
import { useAppSelector } from "../../../hooks";

export const VideoCallLeftSidebar: React.FC<ISidebar> = (props) => {
  const {
    videoCall: { data: videoCall, isLoading: videoCallLoading },
  } = useAppSelector((state) => state.videoCall);

  return (
    <Sidebar {...props}>
      <SidebarContent>
        <SidebarSection
          heading={`Participants (${videoCall?.participants?.length || 0})`}
          className="border-none"
        >
          <div className="flex flex-col">
            {videoCall?.participants?.map((participant) => (
              <div
                key={participant?.id}
                className="transition-all h-[50px] flex flex-row justify-between items-center"
              >
                <div className="flex flex-row justify-start items-center gap-[10px]">
                  <Avatar size={30} src={participant?.avatar} />
                  <div className="flex flex-col justify-center items-start">
                    <span className="text-white text-sm font-medium">
                      {participant?.firstName}
                    </span>
                  </div>
                </div>
                <div className="flex flex-row items-center gap-[5px]">
                  <IconButton
                    className="text-white"
                    icon={false ? VideoCameraOffIcon : VideoCameraIcon}
                    iconProps={{
                      size: 18,
                    }}
                    // onClick={handleVideoToggle}
                  />

                  <IconButton
                    className="text-white"
                    icon={false ? MicOffIcon : MicIcon}
                    iconProps={{
                      size: 18,
                    }}
                    // onClick={handleAudioToggle}
                  />
                </div>
              </div>
            ))}
          </div>
        </SidebarSection>
      </SidebarContent>
    </Sidebar>
  );
};
