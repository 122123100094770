import React, { useEffect } from "react";

import { CardList, Container, PageHeader } from "../../components";
import { WebsocketEvents } from "../../shared";
import {
  useAppDispatch,
  useAppSelector,
  useLoading,
  useAccount,
} from "../../hooks";
import { EventService } from "../../services";
import { setNotifications } from "../../store";
import { date } from "../../utils";
import { NotificationCard } from "..";

export const NotificationContainer = () => {
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useLoading();

  const {
    notifications: {
      data: notifications,
      results: notificationCount,
      refreshedAt,
    },
  } = useAppSelector((state) => state.notification);

  useEffect(() => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => {
      EventService.send({
        event: WebsocketEvents.VIEW_USER_NOTIFICATIONS,
      });
    };
  }, []);

  return (
    <Container>
      <PageHeader back={false}>
        Notifications ({notificationCount || 0})
      </PageHeader>

      <CardList loading={loading} className="mt-[10px]">
        {notifications?.map((notification) => (
          <NotificationCard key={notification.id} {...notification} />
        ))}
      </CardList>
    </Container>
  );
};
