import { AxiosRequestConfig } from "axios";
import { ApiService } from "../../../services";
import { AppDispatch } from "../../store";

import { digitalPortfolioSlice } from "./digital-portfolio.slice";

export const setDigitalAsset =
  (payload: any) => async (dispatch: AppDispatch) => {
    dispatch(digitalPortfolioSlice.actions.setDigitalAsset(payload));
  };

export const getDigitalPortfolioByUserId =
  (userId: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(
        digitalPortfolioSlice.actions.getDigitalPortfolioByUserIdRequest()
      );

      const response = await ApiService.get(
        `profiles/${userId}/digital-portfolio`
      );
      const { data, results } = response.data;

      dispatch(
        digitalPortfolioSlice.actions.getDigitalPortfolioByUserIdSuccess({
          data,
          results,
        })
      );

      return data;
    } catch (e: any) {
      dispatch(
        digitalPortfolioSlice.actions.getDigitalPortfolioByUserIdError(
          e.response?.data?.error
        )
      );
    }
  };

export const getDigitalAssets =
  (options?: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(digitalPortfolioSlice.actions.getDigitalAssetsRequest());

      const response = await ApiService.post(`digital-assets/query`, options);
      const { data, results } = response.data;

      dispatch(
        digitalPortfolioSlice.actions.getDigitalAssetsSuccess({ data, results })
      );

      return data;
    } catch (e: any) {
      const error = e.response?.data?.error;

      dispatch(digitalPortfolioSlice.actions.getDigitalAssetsError(error));
    }
  };

export const getUserLicensedDigitalAssets =
  (options?: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(digitalPortfolioSlice.actions.getDigitalAssetsRequest());

      const response = await ApiService.get(
        `digital-assets/?page=1&licensed=true`,
        options
      );
      const { data, results } = response.data;

      dispatch(
        digitalPortfolioSlice.actions.getDigitalAssetsSuccess({ data, results })
      );

      return data;
    } catch (e: any) {
      const error = e.response?.data?.error;

      dispatch(digitalPortfolioSlice.actions.getDigitalAssetsError(error));
    }
  };

export const getDigitalAssetById =
  (fileId: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(digitalPortfolioSlice.actions.getDigitalAssetRequest());

      const response = await ApiService.get(`digital-assets/${fileId}`);
      const { data, results } = response.data;

      dispatch(
        digitalPortfolioSlice.actions.getDigitalAssetSuccess({
          data,
          results,
        })
      );

      return data;
    } catch (e: any) {
      const error = e.response?.data?.message;

      dispatch(digitalPortfolioSlice.actions.getDigitalAssetError(error));
    }
  };

export const createDigitalAsset =
  (
    payload: any,
    onUploadProgress: (e: { loaded: number; total: number }) => void
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(digitalPortfolioSlice.actions.createDigitalAssetRequest());

      const response = await ApiService.post(`digital-assets`, payload, {
        timeout: 15 * 60 * 1000,
        onUploadProgress,
      });
      const { data, results } = response.data;

      dispatch(
        digitalPortfolioSlice.actions.createDigitalAssetSuccess({
          data,
          results,
        })
      );

      return data;
    } catch (e: any) {
      console.log({ e });

      if (!!e?.response)
        dispatch(
          digitalPortfolioSlice.actions.createDigitalAssetError(
            e.response?.data?.error
          )
        );

      return e;
    }
  };

export const updateDigitalAssetById =
  (id: string, payload: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(digitalPortfolioSlice.actions.updateDigitalAssetRequest());

      const response = await ApiService.put(`digital-assets/${id}`, payload);
      const { data } = response.data;

      dispatch(digitalPortfolioSlice.actions.updateDigitalAssetSuccess(data));

      await dispatch(getDigitalAssetById(id));

      return data;
    } catch (e: any) {
      const error = e.response?.data?.error;

      dispatch(digitalPortfolioSlice.actions.updateDigitalAssetError(error));
    }
  };

export const removeDigitalAssetById =
  (id: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(digitalPortfolioSlice.actions.removeDigitalAssetByIdRequest());

      const response = await ApiService.delete(`digital-assets/${id}`);
      const data = response.data.data;

      dispatch(
        digitalPortfolioSlice.actions.removeDigitalAssetByIdSuccess(data)
      );

      return { id };
    } catch (e: any) {
      dispatch(
        digitalPortfolioSlice.actions.removeDigitalAssetByIdError(
          e.response?.data?.error
        )
      );
    }
  };

export const createDigitalAssetLicense =
  (id: string, payload: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(
        digitalPortfolioSlice.actions.createDigitalAssetLicenseRequest()
      );

      const response = await ApiService.post(
        `digital-assets/${id}/licenses`,
        payload
      );
      const { data } = response.data;

      // Updating the digital asset
      await dispatch(
        setDigitalAsset({ isLicense: true, license: { id: data?.id } })
      );

      await dispatch(
        digitalPortfolioSlice.actions.createDigitalAssetLicenseSuccess(data)
      );

      return data;
    } catch (e: any) {
      dispatch(
        digitalPortfolioSlice.actions.createDigitalAssetLicenseError(
          e.response?.data?.error
        )
      );
    }
  };

export const updateDigitalAssetLicense =
  (id: string, payload: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(
        digitalPortfolioSlice.actions.updateDigitalAssetLicenseRequest()
      );

      await ApiService.put(`digital-assets/${id}/licenses`, payload);

      dispatch(
        digitalPortfolioSlice.actions.updateDigitalAssetLicenseSuccess({
          ...payload,
          isLicense: true,
          isPublic: true,
        })
      );

      return { id };
    } catch (e: any) {
      dispatch(
        digitalPortfolioSlice.actions.updateDigitalAssetLicenseError(
          e.response?.data?.error
        )
      );
    }
  };

export const enableDigitalAssetLicensing =
  (digitalAssetId: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(digitalPortfolioSlice.actions.updateDigitalAssetRequest());

      await ApiService.put(`digital-assets/${digitalAssetId}/licenses/enable`);

      dispatch(
        digitalPortfolioSlice.actions.updateDigitalAssetSuccess({
          isLicense: true,
        })
      );

      return true;
    } catch (e: any) {
      const error = e.response?.data?.error;

      dispatch(digitalPortfolioSlice.actions.updateDigitalAssetError(error));

      return false;
    }
  };

export const disableDigitalAssetLicensing =
  (digitalAssetId: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(digitalPortfolioSlice.actions.updateDigitalAssetRequest());

      await ApiService.put(`digital-assets/${digitalAssetId}/licenses/disable`);

      dispatch(
        digitalPortfolioSlice.actions.updateDigitalAssetSuccess({
          isLicense: false,
        })
      );

      return true;
    } catch (e: any) {
      const error = e.response?.data?.error;

      dispatch(digitalPortfolioSlice.actions.updateDigitalAssetError(error));

      return false;
    }
  };
