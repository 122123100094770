import React from "react";
import { useNavigate } from "react-router";

import { ModalType } from "../../../shared";

import { useAppDispatch, useModal } from "../../../hooks";

import { Modal } from "../../../components";
import { CompanyCommentCreateForm } from "../../../features";

export const CompanyCommentCreateModal = () => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const { modal, isOpen, closeModal } = useModal({
    type: ModalType.COMPANY_COMMENT_CREATE,
  });

  const {
    props: { jobApplicationId, userId, companyId },
  } = modal;

  const handleClose = () => {
    closeModal();
  };

  return (
    <Modal
      title="Add Note"
      description="Create a note by filling up the information below."
      open={isOpen}
      onClose={handleClose}
    >
      <CompanyCommentCreateForm
        {...{ jobApplicationId, userId, companyId }}
        onCreate={handleClose}
        onClose={handleClose}
      />
    </Modal>
  );
};
