import React, { useEffect } from "react";
import { useParams } from "react-router";

import {
  Container,
  ImageResponsiveContainer,
  PageHeader,
} from "../../components";
import { PostCardUserInfoBar } from "./../../features";

import { useAppDispatch, useAppSelector, useModal } from "../../hooks";
import { ROUTER } from "../../routes";
import { getGalleryFileById } from "../../store";
import { date } from "../../utils";
import { ModalType } from "../../shared";

export const UserGalleryFileDetailContainer = () => {
  const dispatch = useAppDispatch();

  const { userId, itemId: fileId } = useParams();
  const { setModal } = useModal();

  const {
    galleryFile: { data: galleryFile, isLoading: galleryFileLoading },
  } = useAppSelector((state) => state.gallery);

  const { name, description, src, createdBy, createdById, createdAt } =
    galleryFile || {};

  const handleEdit = () => {
    setModal({
      type: ModalType.GALLERY_FILE_EDIT,
      props: { ...galleryFile },
    });
  };

  const handleDelete = () => {
    setModal({
      type: ModalType.GALLERY_FILE_DELETE,
      props: { ...galleryFile },
    });
  };

  useEffect(() => {
    if (!fileId) return;

    dispatch(getGalleryFileById(fileId));
  }, [fileId]);

  return (
    <Container loading={galleryFileLoading}>
      <PageHeader backTo={ROUTER.USER_GALLERY(userId)}>{name}</PageHeader>
      <Container className="bg-primaryLight rounded-lg box-border p-[15px]">
        <ImageResponsiveContainer src={src} />
        {createdById && (
          <div className="mt-[20px]">
            <PostCardUserInfoBar
              {...{
                id: "",
                createdBy,
                createdById,
                createdAt,
                onEdit: handleEdit,
                onDelete: handleDelete,
              }}
            />
          </div>
        )}

        <div className="w-full mt-[20px] text-white text-sm">
          <p>{description}</p>
        </div>
      </Container>
    </Container>
  );
};
