import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router";

import { useFormik } from "formik";
import * as Yup from "yup";
import { FiArrowLeft as ArrowLeftIcon } from "react-icons/fi";

import {
  Button,
  Container,
  Form,
  TextField,
  IconButton,
  BasicSelect,
  PageHeader,
  TagInput,
  Checkbox,
} from "../../components";
import { createJob, getJobById, getJobs, updateJob } from "../../store";
import {
  useAppDispatch,
  useAppSelector,
  useLoading,
  useToast,
} from "../../hooks";
import { ROUTER } from "../../routes";
import {
  EDUCATION_DEGREES,
  EMPLOYMENT_TYPES,
  JOB_LEVELS,
  JOB_TYPES,
  TIMEZONES,
} from "../../constants";
import { addLineBreaks, FormValidator } from "../../utils";
import { NotificationStatus } from "../../shared";

interface IJobEditForm {
  title?: string;
  type?: string;
  skillLevel?: string;
  positions?: string | number;
  skills?: any[];
  timezone?: string;
  yearsOfExperience?: number;
  degree?: string;
  country?: string;
  state?: string;
  city?: string;
  minSalary?: number | string;
  maxSalary?: number | string;
  salaryDependsOnExperience?: boolean;
  employmentType?: string;
  requirements?: string;
  description?: string;
  desiredSkills?: string;
}

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .max(70, "Exceeded maximum character length of 70")
    .required("Job Title is a required field"),
  type: Yup.string().required("Job Type is a required field"),
  skillLevel: Yup.string().required("Skill level is a required field"),
  positions: Yup.number()
    .typeError("Invalid format")
    .moreThan(0, "No. of Positions is a required field")
    .lessThan(55, "No. of Positions limit set to 99")
    .required("No. of Positions is a required field"),
  minSalary: Yup.number().when("salaryDependsOnExperience", {
    is: false,
    then: Yup.number()
      .typeError("Invalid format")
      .moreThan(0, "Min Salary is a required field")
      .lessThan(900000, "Min Salary limit set to 900000")
      .lessThan(Yup.ref("maxSalary"), "Min Salary must be < Max Salary")
      .required("Min Salary a required field"),
    otherwise: Yup.number().typeError("Invalid format").notRequired(),
  }),
  maxSalary: Yup.number().when("salaryDependsOnExperience", {
    is: false,
    then: Yup.number()
      .typeError("Invalid format")
      .moreThan(0, "Max Salary is a required field")
      .lessThan(900000, "Max Salary limit set to 900000")
      .moreThan(Yup.ref("minSalary"), "Max Salary must be > Min Salary")
      .required("Max Salary a required field"),
    otherwise: Yup.number().typeError("Invalid format").notRequired(),
  }),
  salaryDependsOnExperience: Yup.boolean()
    .typeError("Invalid format")
    .notRequired(),
  skills: Yup.array()
    .min(1, "Skills must contain 1 at least")
    .required("Skills is a required field"),
  timezone: Yup.string().required("Timezone is a required field"),
  yearsOfExperience: Yup.number()
    .typeError("Invalid format")
    .moreThan(0, "Years of Experience is a required field")
    .lessThan(99, "Are you immortal?")
    .required("Years of Experience is a required field"),
  degree: Yup.string().required("Education Degree is a required field"),
  country: Yup.string()
    .max(100, "Exceeded maximum character length of 100")
    .required("Country is a required field"),
  state: Yup.string()
    .max(100, "Exceeded maximum character length of 100")
    .notRequired(),
  city: Yup.string()
    .max(100, "Exceeded maximum character length of 100")
    .required("City is a required field"),
  employmentType: Yup.string().required("Employment Type is a required field"),
  requirements: Yup.string()
    .max(3000, "Exceeded maximum character length of 3000")
    .notRequired(),
  description: Yup.string()
    .max(3000, "Exceeded maximum character length of 3000")
    .required("Description is a required field"),
  desiredSkills: Yup.string()
    .max(1000, "Exceeded maximum character length of 1000")
    .notRequired(),
});

const initialValues: IJobEditForm = {
  title: undefined,
  type: undefined,
  employmentType: undefined,
  skillLevel: undefined,
  positions: undefined,
  skills: [],
  timezone: "",
  yearsOfExperience: undefined,
  degree: undefined,
  country: undefined,
  state: undefined,
  city: undefined,
  minSalary: undefined,
  maxSalary: undefined,
  salaryDependsOnExperience: false,
  requirements: undefined,
  description: undefined,
  desiredSkills: undefined,
};

export const JobEditContainer = () => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();
  const { id } = useParams<{ id: string }>();

  const [loading, setLoading] = useLoading();
  const [updating, setUpdating] = useLoading();
  const { toast } = useToast();

  const {
    account: { data: account },
  } = useAppSelector((state) => state.account);
  const {
    job: { data: job, isLoading: jobLoading },
  } = useAppSelector((state) => state.job);

  const profile = account?.profile;
  const profileId = profile?.id;

  const form = useFormik<IJobEditForm>({
    validationSchema,
    initialValues,
    onSubmit: async (values) => {
      const {
        description,
        requirements,
        desiredSkills,
        skills,
        positions,
        yearsOfExperience,
        minSalary,
        maxSalary,
      } = values || {};

      const data = {
        ...values,
        description: addLineBreaks(description || ""),
        requirements: addLineBreaks(requirements || ""),
        desiredSkills: addLineBreaks(desiredSkills || ""),
        skills: skills?.map((name) => ({ name })),
        positions: Number(positions || 0),
        yearsOfExperience: Number(yearsOfExperience || 0),
        minSalary: Number(minSalary || 0),
        maxSalary: Number(maxSalary || 0),
        createdById: profileId,
      };

      setUpdating(true);

      const updated = id ? await dispatch(updateJob(id, data)) : null;
      if (!updated) {
        setUpdating(false);
        return toast({
          status: NotificationStatus.Error,
          message: "Job Not Updated!",
        });
      }

      setUpdating(false);
      toast({ status: NotificationStatus.Success, message: "Job Updated!" });
      redirect(ROUTER.JOB_DETAIL(id));
    },
  });

  const {
    values,
    errors,
    touched,
    setValues,
    setTouched,
    resetForm,
    setFieldValue,
    handleChange,
    handleSubmit,
  } = form;

  const validator = new FormValidator<IJobEditForm>(form);

  const handleSaveDraft = async (e) => {
    setFieldValue("isDraft", true, true);
    handleSubmit();
  };

  const handleSave = async (e) => {
    setFieldValue("isDraft", false, true);
    handleSubmit();
  };

  const handleCancel = () => {
    redirect(ROUTER.JOB_DETAIL(id));
    resetForm();
  };

  useEffect(() => {
    const { skills } = job;

    setValues({ ...job, skills: skills?.map(({ name }) => name) });
  }, [job]);

  useEffect(() => {
    if (!id) return;

    dispatch(getJobById(id));
  }, [id]);

  return (
    <Container>
      <PageHeader>Edit Job</PageHeader>
      <Container
        className="w-full flex flex-col gap-y-5 pt-5 pb-5"
        loading={jobLoading}
      >
        <div className="flex flex-row justify-between items-center gap-x-[10px]">
          <h1 className="capitalize text-lg font-semibold text-white">
            {job?.title}
          </h1>
        </div>
        <Form onSubmit={handleSubmit}>
          <div className="flex flex-col gap-y-5">
            <TextField
              id="title"
              label="Title"
              type="text"
              variant="filled"
              className="bg-primaryLight text-white border border-solid border-primaryMid"
              labelClassName="text-secondary"
              value={values?.title}
              errorMessage={validator.isInputValid("title")}
              onChange={handleChange}
            />
            <TagInput
              id="skills"
              label="Experience Required"
              type="text"
              variant="filled"
              labelClassName="text-secondary"
              value={values.skills}
              errorMessage={validator.isInputValid("skills")}
              onChange={(value) => setFieldValue("skills", value, true)}
            />

            <div className="flex flex-row gap-x-5 gap-y-5 md:flex-row xxs:flex-col">
              <BasicSelect
                label="Level"
                options={JOB_LEVELS}
                value={values?.skillLevel}
                errorMessage={validator.isInputValid("skillLevel")}
                setValue={(value) => setFieldValue("skillLevel", value, true)}
                required
              />
              <TextField
                id="positions"
                label="No. of Positions"
                type="text"
                variant="filled"
                className="bg-primaryLight text-white border border-solid border-primaryMid"
                labelClassName="text-secondary"
                maxLength={2}
                value={values?.positions}
                errorMessage={validator.isInputValid("positions")}
                onChange={handleChange}
                required
              />
            </div>

            <div className="flex flex-row gap-x-5 gap-y-5 md:flex-row xxs:flex-col">
              <div className="basis-6/12">
                <TextField
                  id="country"
                  label="Country"
                  type="text"
                  variant="filled"
                  className="bg-primaryLight text-white border border-solid border-primaryMid"
                  labelClassName="text-secondary"
                  value={values?.country}
                  errorMessage={validator.isInputValid("country")}
                  onChange={handleChange}
                />
              </div>
              <div className="flex flex-row basis-6/12 gap-x-5 gap-y-5 md:flex-row xxs:flex-col">
                <TextField
                  id="state"
                  label="State"
                  type="text"
                  variant="filled"
                  className="bg-primaryLight text-white border border-solid border-primaryMid"
                  labelClassName="text-secondary"
                  value={values?.state}
                  errorMessage={validator.isInputValid("state")}
                  onChange={handleChange}
                />
                <TextField
                  id="city"
                  label="City"
                  type="text"
                  variant="filled"
                  className="bg-primaryLight text-white border border-solid border-primaryMid"
                  labelClassName="text-secondary"
                  value={values?.city}
                  errorMessage={validator.isInputValid("city")}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="flex flex-row gap-x-5 gap-y-5 md:flex-row xxs:flex-col">
              <div className="basis-6/12 flex flex-row overflow-hidden">
                <BasicSelect
                  label="Timezone"
                  options={TIMEZONES}
                  value={values?.timezone}
                  errorMessage={validator.isInputValid("timezone")}
                  setValue={(value) => setFieldValue("timezone", value, true)}
                />
              </div>

              <div className="basis-6/12 flex flex-row gap-x-[15px]">
                <BasicSelect
                  label="Education"
                  options={EDUCATION_DEGREES}
                  value={values?.degree}
                  errorMessage={validator.isInputValid("degree")}
                  setValue={(value) => setFieldValue("degree", value, true)}
                />
                <TextField
                  id="yearsOfExperience"
                  label="Years of Experience"
                  type="text"
                  variant="filled"
                  className="bg-primaryLight text-white border border-solid border-primaryMid"
                  labelClassName="text-secondary"
                  maxLength={6}
                  value={values?.yearsOfExperience}
                  errorMessage={validator.isInputValid("yearsOfExperience")}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="flex flex-row gap-x-5 gap-y-5 md:flex-row xxs:flex-col">
              <div className="basis-6/12 flex flex-row gap-x-[15px]">
                <TextField
                  id="minSalary"
                  label="Min Salary (USD)"
                  type="number"
                  variant="filled"
                  className="bg-primaryLight text-white border border-solid border-primaryMid"
                  labelClassName="text-secondary"
                  maxLength={6}
                  value={values?.minSalary}
                  errorMessage={validator.isInputValid("minSalary")}
                  disabled={!!values?.salaryDependsOnExperience}
                  onChange={handleChange}
                />
                <TextField
                  id="maxSalary"
                  label="Max Salary (USD)"
                  type="number"
                  variant="filled"
                  className="bg-primaryLight text-white border border-solid border-primaryMid"
                  labelClassName="text-secondary"
                  maxLength={6}
                  value={values?.maxSalary}
                  errorMessage={validator.isInputValid("maxSalary")}
                  disabled={!!values?.salaryDependsOnExperience}
                  onChange={handleChange}
                />
              </div>

              <div className="basis-6/12 flex flex-row gap-x-[15px] h-[55px]">
                <Checkbox
                  id="salaryDependsOnExperience"
                  label="Depends On Experience"
                  checked={values?.salaryDependsOnExperience}
                  onChange={() => {
                    setValues({
                      ...values,
                      salaryDependsOnExperience:
                        !values.salaryDependsOnExperience,
                      minSalary: undefined,
                      maxSalary: undefined,
                    });

                    values.salaryDependsOnExperience &&
                      setTouched({
                        ...touched,
                        minSalary: true,
                        maxSalary: true,
                      });
                  }}
                />
              </div>
            </div>

            <div className="flex flex-row gap-x-5 gap-y-5 md:flex-row xxs:flex-col">
              <BasicSelect
                label="Employment Type"
                options={EMPLOYMENT_TYPES}
                value={values?.employmentType}
                errorMessage={validator.isInputValid("employmentType")}
                setValue={(value) =>
                  setFieldValue("employmentType", value, true)
                }
                required
              />
              <BasicSelect
                label="Job Type"
                options={JOB_TYPES}
                value={values?.type}
                errorMessage={validator.isInputValid("type")}
                setValue={(value) => setFieldValue("type", value, true)}
                required
              />
            </div>
            <div className="flex flex-row gap-x-5 gap-y-5 md:flex-row xxs:flex-col">
              <TextField
                id="requirements"
                label="Requirements"
                type="text"
                variant="filled"
                className="bg-primaryLight text-white border border-solid border-primaryMid"
                labelClassName="text-secondary"
                multiline
                minRows={4}
                maxRows={8}
                value={values?.requirements}
                errorMessage={validator.isInputValid("requirements")}
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-row gap-x-5 gap-y-5 md:flex-row xxs:flex-col">
              <TextField
                id="description"
                label="Description"
                type="text"
                variant="filled"
                className="bg-primaryLight text-white border border-solid border-primaryMid"
                labelClassName="text-secondary"
                multiline
                minRows={4}
                maxRows={8}
                value={values?.description}
                errorMessage={validator.isInputValid("description")}
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-row gap-x-5 gap-y-5 md:flex-row xxs:flex-col">
              <TextField
                id="desiredSkills"
                label="Desired Skills"
                type="text"
                variant="filled"
                className="bg-primaryLight text-white border border-solid border-primaryMid"
                labelClassName="text-secondary"
                multiline
                minRows={4}
                maxRows={8}
                value={values?.desiredSkills}
                errorMessage={validator.isInputValid("desiredSkills")}
                onChange={handleChange}
              />
            </div>

            <div className="flex flex-row justify-between gap-x-[10px] gap-y-[10px] md:flex-row xxs:flex-col-reverse">
              <Button
                color="danger"
                // variant="text"
                variant="outlined"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <div className="flex gap-x-[10px] gap-y-[10px] md:flex-row xxs:flex-col-reverse">
                <Button
                  color="secondary"
                  // variant="text"
                  variant="outlined"
                  onClick={handleSaveDraft}
                >
                  Save Draft
                </Button>
                <Button
                  color="success"
                  variant="outlined"
                  loading={updating}
                  className="min-w-[150px]"
                  onClick={handleSave}
                >
                  Save Job
                </Button>
              </div>
            </div>
          </div>
        </Form>
      </Container>
    </Container>
  );
};
