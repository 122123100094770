import React from "react";

import { VscChromeClose as CloseIcon } from "react-icons/vsc";

import { IconButton, IIconButtonProps } from "..";

interface ICloseIconButton extends IIconButtonProps {}

export const CloseIconButton: React.FC<ICloseIconButton> = ({
  iconClassName,
  iconProps,
  onClick,
}) => (
  <IconButton
    icon={CloseIcon}
    iconProps={iconProps}
    iconClassName={iconClassName}
    onClick={onClick || (() => {})}
  />
);

CloseIconButton.defaultProps = {
  iconProps: { size: 14 },
  iconClassName: "fill-secondary",
};
