import { createSlice } from "@reduxjs/toolkit";

import { IJob } from "../../../shared";
import { appendToStore, AppReducerState, popFromStore } from "../../../store";

interface IJobState {
  jobs: AppReducerState<IJob[]>;
  job: AppReducerState<IJob>;
  recentlyPostedJobs: AppReducerState<IJob[]>;
}

const initialState: IJobState = {
  jobs: {
    isLoading: false,
    data: [],
    error: null,
  },
  job: {
    isLoading: false,
    isUpdating: false,
    data: {},
    error: null,
  },
  recentlyPostedJobs: {
    isLoading: false,
    data: [],
    error: null,
  },
};

export const jobSlice = createSlice({
  name: "Job",
  initialState,
  reducers: {
    appendJobs: appendToStore("jobs"),
    popJobs: popFromStore("jobs"),

    getJobsRequest: (state) => {
      state.jobs.isLoading = true;
      state.jobs.results = 0;
      state.jobs.data = [];
    },
    getJobsSuccess: (state, action) => {
      const { data, results } = action.payload;

      state.jobs.isLoading = false;
      state.jobs.results = results;
      state.jobs.data = data;
    },
    getJobsError: (state, action) => {
      state.jobs.isLoading = false;
      state.jobs.results = 0;
      state.jobs.data = [];
      state.jobs.error = action.payload;
    },

    getJobRequest: (state) => {
      state.job.isLoading = true;
    },
    getJobSuccess: (state, action) => {
      state.job.isLoading = false;
      state.job.data = action.payload;
    },
    getJobError: (state, action) => {
      const error = action.payload;

      state.job.isLoading = false;
      state.job.error = error;
    },

    createJobRequest: (state) => {
      state.job.isLoading = true;
    },
    createJobSuccess: (state, action) => {
      state.job.isLoading = false;
    },
    createJobError: (state, action) => {
      state.job.isLoading = false;
      state.job.error = action.payload;
    },

    updateJobRequest: (state) => {
      state.job.isUpdating = true;
    },
    updateJobSuccess: (state, action) => {
      state.job.isUpdating = false;
    },
    updateJobError: (state, action) => {
      state.job.isUpdating = false;
      state.job.error = action.payload;
    },

    removeJobRequest: (state) => {
      state.job.isLoading = true;
    },
    removeJobSuccess: (state, action) => {
      state.job.data = {};
      state.job.isLoading = false;
    },
    removeJobError: (state, action) => {
      state.job.error = action.payload;
      state.job.isLoading = false;
    },

    applyJobRequest: (state) => {},
    applyJobSuccess: (state, action) => {
      const { jobApplication } = state.job.data;

      const jobApplicationId = action.payload.id;

      state.job.data = {
        ...state.job.data,
        jobApplication: {
          ...jobApplication,
          id: jobApplicationId,
        },
      };
    },
    applyJobError: (state, action) => {},

    getRecentlyPostedJobsRequest: (state) => {
      state.recentlyPostedJobs.isLoading = true;
      state.recentlyPostedJobs.results = 0;
      state.recentlyPostedJobs.data = [];
    },
    getRecentlyPostedJobsSuccess: (state, action) => {
      const { data, results } = action.payload;

      state.recentlyPostedJobs.isLoading = false;
      state.recentlyPostedJobs.results = results;
      state.recentlyPostedJobs.data = data;
    },
    getRecentlyPostedJobsError: (state, action) => {
      state.recentlyPostedJobs.isLoading = false;
      state.recentlyPostedJobs.results = 0;
      state.recentlyPostedJobs.data = [];
      state.recentlyPostedJobs.error = action.payload;
    },
  },
});

export const jobReducer = jobSlice.reducer;
