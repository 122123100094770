import * as Yup from "yup";

export interface ILicensePurchaseForm {
  duration?: number;
  postLimits?: any[];
}

export const LicensePurchaseFormInitialValues = {
  duration: 0,
  postLimits: [],
};

export const LicensePurchaseFormValidationSchema = Yup.object().shape({
  duration: Yup.number()
    .typeError("Invalid format")
    .min(365, "Duration must be 365 days at least")
    .max(1000, "Duration must be less than 1000 days")
    .required("Duration is a required field"),
  postLimits: Yup.array().of(
    Yup.object().shape({
      platform: Yup.string().required("Platform is a required field"),
      username: Yup.string().required("Username is a required field"),
    })
  ),
});
