import React from "react";

import { AuthLayout } from "../../layouts";
import { lazyload } from "../../utils";

const { RegisterContainer } = lazyload(() => import("./../../features"));

export const RegisterPage = () => (
  <AuthLayout>
    <RegisterContainer />
  </AuthLayout>
);
