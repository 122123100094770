import React, { useEffect, useState } from "react";

import { PageContainerSwitch } from "../../components";
import {
  AdminUserVerificationListContainer,
  AdminUserAuditListContainer,
} from "./../../features";

export const AdminUserContainer = () => {
  const tabs = [
    {
      label: `Verification`,
      component: AdminUserVerificationListContainer,
    },
    {
      label: `Audit`,
      component: AdminUserAuditListContainer,
    },
  ];

  return <PageContainerSwitch tabs={tabs} indicatorClassName="hidden" />;
};
