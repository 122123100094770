import React from "react";

import { useNavigate } from "react-router";
import {
  ModalType,
  NotificationStatus,
  NotificationType,
  NotificationPriority,
} from "../../../shared";

import { useAppDispatch, useLoading, useModal, useToast } from "../../../hooks";
import { removeJob, updateJob } from "../../../store";

import { Button, Modal } from "./../../../components";
import { ROUTER } from "../../../routes";

export const JobDeleteModal = () => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const { modal, isOpen, closeModal } = useModal({
    type: ModalType.JOB_DELETE,
  });

  const { toast } = useToast();

  const [deleting, setDeleting] = useLoading();

  const { id, title, redirectTo } = modal?.props || {};

  const handleDelete = async () => {
    setDeleting(true);

    //  Deleting the job
    const deleted = await dispatch(removeJob(id));
    if (!deleted?.id) {
      setDeleting(false);
      closeModal();

      return toast({
        status: NotificationStatus.Error,
        message: "Job has not been deleted",
      });
    }

    closeModal();
    setDeleting(false);

    toast({
      status: NotificationStatus.Success,
      message: "Job has been deleted",
    });

    redirectTo ? redirect(redirectTo) : redirect(ROUTER.HOME);
  };

  const handleDiscard = () => {
    closeModal();
  };

  return (
    <Modal
      title="Delete"
      description="Please read below before taking action"
      titleClassName="text-danger"
      open={isOpen}
    >
      <div>
        <div className="text-sm text-secondary break-words">
          <p>
            Are you sure you want to delete job{" "}
            <span className="text-white font-semibold">{title}</span>? This will
            remove all fields and data including any associations of
            verification that you or a company has paid for. An alternative is
            to delist the experience/job/education if you don't want others to
            know you are associated with it.
          </p>
        </div>
        <div className="flex flex-row justify-end items-center gap-x-[10px] mt-5">
          <div className="flex flex-row gap-x-[10px]">
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleDiscard}
            >
              Discard
            </Button>
            <Button
              variant="outlined"
              color="danger"
              loading={deleting}
              className="min-w-[150px]"
              onClick={handleDelete}
            >
              Delete
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
