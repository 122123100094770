import React from "react";

import { MainLayout, MainExtendedLayout } from "../../layouts";
import { lazyload } from "../../utils";

const { MarketplaceContainer } = lazyload(() => import("../../containers"));

export const MarketplacePage = () => (
  <MainExtendedLayout hideFooter>
    <MarketplaceContainer />
  </MainExtendedLayout>
);
