import React, { useEffect, useMemo, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ISidebar } from "@types";
import { ModalType, Role } from "../../../shared";

import {
  Link,
  SidebarSection,
  DropdownItem,
  Button,
  Loader,
  Sidebar,
  Avatar,
  RBAC,
  SidebarContent,
} from "../../../components";

import {
  useAppDispatch,
  useAppSelector,
  useModal,
  useToast,
} from "../../../hooks";
import { date } from "../../../utils";
import { removeJob, updateJob } from "../../../store";
import { ROUTER } from "../../../routes";

import { ScheduledInterviewWidget, ChatMessageButton } from "../../../features";

export const JobApplicationDetailSidebar: React.FC<ISidebar> = (props) => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();
  const { toast } = useToast();

  const {
    account: { data: account },
  } = useAppSelector((state) => state.account);

  const {
    profile: { isLoading: profileLoading },
  } = useAppSelector((state) => state.profile);

  const {
    resume: { data: resume, isLoading: resumeLoading },
  } = useAppSelector((state) => state.resume);

  const {
    jobApplication: { data: jobApplication, isLoading: jobApplicationLoading },
  } = useAppSelector((state) => state.jobApplication);

  const isLoading = profileLoading || resumeLoading || jobApplicationLoading;

  const { createdBy, createdById } = jobApplication || {};

  const profile = createdBy || resume?.profile;
  const profileId = account?.profile?.id;
  const userId = jobApplication?.createdById;
  const companyId = jobApplication?.job?.companyId;

  const isAuthor = createdById === profileId || false;
  const isOwner = account?.ownership?.id === companyId;
  const isEmployee = account?.employment?.id === companyId;
  const isEmployed = isOwner || isEmployee;

  return (
    <Sidebar {...props}>
      <RBAC
        roles={[Role.Admin]}
        conditions={[
          {
            roles: [Role.Owner, Role.TeamLead, Role.Employee],
            condition: isEmployed,
          },
          // { roles: [Role.User], condition: isAuthor },
        ]}
      >
        <SidebarContent>
          {profile?.id && (
            <SidebarSection
              heading="Personal Info"
              className="border-none"
              loading={isLoading}
            >
              <div className="flex flex-row justify-start items-center gap-x-[8px]">
                <Link to={ROUTER.USER_DETAIL(profile?.id)}>
                  <Avatar size={40} src={profile?.avatar}>
                    {profile?.lastName}
                  </Avatar>
                </Link>
                <div className="flex flex-col gap-y-[5px]">
                  <Link
                    to={ROUTER.USER_DETAIL(profile?.id)}
                    className="text-base text-white font-semibold leading-none"
                  >
                    {profile?.firstName} {profile?.lastName}
                  </Link>
                  <span className="text-xs text-secondary font-medium leading-none">
                    Legitbook User
                  </span>
                </div>
              </div>
              <div className="mt-[25px]">
                <ChatMessageButton userId={userId} className="w-full" />
              </div>
            </SidebarSection>
          )}
        </SidebarContent>
      </RBAC>
    </Sidebar>
  );
};
