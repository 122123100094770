import React from "react";
import { Action } from "../../../../shared";

export interface IResumeSectionCardProps {
  heading?: string;
  className?: string;
  navbar?: JSX.Element;
  actions?: Action[];
  onEdit?: (id?: string) => void;
  onDelete?: (id?: string) => void;
}

export const ResumeSectionCard: React.FC<IResumeSectionCardProps> = ({
  children,
  heading,
  navbar,
  className,
}) => (
  <div className="">
    <div className="flex flex-row justify-between items-center mb-[8px]">
      <h2 className="text-secondary text-base font-semibold break-words">
        {heading}
      </h2>
      <div>{navbar || <></>}</div>
    </div>

    <div className={`bg-primaryLight rounded-md overflow-hidden ${className}`}>
      {children}
    </div>
  </div>
);

ResumeSectionCard.defaultProps = {
  className: "",
};
