import React, { Suspense } from "react";

import { Loader, Navbar, BottomNavbar, Logo, Footer } from "../components";
import { ILayout } from "@types";
import { ScrollTopButton, TicketIconButton } from "../features";

interface IMainLayoutProps extends ILayout {
  hideFooter?: boolean;
}

export const MainLayout: React.FC<IMainLayoutProps> = ({
  children,
  leftSidebar: LeftSidebar,
  rightSidebar: RightSidebar,
  hideFooter,
}) => (
  <div className="flex flex-col w-full min-h-screen h-auto bg-primaryDark overflow-x-hidden font-gilroy">
    <Navbar />

    {LeftSidebar && (
      <div
        className="fixed h-screen w-auto flex flex-col box-border border-r border-solid border-primaryLight bg-primaryDark z-30
                  xxs:hidden xl:flex 
    "
      >
        <Suspense fallback={<Loader />}>
          <LeftSidebar position="left" className="xl:w-[280px] 2xl:w-[350px]" />
        </Suspense>
      </div>
    )}

    <div
      className="
      w-full max-w-[768px] flex flex-col h-auto min-h-[500px] mt-[75px] mx-auto px-[20px] py-[20px] box-border text-secondary
      md:px-[0px]
    "
    >
      <Suspense fallback={<Loader />}>
        <div className="relative w-full">
          <div className="relative">{children}</div>
          {!hideFooter && (
            <div className="mt-[50px] pt-[30px] border-t-[1px] border-solid border-primaryLight">
              <Footer />
            </div>
          )}
        </div>
      </Suspense>
    </div>

    {RightSidebar && (
      <div
        className="fixed w-auto h-screen right-[0px] flex flex-col box-border border-l border-solid border-primaryLight bg-primaryDark z-30
                  xxs:hidden xl:flex          
    "
      >
        <Suspense fallback={<Loader />}>
          <RightSidebar
            position="right"
            className="xl:w-[280px] 2xl:w-[350px]"
          />
        </Suspense>
      </div>
    )}

    <div className="fixed z-50 bottom-0 w-full h-[70px] flex md:hidden xxs:flex">
      <BottomNavbar />
    </div>
    <div className="w-full h-[75px]"></div>
  </div>
);

MainLayout.defaultProps = {
  hideFooter: false,
};
