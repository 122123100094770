import { ILicenseApiQueryParams } from "../../../shared";

import { ApiService } from "../../../services";
import { AppDispatch } from "../../store";

import { licenseSlice } from "./license.slice";
import { stringifyQueryParams } from "../../../utils";

export const setLicense = (payload: any) => async (dispatch: AppDispatch) => {
  dispatch(licenseSlice.actions.setLicense(payload));
};

export const getUserLicenses =
  (params?: ILicenseApiQueryParams) => async (dispatch: AppDispatch) => {
    try {
      dispatch(licenseSlice.actions.getLicensesRequest());

      const response = await ApiService.get(`licenses`);
      const { data, results } = response.data || {};

      dispatch(licenseSlice.actions.getLicensesSuccess({ data, results }));

      return data;
    } catch (e: any) {
      dispatch(licenseSlice.actions.getLicensesError(e.response?.data?.error));
    }
  };

export const getUserLicenseCopies =
  (params?: ILicenseApiQueryParams) => async (dispatch: AppDispatch) => {
    try {
      dispatch(licenseSlice.actions.getLicenseCopiesRequest());

      const query = stringifyQueryParams(params);

      const response = await ApiService.get(
        `license-copies/${query ? `?${query}` : ""}`
      );
      const { data, results, page, pageCount } = response.data || {};

      dispatch(
        licenseSlice.actions.getLicenseCopiesSuccess({
          data,
          results,
          page,
          pageCount,
        })
      );

      return data;
    } catch (e: any) {
      dispatch(
        licenseSlice.actions.getLicenseCopiesError(e.response?.data?.error)
      );
    }
  };

export const getUserSoldLicenseCopies =
  (params?: ILicenseApiQueryParams) => async (dispatch: AppDispatch) => {
    try {
      dispatch(licenseSlice.actions.getLicenseCopiesRequest());

      const query = stringifyQueryParams(params);

      const response = await ApiService.get(
        `license-copies/sold/${query ? `?${query}` : ""}`
      );
      const { data, results, page, pageCount } = response.data || {};

      dispatch(
        licenseSlice.actions.getLicenseCopiesSuccess({
          data,
          results,
          page,
          pageCount,
        })
      );

      return data;
    } catch (e: any) {
      dispatch(
        licenseSlice.actions.getLicenseCopiesError(e.response?.data?.error)
      );
    }
  };

export const getUserLicensedItems = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(licenseSlice.actions.getLicensesRequest());

    const response = await ApiService.get(`licenses`);
    const { data, results } = response.data || {};

    dispatch(licenseSlice.actions.getLicensesSuccess({ data, results }));

    return data;
  } catch (e: any) {
    dispatch(licenseSlice.actions.getLicensesError(e.response?.data?.error));
  }
};

export const getLicenseById = (id: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(licenseSlice.actions.getLicenseRequest());

    const response = await ApiService.get(`licenses/${id}`);
    const { data } = response.data;

    dispatch(licenseSlice.actions.getLicenseSuccess(data));

    return data;
  } catch (e: any) {
    dispatch(licenseSlice.actions.getLicenseError(e.response?.data?.error));
  }
};

export const calculateLicense =
  (payload: { duration: number; price: number }) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(licenseSlice.actions.calculateLicensePaymentTotalRequest());

      const response = await ApiService.post("licenses/calculate", payload);
      const { data } = response.data;

      dispatch(licenseSlice.actions.calculateLicensePaymentTotalSuccess(data));

      return data;
    } catch (e: any) {
      dispatch(
        licenseSlice.actions.calculateLicensePaymentTotalError(
          e.response?.data?.error
        )
      );
    }
  };

export const purchaseLicense =
  ({
    licenseId,
    duration,
    postLimits,
    payment,
  }: {
    licenseId: string;
    duration: number;
    postLimits?: any[];
    payment: {
      successUrl: string;
      cancelUrl: string;
    };
  }) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(licenseSlice.actions.licensePaymentRequest());

      const response = await ApiService.post(`licenses/${licenseId}/purchase`, {
        duration,
        postLimits,
        payment,
      });
      const { data } = response.data;

      dispatch(licenseSlice.actions.licensePaymentSuccess(data));

      return data;
    } catch (e: any) {
      dispatch(
        licenseSlice.actions.licensePaymentError(e.response?.data?.error)
      );
    }
  };

export const calculateLicensePurchaseTotal =
  (licenseId: string, payload: { duration: number }) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(licenseSlice.actions.calculateLicensePaymentTotalRequest());

      const response = await ApiService.post(
        `licenses/${licenseId}/purchase/calculate`,
        payload
      );
      const { data } = response.data;

      dispatch(licenseSlice.actions.calculateLicensePaymentTotalSuccess(data));

      return data;
    } catch (e: any) {
      dispatch(
        licenseSlice.actions.calculateLicensePaymentTotalError(
          e.response?.data?.error
        )
      );
    }
  };
