import { createSlice } from "@reduxjs/toolkit";

import { IVerification } from "../../../shared";
import { AppReducerState } from "../../store";
import { appendToStore, popFromStore } from "../../storeManager";

interface ICalendarEventState {
  calendarEvents: AppReducerState<any[]>;
  calendarEvent: AppReducerState<any>;
}

const initialState: ICalendarEventState = {
  calendarEvents: {
    isLoading: false,
    results: 0,
    data: [],
    error: null,
  },
  calendarEvent: {
    isLoading: false,
    data: {},
    error: null,
  },
};

export const calendarEventSlice = createSlice({
  name: "Calendar Event",
  initialState,
  reducers: {
    getCalendarEventsRequest: (state) => {
      state.calendarEvents.isLoading = true;
    },
    getCalendarEventsSuccess: (state, action) => {
      const { data, results } = action.payload;

      state.calendarEvents.isLoading = false;
      state.calendarEvents.data = data;
      state.calendarEvents.results = results;
    },
    getCalendarEventsError: (state, action) => {
      state.calendarEvents.isLoading = false;
      state.calendarEvents.error = action.payload;
    },

    getCalendarEventByIdRequest: (state) => {
      state.calendarEvent.isLoading = true;
    },
    getCalendarEventByIdSuccess: (state, action) => {
      const { data } = action.payload;

      state.calendarEvent.isLoading = false;
      state.calendarEvent.data = data;
    },
    getCalendarEventByIdError: (state, action) => {
      state.calendarEvent.isLoading = false;
      state.calendarEvent.error = action.payload;
    },

    createCalendarEventRequest: (state) => {},
    createCalendarEventSuccess: (state, action) => {
      const payload = action.payload;

      state.calendarEvents.data = [...state.calendarEvents.data, payload];
    },
    createCalendarEventError: (state, action) => {
      state.calendarEvents.error = action.payload;
    },

    updateCalendarEventRequest: (state) => {
      state.calendarEvent.isLoading = true;
    },
    updateCalendarEventSuccess: (state, action) => {
      const payload = action.payload;

      const calendarEvents = state.calendarEvents.data;
      const index = calendarEvents.findIndex(
        (event) => event.id === payload.id
      );

      // Updating the calendar event
      state.calendarEvent.isLoading = false;
      state.calendarEvent.data = action.payload;

      // Updating the calendar events
      const calendarEvent = calendarEvents[index];

      calendarEvents[index] = {
        ...calendarEvent,
        ...payload,
      };

      state.calendarEvents.data = [...calendarEvents];
    },
    updateCalendarEventError: (state, action) => {
      state.calendarEvent.isLoading = false;
      state.calendarEvent.error = action.payload;
    },

    removeCalendarEventRequest: (state) => {},
    removeCalendarEventSuccess: (state, action) => {
      const payload = action.payload;

      // Removing Event
      state.calendarEvent.data = {};

      // Removing Event From Calendar
      const calendarEvents = state.calendarEvents.data || [];

      state.calendarEvents.data = !!calendarEvents.length
        ? calendarEvents?.filter(({ id }) => id !== payload?.id)
        : [];
    },
    removeCalendarEventError: (state, action) => {
      state.calendarEvent.isLoading = false;
      state.calendarEvent.error = action.payload;
    },
  },
});

export const calendarEventReducer = calendarEventSlice.reducer;
