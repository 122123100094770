import React, { useEffect } from "react";

import { CardList } from "../../components";
import { UserVerificationCard } from "./../../features";
import { VerificationStatus } from "../../shared";
import {
  useAppDispatch,
  useAppSelector,
  useLoading,
  useToast,
} from "../../hooks";
import { getVerificationRequests } from "../../store";

export const AdminUserVerificationListContainer = () => {
  const dispatch = useAppDispatch();

  const [approveLoading, setApproveLoading] = useLoading();
  const [rejectLoading, setRejectLoading] = useLoading();
  const { toast } = useToast();

  const {
    verifications: { data: verifications, isLoading },
  } = useAppSelector((state) => state.verification);

  useEffect(() => {
    dispatch(
      getVerificationRequests({
        where: { status: VerificationStatus.Pending, companyId: null },
      })
    );
  }, []);

  return (
    <CardList loading={isLoading}>
      {!!verifications?.length ? (
        verifications?.map((request) => (
          <UserVerificationCard
            key={request?.id}
            {...request}
            // isApproving={approveLoading}
            // isRejecting={rejectLoading}
            // onApprove={() => handleApprove(request?.id)}
            // onReject={() => handleReject(request?.id)}
          />
        ))
      ) : (
        <span>No verifications found.</span>
      )}
    </CardList>
  );
};
