import React from "react";
import { useNavigate } from "react-router";

import { ModalType } from "../../../shared";

import {
  useAppDispatch,
  useLoading,
  useModal,
  useAccount,
  useToast,
} from "../../../hooks";

import {
  Avatar,
  BasicDatePicker,
  BasicTimePicker,
  Button,
  Form,
  FormSection,
  Modal,
  TextField,
} from "../../../components";
import { textformat } from "../../../utils";
import { ROUTER } from "../../../routes";

export const VideoCallJoinModal = () => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const { modal, isOpen, closeModal } = useModal({
    type: ModalType.VIDEO_CALL_JOIN,
  });

  const { toast } = useToast();
  const [loading, setLoading] = useLoading();

  const { activeProfile } = useAccount();

  const {
    props: { jobApplicationId, userId, companyId },
  } = modal;

  const handleJoin = () => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
      closeModal();

      redirect(ROUTER.MEET);
    }, 500);
  };

  return (
    <Modal
      title="Join Meeting"
      description="Please confirm if you want to join the meeting."
      open={isOpen}
    >
      {/* <div className="flex flex-col">
        <div className="flex w-full min-h-[200px] rounded-lg bg-gray-500"></div>
      </div> */}

      <div className="flex flex-col text-sm gap-[10px]">
        <div className="flex flex-col justify-start items-start">
          <h3 className="text-base font-semibold">Event</h3>

          <div className="flex flex-col gap-[5px]">
            <div className="flex flex-col">
              <span className="text-sm text-secondary">Title:</span>
              <span className="text-white font-medium text-sm">
                Job Interview for{" "}
                <span className="text-warning">
                  {textformat.shorten("Senior Backend Engineer")}
                </span>
              </span>
            </div>
            <div className="flex flex-col">
              <span className="text-sm text-secondary">Description:</span>
              <p className="text-white text-sm">
                {textformat.shorten(
                  "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
                  125
                )}
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-col justify-start items-start mt-[10px]">
          <h3 className="text-base font-semibold">Participants</h3>
          <div className="flex flex-row gap-[5px] mt-[5px] pl-[20px]">
            {[
              { id: 1, firstName: "Jeff", avatar: "", isHost: true },
              { id: 2, firstName: "Mark", avatar: "" },
            ]?.map(({ id, firstName, isHost }) => (
              <Avatar
                key={id}
                size={40}
                to="#"
                className={`
                  ${isHost ? "border-warning" : "border-gray-500"}
                  border-[2px] -ml-[20px]
                `}
                alt="HELLO"
              >
                {firstName}
              </Avatar>
            ))}
          </div>
        </div>
      </div>

      <div className="flex flex-row justify-end gap-[10px] mt-[15px]">
        <Button variant="outlined" color="secondary" onClick={closeModal}>
          Discard
        </Button>
        <Button
          variant="outlined"
          color="success"
          className="min-w-[150px]"
          loading={loading}
          onClick={handleJoin}
        >
          Join Meeting
        </Button>
      </div>
    </Modal>
  );
};
