import React, { useEffect, useMemo } from "react";

import { IResumeCover } from "../../../../shared";

import { Checkbox } from "../../../../components";
import {
  IResumeSectionSelectProps,
  ResumeCoverCard,
  ResumeSectionCard,
  ResumeSectionCardBody,
} from "../../../../features";

import { getProfileCovers } from "../../../../store";
import { useAppDispatch, useAppSelector } from "../../../../hooks";

export interface IResumeCoverSelectSectionProps
  extends IResumeSectionSelectProps<IResumeCover> {}

export const ResumeCoverSelectSection: React.FC<
  IResumeCoverSelectSectionProps
> = ({ selected, setSelected, isChecked, onChange }) => {
  const dispatch = useAppDispatch();

  const {
    account: { data: account },
  } = useAppSelector((state) => state.account);
  const { profileCovers } = useAppSelector((state) => state.profileCover);

  const { resume } = useAppSelector((state) => state.resume);
  const resumeCovers = resume?.data?.covers || [];

  const profile = account?.profile;
  const profileId = profile?.id;
  const resumeId = resume?.data?.id;

  const archivedCovers = profileCovers?.data?.filter(
    ({ id }) =>
      !resumeCovers
        ?.map(({ originId }) => originId)
        ?.filter((originId: string) => originId)
        ?.includes(id)
  );

  const data = [...resumeCovers, ...archivedCovers];

  useEffect(() => {
    if (!setSelected) return;

    setSelected(resumeCovers?.map(({ id }) => id) || []);
  }, [resumeCovers]);

  useEffect(() => {
    if (!profileId || !resumeId) return;

    dispatch(
      getProfileCovers({
        where: { profileId, isArchived: true },
      })
    );
  }, []);

  return (
    <ResumeSectionCard heading="Cover">
      <ResumeSectionCardBody loading={profileCovers?.isLoading}>
        {data?.map((cover) => (
          <div
            key={cover?.id}
            className="flex flex-row justify-start items-start gap-x-[10px]"
          >
            <Checkbox
              value={cover?.id}
              checked={isChecked ? isChecked(cover?.id) : false}
              onChange={onChange}
            />
            <ResumeCoverCard actions={[]} {...cover} />
          </div>
        ))}
      </ResumeSectionCardBody>
    </ResumeSectionCard>
  );
};

ResumeCoverSelectSection.defaultProps = {};
