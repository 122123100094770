import React, { useEffect } from "react";
import { useNavigate } from "react-router";

import { FiClock as ClockIcon } from "react-icons/fi";

import {
  ModalType,
  NotificationStatus,
  NotificationType,
  NotificationPriority,
} from "../../../shared";

import {
  useAccount,
  useAppDispatch,
  useAppSelector,
  useLoading,
  useModal,
  useToast,
} from "../../../hooks";
import { getCalendarEventById, removeChat, removeJob } from "../../../store";

import {
  BasicDatePicker,
  Button,
  Container,
  Form,
  FormSection,
  Modal,
  TextField,
} from "../../../components";
import { date, FormValidator } from "../../../utils";
import { ROUTER } from "../../../routes";

export const CalendarEventDetailModal = () => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const { toast } = useToast();
  const { setModal } = useModal();
  const { activeProfile } = useAccount();

  const { modal, isOpen, closeModal } = useModal({
    type: ModalType.CALENDAR_EVENT_DETAIL,
  });

  const [joining, setJoining] = useLoading();
  const [deleting, setDeleting] = useLoading();

  const {
    calendarEvent: { data: calendarEvent, isLoading: calendarEventLoading },
  } = useAppSelector((state) => state.calendarEvent);

  const { props } = modal;
  const { id, onEdit, onDelete } = props || {};

  const profileId = activeProfile?.id;
  const videoCallId = calendarEvent?.interview?.videoCallId;

  const interview = calendarEvent?.interview;

  const isInterview = calendarEvent?.type === "interview";
  const isInterviewer = interview?.interviewerId === profileId;

  const handleEdit = () => {
    setModal({ type: ModalType.CALENDAR_EVENT_EDIT, props: calendarEvent });
  };

  const handleDelete = async () => {
    setModal({
      type: ModalType.CALENDAR_EVENT_DELETE,
      props: { ...calendarEvent },
    });
  };

  const handleInterviewJoin = async () => {
    setJoining(true);

    setTimeout(() => {
      setJoining(false);
      closeModal();

      redirect(`${ROUTER.MEET}?id=${videoCallId}`);
    }, 500);
  };

  useEffect(() => {
    dispatch(getCalendarEventById(id));
  }, []);

  return (
    <Modal
      title={calendarEvent?.name || "Event"}
      open={isOpen}
      onClose={closeModal}
    >
      <Container
        loading={calendarEventLoading}
        className="text-secondary text-sm"
      >
        {!!calendarEvent?.id ? (
          <div className="flex flex-col">
            <div>
              <p>{calendarEvent?.description}</p>
              <div className="flex flex-row justify-start items-center text-sm font-medium mt-[20px] gap-x-[10px]">
                <ClockIcon size={22} className="text-secondary" />
                <span className="text-white">
                  {date(calendarEvent?.startedAt).format("ddd, MMM DD")}{" "}
                  <span className="text-warning">
                    {date(calendarEvent?.startedAt).format("HH:mm")}-
                    {date(calendarEvent?.finishedAt).format("HH:mm")}
                  </span>
                </span>
              </div>

              {isInterview && (
                <div className="flex mt-[15px]">
                  <Button
                    color="success"
                    variant="outlined"
                    className="w-full md:max-w-[200px] xxs:max-w-full"
                    loading={joining}
                    onClick={handleInterviewJoin}
                  >
                    Join Interview
                  </Button>
                </div>
              )}
            </div>

            {isInterview ? (
              isInterviewer ? (
                <div className="flex flex-row items-center mt-[15px] gap-[10px] md:justify-end xxs:justify-between">
                  <div className="md:basis-auto xxs:basis-6/12">
                    <Button
                      color="secondary"
                      variant="outlined"
                      className="min-w-[125px] md:w-auto xxs:w-full"
                      onClick={handleEdit}
                    >
                      Edit
                    </Button>
                  </div>
                  <div className="md:basis-auto xxs:basis-6/12">
                    <Button
                      color="danger"
                      variant="outlined"
                      className="md:w-auto xxs:w-full"
                      loading={deleting}
                      onClick={handleDelete}
                    >
                      Delete
                    </Button>
                  </div>
                </div>
              ) : (
                <></>
              )
            ) : (
              <div className="flex flex-row items-center mt-[15px] gap-[10px] md:justify-end xxs:justify-between">
                <div className="md:basis-auto xxs:basis-6/12">
                  <Button
                    color="secondary"
                    variant="outlined"
                    className="min-w-[125px] md:w-auto xxs:w-full"
                    onClick={handleEdit}
                  >
                    Edit
                  </Button>
                </div>
                <div className="md:basis-auto xxs:basis-6/12">
                  <Button
                    color="danger"
                    variant="outlined"
                    className="md:w-auto xxs:w-full"
                    loading={deleting}
                    onClick={handleDelete}
                  >
                    Delete
                  </Button>
                </div>
              </div>
            )}
          </div>
        ) : (
          <span>Event Not Found</span>
        )}
      </Container>
    </Modal>
  );
};
