import React, { Suspense } from "react";

import { ModalType } from "../../shared";
import { useAppSelector } from "../../hooks";
import { lazyload } from "../../utils";

const {
  InfoModal,
  FAQAvaialbleCountriesModal,
  TermsAndConditionsModal,
  JobApplicantionMessageModal,
  ResumeCoverDeleteModal,
  ResumeEducationDeleteModal,
  ResumeExperienceDeleteModal,
  ResumeDigitalAssetDeleteModal,
  ResumeDigitalAssetAddModal,
  ResumeDigitalAssetSelectAssetModal,
  ResumeDeleteModal,
  JobApplyModal,
  JobDeleteModal,
  JobWithdrawModal,
  VerificationRequestCreateModal,
  InviteEmployeeModal,
  ChatDeleteModal,
  ChatMessageModal,
  CalendarEventCreateModal,
  CalendarEventDetailModal,
  CalendarEventEditModal,
  CalendarEventDeleteModal,
  GalleryUploadModal,
  GalleryFileDeleteModal,
  GalleryFileEditModal,
  DigitalAssetDeleteModal,
  ResumeDigitalAssetPreviewModal,
  DigitalAssetEditModal,
  DigitalAssetUploadModal,
  TicketCreateModal,
  InterviewScheduleModal,
  InterviewStartModal,
  InterviewDetailModal,
  CompanyCommentCreateModal,
  VideoCallJoinModal,
  VideoCallLeaveModal,
  VideoCallSettingsModal,
  PostCreateModal,
  PostDeleteModal,
  LicenseOptionsModal,
  LicensePurchaseModal,
  LicenseAgreementModal,
  LicensePurchaseSuccessModal,
  LicensePurchaseErrorModal,
  LicensePaymentModal,
} = lazyload(() => import("../../features"));

export const ModalProvider: React.FC = () => {
  const { modal } = useAppSelector((state) => state.app);

  const modals = [
    // Info
    {
      type: ModalType.TERMS_AND_CONDITIONS,
      component: <TermsAndConditionsModal />,
    },

    // FAQ
    {
      type: ModalType.FAQ_AVAILABLE_COUNTRIES,
      component: <FAQAvaialbleCountriesModal />,
    },

    // Company
    {
      type: ModalType.COMPANY_EMPLOYEE_INVITE,
      component: <InviteEmployeeModal />,
    },

    // Company Comment
    {
      type: ModalType.COMPANY_COMMENT_CREATE,
      component: <CompanyCommentCreateModal />,
    },

    // Resume
    {
      type: ModalType.RESUME_TEMPLATE_DELETE,
      component: <ResumeDeleteModal />,
    },
    {
      type: ModalType.RESUME_COVER_DELETE,
      component: <ResumeCoverDeleteModal />,
    },
    {
      type: ModalType.RESUME_EDUCATION_DELETE,
      component: <ResumeEducationDeleteModal />,
    },
    {
      type: ModalType.RESUME_EXPERIENCE_DELETE,
      component: <ResumeExperienceDeleteModal />,
    },
    {
      type: ModalType.RESUME_DIGITAL_ASSET_ADD,
      component: <ResumeDigitalAssetAddModal />,
    },
    {
      type: ModalType.RESUME_DIGITAL_ASSET_DELETE,
      component: <ResumeDigitalAssetDeleteModal />,
    },
    {
      type: ModalType.RESUME_DIGITAL_ASSET_SELECT_ASSET,
      component: <ResumeDigitalAssetSelectAssetModal />,
    },

    // Job
    {
      type: ModalType.JOB_APPLY,
      component: <JobApplyModal />,
    },
    {
      type: ModalType.JOB_WITHDRAW,
      component: <JobWithdrawModal />,
    },
    {
      type: ModalType.JOB_DELETE,
      component: <JobDeleteModal />,
    },
    {
      type: ModalType.JOB_APPLICATION_MESSAGE,
      component: <JobApplicantionMessageModal />,
    },

    // Verification
    {
      type: ModalType.VERIFICATION_REQUEST_CREATE,
      component: <VerificationRequestCreateModal />,
    },

    // Chat
    {
      type: ModalType.CHAT_MESSAGE,
      component: <ChatMessageModal />,
    },
    {
      type: ModalType.CHAT_DELETE,
      component: <ChatDeleteModal />,
    },

    // Post
    {
      type: ModalType.POST_CREATE,
      component: <PostCreateModal />,
    },
    {
      type: ModalType.POST_DELETE,
      component: <PostDeleteModal />,
    },

    // Event
    {
      type: ModalType.CALENDAR_EVENT_DETAIL,
      component: <CalendarEventDetailModal />,
    },
    {
      type: ModalType.CALENDAR_EVENT_CREATE,
      component: <CalendarEventCreateModal />,
    },
    {
      type: ModalType.CALENDAR_EVENT_EDIT,
      component: <CalendarEventEditModal />,
    },
    {
      type: ModalType.CALENDAR_EVENT_DELETE,
      component: <CalendarEventDeleteModal />,
    },

    // Gallery
    {
      type: ModalType.GALLERY_UPLOAD,
      component: <GalleryUploadModal />,
    },
    {
      type: ModalType.GALLERY_FILE_EDIT,
      component: <GalleryFileEditModal />,
    },
    {
      type: ModalType.GALLERY_FILE_DELETE,
      component: <GalleryFileDeleteModal />,
    },

    // Digital Portfolio
    {
      type: ModalType.DIGITAL_PORTFOLIO_UPLOAD,
      component: <DigitalAssetUploadModal />,
    },
    {
      type: ModalType.DIGITAL_ASSET_EDIT,
      component: <DigitalAssetEditModal />,
    },
    {
      type: ModalType.RESUME_DIGITAL_ASSET_PREVIEW,
      component: <ResumeDigitalAssetPreviewModal />,
    },
    {
      type: ModalType.DIGITAL_ASSET_DELETE,
      component: <DigitalAssetDeleteModal />,
    },

    // Ticket
    {
      type: ModalType.TICKET_CREATE,
      component: <TicketCreateModal />,
    },

    // Video Call
    {
      type: ModalType.VIDEO_CALL_JOIN,
      component: <VideoCallJoinModal />,
    },
    {
      type: ModalType.VIDEO_CALL_LEAVE,
      component: <VideoCallLeaveModal />,
    },
    {
      type: ModalType.VIDEO_CALL_SETTINGS,
      component: <VideoCallSettingsModal />,
    },

    // Interview
    {
      type: ModalType.INTERVIEW_SCHEDULE_MODAL,
      component: <InterviewScheduleModal />,
    },
    {
      type: ModalType.INTERVIEW_DETAIL_MODAL,
      component: <InterviewDetailModal />,
    },
    {
      type: ModalType.INTERVIEW_START_MODAL,
      component: <InterviewStartModal />,
    },

    // License
    {
      type: ModalType.LICENSE_OPTIONS,
      component: <LicenseOptionsModal />,
    },
    {
      type: ModalType.LICENSE_PURCHASE,
      component: <LicensePurchaseModal />,
    },
    {
      type: ModalType.LICENSE_AGREEMENT,
      component: <LicenseAgreementModal />,
    },
    {
      type: ModalType.LICENSE_PURCHASE_SUCCESS,
      component: <LicensePurchaseSuccessModal />,
    },
    {
      type: ModalType.LICENSE_PURCHASE_ERROR,
      component: <LicensePurchaseErrorModal />,
    },
    {
      type: ModalType.LICENSE_PAYMENT,
      component: <LicensePaymentModal />,
    },
  ];

  const render = () => {
    const element = modals.find(({ type }) => modal.type === type);

    if (!element) return <></>;

    return <Suspense fallback={<></>}>{element.component}</Suspense>;
  };

  return render();
};
