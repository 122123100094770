import React, { useEffect, useMemo, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  FiMapPin as MapIcon,
  FiMonitor as MonitorIcon,
  FiUsers as UsersIcon,
  FiArrowLeft as ArrowLeftIcon,
  FiChevronRight as ChevronRight,
} from "react-icons/fi";

import { ISidebar } from "@types";
import { ModalType, Role } from "../../../shared";

import {
  SidebarSection,
  Sidebar,
  Container,
  BulletPointList,
  BulletPointListItem,
  RBAC,
  SidebarContent,
  Tag,
  FilterTag,
} from "../../../components";
import { DevelopingFeatureInfoMessage } from "../../../features";

import {
  useAppDispatch,
  useAppSelector,
  useModal,
  useToast,
} from "../../../hooks";
import { date } from "../../../utils";
import { removeJob, updateJob } from "../../../store";
import { ROUTER } from "../../../routes";

export const JobApplicationJobDetailSidebar: React.FC<ISidebar> = (props) => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();
  const { toast } = useToast();

  const {
    account: { data: account },
  } = useAppSelector((state) => state.account);
  const {
    job: { data: job, isLoading },
  } = useAppSelector((state) => state.job);

  const { modal, setModal } = useModal();

  const profile = account?.profile;
  const profileId = profile?.id;
  const companyId = job?.companyId;

  const isOwner = account?.ownership?.id === companyId;
  const isEmployee = account?.employment?.id === companyId;
  const isEmployed = isOwner || isEmployee;

  return (
    <Sidebar {...props}>
      <RBAC
        roles={[Role.Admin, Role.User]}
        conditions={[
          {
            roles: [Role.Owner, Role.TeamLead, Role.Employee],
            condition: isEmployed,
          },
        ]}
      >
        <SidebarContent>
          <SidebarSection heading="Job Details" className="border-t-0">
            <Container loading={isLoading}>
              <div className="flex flex-row justify-between items-center gap-x-[10px]">
                <h3 className="capitalize text-base font-medium text-white">
                  {job?.title}
                </h3>
              </div>

              <div className="flex flex-row justify-start flex-wrap items-center capitalize gap-x-[10px] text-sm font-medium mt-[10px]">
                <div className="flex flex-row justify-between items-center gap-x-[5px]">
                  <MonitorIcon />
                  <span>{job?.type}</span>
                </div>
                <div className="flex flex-row justify-between items-center gap-x-[5px]">
                  <MapIcon />
                  <span>
                    {job?.city}, {job?.country}
                  </span>
                </div>
                <div className="flex flex-row justify-between items-center gap-x-[5px]">
                  <UsersIcon />
                  <span>{job?.positions} Positions Available</span>
                </div>
              </div>

              <div className="flex flex-col gap-y-[50px] mt-[30px]">
                {!!job?.description?.length && (
                  <div className="text-base font-medium">
                    <h4 className="text-white text-base font-medium mb-[10px]">
                      Description
                    </h4>
                    <p className="text-sm">{job?.description}</p>
                  </div>
                )}

                {!!job?.skills?.length && (
                  <div className="text-base font-medium">
                    <h4 className="text-white text-base font-medium mb-[10x]">
                      Experience Required
                    </h4>
                    <div className="flex flex-col gap-y-[15px] mt-[18px]">
                      <BulletPointList
                        data={job?.skills?.map((i) => i?.name)?.join("\n")}
                      />
                    </div>
                  </div>
                )}

                {!!job?.requirements?.length && (
                  <div className="text-base font-medium">
                    <h4 className="text-white text-base font-medium">
                      Requirements
                    </h4>
                    <div className="flex flex-col gap-y-[15px] mt-[10px]">
                      <BulletPointList data={job?.requirements} />
                    </div>
                  </div>
                )}

                {!!job?.desiredSkills?.length && (
                  <div className="text-base font-medium">
                    <h4 className="text-white text-base font-medium">
                      Desired Skills
                    </h4>
                    <div className="flex flex-col gap-y-[15px] mt-[10px]">
                      <BulletPointList data={job?.desiredSkills} />
                    </div>
                  </div>
                )}
              </div>
            </Container>
          </SidebarSection>

          {/* <SidebarSection heading="More Jobs posted by you">
            <DevelopingFeatureInfoMessage />
          </SidebarSection> */}
        </SidebarContent>
      </RBAC>
    </Sidebar>
  );
};
