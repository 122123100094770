export enum VerificationStatus {
  Pending = "pending",
  Approved = "approved",
  Rejected = "rejected",
}

export enum VerificationType {
  Experience = "experience",
  Education = "education",
}
