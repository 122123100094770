import React, { useRef } from "react";

import { FiEdit as EditIcon } from "react-icons/fi";

import { Form, Link, Loader, AvatarStatus } from "./../../components";

interface IAvatarProps {
  children?: string | null;
  className?: string;
  status?: "success" | "warning" | "danger" | null;
  to?: string | null;
  src?: string | null;
  alt?: string;
  size?: number;
  loading?: boolean;
  editing?: boolean;
  onChange?: (e?: React.ChangeEvent) => void;
}

export const Avatar: React.FC<IAvatarProps> = ({
  children,
  status,
  to,
  alt,
  size,
  src,
  className,
  loading,
  editing,
  onChange,
  ...props
}) => {
  const filePickerRef = useRef<HTMLInputElement>(null);

  const handleFileChange = () => {
    if (!filePickerRef.current) return;

    // @ts-ignore
    filePickerRef.current.value = null;
    filePickerRef.current?.click();
  };

  return (
    <div
      className={`${className} relative flex flex-row justify-center items-center rounded-full bg-secondary text-white font-gilroy font-medium select-none`}
      style={{
        width: size,
        height: size,
        minWidth: size,
        minHeight: size,
      }}
    >
      {loading ? (
        <div className="absolute w-full h-full flex flex-row justify-center items-center bg-secondary z-10 rounded-full overflow-hidden">
          <Loader size={(size || 20) / 5} className="text-white" />
        </div>
      ) : (
        <>
          {src ? (
            <div className="bg-secondary overflow-hidden rounded-full">
              <img
                src={src}
                className={`w-full h-full pointer-events-none`}
                alt={alt}
              />
            </div>
          ) : (
            <>{children?.slice(0, 1)}</>
          )}

          {to && <Link to={to} className="absolute w-full h-full" />}
          {status && (
            <div className="absolute bottom-[0.125rem] right-[0.075rem]">
              <AvatarStatus status={status} />
            </div>
          )}

          <div
            className={`${
              editing
                ? "transition-all flex flex-row justify-center items-center opacity-100 cursor-pointer hover:opacity-100"
                : "hidden"
            } absolute w-full h-full rounded-full overflow-hidden`}
          >
            <div className="absolute w-full h-full bg-black opacity-40 z-10 bg-black"></div>
            <div
              className="w-full h-full flex flex-row justify-center items-center opacity-100 z-20"
              onClick={handleFileChange}
            >
              <input
                id="upload"
                name="avatar"
                type="file"
                accept="image/*"
                ref={filePickerRef}
                hidden
                onChange={onChange}
              />

              <div className="z-20 w-[48px] h-[48px] flex flex-row justify-center items-center bg-primaryLight text-white rounded-full cursor-pointer">
                <EditIcon size={18} />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

Avatar.defaultProps = {
  className: "",
  status: null,
  size: 20,
  src: null,
  alt: "",
  loading: false,
  editing: false,
};
