import React, { useEffect } from "react";

import { Container, DataList, PageHeader } from "../../components";
import { PostCard, PostCreateForm } from "..";

import { getUserPosts } from "../../store";

import {
  useAppDispatch,
  useAppSelector,
  useDrawer,
  useQueryParams,
} from "../../hooks";

export const UserPostsContainer = () => {
  const dispatch = useAppDispatch();

  const { queryParams, setQueryParams, isQueryParams } = useQueryParams();
  const { setDrawer, resetDrawer } = useDrawer();

  const { page } = queryParams || {};

  const {
    posts: {
      data: posts,
      results: postCount,
      isLoading: postInitialLoading,
      isLoadingMore: postLoading,
      page: postPage,
      pageSize: postPageSize,
      pageCount: postPageCount,
    },
  } = useAppSelector((state) => state.post);

  const handleLoad = async () => {
    const nextPage = page ? page + 1 : 1;

    setQueryParams({ page: nextPage });
  };

  useEffect(() => {
    if (!isQueryParams) {
      setQueryParams({ page: 1 });
      return;
    }

    if (!page) return;

    const actionMode = page > 1 ? "append" : "get";

    dispatch(getUserPosts(queryParams, actionMode));
  }, [queryParams]);

  useEffect(() => {
    setQueryParams({ page: 1 });
  }, []);

  return (
    <Container loading={postInitialLoading}>
      <PageHeader back={false}>Home</PageHeader>
      <PostCreateForm />
      <div className="mt-[15px]">
        <DataList
          data={posts}
          loading={postLoading}
          results={postCount}
          page={postPage}
          pageSize={postPageSize}
          pageCount={postPageCount}
          ElementComponent={PostCard}
          onLoad={handleLoad}
        />
      </div>
    </Container>
  );
};
