import React, { useEffect, useRef, useState } from "react";

import { IFilterTag } from "@types";

import { Generator } from "../../utils";
import {
  Tag,
  TextField,
  InputError,
  FilterTag,
  ITextFieldProps,
} from "./../../components";

interface IAdvancedFilterInputProps extends ITextFieldProps {
  filterId: string;
  values?: string[];
  autoComplete?: "on" | "off";
  separators?: string[];
  className?: string;
  labelClassName?: string;
  placeholder?: string;
  onExisting?: (e?: any) => void;
  setError?: (error: string) => void;
  onRemove?: (e?: any) => void;
  onChange?: (e?: any) => void;
  onBlur?: (e?: any) => void;
  // [x: string]: any;
}

export const AdvancedFilterInput: React.FC<IAdvancedFilterInputProps> = ({
  filterId,
  values,
  error,
  errorMessage,
  className,
  labelClassName,
  setError,
  separators,
  onExisting,
  onRemove,
  onChange,
  onBlur,
  ...props
}) => {
  const [value, setValue] = useState<string>("");

  const [tags, setTags] = useState<IFilterTag[]>([]);

  const inputRef = useRef<HTMLFormElement>(null);

  const appendValue = (tag: IFilterTag) => {
    const isEmpty = (value: string) => value?.length <= 0 || false;

    const isDuplicated = (value: string) =>
      tags?.find((tag) => tag.value?.toLowerCase() === value?.toLowerCase());

    if (isDuplicated(tag.value) || isEmpty(tag.value)) return;

    setTags([...tags, tag]);
    setValues([...tags, tag]);
  };

  const clearValue = () => {
    setValue("");
  };

  const setValues = (tags: IFilterTag[]) => {
    if (onChange) {
      onChange(tags?.map(({ value }) => value || []));
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e?.target?.value;
    const isEmpty = !value?.length;

    if (isEmpty) {
      return clearValue();
    }

    setValue(value);
  };

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const key = e.key;

    const separated = separators?.some((i) => i === key);

    const formatValue = (s: string): string =>
      separators?.some((i) => s?.endsWith(i))
        ? s?.slice(0, s.length - 1)?.trim()
        : value?.trim();

    if (separated) {
      appendValue({
        id: Generator.uuid(),
        filterId,
        value: formatValue(value),
      });

      return clearValue();
    }
  };

  const handleDelete = (id: string) => {
    const result = tags?.filter((tag) => tag?.id !== id) || [];

    setTags(result);
    setValues(result);

    if (onRemove) {
      onRemove(id);
    }
  };

  const handleClear = () => {
    setTags([]);
    setValues([]);
  };

  useEffect(() => {
    if (!values?.length) {
      return setTags([]);
    }

    const tags: IFilterTag[] = Array.isArray(values)
      ? values?.map((value) => ({ id: Generator.uuid(), filterId, value }))
      : [values]?.map((value) => ({
          id: Generator.uuid(),
          filterId,
          value,
        }));

    setTags(tags);
  }, [values]);

  return (
    <div className="bg-primaryLight text-white rounded-lg">
      <TextField
        ref={inputRef}
        variant="standard"
        labelClassName="text-secondary"
        className={`
          z-20
          ${!!tags?.length ? "!rounded-b-none" : "!rounded-full"}
        `}
        value={value}
        errorMessage={null}
        onChange={handleChange}
        onKeyUp={handleOnKeyDown}
        onBlur={onBlur}
        {...props}
      />
      {tags?.length ? (
        <div
          className={`z-10 flex flex-col flex-wrap gap-y-[10px] w-full bg-inherit rounded-b-lg box-border px-[10px] py-[10px]`}
        >
          <div className="flex flex-wrap justify-start items-center gap-x-[5px] gap-y-[5px]">
            {tags?.map((tag) => (
              <FilterTag
                key={tag.id}
                {...tag}
                className="z-10 flex flex-row justify-center items-center h-[50px] px-[8px] bg-primaryMid box-border rounded-lg h-[20px] text-xs font-medium capitalize"
                onDelete={() => handleDelete(tag.id)}
              ></FilterTag>
            ))}
          </div>
          <div className="text-xs text-secondary font-medium cursor-pointer ml-[5px]">
            <span onClick={handleClear}>Clear All</span>
          </div>
        </div>
      ) : (
        <></>
      )}
      <InputError
        className="mt-[5px]"
        error={errorMessage?.toString() || null}
      />
    </div>
  );
};

AdvancedFilterInput.defaultProps = {
  values: [],
  separators: ["Enter", ",", ";"],
  autoComplete: "off",
};
