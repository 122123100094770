import React from "react";

import colors from "tailwindcss/colors";
import { Switch as MuiSwitch } from "@mui/material";

interface ISwitchProps {
  className?: string;
  leftLabel?: string;
  rightLabel?: string;
  value?: boolean;
  onChange?: (e?: boolean) => void;
}

export const Switch: React.FC<ISwitchProps> = ({
  className,
  leftLabel,
  rightLabel,
  value,
  onChange,
}) => {
  const isChecked = !!value;

  return (
    <div
      className={`${className} flex flex-row justify-start items-center text-xs font-medium`}
    >
      {leftLabel && (
        <span className={!isChecked ? "text-blue-500" : "text-inherit"}>
          {leftLabel}
        </span>
      )}
      <MuiSwitch
        className="flex flex-row justify-center items-center h-[28px]"
        sx={{
          ".MuiSwitch-track": {
            background: "transparent",
            border: `2px solid ${
              !isChecked ? colors.gray[500] : colors.blue[500]
            }`,
            width: "32px",
            height: "16px",
            borderRadius: "10px",
            boxSizing: "border-box",
          },
          ".MuiSwitch-thumb": {
            width: "8px",
            height: "8px",
            background: !isChecked ? colors.gray[500] : colors.blue[500],
          },

          "& .MuiSwitch-switchBase": {
            transform: "translateX(8px) translateY(1px)",
            "&.Mui-checked": {
              transform: "translateX(24px) translateY(1px)",
              "& + .MuiSwitch-track": {
                background: "transparent",
                border: `2px solid ${
                  !isChecked ? colors.gray[500] : colors.blue[500]
                }`,
                width: "32px",
                height: "16px",
                borderRadius: "10px",
              },
              "& + .MuiSwitch-thumb": {
                width: "8px",
                height: "8px",
                background: colors.red[500],
              },
            },

            "&.Mui-disabled + .MuiSwitch-track": {
              background: colors.red[900],
              border: `2px solid ${colors.gray[500]}`,
              width: "30px",
              height: "16px",
              borderRadius: "10px",
            },
          },
        }}
        value={value}
        checked={isChecked}
        onChange={(e, value) => (onChange ? onChange(Boolean(value)) : {})}
        disableRipple
      />
      {rightLabel && (
        <span className={isChecked ? "text-red-500" : "text-inherit"}>
          {rightLabel}
        </span>
      )}
    </div>
  );
};

Switch.defaultProps = {
  value: false,
};

export const PrivateModeSwitch: React.FC<ISwitchProps> = ({
  className,
  leftLabel,
  rightLabel,
  value,
  onChange,
}) => {
  const isChecked = !!value;

  return (
    <div
      className={`${className} flex flex-row justify-start items-center text-xs font-medium`}
    >
      {leftLabel && (
        <span className={!isChecked ? "text-blue-500" : "text-inherit"}>
          {leftLabel}
        </span>
      )}
      <MuiSwitch
        className="flex flex-row justify-center items-center h-[28px]"
        sx={{
          ".MuiSwitch-track": {
            background: "transparent",
            border: `2px solid ${
              !isChecked ? colors.blue[500] : colors.red[500]
            }`,
            width: "32px",
            height: "16px",
            borderRadius: "10px",
            boxSizing: "border-box",
          },
          ".MuiSwitch-thumb": {
            width: "8px",
            height: "8px",
            background: !isChecked ? colors.blue[500] : colors.red[500],
          },

          "& .MuiSwitch-switchBase": {
            transform: "translateX(8px) translateY(1px)",
            "&.Mui-checked": {
              transform: "translateX(24px) translateY(1px)",
              "& + .MuiSwitch-track": {
                background: "transparent",
                border: `2px solid ${
                  !isChecked ? colors.blue[500] : colors.red[500]
                }`,
                width: "32px",
                height: "16px",
                borderRadius: "10px",
              },
              "& + .MuiSwitch-thumb": {
                width: "8px",
                height: "8px",
                background: colors.red[500],
              },
            },

            "&.Mui-disabled + .MuiSwitch-track": {
              background: colors.red[900],
              border: `2px solid ${colors.gray[500]}`,
              width: "30px",
              height: "16px",
              borderRadius: "10px",
            },
          },
        }}
        value={value}
        onChange={(e, value) => (onChange ? onChange(Boolean(value)) : {})}
        disableRipple
      />
      {rightLabel && (
        <span className={isChecked ? "text-red-500" : "text-inherit"}>
          {rightLabel}
        </span>
      )}
    </div>
  );
};

PrivateModeSwitch.defaultProps = {
  value: false,
};
