import React from "react";

import { FiArrowUp } from "react-icons/fi";

import { IconButton } from "../../components";
import { useScroll } from "../../hooks";

interface IScrollTopButtonProps {}

export const ScrollTopButton: React.FC<IScrollTopButtonProps> = () => {
  const { scrollTop } = useScroll();

  const handleScrollTop = () => {
    scrollTop();
  };

  return (
    <div className="w-[50px] h-[50px] flex flex-row">
      <IconButton
        className="w-[50px] h-[50px] rounded-full bg-primaryLight hover:bg-primaryMid"
        icon={FiArrowUp}
        iconProps={{ size: 20 }}
        onClick={handleScrollTop}
      />
    </div>
  );
};
