import React from "react";

import { FiLayers as StackIcon, FiAward as AwardIcon } from "react-icons/fi";

import { ModalType } from "../../../shared";

import { Modal } from "../../../components";
import { useAppDispatch, useModal } from "../../../hooks";

export const ResumeDigitalAssetAddModal = () => {
  const dispatch = useAppDispatch();

  const { modal, isOpen, setModal, closeModal } = useModal({
    type: ModalType.RESUME_DIGITAL_ASSET_ADD,
  });

  const { props } = modal;
  const { resumeId, createdById } = props || {};

  const handleDigitalAssetAdd = () => {
    setModal({
      type: ModalType.RESUME_DIGITAL_ASSET_SELECT_ASSET,
      props: { resumeId, createdById },
    });
  };

  return (
    <Modal
      title="Add Digital Portfolio Asset"
      description="Add your creative assets to your resume to make it impactful."
      className="max-w-[360px]"
      open={isOpen}
      onClose={closeModal}
    >
      <div className="flex flex-row justify-between items-center gap-[10px] mt-[10px]">
        <div
          className="relative transition-all flex flex-col justify-center items-center gap-[15px] w-[150px] h-[150px] bg-primaryLight rounded-lg select-none cursor-pointer overflow-hidden p-[20px] box-border hover:brightness-125"
          onClick={handleDigitalAssetAdd}
        >
          <StackIcon size={40} className="text-secondary" />
          <span className="text-white text-xs font-semibold text-center">
            Add Digital Assets
          </span>
        </div>
        <div className="relative transition-all flex flex-col justify-center items-center gap-[15px] w-[150px] h-[150px] bg-primaryLight rounded-lg select-none cursor-not-allowed overflow-hidden p-[20px] box-border">
          <AwardIcon size={40} className="text-secondary brightness-50" />
          <span className="text-white text-xs font-semibold text-center brightness-50">
            Add Certificates
          </span>
        </div>
      </div>
    </Modal>
  );
};
