import { ApiService } from "../../../services";
import { AppDispatch } from "../../store";
import { removeEducation, updateEducation } from "../education";
import { removeExperience, updateExperience } from "../experience";
import { updateProfileCover, removeProfileCover } from "../profile-cover";

import { resumeSlice } from "./resume.slice";

export const appendResumes = (payload: any) => async (dispatch: AppDispatch) =>
  dispatch(resumeSlice.actions.appendResumes(payload));

export const popResumes = (payload: any) => async (dispatch: AppDispatch) =>
  dispatch(resumeSlice.actions.popResumes(payload));

export const updateResumeState =
  (payload: any) => async (dispatch: AppDispatch) =>
    dispatch(resumeSlice.actions.updateResume(payload));

export const getResumes = (options?: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(resumeSlice.actions.getResumesRequest());
    console.log({ options });

    const response = await ApiService.post("resumes/query", options);
    const { data, results } = response.data;

    dispatch(resumeSlice.actions.getResumesSuccess({ data, results }));

    return data;
  } catch (e: any) {
    dispatch(resumeSlice.actions.getResumesError(e.response?.data?.error));
  }
};

export const getResume = (id: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(resumeSlice.actions.getResumeRequest());

    const response = await ApiService.get(`resumes/${id}`);
    const { data } = response.data;

    dispatch(resumeSlice.actions.getResumeSuccess(data));

    return data;
  } catch (e: any) {
    dispatch(resumeSlice.actions.getResumeError(e.response?.data?.error));
  }
};

export const getResumeById =
  (resumeId: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(resumeSlice.actions.getResumeRequest());

      const response = await ApiService.get(`resumes/${resumeId}`);
      const { data } = response.data;

      dispatch(resumeSlice.actions.getResumeSuccess(data));

      return data;
    } catch (e: any) {
      dispatch(resumeSlice.actions.getResumeError(e.response?.data?.error));
    }
  };

export const getDefaultResume = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(resumeSlice.actions.getDefaultResumeRequest());

    const response = await ApiService.get(`resumes/default`);
    const { data } = response.data;

    dispatch(resumeSlice.actions.getDefaultResumeSuccess(data));

    return data;
  } catch (e: any) {
    console.log({ e });
    dispatch(
      resumeSlice.actions.getDefaultResumeError(e.response?.data?.error)
    );
  }
};

export const createResume = (payload: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(resumeSlice.actions.createResumeRequest());

    const response = await ApiService.post("resumes", payload);
    const { data } = response.data;

    dispatch(resumeSlice.actions.createResumeSuccess(data));
    dispatch(resumeSlice.actions.appendResumes(data));

    return data;
  } catch (e: any) {
    dispatch(resumeSlice.actions.createResumeError(e.response?.data?.error));
  }
};

export const updateResume =
  (id: string, payload: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(resumeSlice.actions.updateResumeRequest());

      const response = await ApiService.put(`resumes/${id}`, payload);
      const { data } = response.data;

      dispatch(resumeSlice.actions.updateResumeSuccess(data));

      return id;
    } catch (e: any) {
      dispatch(resumeSlice.actions.updateResumeError(e.response?.data?.error));
    }
  };

export const copyResumeAsDefault =
  (id: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(resumeSlice.actions.copyResumeAsDefaultRequest());

      const response = await ApiService.put(`resumes/${id}/default`);
      const { data } = response.data;

      dispatch(resumeSlice.actions.copyResumeAsDefaultSuccess(data));

      return data;
    } catch (e: any) {
      console.log({ e });
      dispatch(
        resumeSlice.actions.copyResumeAsDefaultError(e.response?.data?.error)
      );
    }
  };

export const removeResume = (id: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(resumeSlice.actions.removeResumeRequest());

    const response = await ApiService.delete(`resumes/${id}`);
    const { data } = response.data;

    await dispatch(resumeSlice.actions.removeResumeSuccess(data));
    await dispatch(resumeSlice.actions.popResumes({ id }));

    return id;
  } catch (e: any) {
    console.log("error", { e });
    dispatch(resumeSlice.actions.removeResumeError(e.response?.data?.error));
  }
};

// Covers
export const getResumeCovers =
  (id: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(resumeSlice.actions.getResumeCoversRequest());

      const response = await ApiService.get(`resumes/${id}/covers`);
      const { data } = response.data;

      dispatch(resumeSlice.actions.getResumeCoversSuccess(data));

      return id;
    } catch (e: any) {
      console.log({ e });
      dispatch(
        resumeSlice.actions.getResumeCoversError(e.response?.data?.error)
      );
    }
  };

export const createResumeCover =
  (id: string, payload: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(resumeSlice.actions.createResumeCoverRequest());

      const response = await ApiService.post(`resumes/${id}/covers`, payload);
      const { data } = response.data;

      dispatch(resumeSlice.actions.createResumeCoverSuccess(data));

      dispatch(resumeSlice.actions.appendResumeCover(data));

      return id;
    } catch (e: any) {
      console.log({ e });
      dispatch(
        resumeSlice.actions.createResumeCoverError(e.response?.data?.error)
      );
    }
  };

export const updateResumeCover =
  (id: string, payload: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(updateProfileCover(id, payload));
      dispatch(
        resumeSlice.actions.appendResumeCover({
          id,
          ...payload,
        })
      );

      return id;
    } catch (e: any) {
      console.log({ e });
      dispatch(
        resumeSlice.actions.createResumeCoverError(e.response?.data?.error)
      );
    }
  };

export const removeResumeCover =
  (id: string) => async (dispatch: AppDispatch) => {
    try {
      const result = await dispatch(removeProfileCover(id));

      dispatch(resumeSlice.actions.popResumeCover(id));

      return result;
    } catch (e: any) {
      console.log({ e });
      dispatch(
        resumeSlice.actions.createResumeCoverError(e.response?.data?.error)
      );
    }
  };

// Experiences
export const getResumeExperiences =
  (id: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(resumeSlice.actions.getResumeExperiencesRequest());

      const response = await ApiService.get(`resumes/${id}/experiences`);
      const { data } = response.data;

      dispatch(resumeSlice.actions.getResumeExperiencesSuccess(data));

      return id;
    } catch (e: any) {
      console.log({ e });
      dispatch(
        resumeSlice.actions.getResumeExperiencesError(e.response?.data?.error)
      );
    }
  };

export const createResumeExperience =
  (id: string, payload: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(resumeSlice.actions.createResumeExperienceRequest());

      const response = await ApiService.post(
        `resumes/${id}/experiences`,
        payload
      );
      const { data } = response.data;

      dispatch(resumeSlice.actions.createResumeExperienceSuccess(data));

      dispatch(resumeSlice.actions.appendResumeExperience(data));

      return id;
    } catch (e: any) {
      console.log({ e });
      dispatch(
        resumeSlice.actions.createResumeExperienceError(e.response?.data?.error)
      );
    }
  };

export const updateResumeExperience =
  (id: string, payload: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(updateExperience(id, payload));
      dispatch(
        resumeSlice.actions.appendResumeExperience({
          id,
          ...payload,
        })
      );

      return id;
    } catch (e: any) {
      console.log({ e });
      dispatch(
        resumeSlice.actions.createResumeExperienceError(e.response?.data?.error)
      );
    }
  };

export const removeResumeExperience =
  (id: string) => async (dispatch: AppDispatch) => {
    try {
      const result = await dispatch(removeExperience(id));

      dispatch(resumeSlice.actions.popResumeExperience(id));

      return result;
    } catch (e: any) {
      console.log({ e });
      dispatch(
        resumeSlice.actions.createResumeExperienceError(e.response?.data?.error)
      );
    }
  };

// Educations
export const getResumeEducations =
  (id: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(resumeSlice.actions.getResumeEducationsRequest());

      const response = await ApiService.get(`resumes/${id}/educations`);
      const { data } = response.data;

      dispatch(resumeSlice.actions.getResumeEducationsSuccess(data));

      return id;
    } catch (e: any) {
      console.log({ e });
      dispatch(
        resumeSlice.actions.getResumeEducationsError(e.response?.data?.error)
      );
    }
  };

export const createResumeEducation =
  (id: string, payload: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(resumeSlice.actions.createResumeEducationRequest());

      const response = await ApiService.post(
        `resumes/${id}/educations`,
        payload
      );
      const { data } = response.data;

      dispatch(resumeSlice.actions.createResumeEducationSuccess(data));

      dispatch(resumeSlice.actions.appendResumeEducation(data));

      return id;
    } catch (e: any) {
      console.log({ e });
      dispatch(
        resumeSlice.actions.createResumeEducationError(e.response?.data?.error)
      );
    }
  };

export const updateResumeEducation =
  (id: string, payload: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(updateEducation(id, payload));
      dispatch(
        resumeSlice.actions.appendResumeEducation({
          id,
          ...payload,
        })
      );

      return id;
    } catch (e: any) {
      console.log({ e });
      dispatch(
        resumeSlice.actions.createResumeEducationError(e.response?.data?.error)
      );
    }
  };

export const removeResumeEducation =
  (id: string) => async (dispatch: AppDispatch) => {
    try {
      const result = await dispatch(removeEducation(id));

      dispatch(resumeSlice.actions.popResumeEducation(id));

      return result;
    } catch (e: any) {
      console.log({ e });
      dispatch(
        resumeSlice.actions.createResumeEducationError(e.response?.data?.error)
      );
    }
  };

export const addDigitalAssetsToResume =
  (resumeId: string, payload) => async (dispatch: AppDispatch) => {
    try {
      dispatch(resumeSlice.actions.addDigitalAssetsToResumeRequest());

      const response = await ApiService.put(
        `resumes/${resumeId}/digital-assets/add`,
        payload
      );
      const { data } = response.data;

      dispatch(resumeSlice.actions.addDigitalAssetsToResumeSuccess(data));

      return data;
    } catch (e: any) {
      console.log({ e });
      dispatch(
        resumeSlice.actions.addDigitalAssetsToResumeError(
          e.response?.data?.error
        )
      );
    }
  };

export const removeDigitalAssetsFromResume =
  (resumeId: string, payload: { digitalAssets: string[] }) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(resumeSlice.actions.removeDigitalAssetsFromResumeRequest());

      const response = await ApiService.put(
        `resumes/${resumeId}/digital-assets/delete`,
        payload
      );
      const { data } = response.data;

      console.log({ data });

      dispatch(resumeSlice.actions.removeDigitalAssetsFromResumeSuccess(data));

      return data;
    } catch (e: any) {
      console.log({ e });
      dispatch(
        resumeSlice.actions.removeDigitalAssetsFromResumeError(
          e.response?.data?.error
        )
      );
    }
  };
