import React from "react";

import { useFormik } from "formik";
import * as Yup from "yup";

import { Form, TextField, Button } from "../../../components";
import {
  useAccount,
  useAppDispatch,
  useLoading,
  useToast,
} from "../../../hooks";
import { FormValidator } from "../../../utils";
import {
  updatePasswordByAccountId,
  updateUserProfile,
  validatePasswordByAccountId,
} from "../../../store";
import { NotificationStatus } from "../../../shared";

interface IProfileLocationEditForm {
  country: string;
  state: string;
  city: string;
}

const validationSchema = Yup.object().shape({
  country: Yup.string()
    .max(100, "Exceeded maximum character length of 100")
    .notRequired(),
  state: Yup.string()
    .max(100, "Exceeded maximum character length of 100")
    .notRequired(),
  city: Yup.string()
    .max(100, "Exceeded maximum character length of 100")
    .notRequired(),
});

export const UserSettingsProfileLocationEditForm = () => {
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useLoading();
  const { toast } = useToast();
  const { account, activeProfile } = useAccount();

  const { country, state, city } = activeProfile || {};

  const form = useFormik<IProfileLocationEditForm>({
    validationSchema,
    initialValues: {
      country: country || "",
      state: state || "",
      city: city || "",
    },
    onSubmit: async (values) => {
      const data = { ...values };

      setLoading(true);

      // Updating the profile
      const updated = await dispatch(updateUserProfile(data));
      if (!updated?.id) {
        setLoading(false);
        toast({
          status: NotificationStatus.Error,
          message: `Settings haven't been saved.`,
        });
        return;
      }
      setLoading(false);
      toast({
        status: NotificationStatus.Success,
        message: `Settings have been saved.`,
      });
    },
  });

  const validator = new FormValidator<IProfileLocationEditForm>(form);

  const { values, resetForm, handleChange, handleSubmit } = form;

  return (
    <Form onSubmit={handleSubmit}>
      <div className="w-full flex flex-col gap-[10px]">
        <TextField
          id="country"
          label="Country"
          type="text"
          variant="filled"
          className="!bg-primaryDark !text-white"
          value={values?.country}
          errorMessage={validator.isInputValid("country")}
          onChange={handleChange}
        />
        <TextField
          id="state"
          label="State"
          type="text"
          variant="filled"
          className="!bg-primaryDark !text-white"
          value={values?.state}
          errorMessage={validator.isInputValid("state")}
          onChange={handleChange}
        />
        <TextField
          id="city"
          label="City"
          type="text"
          variant="filled"
          className="!bg-primaryDark !text-white"
          value={values?.city}
          errorMessage={validator.isInputValid("city")}
          onChange={handleChange}
        />
      </div>
      <div className="w-full flex flex-row justify-end items-center mt-[15px]">
        <Button
          variant="outlined"
          color="success"
          className="min-w-[150px]"
          loading={loading}
          onClick={() => handleSubmit()}
        >
          Save Changes
        </Button>
      </div>
    </Form>
  );
};
