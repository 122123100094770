import React, { Suspense } from "react";

import { StyledEngineProvider } from "@mui/material";

import { ErrorBoundary } from "./../../components";

export const AppProvider: React.FC = ({ children }) => {
  return (
    <ErrorBoundary>
      <StyledEngineProvider injectFirst>
        <Suspense fallback={<></>}>{children}</Suspense>
      </StyledEngineProvider>
    </ErrorBoundary>
  );
};
