import React from "react";
import { useNavigate } from "react-router";

import { useFormik } from "formik";
import * as Yup from "yup";

import {
  ModalType,
  NotificationStatus,
  NotificationType,
  NotificationPriority,
} from "../../../shared";

import { useAppDispatch, useLoading, useModal, useToast } from "../../../hooks";
import { createCalendarEvent, removeChat, removeJob } from "../../../store";

import {
  BasicDatePicker,
  BasicTimePicker,
  Button,
  Form,
  FormSection,
  Modal,
  TextField,
} from "../../../components";
import { date, FormValidator } from "../../../utils";

interface ICalendarEventCreateForm {
  name?: string;
  description?: string;
  startDate?: Date;
  startTime?: Date;
  finishDate?: Date;
  finishTime?: Date;
  startedAt?: Date;
  finishedAt?: Date;
}

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .max(100, "Exceeded maximum character length of 100")
    .required("Title is a required field"),
  description: Yup.string()
    .max(1500, "Exceeded maximum character length of 1500")
    .notRequired(),
  startDate: Yup.date()
    .nullable()
    .typeError("Invalid date format")
    .required("Date is a required field"),
  startTime: Yup.date()
    .nullable()
    .typeError("Invalid date format")
    .max(Yup.ref("finishTime"), "Start can't be more than End Time")
    .required("Start Time is a required field"),
  finishTime: Yup.date()
    .nullable()
    .typeError("Invalid date format")
    .min(Yup.ref("startTime"), "End Time can't be less than Start Time")
    .required("End Time is a required field"),
});

export const CalendarEventCreateModal = () => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const { modal, isOpen, closeModal } = useModal({
    type: ModalType.CALENDAR_EVENT_CREATE,
  });

  const { toast } = useToast();
  const [loading, setLoading] = useLoading();

  const {
    props: { startedAt },
  } = modal;

  const form = useFormik<ICalendarEventCreateForm>({
    initialValues: {
      name: "",
      description: "",
      startDate: startedAt || new Date(),
      startTime: new Date(),
      finishTime: date().add(1, "h").toDate() || new Date(),
    },
    validationSchema,
    onSubmit: async (values) => {
      const { name, description } = values;

      // Parsing Time
      const startedAt = values?.startDate
        ? new Date(values.startDate)
        : new Date();

      const finishedAt = values?.startDate
        ? new Date(values.startDate)
        : new Date();

      const startTime = values?.startTime
        ? new Date(values.startTime.toString())
        : new Date();

      const finishTime = values?.finishTime
        ? new Date(values.finishTime.toString())
        : new Date();

      startedAt.setHours(startTime.getHours());
      startedAt.setMinutes(startTime.getMinutes());

      finishedAt.setHours(finishTime.getHours());
      finishedAt.setMinutes(finishTime.getMinutes());

      // Creating Event
      const data = {
        name,
        description,
        startedAt,
        finishedAt,
      };

      setLoading(true);

      const calendarEvent = await dispatch(createCalendarEvent(data));
      if (!calendarEvent?.id) {
        handleCancel();

        toast({
          status: NotificationStatus.Error,
          message: "Calendar Event Not Created!",
        });

        return setLoading(false);
      }

      setLoading(false);
      closeModal();
    },
  });

  const validator = new FormValidator<ICalendarEventCreateForm>(form);

  const {
    values,
    errors,
    resetForm,
    setFieldValue,
    handleChange,
    handleSubmit,
  } = form;

  const handleClose = () => {
    closeModal();
  };

  const handleCancel = () => {
    handleClose();
    resetForm();
  };

  return (
    <Modal
      title="Create Event"
      description="Please create your next event using the form below."
      open={isOpen}
      onClose={handleClose}
    >
      <Form onSubmit={handleSubmit}>
        <div className="w-full flex flex-col text-white gap-y-[10px]">
          <div className="flex flex-row justify-start gap-x-[10px] gap-y-[10px] md:flex-row xxs:flex-col">
            <TextField
              id="name"
              label="Title"
              type="text"
              variant="filled"
              className="!bg-primaryDark !text-white"
              labelClassName="text-secondary"
              inputClassName="text-inherit"
              value={values?.name}
              errorMessage={validator.isInputValid("name")}
              autoFocus
              onChange={handleChange}
            />
          </div>

          <div className="flex text-white h-auto gap-x-[10px] gap-y-[10px] md:flex-row xxs:flex-col">
            <BasicDatePicker
              id="startDate"
              label="Date"
              value={values?.startDate}
              errorMessage={validator.isInputValid("startDate")}
              className="!bg-primaryDark !text-white"
              onChange={(value) => setFieldValue("startDate", value, true)}
            />

            <div className="flex flex-row gap-[10px]">
              <div className="xs:basis-full md:basis-6/12">
                <BasicTimePicker
                  id="startTime"
                  label="Start Time"
                  value={values?.startTime}
                  errorMessage={validator.isInputValid("startTime")}
                  className="!bg-primaryDark !text-white"
                  onChange={(value) => setFieldValue("startTime", value, true)}
                />
              </div>
              <div className="xs:basis-full md:basis-6/12">
                <BasicTimePicker
                  id="finishTime"
                  label="End Time"
                  value={values?.finishTime}
                  errorMessage={validator.isInputValid("finishTime")}
                  className="!bg-primaryDark !text-white"
                  onChange={(value) => setFieldValue("finishTime", value, true)}
                />
              </div>
            </div>
          </div>

          <div>
            <TextField
              id="description"
              label="Description"
              type="text"
              variant="filled"
              className="!bg-primaryDark !text-white"
              controlClassName="basis-full"
              labelClassName="text-secondary"
              multiline
              minRows={2}
              maxRows={5}
              value={values?.description}
              errorMessage={validator.isInputValid("description")}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="flex flex-row justify-end items-center mt-[15px] gap-x-[10px] gap-y-[10px] md:flex-row xxs:flex-col-reverse">
          <Button
            color="secondary"
            variant="outlined"
            className="md:w-auto xxs:w-full"
            onClick={handleCancel}
          >
            Discard
          </Button>
          <Button
            type="submit"
            color="success"
            variant="outlined"
            className="min-w-[125px] md:w-auto xxs:w-full"
            loading={loading}
          >
            Create Event
          </Button>
        </div>
      </Form>
    </Modal>
  );
};
