import React from "react";

import { ISidebar } from "@types";

import { SidebarSection, Sidebar, SidebarContent } from "../../../components";

export const VideoCallRightSidebar: React.FC<ISidebar> = (props) => (
  <Sidebar {...props}>
    <SidebarContent>
      <SidebarSection
        heading="Live Chat"
        className="border-none"
      ></SidebarSection>
    </SidebarContent>
  </Sidebar>
);
