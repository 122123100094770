import React, { useEffect, useState } from "react";

import { FormikErrors } from "formik";
import { FiClock } from "react-icons/fi";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import { ITextFieldProps, TextField } from "./../../components";

interface IBasicTimePickerProps {
  id?: string;
  label?: string;
  value?: Date | string | null;
  errorMessage?: string | null;
  className?: string;
  disabled?: boolean;
  required?: boolean;
  onChange?: (
    value: string | Date | null | undefined,
    keyboardInputValue?: string | undefined
  ) => void;
}

export const BasicTimePicker: React.FC<IBasicTimePickerProps> = ({
  id,
  label,
  value,
  errorMessage,
  className,
  disabled,
  onChange,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        label={label}
        value={value}
        ampm={false}
        mask="__:__"
        minutesStep={5}
        disableOpenPicker
        renderInput={(props: any) => (
          <TextField
            {...props}
            id={id}
            variant="filled"
            errorMessage={errorMessage}
            className={className}
            controlClassName="basis-1/2"
            labelClassName="text-secondary"
          />
        )}
        components={{
          OpenPickerIcon: !disabled ? FiClock : () => <></>,
        }}
        onChange={onChange ? onChange : () => {}}
        {...{ disabled }}
      />
    </LocalizationProvider>
  );
};

BasicTimePicker.defaultProps = {};
