import React, { useEffect, useState } from "react";

import { useLocation, useNavigate, useParams } from "react-router";

import {
  FiMapPin as MapIcon,
  FiMonitor as MonitorIcon,
  FiUsers as UsersIcon,
  FiArrowLeft as ArrowLeftIcon,
  FiChevronRight as ChevronRight,
  FiEdit as EditIcon,
} from "react-icons/fi";

import {
  Avatar,
  Button,
  Card,
  Container,
  InfoCard,
  PageHeader,
  VerifiedAccountBadge,
} from "../../components";
import { PostList } from "..";
import { ROUTER } from "../../routes";
import {
  ModalType,
  NotificationStatus,
  NotificationType,
  NotificationPriority,
} from "../../shared";
import {
  addFriend,
  removeFriend,
  getPosts,
  getProfileById,
  updateUserAvatar,
  createChat,
  getCompanyById,
  getCompanyByAlias,
} from "../../store";
import { useAppDispatch, useAppSelector, useLoading } from "../../hooks";

export const CompanyDetailContainer = () => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const { companyId, companyAlias } = useParams();

  const [editMode, setEditMode] = useState(false);
  const [editLoading, setEditLoading] = useLoading();

  const {
    account: { data: account },
  } = useAppSelector((state) => state.account);

  const {
    company: { data: company, isLoading: companyLoading },
  } = useAppSelector((state) => state.company);

  const { ownerId } = company;
  const { name, logo, country, state, city } = company?.profile || {};

  const profile = account?.profile;
  const location = [country, state, city]?.filter((i) => !!i)?.join(", ");

  const isBusinessUser = profile?.isBusiness || false;
  const isOwner = isBusinessUser && ownerId === account?.id;

  const handleEditModeSwitch = async () => {
    if (editMode) {
      setEditLoading(true);

      return setTimeout(() => {
        setEditMode(!editMode);
        setEditLoading(false);
      }, 500);
    }

    setEditMode(!editMode);
  };

  useEffect(() => {
    if (companyId) {
      console.log({ companyId });

      dispatch(getCompanyById(companyId));
    }

    if (companyAlias) {
      dispatch(getCompanyByAlias(companyAlias));
    }
  }, [companyId, companyAlias]);

  return (
    <Container
      className="w-full flex flex-col gap-y-[20px] py-[15px]"
      loading={companyLoading}
    >
      <div className="relative w-full h-[325px] flex flex-col justify-end rounded-lg overflow-hidden">
        <div className="absolute w-full h-full bg-gray-500 z-10">
          {/* {editMode && (
            <>
              <div className="z-10 absolute w-full h-full bg-black opacity-40"></div>
              <div className="z-20 absolute top-[20px] right-[20px] w-[48px] h-[48px] flex flex-row justify-center items-center bg-primaryLight text-white rounded-full cursor-pointer">
                <EditIcon size={18} />
              </div>
            </>
          )} */}
        </div>

        <div className="relative z-30 w-full flex flex-col justify-end px-[25px]">
          <div className="w-full h-[80px] flex flex-row items-center box-border text-white text-xl font-bold">
            <Avatar
              // loading={avatarLoading}
              size={128}
              src={logo}
              className="border-[0px] border-white text-xl"
              // onChange={handleAvatarUpload}
              // editing={editMode}
            >
              {name}
            </Avatar>
            <div className="flex flex-col items-start ml-[18px]">
              <div className="flex flex-row justify-start items-center gap-x-[5px]">
                <h2>{name || ""}</h2>
                <VerifiedAccountBadge />
              </div>
              <span className="text-white text-base font-medium">
                {location}
              </span>
            </div>
          </div>
        </div>

        <div className="w-full min-h-[55px] z-20 flex flex-row justify-between items-center bg-primaryLight box-border px-[20px] py-[15px]">
          <div></div>
          <div className="flex flex-row gap-x-[15px]">
            {isOwner ? (
              editMode ? (
                <Button
                  color="success"
                  loading={editLoading}
                  onClick={handleEditModeSwitch}
                >
                  Save Changes
                </Button>
              ) : (
                <Button color="secondary" onClick={handleEditModeSwitch}>
                  Edit Profile
                </Button>
              )
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>

      {/* {isMyProfile || isFriend ? (
        <div className="w-full h-full flex flex-row gap-x-[20px]">
          <div className="basis-4/12 w-full flex flex-col justify-start gap-y-[20px]">
            <InfoCard>
              <div className="flex flex-row justify-center items-center gap-x-[20px]">
                <div className="flex flex-col justify-center items-center gap-y-[2.5px]">
                  <span className="text-xl text-white font-bold">
                    {friendCount}
                  </span>
                  <span className="text-sm text-secondary font-medium">
                    Followers
                  </span>
                </div>
                <div className="w-[0.5px] h-full bg-primaryMid mx-[30px]"></div>
                <div className="flex flex-col justify-center items-center gap-y-[2.5px]">
                  <span className="text-xl text-white font-bold">0</span>
                  <span className="text-sm text-secondary font-medium">
                    Following
                  </span>
                </div>
              </div>
            </InfoCard>

            <InfoCard heading="About">
              <div>
                <p>
                  Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                  amet sint. Velit officia consequat duis enim velit mollit.
                </p>
              </div>

              {country && (
                <div className="flex flex-row justify-start items-center gap-x-[10px] font-medium">
                  <MapIcon size={20} className="text-secondary" />
                  <span>
                    {[country, state, city]?.filter((i) => i)?.join(", ")}
                  </span>
                </div>
              )}
            </InfoCard>

            <InfoCard
              heading="Digital Portfolio"
              className="px-[0px] pb-[0px]"
              headingClassName="px-[25px]"
            >
              <div className="flex flex-row flex-wrap">
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]?.map((i, key) => (
                  <div
                    key={key}
                    className={`basis-3/12 flex flex-row justify-center items-center h-[80px] bg-primaryMid border-[1px] border-primaryLight cursor-pointer transition-all hover:bg-gray-700`}
                  ></div>
                ))}
              </div>
            </InfoCard>

            <InfoCard
              heading="Gallery"
              className="px-[0px] pb-[0px]"
              headingClassName="px-[25px]"
            >
              <div className="flex flex-row flex-wrap">
                {[1, 2, 3, 4, 5, 6, 7, 8]?.map((i, key) => (
                  <div
                    key={key}
                    className={`basis-3/12 flex flex-row justify-center items-center h-[80px] bg-primaryMid border-[1px] border-primaryLight cursor-pointer transition-all hover:bg-gray-700`}
                  ></div>
                ))}
              </div>
            </InfoCard>
          </div>

          <div className="basis-8/12">
            <PostList />
          </div>
        </div>
      ) : (
        <></>
      )} */}
    </Container>
  );
};
