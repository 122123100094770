import React, { useEffect } from "react";

import { useNavigate } from "react-router";

import { useFormik } from "formik";
import * as Yup from "yup";

import { MdSend as SendIcon } from "react-icons/md";

import {
  Avatar,
  Button,
  Form,
  FormSection,
  TextField,
  IconButton,
  StarRating,
  Switch,
} from "../../../components";
import {
  useAppDispatch,
  useAppSelector,
  useLoading,
  useToast,
  useURL,
} from "../../../hooks";
import { addPostComment, createPost, createResume } from "../../../store";
import { ROUTER } from "../../../routes";
import { FormValidator, addLineBreaks } from "../../../utils";
import { NotificationStatus } from "../../../shared";

const validationSchema = Yup.object().shape({
  details: Yup.string()
    .max(1000, "Exceeded maximum character length of 1000")
    .required("Comment is a required field"),
});

interface IPostCommentCreateFormProps {
  postId?: string;
  onClose?: (e?: React.MouseEvent) => void;
  onCreate?: (value?: any) => void;
}

export const PostCommentCreateForm: React.FC<IPostCommentCreateFormProps> = ({
  postId,
  onClose,
  onCreate,
}) => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const [loading, setLoading] = useLoading();
  const [URL, setURL] = useURL();
  const { toast } = useToast();

  const {
    account: { data: account },
  } = useAppSelector((state) => state.account);

  const profile = account?.profile;
  const profileId = profile?.id;

  const { firstName, avatar } = profile || {};

  const form = useFormik({
    initialValues: { details: "" },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      if (!postId) return;

      const { details }: any = values;

      const data = {
        details: addLineBreaks(details),
      };

      setLoading(true);

      const comment = await dispatch(addPostComment(postId, data));
      if (!comment?.id) {
        setLoading(false);

        return toast({
          status: NotificationStatus.Error,
          message: "Comment Not Added!",
        });
      }

      if (onCreate) {
        onCreate(comment);
      }

      setLoading(false);
      resetForm();
    },
  });

  const validator = new FormValidator(form);

  const { values, errors, touched, handleChange, handleSubmit } = form;

  return (
    <div className="flex flex-row justify-start items-start gap-x-[10px]">
      <div className="h-[45px] flex flex-row justify-center items-center">
        <Avatar size={40} src={avatar}>
          {firstName}
        </Avatar>
      </div>
      <div className="w-full">
        <Form onSubmit={handleSubmit}>
          <div className="flex flex-row justify-between items-start gap-x-[10px]">
            <TextField
              id="details"
              type="text"
              variant="standard"
              className="h-auto min-h-[45px] !bg-primaryDark !text-white"
              labelClassName="text-secondary"
              placeholder="Typing a comment..."
              multiline
              maxRows={10}
              required
              value={values?.details}
              errorMessage={validator.isInputValid("details")}
              onChange={handleChange}
            />
            <div className="transition-all w-[60px] h-[45px] flex flex-row items-center bg-primaryMid rounded-lg hover:brightness-110">
              <IconButton
                icon={SendIcon}
                iconProps={{ size: 18 }}
                loading={loading}
                className="w-full h-full"
                onClick={() => handleSubmit()}
              />
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

PostCommentCreateForm.defaultProps = {};
