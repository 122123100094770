import { matchPath, useLocation } from "react-router";

interface IUsePathHook {}

export const usePath = () => {
  const pathname = useLocation().pathname;

  const isRouteActive = (pattern: string): boolean =>
    !!matchPath(pattern, pathname);

  return { pathname, isRouteActive };
};
