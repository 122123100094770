import React from "react";

import { MdCheck as CheckIcon, MdClose as CloseIcon } from "react-icons/md";

import { VerificationStatus } from "./../../shared";

interface IStatusCheckboxProps {
  status?: VerificationStatus;
}

export const StatusCheckbox: React.FC<IStatusCheckboxProps> = ({ status }) => {
  let icon, className;

  switch (status) {
    case VerificationStatus.Approved:
      icon = <CheckIcon size={14} />;
      className = "bg-success";
      break;
    case VerificationStatus.Rejected:
      icon = <CloseIcon size={14} />;
      className = "bg-danger";
      break;
    default:
      break;
  }

  return (
    <div
      className={`${className} flex flex-row justify-center items-center w-[24px] h-[24px] text-primaryDark rounded-lg`}
    >
      {icon}
    </div>
  );
};

StatusCheckbox.defaultProps = {
  status: VerificationStatus.Pending,
};
