import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { useFormik } from "formik";
import * as Yup from "yup";

import {
  Button,
  TextField,
  Form,
  FormSection,
  CloseIcon,
  IconButton,
} from "../../../../components";
import { ROUTER } from "../../../../routes";
import {
  useAppDispatch,
  useAppSelector,
  useLoading,
  useScreen,
  useToast,
} from "../../../../hooks";

import {
  NotificationStatus,
  NotificationType,
  NotificationPriority,
} from "../../../../shared";
import { updateResumeCover, updateProfileCover } from "../../../../store";
import { addLineBreaks, FormValidator } from "../../../../utils";

interface IResumeCoverEditForm {
  title: string;
  details?: string;
}

interface IEditFormProps<T = any> {
  data?: T;
  className?: string;
  onCancel: (e?: any) => void;
}

const schema = Yup.object().shape({
  title: Yup.string()
    .max(70, "Exceeded maximum character length of 70")
    .required("Title is a required field"),
  details: Yup.string()
    .max(1000, "Exceeded maximum character length of 1000")
    .required("Details is a required field"),
});

const draftSchema = Yup.object().shape({
  title: Yup.string()
    .max(70, "Exceeded maximum character length of 70")
    .required("Title is a required field"),
});

export const ResumeCoverEditForm: React.FC<IEditFormProps> = ({
  data,
  className,
  onCancel,
}) => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const [loading, setLoading] = useLoading(false);
  const { isMobile } = useScreen();
  const { toast } = useToast();

  const [validationSchema, setValidationSchema] =
    useState<Yup.ObjectSchema<any>>(schema);

  const {
    account: { data: account },
  } = useAppSelector((state) => state.account);
  const { resume } = useAppSelector((state) => state.resume);

  const { id } = data;
  const profile = account?.profile;
  const profileId = profile?.id;
  const resumeId = resume?.data?.id;

  const form = useFormik<IResumeCoverEditForm>({
    initialValues: {
      title: data?.title,
      details: data?.details,
    },
    validationSchema,
    onSubmit: async (values) => {
      const { details } = values;

      const payload = {
        ...data,
        ...values,
        details: addLineBreaks(details || ""),
      };

      setLoading(true);

      const updated = await dispatch(
        resumeId
          ? updateResumeCover(id, payload)
          : updateProfileCover(id, payload)
      );
      if (!updated) {
        setLoading(false);

        return toast({
          status: NotificationStatus.Error,
          message: "Cover Not Updated!",
        });
      }

      toast({ status: NotificationStatus.Success, message: "Cover Updated!" });

      setLoading(false);
      resetForm();
      onCancel();
    },
  });

  const validator = new FormValidator<IResumeCoverEditForm>(form);

  const { values, resetForm, setFieldValue, handleChange, handleSubmit } = form;

  const handleDiscard = () => {
    onCancel();
    resetForm();
  };

  const handleDelist = () => {};

  const handleSaveDraft = async (e) => {
    // @ts-ignore
    await setValidationSchema(draftSchema);

    setFieldValue("isDraft", true, true);
    handleSubmit();
  };

  const handleSave = async (e) => {
    setFieldValue("isDraft", false, true);
    handleSubmit();
  };

  return (
    <FormSection heading="Edit Cover" className="border-primaryMid">
      <div className="absolute top-[15px] right-[0px]">
        <IconButton
          icon={CloseIcon}
          iconProps={{ size: 18 }}
          className="transition-all w-[24px] h-[24px] rounded-full hover:bg-primaryMid"
          onClick={handleDiscard}
        />
      </div>
      <Form
        onSubmit={handleSubmit}
        className="w-full flex flex-col text-white mt-5 gap-y-[10px]"
      >
        <TextField
          id="title"
          label="Title"
          type="text"
          variant="filled"
          className="!bg-primaryDark !text-white"
          controlClassName="basis-1/2"
          labelClassName="text-secondary"
          inputClassName="text-inherit"
          required
          value={values.title}
          errorMessage={validator.isInputValid("title")}
          onChange={handleChange}
        />

        <TextField
          id="details"
          label="Details"
          type="text"
          variant="filled"
          className="!bg-primaryDark !text-white"
          controlClassName="basis-full"
          labelClassName="text-secondary"
          required
          multiline
          minRows={4}
          value={values?.details}
          errorMessage={validator.isInputValid("details")}
          onChange={handleChange}
        />

        <div className="flex flex-row justify-end items-center mt-2 gap-y-[10px] md:flex-row xxs:flex-col-reverse">
          <div className="flex flex-row justify-between items-center gap-x-[10px] gap-y-[10px] md:w-auto xxs:w-full md:flex-row xxs:flex-col-reverse">
            <Button
              color="secondary"
              variant="outlined"
              className="md:w-auto xxs:w-full"
              disabled={loading}
              onClick={handleSaveDraft}
            >
              Save Draft
            </Button>
            <Button
              color="success"
              variant="outlined"
              type="submit"
              className="min-w-[150px] md:w-auto xxs:w-full"
              loading={loading}
              onClick={handleSave}
            >
              Save
            </Button>
          </div>
        </div>
      </Form>
    </FormSection>
  );
};

ResumeCoverEditForm.defaultProps = {};
