import React from "react";

import { IAudit, IProfile } from "../../shared";

import {
  Button,
  Card,
  Avatar,
  CardFooter,
  CardBody,
  Link,
  StatusCheckbox,
} from "../../components";
import { VerificationStatus } from "../../shared";
import { ROUTER } from "../../routes";

import { date } from "../../utils";

interface IBusinessAuditCardProps extends IAudit {}

export const BusinessAuditCard: React.FC<IBusinessAuditCardProps> = ({
  id,
  businessVerifications,
  user,
}) => {
  const isApproved = (status: string) => status === VerificationStatus.Approved;

  return (
    <Card>
      <CardBody>
        <div className="flex justify-between items-center">
          <div className="w-full flex flex-col">
            <div className="w-full flex flex-col">
              <div className="flex flex-row justify-start items-center gap-x-[10px]">
                <Avatar
                  size={40}
                  to={ROUTER.USER_DETAIL(user?.id)}
                  src={user?.avatar}
                >
                  {user?.firstName}
                </Avatar>
                <div className="w-full flex flex-col justify-center items-start gap-y-[3px] mt-[6px]">
                  <Link to={ROUTER.USER_DETAIL(user?.id)}>
                    <h3 className="text-base font-semibold text-white leading-none">
                      {user?.firstName || ""} {user?.lastName || ""}
                    </h3>
                  </Link>
                  <span className="text-xs leading-none">********</span>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-between gap-x-5">
            {/* <div className="flex flex-row items-center gap-x-[10px]">
            <span className="text-xs font-normal text-secondary">
              Assigned to:
            </span>
            <Avatar size={30} />
            <span className="text-white">{assignedTo?.name}</span>
          </div> */}
            <Button
              variant="outlined"
              to={ROUTER.ADMIN_BUSINESS_AUDIT_DETAIL(id)}
            >
              Details
            </Button>
          </div>
        </div>

        <div className="mt-[20px]">
          <span className="text-xs font-medium text-white">
            Recent Verifications
          </span>
          <div className="flex flex-col">
            {businessVerifications?.map(
              ({
                id,
                status,
                name: companyName,
                tin,
                company,
                country,
                city,
                requestedBy,
                createdAt,
                updatedAt,
              }) => (
                <div
                  key={id}
                  className="flex flex-row justify-between items-center gap-x-[15px] box-border py-[10px] border-t border-primaryMid first:border-t-0"
                >
                  <div className="basis-4/12 flex flex-row items-center gap-x-[10px]">
                    <Avatar
                      size={30}
                      to={ROUTER.ADMIN_BUSINESS_VERIFICATION_DETAIL(id)}
                    >
                      {companyName}
                    </Avatar>
                    <div className="flex flex-col items-start gap-y-[3px]">
                      <Link to={ROUTER.ADMIN_BUSINESS_VERIFICATION_DETAIL(id)}>
                        <span className="text-xs font-semibold text-white leading-none">
                          {companyName}
                        </span>
                      </Link>
                      <span className="text-xxs font-medium text-secondary leading-none">
                        {[country, city]?.filter((i) => !!i)?.join(",") ||
                          "********"}
                      </span>
                    </div>
                  </div>
                  <div className="basis-2/12 flex flex-col justify-between items-start gap-y-[3px]">
                    <span className="text-xs font-semibold text-white">
                      TIN
                    </span>
                    <span className="text-xxs font-medium text-secondary">
                      {tin || "********"}
                    </span>
                  </div>
                  <div className="basis-2/12 flex flex-col justify-between items-start gap-y-[3px]">
                    <span className="text-xs font-semibold text-white">
                      Requester
                    </span>
                    {/* <Link
                      to={ROUTER.USER_DETAIL(createdBy?.id)}
                      className="bg-red-500 leading-none py-[0px] my-[0px] h-[10px]"
                    > */}
                    <span className="text-xxs font-medium text-secondary">
                      {[
                        requestedBy?.firstName?.slice(0, 1),
                        requestedBy?.lastName,
                      ]
                        ?.filter((i) => !!i)
                        ?.join(". ") || "********"}
                    </span>
                    {/* </Link> */}
                  </div>
                  <div className="basis-2/12 flex flex-col justify-between items-start gap-y-[3px]">
                    <span className="text-xs font-semibold text-white">
                      Requested
                    </span>
                    <span className="text-xxs font-medium text-secondary">
                      {date(createdAt).format("MMM DD, HH:mm")}
                    </span>
                  </div>
                  <div className="basis-2/12 flex flex-col justify-between items-start gap-y-[3px]">
                    <span className="text-xs font-semibold text-white ">
                      Updated
                    </span>
                    <span className="text-xxs font-medium text-secondary">
                      {date(createdAt).format("MMM DD, HH:mm")}
                    </span>
                  </div>

                  <div className="flex flex-row justify-center items-center">
                    <StatusCheckbox status={status} />
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </CardBody>
      {/* <CardFooter>
      <div className="flex flex-row justify-center items-center">
        <Button variant="text" className="capitalize text-white">
          Add Quick Note
        </Button>
      </div>
    </CardFooter> */}
    </Card>
  );
};

BusinessAuditCard.defaultProps = {};
