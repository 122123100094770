import React, { useEffect, useMemo, useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  FiClipboard as ClipboardIcon,
  FiTerminal as TerminalIcon,
  FiBarChart2 as ChartIcon,
  FiHash as HashIcon,
  FiBriefcase as BriefcaseIcon,
  FiArrowRight as ArrowRightIcon,
} from "react-icons/fi";

import {
  Link,
  SidebarSection,
  DropdownItem,
  Button,
  Loader,
  Sidebar,
  SidebarContent,
} from "../../../components";
import { ResumeCreateForm } from "../..";

import { getResumes } from "../../../store";
import {
  NotificationStatus,
  NotificationType,
  NotificationPriority,
} from "../../../shared";
import { ROUTER } from "../../../routes";
import {
  useAppDispatch,
  useAppSelector,
  useModal,
  useToast,
  useURL,
} from "../../../hooks";
import { IconBaseProps } from "react-icons";
import { ISidebar } from "@types";

export const ResumeBuilderSidebar: React.FC<ISidebar> = (props) => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const { toast } = useToast();
  const [URL, setURL] = useURL();

  const RESUME_TEMPLATE_CREATE_LIMIT = 10;

  const [createForm, setCreateForm] = useState(false);

  const { resumes } = useAppSelector((state) => state.resume);
  const {
    account: { data: account },
  } = useAppSelector((state) => state.account);

  const profile = account?.profile;
  const profileId = profile?.id;
  const isCreateDisabled =
    resumes?.data?.length >= RESUME_TEMPLATE_CREATE_LIMIT;

  const isActive = ({ paths, params }: any): boolean =>
    paths?.includes(URL.path) && params?.id === URL.params?.id;

  const handleCreateTemplate = () => {
    if (isCreateDisabled)
      return toast({
        status: NotificationStatus.Error,
        message: `You can create up to ${RESUME_TEMPLATE_CREATE_LIMIT} resume templates!`,
      });

    setCreateForm(true);
  };

  return (
    <Sidebar {...props}>
      <SidebarContent>
        <SidebarSection heading="Templates" className="border-none">
          <div className="flex flex-col gap-y-[15px]">
            {resumes?.data
              ?.filter((resume) => resume?.isDefault)
              ?.map(({ id }) => (
                <MenuItem
                  key={id}
                  className="py-[0px]"
                  icon={ClipboardIcon}
                  switchURL={{
                    path: ROUTER.RESUME_TEMPLATE_DETAIL(),
                    params: { id },
                  }}
                  active={isActive({
                    paths: [
                      ROUTER.RESUME_TEMPLATE_DEFAULT,
                      ROUTER.RESUME_TEMPLATE_DETAIL(),
                      ROUTER.RESUME_TEMPLATE_EDIT(),
                    ],
                    params: { id },
                  })}
                >
                  Default Resume
                </MenuItem>
              ))}

            {resumes?.isLoading ? (
              <Loader />
            ) : (
              resumes?.data
                ?.filter((resume) => !resume?.isDefault)
                ?.map(({ id, name }) => (
                  <MenuItem
                    key={id}
                    switchURL={{
                      path: ROUTER.RESUME_TEMPLATE_DETAIL(),
                      params: { id },
                    }}
                    icon={HashIcon}
                    active={isActive({
                      paths: [
                        ROUTER.RESUME_TEMPLATE_DETAIL(),
                        ROUTER.RESUME_TEMPLATE_EDIT(),
                      ],
                      params: { id },
                    })}
                  >
                    {name}
                  </MenuItem>
                ))
            )}
          </div>

          <div className="mt-[30px]">
            <MenuItem
              icon={BriefcaseIcon}
              switchURL={{ path: ROUTER.RESUME_TEMPLATE_HISTORY }}
              active={isActive({ paths: [ROUTER.RESUME_TEMPLATE_HISTORY] })}
            >
              All Work History
            </MenuItem>
          </div>
          <div className="mt-[35px]">
            {createForm ? (
              <ResumeCreateForm onClose={() => setCreateForm(false)} />
            ) : (
              <Button
                color="primary"
                variant="outlined"
                className="w-full"
                onClick={handleCreateTemplate}
              >
                Create New
              </Button>
            )}
          </div>
        </SidebarSection>
      </SidebarContent>
    </Sidebar>
  );
};

interface IMenuItemProps {
  className?: string;
  to?: string;
  switchURL?: any;
  icon?: (props: IconBaseProps | any) => JSX.Element;
  iconSize?: number;
  iconClassName?: string;
  active?: boolean;
  activeClassName?: string;
  activeIconClassName?: string;
  // onClick;
}

export const MenuItem: React.FC<IMenuItemProps> = ({
  children,
  className,
  to,
  switchURL,
  icon: Icon,
  iconSize,
  active,
  activeClassName,
}) => {
  const activeStyles = active
    ? `${activeClassName} text-white hover:text-white font-semibold`
    : `text-secondary font-medium hover:text-white`;

  const iconStyles = active
    ? `${activeClassName} text-primary hover:text-primary font-semibold`
    : `text-secondary font-medium hover:text-white`;

  const icon = (
    // @ts-ignore
    <Icon size={iconSize} className={`mr-1.5 -mt-[1.5px] ${iconStyles}`} />
  ) || <></>;

  return (
    <div
      className={`${className} ${activeStyles} relative flex flex-row justify-start items-center cursor-pointer text-base`}
    >
      <Link
        {...{ to, switchURL }}
        className="absolute w-full h-full bg-transparent"
      ></Link>
      {icon}
      {children}
    </div>
  );
};
