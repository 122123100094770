import React from "react";

import { RBAC, RBACSwitch } from "./../../components";
import { Role } from "../../shared";
import { MainLayout } from "./../../layouts";
import { lazyload } from "../../utils";

const {
  AdminHomeContainer,
  BusinessHomeContainer,
  UserPostsContainer,
  CompanyUserFeedRightSidebar,
  UserFeedRightSidebar,
} = lazyload(() => import("./../../features"));

export const HomePage = () => (
  <MainLayout
    rightSidebar={(props) =>
      RBACSwitch({
        elements: [
          {
            roles: [Role.User],
            component: () => <UserFeedRightSidebar {...props} />,
          },
          {
            roles: [Role.Owner, Role.TeamLead, Role.Employee],
            component: () => <CompanyUserFeedRightSidebar {...props} />,
          },
        ],
      })
    }
  >
    <RBAC roles={[Role.Owner, Role.TeamLead, Role.Employee]}>
      <BusinessHomeContainer />
    </RBAC>
    <RBAC roles={[Role.User]}>
      <UserPostsContainer />
    </RBAC>
    <RBAC roles={[Role.Admin]}>
      <AdminHomeContainer />
    </RBAC>
  </MainLayout>
);
