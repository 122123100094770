import React from "react";

import { CardList, Container, LoadMoreButton } from "../../components";
import { PostCard } from "../../features";

import { useLoading } from "../../hooks";

interface IDataListProps<T = any> {
  data?: T[];
  loading?: boolean;
  results?: number;
  page?: number;
  pageSize?: number;
  pageCount?: number;
  ElementComponent?: React.ComponentType;
  onLoad?: (...args) => Promise<void>;
}

export const DataList: React.FC<IDataListProps> = ({
  data,
  onLoad,
  ...props
}) => {
  const {
    loading,
    results = 0,
    page = 1,
    pageSize = 0,
    pageCount = 0,
    ElementComponent = PostCard,
  } = props;

  const isFound = pageCount >= 1;
  const isLastPage = pageCount >= 1 ? page >= pageCount : true;

  const handleLoad = async () => {
    if (!onLoad) return;

    await onLoad();
  };

  return (
    <Container>
      <CardList>
        {data?.map((element, key) => (
          <ElementComponent key={element?.id || key} {...element} />
        ))}

        <div>
          {isFound ? (
            isLastPage ? (
              <></>
            ) : (
              <LoadMoreButton loading={loading} onClick={handleLoad} />
            )
          ) : (
            <></>
          )}
        </div>
      </CardList>
    </Container>
  );
};

DataList.defaultProps = {
  page: 1,
  pageSize: 0,
  pageCount: 0,
  ElementComponent: PostCard,
};
