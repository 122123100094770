import React from "react";

import { ModalType } from "../../../shared";

import { Container, Modal } from "../../../components";
import { useModal } from "../../../hooks";

export const InfoModal = () => {
  const { modal, isOpen } = useModal({
    type: ModalType.INFO,
  });

  const { title, details } = modal.props || {};

  return (
    <Modal title={title} open={isOpen} dialogClassName="max-w-[480px]">
      <Container>
        <p>{details}</p>
      </Container>
    </Modal>
  );
};
