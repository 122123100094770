import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

import { ModalType } from "../../../shared";

import { Button, Container, Modal } from "../../../components";

import { useAppDispatch, useAppSelector, useModal } from "../../../hooks";

export const LicensePurchaseErrorModal = () => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const { modal, isOpen, closeModal } = useModal({
    type: ModalType.LICENSE_PURCHASE_ERROR,
  });

  const { id, name, previewSrc } = modal.props;

  const handleClose = () => {
    searchParams.delete("license_status");
    setSearchParams(searchParams);

    closeModal();
  };

  return (
    <Modal
      title="License Purchase"
      open={isOpen}
      dialogClassName="max-w-[360px]"
      onClose={handleClose}
    >
      <Container className="w-full h-full">
        <div>
          <div className="w-full aspect-video rounded-lg overflow-hidden">
            <img className="w-full aspect-square" src={previewSrc} alt="" />
          </div>

          <div className="mt-[20px]">
            <p>
              Error! ❌
              <br />
              Something went wrong. The digital asset <b>{name}</b> hasn't been
              purchased.
            </p>
          </div>
        </div>
        <div className="w-full flex flex-row justify-end mt-[15px]">
          <Button
            variant="outlined"
            color="secondary"
            type="submit"
            className="min-w-[150px]"
            onClick={handleClose}
          >
            OK
          </Button>
        </div>
      </Container>
    </Modal>
  );
};
