import React from "react";

import { ISidebar } from "@types";

import {
  SidebarSection,
  Sidebar,
  SidebarContent,
  Button,
  Searchbar,
  MenuLink,
} from "../../../components";
import { ROUTER } from "../../../routes";
import { usePath } from "./../../../hooks";

export const LicenseSidebar: React.FC<ISidebar> = (props) => {
  const { pathname, isRouteActive } = usePath();

  return (
    <Sidebar {...props}>
      <SidebarContent>
        <SidebarSection heading="Licenses" className="border-none">
          <div className="w-full flex flex-col gap-[15px]">
            <MenuLink
              to={ROUTER.USER_LICENSES}
              active={isRouteActive(ROUTER.USER_LICENSES)}
            >
              My Licenses
            </MenuLink>

            <MenuLink
              to={ROUTER.USER_LICENSES_PURCHASED}
              active={isRouteActive(ROUTER.USER_LICENSES_PURCHASED)}
            >
              Purchased Licenses
            </MenuLink>
            <MenuLink
              to={ROUTER.USER_LICENSES_SOLD}
              active={isRouteActive(ROUTER.USER_LICENSES_SOLD)}
            >
              Sold Licenses
            </MenuLink>
          </div>
        </SidebarSection>
      </SidebarContent>
    </Sidebar>
  );
};
