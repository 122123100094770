export const ROUTER = {
  ROOT: "/",
  TEST: "/test",
  FAQ: "/faq",

  LOGIN: "/login",
  REGISTER: "/register",

  REGISTER_PASSPORT_SCAN: "register",
  REGISTER_PASSPORT_DATA: "register/passport",
  REGISTER_EMAIL: "register/email",
  REGISTER_EMAIL_CONFIRM: "register/confirm",
  REGISTER_PASSWORD: "register/password",

  PASSWORD_RESET: "/password-reset",
  PASSWORD_RESET_EMAIL: "/password-reset/email",
  PASSWORD_RESET_CONFIRM: "/password-reset/confirm",

  HOME: "/home",

  // User
  USERS: "/users",
  FRIENDS: "/friends",
  USER_SETTINGS: "/settings",
  USER_SETTINGS_ACCOUNT: "/settings/account",
  USER_SETTINGS_PROFILE: "/settings/profile",
  USER_DETAIL: (userId?: string) => `/users/${userId || ":userId"}`,
  USER_FRIENDS: (userId?: string) => `/users/${userId || ":userId"}/friends`,
  USER_GALLERY: (userId?: string) => `/users/${userId || ":userId"}/gallery`,
  USER_GALLERY_ITEM_DETAIL: (userId?: string, itemId?: string) =>
    `/users/${userId || ":userId"}/gallery/${itemId || ":itemId"}`,
  USER_DIGITAL_ASSETS: (userId?: string) =>
    `/users/${userId || ":userId"}/digital-assets`,
  USER_DIGITAL_ASSET_DETAIL: (userId?: string, assetId?: string) =>
    `/users/${userId || ":userId"}/digital-assets/${assetId || ":assetId"}`,

  USER_LICENSES: "/licenses",
  USER_LICENSES_SOLD: "/licenses/sold",
  USER_LICENSE_DETAIL: (licenseId?: string) =>
    `/licenses/${licenseId || ":licenseId"}`,
  USER_LICENSES_PURCHASED: "/licenses/purchased",
  USER_LICENSES_PURCHASED_DETAIL: (itemId?: string) =>
    `/licenses/purchased/${itemId || ":itemId"}`,

  // Invite
  INVITE_DETAIL: (inviteId?: string) => `/invites/${inviteId || ":inviteId"}`,

  // Company
  COMPANY_DETAIL_ALIAS: (companyAlias?: string) =>
    `/${companyAlias || ":companyAlias"}`,
  COMPANY_DETAIL: (companyId?: string) =>
    `/companies/${companyId || ":companyId"}`,

  JOBS: "/jobs",
  JOB_SEARCH: "/job-search",
  JOB_CREATE: "/jobs/create",
  JOB_DETAIL: (id?: string) => `/jobs/${id || ":id"}`,
  JOB_EDIT: (id?: string) => `/jobs/${id || ":id"}/edit`,
  JOB_APPLICATION_LIST: (jobId?: string) =>
    `/jobs/${jobId || ":id"}/applications`,
  JOB_APPLICATION_DETAIL: (jobId?: string, jobApplicationId?: string) =>
    `/jobs/${jobId || ":id"}/applications/${
      jobApplicationId || ":jobApplicationId"
    }`,

  NEWS: "/news",
  EDUCATION: "/education",
  CREATORS: "/creators",

  // Marketplace
  MARKETPLACE: "/marketplace",
  MARKETPLACE_ITEM: (itemId?: string) => `/marketplace/${itemId || ":itemId"}`,

  POST_ROOT: "/posts",
  POST_DETAIL: (id?: string) => `/posts/${id || ":id"}`,
  POST_EDIT: (id?: string) => `/posts/${id || ":id"}/edit`,

  // Events
  EVENTS: "/events",

  // Admin
  ADMIN: "/admin",
  ADMIN_BUSINESS_VERIFICATION_DETAIL: (id?: string) =>
    `/admin/business-verifications/${id || ":id"}`,
  ADMIN_BUSINESS_AUDIT_DETAIL: (auditId?: string) =>
    `/admin/business-audits/${auditId || ":auditId"}`,

  // Business
  BUSINESS_ADMINISTRATION: "/administration",
  BUSINESS_AUDIT_DETAIL: (auditId?: string) =>
    `/administration/audits/${auditId || ":auditId"}`,

  // Audit
  AUDITS: "/audits",

  // Employee
  EMPLOYEE_SEARCH: "/employees",
  EMPLOYEE_DETAIL: (employeeId?: string) =>
    `/employees/${employeeId || ":employeeId"}`,

  // Resumes
  RESUMES: "/resumes",
  RESUME_DETAIL: (id?: string) => `/resumes/${id || ":id"}`,

  // Resume Builder
  RESUME_BUILDER: "/resume-builder",
  RESUME_TEMPLATE_HISTORY: `/resume-builder/all`,
  RESUME_TEMPLATE_DEFAULT: "/resume/builder/default",
  RESUME_TEMPLATE_DETAIL: (id?: string) => `/resume-builder/${id || ":id"}`,
  RESUME_TEMPLATE_EDIT: (id?: string) => `/resume-builder/${id || ":id"}/edit`,

  // Business
  BUSINESS_REGISTRATION: "/register-business",

  // Search
  SEARCH: (query?: string) => `/search${query ? `?q=${query}` : ""}`,

  // Notifications
  NOTIFICATIONS: "/notifications",

  // Meet
  MEET: "/meet",
  MEETX: "/meetx",

  // Chats
  CHATS: "/chats",
  CHAT_DETAIL: (chatId?: string) => `/chats/${chatId || ":chatId"}`,
};
