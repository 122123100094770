import React, { useEffect, useMemo, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import { BsChatDotsFill as ChatIcon } from "react-icons/bs";

import { ISidebar } from "@types";

import {
  Link,
  SidebarSection,
  DropdownItem,
  Button,
  Loader,
  Sidebar,
  SidebarAccordion,
  Avatar,
  FormSection,
  RBAC,
  SidebarContent,
  IconButton,
  CloseIcon,
} from "../../../components";
import {
  CompanyCommentCreateForm,
  CompanyCommentLogWidget,
  CompanyActivityLogWidget,
  ScheduledInterviewWidget,
} from "../..";

import {
  useAppDispatch,
  useAppSelector,
  useAuthor,
  useModal,
  useToast,
} from "../../../hooks";

import { ROUTER } from "../../../routes";
import { ModalType, Role } from "../../../shared";

export const UserCompanyActivitySidebar: React.FC<ISidebar> = (props) => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const { setModal } = useModal();

  const [commentForm, setCommentForm] = useState(false);

  const {
    account: { data: account },
  } = useAppSelector((state) => state.account);
  const {
    jobApplication: { data: jobApplication },
  } = useAppSelector((state) => state.jobApplication);
  const {
    companyComments: { results: companyCommentsCount },
  } = useAppSelector((state) => state.company);

  const { id: jobApplicationId, job, createdBy } = jobApplication || {};

  const companyId = job?.companyId;
  const userId = createdBy?.id;
  const interviewId = jobApplication?.interviews?.[0]?.id || null;

  const isOwner = account?.ownership?.id === companyId;
  const isEmployee = account?.employment?.id === companyId;
  const isEmployed = isOwner || isEmployee;

  const handleCompanyCommentCreate = () => {
    setModal({
      type: ModalType.COMPANY_COMMENT_CREATE,
      props: { jobApplicationId, userId, companyId },
    });
  };

  const handleScheduleInterview = () => {
    setModal({
      type: ModalType.INTERVIEW_SCHEDULE_MODAL,
      props: {
        jobApplication,
      },
    });
  };

  return (
    <Sidebar {...props}>
      <RBAC
        roles={[Role.Admin, Role.User]}
        conditions={[
          {
            roles: [Role.Owner, Role.TeamLead, Role.Employee],
            condition: isEmployed,
          },
        ]}
      >
        <SidebarContent>
          <div className="relative h-full flex flex-col justify-between">
            <div className="h-full relative overflow-y-scroll">
              <RBAC
                roles={[Role.Admin, Role.Owner, Role.TeamLead, Role.Employee]}
              >
                <SidebarSection heading="Activity">
                  <CompanyActivityLogWidget
                    companyId={companyId}
                    where={{ userId }}
                  />
                </SidebarSection>
                <SidebarSection
                  heading={`Company Notes ${
                    companyCommentsCount && `(${companyCommentsCount})`
                  }`}
                >
                  <CompanyCommentLogWidget
                    companyId={companyId}
                    where={{ userId }}
                  />
                </SidebarSection>
              </RBAC>
            </div>

            <div className="max-h-[480px]">
              <SidebarSection className="w-full !px-[0px] !py-[0px]">
                <RBAC
                  roles={[Role.Admin, Role.Owner, Role.TeamLead, Role.Employee]}
                >
                  {interviewId && (
                    <SidebarSection className="bordet-t-[0px]">
                      <ScheduledInterviewWidget />
                    </SidebarSection>
                  )}

                  <SidebarSection className="bordet-t-[0px]">
                    <div className="flex flex-col gap-[10px]">
                      <Button
                        variant="outlined"
                        color="primary"
                        className="w-full"
                        onClick={handleCompanyCommentCreate}
                      >
                        Add Note
                      </Button>
                      <Button
                        variant="outlined"
                        color="success"
                        className="w-full"
                        onClick={handleScheduleInterview}
                      >
                        Schedule Interview
                      </Button>
                    </div>
                  </SidebarSection>
                </RBAC>
              </SidebarSection>
            </div>
          </div>
        </SidebarContent>
      </RBAC>
    </Sidebar>
  );
};
