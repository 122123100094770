import React from "react";

import { CircularProgress } from "@mui/material";

import { CircularProgressLoader, Loader } from "./../../components";

interface IUploadProgressLoaderProps {
  value: number;
  label?: string;
  className?: string;
  hideCounter?: boolean;
}

export const UploadProgressLoader: React.FC<IUploadProgressLoaderProps> = ({
  value,
  label,
  className,
  hideCounter,
  ...props
}) => {
  const progress = (value > 0 && value < 99) || false;

  return (
    <div
      className={`${className} flex flex-col justify-center items-center w-full h-auto min-h-[240px] p-[20px] box-border`}
    >
      {progress ? (
        <CircularProgressLoader value={value} size={40} />
      ) : (
        <Loader className="text-secondary" size={40} />
      )}

      <div className="mt-[20px] text-sm text-secondary">
        <span>{label}</span>
      </div>
    </div>
  );
};

UploadProgressLoader.defaultProps = {
  className: "",
  label: "Loading..",
  hideCounter: false,
};
