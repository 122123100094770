import React from "react";

import { BasicSelect } from "./../../../components";

export const AuditLocationFilterForm = () => {
  const options = [
    { id: 1, value: "Oliver Hansen" },
    { id: 2, value: "Van Henry" },
    { id: 3, value: "April Tucker" },
    { id: 4, value: "Kelly Snyder" },
    { id: 5, value: "Ralph Hubbard" },
    { id: 6, value: "Omar Alexander" },
    { id: 7, value: "Virginia Andrews" },
  ];

  const [country, setCountry] = React.useState(null);
  const [state, setState] = React.useState(null);
  const [city, setCity] = React.useState(null);

  return (
    <div className="flex flex-col gap-y-[10px]">
      <BasicSelect
        label="Country"
        options={options}
        value={country}
        setValue={setCountry}
      />
      <BasicSelect
        label="State"
        options={options}
        value={state}
        setValue={setState}
      />
      <BasicSelect
        label="City"
        options={options}
        value={city}
        setValue={setCity}
      />
    </div>
  );
};
