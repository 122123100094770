import React from "react";
import { useNavigate } from "react-router";

import { IGalleryFile } from "../../shared";
import { ROUTER } from "../../routes";

interface IGalleryFilePreviewCardProps extends IGalleryFile {
  to?: string;
  alt?: string;
}

export const GalleryFilePreviewCard: React.FC<IGalleryFilePreviewCardProps> = ({
  id,
  previewSrc,
  alt,
  createdById,
}) => {
  const redirect = useNavigate();

  const handleRedirect = () => {
    if (!id || !createdById) return;

    redirect(ROUTER.USER_GALLERY_ITEM_DETAIL(createdById, id));
  };

  return (
    <div
      className={`
        relative flex flex-row justify-center items-center aspect-square bg-primaryMid cursor-pointer transition-all hover:bg-gray-700
        xxs:basis-full xs:basis-6/12 sm:basis-4/12 md:basis-3/12
`}
    >
      <div
        className="transition-all absolute w-full h-full bg-gray-900 opacity-20 z-40 hover:opacity-0"
        onClick={handleRedirect}
      />
      {previewSrc ? (
        <img
          src={previewSrc}
          className="transition-all w-full h-full select-none z-30 brightness-100 hover:brightness-125"
          alt={alt}
        />
      ) : (
        <></>
      )}
    </div>
  );
};
