import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { useFormik } from "formik";
import * as Yup from "yup";

import { Button, Form, TextField, Container } from "../../../components";
import { useAppDispatch, useLoading, useToast, useURL } from "../../../hooks";
import { ROUTER } from "../../../routes";
import { checkEmail, validateEmail, verifyEmail } from "../../../store";
import { RegisterContext } from "../../../context";
import { FormValidator } from "../../../utils";

interface IRegisterEmailForm {
  email?: string;
}

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email")
    .required("Email is a required field"),
});

export const RegisterEmail = () => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const { registerState, setRegisterState } = useContext(RegisterContext);

  const { toast } = useToast();
  const [URL, setURL] = useURL();
  const [loading, setLoading] = useLoading();

  const form = useFormik<IRegisterEmailForm>({
    initialValues: { email: "" },
    validationSchema,
    onSubmit: async (values) => {
      const email = values?.email?.toLowerCase();

      if (!email) return;

      setLoading(true);

      // Checking Email
      const existedEmail = await dispatch(checkEmail(email));
      if (existedEmail) {
        setLoading(false);
        return setFieldError("email", "Email Already Exists");
      }

      // Validating Email
      const validatedEmail = await dispatch(validateEmail(email));
      if (!validatedEmail) {
        setLoading(false);
        return setFieldError("email", "Please Enter Valid Email");
      }

      // Sending Token
      const verification = dispatch(verifyEmail(email));
      if (!verification) {
        setLoading(false);
        return setFieldError("email", "Please Enter Valid Email");
      }

      setLoading(false);
      setRegisterState({ ...registerState, email });
      setURL({ path: ROUTER.REGISTER_EMAIL_CONFIRM });
    },
  });

  const { values, setFieldError, handleChange, handleSubmit } = form;

  const validator = new FormValidator<IRegisterEmailForm>(form);

  return (
    <Container className="w-full min-h-[250px] py-[50px]">
      <div className="w-full text-center mb-[50px]">
        <h1 className="text-2xl font-semibold">Provide your Email</h1>
      </div>
      <Form onSubmit={handleSubmit}>
        <div className="w-full flex flex-col justify-start items-start gap-y-[10px] text-black">
          <TextField
            id="email"
            label="Email"
            type="text"
            variant="filled"
            className="!bg-white !text-black"
            value={values?.email}
            errorMessage={validator.isInputValid("email")}
            onChange={handleChange}
          />

          <div className="w-full flex flex-row justify-between items-center">
            <Button
              type="submit"
              color="primary"
              variant="contained"
              className="w-full min-h-[55px] font-medium bg-blue-500 text-blue-50"
              loading={loading}
            >
              Submit
            </Button>
          </div>
        </div>
      </Form>
    </Container>
  );
};
