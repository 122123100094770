import { IVideoCallClient } from "@types";

import { ApiService } from "../../../services";
import { AppDispatch, GetRootState } from "../../store";

import { videoCallSlice } from "./video-call.slice";

export const setVideoCallClient =
  (payload?: IVideoCallClient) => async (dispatch: AppDispatch) => {
    dispatch(videoCallSlice.actions.setVideoCallClient(payload));
  };

export const setVideoCall =
  (payload?: any) => async (dispatch: AppDispatch) => {
    dispatch(videoCallSlice.actions.setVideoCall(payload));
  };

export const getVideoCallById =
  (id: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(videoCallSlice.actions.getVideoCallByIdRequest());

      const response = await ApiService.get(`video-calls/${id}`);
      const { data } = response.data;

      dispatch(videoCallSlice.actions.getVideoCallByIdSuccess({ data }));

      return data;
    } catch (e: any) {
      dispatch(
        videoCallSlice.actions.getVideoCallByIdError(e.response?.data?.error)
      );
    }
  };

export const joinVideoCallById =
  (id: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(videoCallSlice.actions.joinVideoCallByIdRequest());

      const response = await ApiService.get(`video-calls/${id}/join`);
      const { data } = response.data;

      dispatch(videoCallSlice.actions.joinVideoCallByIdSuccess(data));

      return data;
    } catch (e: any) {
      dispatch(
        videoCallSlice.actions.joinVideoCallByIdError(e.response?.data?.error)
      );
    }
  };

export const startVideoCallRecording =
  () => async (dispatch: AppDispatch, getState: GetRootState) => {
    try {
      const state = getState();
      const videoCallId = state.videoCall.videoCall.data.id;

      if (!videoCallId) return;

      dispatch(videoCallSlice.actions.startVideoCallRecordingRequest());

      const response = await ApiService.get(
        `video-calls/${videoCallId}/recording/start`
      );
      const { data } = response.data;

      dispatch(videoCallSlice.actions.startVideoCallRecordingSuccess(data));

      return data;
    } catch (e: any) {
      dispatch(
        videoCallSlice.actions.startVideoCallRecordingError(
          e.response?.data?.error
        )
      );
    }
  };

export const stopVideoCallRecording =
  () => async (dispatch: AppDispatch, getState: GetRootState) => {
    try {
      const state = getState();
      const videoCallId = state.videoCall.videoCall.data.id;

      if (!videoCallId) return;

      dispatch(videoCallSlice.actions.startVideoCallRecordingRequest());

      const response = await ApiService.get(
        `video-calls/${videoCallId}/recording/stop`
      );
      const { data } = response.data;

      dispatch(videoCallSlice.actions.startVideoCallRecordingSuccess(data));

      return data;
    } catch (e: any) {
      dispatch(
        videoCallSlice.actions.startVideoCallRecordingError(
          e.response?.data?.error
        )
      );
    }
  };
