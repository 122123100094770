import React from "react";

import { MainLayout } from "../../layouts";
import { lazyload } from "../../utils";

const { VideoCallContainer, VideoCallLeftSidebar, VideoCallRightSidebar } =
  lazyload(() => import("./../../features"));

export const VideoCallPage = () => (
  <MainLayout
    // leftSidebar={VideoCallLeftSidebar}x
    rightSidebar={VideoCallRightSidebar}
    hideFooter
  >
    <VideoCallContainer />
  </MainLayout>
);
