import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Container, PageHeader, TabSwitch } from "../../components";
import { lazyload } from "../../utils";
import { useAccount, useAppDispatch } from "../../hooks";

const { UserSettingsAccountViewContainer, UserSettingsProfileViewContainer } =
  lazyload(() => import("./../../features"));

export const UserSettingsContainer = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const { account, activeProfile } = useAccount();

  const params = {
    tab: searchParams.get("tab"),
  };

  const [tab, setTab] = useState<string>();

  const tabs = [
    {
      id: "account",
      label: "Account",
      component: () => <UserSettingsAccountViewContainer />,
    },
    {
      id: "profile",
      label: "Profile",
      component: () => <UserSettingsProfileViewContainer />,
    },
  ];

  const handleTabChange = (tabId: string) => {
    if (!tabId) return;

    setTab(tabId);
  };

  useEffect(() => {
    if (!tab) return;

    setSearchParams({ ...searchParams, tab });
  }, [tab]);

  useEffect(() => {
    if (!params?.tab) return;

    setTab(params?.tab);
  }, []);

  return (
    <Container>
      <PageHeader>Settings</PageHeader>
      <Container>
        <TabSwitch activeTabId={tab} tabs={tabs} onChange={handleTabChange} />
      </Container>
    </Container>
  );
};
