import { IPostQueryParams } from "../../../shared";

import { ApiService } from "../../../services";
import { stringifyQueryParams } from "../../../utils";
import { AppDispatch } from "../../store";

export const getDocumentByType =
  (
    documentType: string,
    params?: { [key: string]: number | string | boolean | undefined }
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      const query = stringifyQueryParams(params);

      const response = await ApiService.get(
        `documents/?type=${documentType}${query ? `&${query}` : ""}`
      );

      const data = response.data.data;

      return data;
    } catch (e: any) {
      console.log({ e });

      return null;
    }
  };
