import React, { useEffect, useState } from "react";

import {
  FiUser as UserIcon,
  FiUsers as UsersIcon,
  FiCheckCircle as CheckIcon,
  FiHeart as LikeIcon,
  FiBookmark as BookmarkIcon,
  FiMessageCircle as CommentIcon,
  FiShare2 as ShareIcon,
} from "react-icons/fi";

import {
  Button,
  Card,
  Avatar,
  VerificationStatusBadge,
  Link,
  ActionMenu,
  CardBody,
  CardFooter,
  LikeButton,
  Container,
  RBACSwitch,
} from "../../components";
import {
  PostCardUserInfoBar,
  PostCommentCreateForm,
  PostCommentList,
  PostEditForm,
} from "./../../features";
import {
  Action,
  IPost,
  ModalType,
  NotificationStatus,
  Role,
} from "../../shared";

import {
  useAccount,
  useAppDispatch,
  useAppSelector,
  useLoading,
  useModal,
  useToast,
} from "../../hooks";
import { ROUTER } from "../../routes";
import { dislikePost, getPostComments, likePost } from "../../store";
import { date, textformat } from "../../utils";

interface IPostCardProps extends IPost {
  actions?: any[];
  onEdit?: () => void;
  onDelete?: () => void;
  onDelist?: () => void;
  onArchive?: () => void;
}

export const PostCard: React.FC<IPostCardProps> = ({
  id,
  title,
  image,
  tags,
  isLiked,
  createdById,
  createdBy,
  createdAt,
  actions,
  onEdit,
  onDelete,
  onDelist,
  onArchive,
  ...props
}) => {
  const dispatch = useAppDispatch();

  const { toast } = useToast();
  const { setModal } = useModal();
  const { account } = useAccount();

  const [details, setDetails] = useState<string>();

  const [loading, setLoading] = useLoading();
  const [editing, setEditing] = useLoading();

  const [likeLoading, setLikeLoading] = useLoading();
  const [liked, setLiked] = useState<boolean>(isLiked || false);
  const [likeCount, setLikeCount] = useState(0);

  const [commentLoading, setCommentLoading] = useLoading();
  const [commentSection, setCommentSection] = useState(false);
  const [comments, setComments] = useState<any[]>([]);
  const [commentCount, setCommentCount] = useState(0);

  const isAuthor = account?.profileId === createdById || false;

  const handleEdit = () => {
    setEditing(true);
  };

  const handleEditSave = (data) => {
    if (!data) return;

    setDetails(data?.details);
    setEditing(false);
  };

  const handleEditClose = () => {
    setEditing(false);
  };

  const handleDelete = async () => {
    setModal({
      type: ModalType.POST_DELETE,
      props: {
        id,
        details: textformat.shortenByChars(details || "", 50),
        onDelete,
      },
    });
  };

  const handleLike = async (id?: string) => {
    if (!id) return;

    if (!liked) {
      // Liking
      setLikeLoading(true);
      setLikeCount(likeCount + 1);
      setLiked(true);

      const liked = await dispatch(likePost(id));
      if (liked?.id) return setLikeLoading(false);

      setLikeLoading(false);
      setLikeCount(likeCount - 1);
      setLiked(false);
    } else {
      // Disliking
      setLikeLoading(true);
      setLikeCount(likeCount - 1);
      setLiked(false);

      const disliked = await dispatch(dislikePost(id));
      if (disliked?.id) return setLikeLoading(false);

      setLikeLoading(false);
      setLikeCount(likeCount + 1);
      setLiked(true);
    }
  };

  const handleCommentsToggle = async () => {
    if (!id) return;

    if (commentSection) return setCommentSection(false);

    setCommentLoading(true);
    setCommentSection(true);

    const comments = await dispatch(getPostComments(id));
    if (!comments) {
      return setCommentLoading(false);
    }

    setComments(comments);
    setCommentLoading(false);
  };

  const handleCommentCreate = async (comment) => {
    setComments([...comments, comment]);
    setCommentCount(commentCount + 1);
  };

  const handleCommentDelete = async (commentId: string) => {
    setComments(comments?.filter((comment: any) => comment?.id !== commentId));
    setCommentCount(commentCount - 1);
  };

  useEffect(() => {
    if (!props?.details) return;

    setDetails(props.details);
  }, [props?.details]);

  useEffect(() => {
    setLikeCount(props?.likeCount || 0);
  }, [props?.likeCount]);

  useEffect(() => {
    setCommentCount(props?.commentCount || 0);
  }, [props?.commentCount]);

  return (
    <Card>
      <CardBody>
        {/* @ts-ignore */}
        <PostCardUserInfoBar
          {...{
            id,
            createdBy,
            createdById,
            createdAt,
            onEdit: handleEdit,
            onDelete: handleDelete,
          }}
        />
        <div className="mt-[20px]">
          <div className="w-full text-base text-white font-medium">
            {editing ? (
              <PostEditForm
                // @ts-ignore
                id={id}
                details={details}
                onSave={handleEditSave}
                onClose={handleEditClose}
              />
            ) : (
              <p>{details}</p>
            )}
          </div>
        </div>

        {image && (
          <div className="w-full flex flex-row justify-center items-center max-h-[300px] overflow-hidden mt-[20px] rounded-[5px] pointer-events-none select-none">
            <img className="w-full h-full" src={image} alt={title} />
          </div>
        )}
        <div className="flex flex-row justify-start gap-x-[30px] mt-[20px]">
          <LikeButton
            count={likeCount}
            active={liked}
            disabled={likeLoading}
            onClick={() => handleLike(id)}
          />
          <div
            className="flex flex-row justify-start items-center gap-x-[8px] text-base text-secondary font-medium leading-none select-none hover:text-primary cursor-pointer"
            onClick={handleCommentsToggle}
          >
            <CommentIcon size={18} />
            <span>{commentCount}</span>
          </div>
          <div className="flex flex-row justify-start items-center gap-x-[8px] text-base text-secondary font-medium leading-none select-none hover:text-success cursor-pointer">
            <ShareIcon size={18} />
          </div>
          <div className="flex flex-row justify-start items-center gap-x-[8px] text-base text-secondary font-medium leading-none select-none hover:text-warning cursor-pointer">
            <BookmarkIcon size={18} />
          </div>
        </div>
      </CardBody>

      {commentSection && (
        <CardFooter>
          <Container className="flex flex-col py-[20px] gap-y-[25px]">
            <PostCommentList
              postId={id}
              data={comments}
              loading={commentLoading}
              hidden={!commentLoading && !comments?.length}
              onDelete={handleCommentDelete}
            />
            <PostCommentCreateForm postId={id} onCreate={handleCommentCreate} />
          </Container>
        </CardFooter>
      )}
    </Card>
  );
};

PostCard.defaultProps = {
  title: "",
  details: "",
};
