import React from "react";

import { AuthLayout } from "../layouts";
import { lazyload } from "../utils";

const { RegisterContainer } = lazyload(() => import("./../features"));

export const RootPage = () => (
  <AuthLayout>
    <RegisterContainer />
  </AuthLayout>
);
