import React from "react";

import { useNavigate } from "react-router";
import {
  ModalType,
  NotificationStatus,
  NotificationType,
  NotificationPriority,
} from "../../../shared";

import { useAppDispatch, useLoading, useModal, useToast } from "../../../hooks";
import { removeGalleryFileById, removeJob, updateJob } from "../../../store";

import { Button, Modal } from "../../../components";
import { ROUTER } from "../../../routes";

export const GalleryFileDeleteModal = () => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const { modal, isOpen, closeModal } = useModal({
    type: ModalType.GALLERY_FILE_DELETE,
  });

  const { toast } = useToast();
  const [loading, setLoading] = useLoading();

  const { props, redirectTo } = modal;
  const { id, name, createdById } = props || {};

  const handleDelete = async () => {
    if (!id) return;

    setLoading(true);

    const removed = await dispatch(removeGalleryFileById(id));
    if (!removed?.id) {
      closeModal();

      return toast({
        status: NotificationStatus.Error,
        message: "File hasn't been deleted!",
      });
    }

    redirect(ROUTER.USER_GALLERY(createdById));

    closeModal();

    toast({
      status: NotificationStatus.Success,
      message: "File has been successfully deleted!",
    });
  };

  return (
    <Modal
      title="Delete"
      description="Please read below before taking action"
      titleClassName="text-danger"
      open={isOpen}
      onClose={closeModal}
    >
      <div>
        <div className="text-sm text-secondary break-words">
          <p>
            Are you sure you want to delete the following file:{" "}
            <span className="text-white font-semibold">{name}</span>?
          </p>
        </div>
        <div className="flex flex-row justify-end items-center gap-x-[10px] mt-5">
          <div className="flex flex-row gap-x-[10px]">
            <Button variant="outlined" color="secondary" onClick={closeModal}>
              Discard
            </Button>

            <Button
              variant="outlined"
              color="danger"
              className="min-w-[150px]"
              loading={loading}
              onClick={handleDelete}
            >
              Delete
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
