import React from "react";

import { MainLayout } from "../../layouts";
import { lazyload } from "../../utils";

const { UserDigitalAssetDetailContainer } = lazyload(
  () => import("../../features")
);

export const UserDigitalAssetDetailPage = () => (
  <MainLayout>
    <UserDigitalAssetDetailContainer />
  </MainLayout>
);
