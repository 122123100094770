import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router";

import { useFormik } from "formik";
import * as Yup from "yup";

import {
  Button,
  Container,
  Form,
  TextField,
  BasicSelect,
  TagInput,
  Checkbox,
  PageHeader,
} from "../../components";
import { createJob, getJobs } from "../../store";
import {
  EmploymentType,
  JobType,
  SkillLevel,
  EducationDegree,
  NotificationStatus,
} from "../../shared";
import {
  useAppDispatch,
  useAppSelector,
  useLoading,
  useToast,
} from "../../hooks";
import { ROUTER } from "../../routes";
import {
  EDUCATION_DEGREES,
  EMPLOYMENT_TYPES,
  JOB_LEVELS,
  JOB_TYPES,
  TIMEZONES,
} from "../../constants";
import { FormValidator, addLineBreaks } from "../../utils";

interface IJobCreateForm {
  title?: string;
  type?: string;
  skillLevel?: string;
  positions?: string | number;
  skills?: any[];
  timezone?: string;
  yearsOfExperience?: number;
  degree?: string;
  country?: string;
  state?: string;
  city?: string;
  minSalary?: string;
  maxSalary?: string;
  salaryDependsOnExperience?: boolean;
  employmentType?: string;
  requirements?: string;
  description?: string;
  desiredSkills?: string;
}

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .max(70, "Exceeded maximum character length of 70")
    .required("Job Title is a required field"),
  type: Yup.string().required("Job Type is a required field"),
  skillLevel: Yup.string().required("Skill level is a required field"),
  positions: Yup.number()
    .typeError("Invalid format")
    .moreThan(0, "No. of Positions is a required field")
    .lessThan(55, "No. of Positions limit set to 99")
    .required("No. of Positions is a required field"),
  minSalary: Yup.number().when("salaryDependsOnExperience", {
    is: false,
    then: Yup.number()
      .typeError("Invalid format")
      .moreThan(0, "Min Salary is a required field")
      .lessThan(900000, "Min Salary limit set to 900000")
      .lessThan(Yup.ref("maxSalary"), "Min Salary must be < Max Salary")
      .required("Min Salary a required field"),
    otherwise: Yup.number().typeError("Invalid format").notRequired(),
  }),
  maxSalary: Yup.number().when("salaryDependsOnExperience", {
    is: false,
    then: Yup.number()
      .typeError("Invalid format")
      .moreThan(0, "Max Salary is a required field")
      .lessThan(900000, "Max Salary limit set to 900000")
      .moreThan(Yup.ref("minSalary"), "Max Salary must be > Min Salary")
      .required("Max Salary a required field"),
    otherwise: Yup.number().typeError("Invalid format").notRequired(),
  }),
  salaryDependsOnExperience: Yup.boolean()
    .typeError("Invalid format")
    .notRequired(),
  skills: Yup.array()
    .min(1, "Skills must contain 1 at least")
    .required("Skills is a required field"),
  timezone: Yup.string().required("Timezone is a required field"),
  yearsOfExperience: Yup.number()
    .typeError("Invalid format")
    .moreThan(0, "Years of Experience is a required field")
    .lessThan(99, "Are you immortal?")
    .required("Years of Experience is a required field"),
  degree: Yup.string().required("Education Degree is a required field"),
  country: Yup.string()
    .max(100, "Exceeded maximum character length of 100")
    .required("Country is a required field"),
  state: Yup.string()
    .max(100, "Exceeded maximum character length of 100")
    .notRequired(),
  city: Yup.string()
    .max(100, "Exceeded maximum character length of 100")
    .required("City is a required field"),
  employmentType: Yup.string().required("Employment Type is a required field"),
  requirements: Yup.string()
    .max(3000, "Exceeded maximum character length of 3000")
    .notRequired(),
  description: Yup.string()
    .max(3000, "Exceeded maximum character length of 3000")
    .required("Description is a required field"),
  desiredSkills: Yup.string()
    .max(1000, "Exceeded maximum character length of 1000")
    .notRequired(),
});

const initialValues: IJobCreateForm = {
  title: undefined,
  type: undefined,
  skillLevel: undefined,
  employmentType: undefined,
  positions: undefined,
  skills: [],
  timezone: undefined,
  yearsOfExperience: undefined,
  degree: undefined,
  country: undefined,
  state: undefined,
  city: undefined,
  minSalary: undefined,
  maxSalary: undefined,
  salaryDependsOnExperience: false,
  requirements: undefined,
  description: undefined,
  desiredSkills: undefined,
};

export const JobCreateContainer = () => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const [loading, setLoading] = useLoading();
  const { toast } = useToast();

  const {
    account: { data: account },
  } = useAppSelector((state) => state.account);

  const profile = account?.profile;
  const profileId = profile?.id;

  const form = useFormik<IJobCreateForm>({
    validationSchema,
    initialValues,
    onSubmit: async (values) => {
      const {
        description,
        requirements,
        desiredSkills,
        skills,
        positions,
        yearsOfExperience,
        minSalary,
        maxSalary,
      } = values || {};

      const data = {
        ...values,
        description: addLineBreaks(description || ""),
        requirements: addLineBreaks(requirements || ""),
        desiredSkills: addLineBreaks(desiredSkills || ""),
        skills: skills?.map((name) => ({ name })),
        positions: Number(positions || 0),
        yearsOfExperience: Number(yearsOfExperience || 0),
        minSalary: Number(minSalary || 0),
        maxSalary: Number(maxSalary || 0),
        createdById: profileId,
      };

      setLoading(true);

      const created = await dispatch(createJob(data));
      if (!created) {
        setLoading(false);
        return toast({
          status: NotificationStatus.Error,
          message: "Job Not Created!",
        });
      }

      toast({ status: NotificationStatus.Success, message: "Job Created!" });

      setLoading(false);
      redirect(ROUTER.JOB_DETAIL(created.id));
    },
  });

  const validator = new FormValidator<IJobCreateForm>(form);

  const {
    values,
    errors,
    touched,
    resetForm,
    setFieldValue,
    handleChange,
    handleSubmit,
  } = form;

  const handleSaveDraft = async (e) => {
    setFieldValue("isDraft", true, true);
    handleSubmit();
  };

  const handleCancel = () => {
    redirect(ROUTER.HOME);
    resetForm();
  };

  return (
    <Container>
      <PageHeader>Post Job</PageHeader>
      <Container className="w-full flex flex-col gap-y-[15px]">
        <Form onSubmit={handleSubmit}>
          <div className="flex flex-col gap-y-5">
            <TextField
              id="title"
              label="Title"
              type="text"
              variant="filled"
              className="bg-primaryLight text-white border border-solid border-primaryMid"
              labelClassName="text-secondary"
              value={values?.title}
              errorMessage={validator.isInputValid("title")}
              onChange={handleChange}
            />
            <TagInput
              id="skills"
              label="Experience Required"
              type="text"
              variant="filled"
              labelClassName="text-secondary"
              value={values?.skills}
              errorMessage={validator.isInputValid("skills")}
              onChange={(value) => setFieldValue("skills", value, false)}
            />

            <div className="flex flex-row gap-x-5 gap-y-5 md:flex-row xxs:flex-col">
              <BasicSelect
                label="Level"
                options={JOB_LEVELS}
                value={values?.skillLevel}
                errorMessage={validator.isInputValid("skillLevel")}
                setValue={(value) => setFieldValue("skillLevel", value, true)}
              />
              <TextField
                id="positions"
                label="No. of Positions"
                type="number"
                variant="filled"
                className="bg-primaryLight text-white border border-solid border-primaryMid"
                labelClassName="text-secondary"
                maxLength={2}
                value={values?.positions}
                errorMessage={validator.isInputValid("positions")}
                onChange={handleChange}
              />
            </div>

            <div className="flex flex-row gap-x-5 gap-y-5 md:flex-row xxs:flex-col">
              <div className="basis-6/12">
                <TextField
                  id="country"
                  label="Country"
                  type="text"
                  variant="filled"
                  className="bg-primaryLight text-white border border-solid border-primaryMid"
                  labelClassName="text-secondary"
                  value={values?.country}
                  errorMessage={validator.isInputValid("country")}
                  onChange={handleChange}
                />
              </div>
              <div className="flex flex-row basis-6/12 gap-x-5 gap-y-5 md:flex-row xxs:flex-col">
                <TextField
                  id="state"
                  label="State"
                  type="text"
                  variant="filled"
                  className="bg-primaryLight text-white border border-solid border-primaryMid"
                  labelClassName="text-secondary"
                  value={values?.state}
                  errorMessage={validator.isInputValid("state")}
                  onChange={handleChange}
                />
                <TextField
                  id="city"
                  label="City"
                  type="text"
                  variant="filled"
                  className="bg-primaryLight text-white border border-solid border-primaryMid"
                  labelClassName="text-secondary"
                  value={values?.city}
                  errorMessage={validator.isInputValid("city")}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="flex flex-row gap-x-5 gap-y-5 md:flex-row xxs:flex-col">
              <div className="basis-6/12 flex flex-row overflow-hidden">
                <BasicSelect
                  label="Timezone"
                  options={TIMEZONES}
                  value={values?.timezone}
                  errorMessage={validator.isInputValid("timezone")}
                  setValue={(value) => setFieldValue("timezone", value, true)}
                />
              </div>

              <div className="basis-6/12 flex flex-row gap-x-[15px]">
                <BasicSelect
                  label="Education"
                  options={EDUCATION_DEGREES}
                  value={values?.degree}
                  errorMessage={validator.isInputValid("degree")}
                  setValue={(value) => setFieldValue("degree", value, true)}
                />
                <TextField
                  id="yearsOfExperience"
                  label="Years of Experience"
                  type="text"
                  variant="filled"
                  className="bg-primaryLight text-white border border-solid border-primaryMid"
                  labelClassName="text-secondary"
                  maxLength={6}
                  value={values?.yearsOfExperience}
                  errorMessage={validator.isInputValid("yearsOfExperience")}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="flex flex-row gap-x-5 gap-y-5 md:flex-row xxs:flex-col">
              <div className="basis-6/12 flex flex-row gap-x-[15px]">
                <TextField
                  id="minSalary"
                  label="Min Salary (USD)"
                  type="number"
                  variant="filled"
                  className="bg-primaryLight text-white border border-solid border-primaryMid"
                  labelClassName="text-secondary"
                  maxLength={6}
                  value={values?.minSalary}
                  errorMessage={validator.isInputValid("minSalary")}
                  disabled={!!values?.salaryDependsOnExperience}
                  onChange={handleChange}
                />
                <TextField
                  id="maxSalary"
                  label="Max Salary (USD)"
                  type="number"
                  variant="filled"
                  className="bg-primaryLight text-white border border-solid border-primaryMid"
                  labelClassName="text-secondary"
                  maxLength={6}
                  value={values?.maxSalary}
                  errorMessage={validator.isInputValid("maxSalary")}
                  disabled={!!values?.salaryDependsOnExperience}
                  onChange={handleChange}
                />
              </div>

              <div className="basis-6/12 flex flex-row gap-x-[15px] h-[55px]">
                <Checkbox
                  id="salaryDependsOnExperience"
                  label="Depends On Experience"
                  checked={values?.salaryDependsOnExperience}
                  onChange={() => {
                    setFieldValue(
                      "salaryDependsOnExperience",
                      !values.salaryDependsOnExperience,
                      true
                    );
                    setFieldValue("minSalary", undefined);
                    setFieldValue("maxSalary", undefined);
                  }}
                />
              </div>
            </div>

            <div className="flex flex-row gap-x-5 gap-y-[10px] md:flex-row xxs:flex-col">
              <BasicSelect
                label="Employment Type"
                options={EMPLOYMENT_TYPES}
                value={values?.employmentType}
                errorMessage={validator.isInputValid("employmentType")}
                setValue={(value) =>
                  setFieldValue("employmentType", value, true)
                }
              />
              <BasicSelect
                label="Job Type"
                options={JOB_TYPES}
                value={values?.type}
                errorMessage={validator.isInputValid("type")}
                setValue={(value) => setFieldValue("type", value, true)}
              />
            </div>
            <div className="flex flex-row gap-x-5">
              <TextField
                id="requirements"
                label="Requirements"
                type="text"
                variant="filled"
                className="bg-primaryLight text-white border border-solid border-primaryMid"
                labelClassName="text-secondary"
                multiline
                minRows={4}
                maxRows={8}
                value={values?.requirements}
                errorMessage={validator.isInputValid("requirements")}
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-row gap-x-5">
              <TextField
                id="description"
                label="Description"
                type="text"
                variant="filled"
                className="bg-primaryLight text-white border border-solid border-primaryMid"
                labelClassName="text-secondary"
                multiline
                minRows={4}
                maxRows={8}
                value={values?.description}
                errorMessage={validator.isInputValid("description")}
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-row gap-x-5">
              <TextField
                id="desiredSkills"
                label="Desired Skills"
                type="text"
                variant="filled"
                className="bg-primaryLight text-white border border-solid border-primaryMid"
                labelClassName="text-secondary"
                multiline
                minRows={4}
                maxRows={8}
                value={values?.desiredSkills}
                errorMessage={validator.isInputValid("desiredSkills")}
                onChange={handleChange}
              />
            </div>

            <div className="flex flex-row justify-between gap-x-[10px] gap-y-[10px] md:flex-row xxs:flex-col-reverse">
              <Button
                color="danger"
                // variant="text"
                variant="outlined"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <div className="flex gap-x-[10px] gap-y-[10px] md:flex-row xxs:flex-col-reverse">
                <Button
                  color="secondary"
                  // variant="text"
                  variant="outlined"
                  onClick={handleSaveDraft}
                >
                  Save Draft
                </Button>
                <Button
                  type="submit"
                  color="success"
                  variant="outlined"
                  className="min-w-[150px]"
                  loading={loading}
                >
                  Post Now
                </Button>
              </div>
            </div>
          </div>
        </Form>
      </Container>
    </Container>
  );
};
