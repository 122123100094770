import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import AgoraRTC, {
  IRemoteAudioTrack,
  IRemoteVideoTrack,
  ILocalAudioTrack,
  ILocalVideoTrack,
  IAgoraRTCRemoteUser,
  ConnectionState,
  ILocalTrack,
} from "agora-rtc-sdk-ng";

import { IVideoCallClient } from "@types";
import {
  IProfile,
  IVideoCall,
  ModalType,
  NotificationStatus,
  VideoCallConnectionStatus,
} from "../../shared";

import { Container, PageHeader } from "../../components";
import {
  VideoCallControlBar,
  VideoCallParticipantCard,
} from "./../../features";

import {
  useAccount,
  useAppDispatch,
  useAppSelector,
  useLoading,
  useModal,
  useRtc,
  useToast,
} from "../../hooks";
import {
  getProfileById,
  getVideoCallById,
  joinVideoCallById,
  startVideoCallRecording,
  stopVideoCallRecording,
} from "../../store";
import { ROUTER } from "../../routes";
import { textformat } from "../../utils";

export interface IVideoCallParticipant {
  id?: string;
  firstName?: string;
  avatar?: string;
  accountId?: string;
  isYou?: boolean;
  isHost?: boolean;
  isCandidate?: boolean;
  screenSharing?: boolean;
  audioTrack?: IRemoteAudioTrack | ILocalAudioTrack | null;
  videoTrack?: IRemoteVideoTrack | ILocalVideoTrack | null;
  hasAudio?: boolean;
  hasVideo?: boolean;
}

export interface IVideoCallSpeaker {
  id?: string | null;
  firstName?: string | null;
}

export const VideoCallContainer = () => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const { activeProfile } = useAccount();
  const { setModal } = useModal();
  const { toast } = useToast();

  const params = {
    id: searchParams.get("id"),
    joined: Boolean(searchParams.get("joined") || false),
  };

  const {
    videoCall: { data: videoCall, isLoading: videoCallLoading },
  } = useAppSelector((state) => state.videoCall);

  const host = videoCall?.host;

  const [speaker, setSpeaker] = useState<IVideoCallSpeaker | null>(null);
  const [participants, setParticipants] = useState<IVideoCallParticipant[]>([]);

  const rtc = useRtc({ mode: "rtc", codec: "vp8" });

  const [localVideoTrack, setLocalVideoTrack] =
    useState<ILocalVideoTrack | null>(null);
  const [localAudioTrack, setLocalAudioTrack] =
    useState<ILocalAudioTrack | null>(null);
  const [localScreenTrack, setLocalScreenTrack] =
    useState<ILocalVideoTrack | null>(null);

  const [connectionState, setConnectionState] = useState<ConnectionState>(
    VideoCallConnectionStatus.DISCONNECTED
  );

  const [videoControls, setVideoControls] = useState(false);
  const [recording, setRecording] = useState(false);

  const videoEnabled = !!localVideoTrack;
  const audioEnabled = !!localAudioTrack;
  const screenSharing = !!localScreenTrack;

  const rtcRef = useRef(rtc);
  const hostRef = useRef<IVideoCallParticipant>();
  const participantsRef = useRef<IVideoCallParticipant[]>();
  const videoCallRef = useRef<IVideoCall>();
  const videoContainerRef = useRef(null);

  const videoCallId = params?.id;
  const profileId = activeProfile?.id;

  const systemInfo = {
    channel: rtc?.channelName?.slice(-4),
    status: connectionState,
    video: videoEnabled,
    audio: audioEnabled,
    screenSharing,
    speaker,
  };

  const initiateRTC = () => {
    try {
      rtc.on("connection-state-change", (state) => {
        setConnectionState(state);
      });

      rtc.on("user-joined", async (remoteUser) => {
        const videoCall = videoCallRef.current;

        const userId = remoteUser.uid.toString();
        const isYou = profileId === userId;
        const isHost = videoCall?.host?.id === userId;
        const isCandidate = videoCall?.interview?.candidate?.id === userId;

        console.log("USER_JOINED", {
          remoteUser: remoteUser?.uid,
          userId,
          candidateId: videoCall?.interview?.candidate?.id,
          isCandidate,
        });

        // Checking the user
        const profile = await dispatch(getProfileById(userId));
        if (!profile) return;

        const { id, firstName, avatar, accountId } = profile;

        const participant = {
          id,
          firstName,
          avatar,
          accountId,
          isYou,
          isHost,
          isCandidate,
        };

        addParticipant(participant);
      });

      rtc.on("user-left", async (remoteUser) => {
        console.log("USER_LEFT", { remoteUser: remoteUser?.uid });

        const userId = remoteUser.uid.toString();

        removeParticipant(userId);
      });

      rtc.on("user-published", async (remoteUser, mediaType) => {
        const rtc = rtcRef.current;
        const videoCall = videoCallRef.current;
        const videoContainer = videoContainerRef.current;

        console.log("USER_PUBLISHED", {
          remoteUser: remoteUser?.uid,
          mediaType,
        });

        // Subscribing to the remote tracks
        await rtc.subscribe(remoteUser, mediaType);

        const userId = remoteUser.uid.toString();
        const isHost = videoCall?.host?.id === userId;
        const isCandidate = videoCall?.interview?.candidate?.id === userId;

        const amIHost = videoCall?.host?.id === profileId;
        const amICandidate = videoCall?.interview?.candidate?.id === profileId;

        const { videoTrack, audioTrack } = remoteUser;

        setTimeout(() => {
          // Updating the local tracks
          switch (mediaType) {
            case "video":
              if (!videoTrack) return;

              console.log("USER_PUBLISHED_VIDEO", remoteUser);

              if (amIHost) {
                if (isCandidate) {
                  console.log("USER_CANDIDATE_JOINED", {
                    candidate: videoCall.interview?.candidate,
                  });

                  updateParticipant(userId, {
                    videoTrack,
                    screenSharing: true,
                  });

                  setSpeaker(null);
                  setSpeaker({ id: userId });

                  if (!videoContainer) return;

                  videoTrack.play(videoContainer);

                  return;
                }
              }

              if (amICandidate) {
                if (isHost) {
                  console.log("USER_HOST_JOINED", {
                    host: videoCall.host?.firstName,
                  });

                  updateParticipant(userId, {
                    videoTrack,
                    screenSharing: true,
                  });

                  setSpeaker(null);
                  setSpeaker({ id: userId });

                  if (!videoContainer) return;

                  videoTrack.play(videoContainer);

                  return;
                }
              }

              if (isCandidate) {
                console.log("USER_REGULAR_JOINED", { userId });

                updateParticipant(userId, {
                  videoTrack,
                  screenSharing: true,
                });

                setSpeaker(null);
                setSpeaker({ id: userId });

                if (!videoContainer) return;

                videoTrack.play(videoContainer);

                return;
              }

              updateParticipant(userId, { videoTrack });

              break;
            case "audio":
              if (!audioTrack) return;

              console.log("USER_PUBLISHED_AUDIO", remoteUser);

              updateParticipant(userId, { audioTrack });
              break;
          }
        }, 1000);
      });

      rtc.on("user-unpublished", async (remoteUser, mediaType) => {
        console.log("USER_UNPUBLISHED", {
          remoteUser: remoteUser?.uid,
          mediaType,
        });

        const userId = remoteUser.uid.toString();

        setTimeout(() => {
          // Updating the local tracks
          switch (mediaType) {
            case "video":
              console.log("USER_UNPUBLISHED_VIDEO", remoteUser);

              updateParticipant(userId, { videoTrack: null });
              break;
            case "audio":
              console.log("USER_UNPUBLISHED_AUDIO", remoteUser);

              updateParticipant(userId, { audioTrack: null });
              break;
          }
        }, 500);
      });

      return rtc;
    } catch (e) {}
  };

  const join = async () => {
    const rtc = rtcRef.current;

    if (!rtc || !videoCallId) return;

    // Checking the video call
    const videoCall = await dispatch(getVideoCallById(videoCallId));
    if (!videoCall?.id) {
      toast({
        status: NotificationStatus.Error,
        message: `You are not able to join this video call`,
      });
      return;
    }

    // Joining the video call
    const joinedVideoCall = await dispatch(joinVideoCallById(videoCallId));
    if (!joinedVideoCall?.id) {
      toast({
        status: NotificationStatus.Error,
        message: `You are not able to join this video call`,
      });
      return;
    }

    // Checking the profile
    const { id, firstName, avatar, accountId } = activeProfile || {};
    const isYou = true;
    const isHost = videoCall?.host?.id === id;
    const isCandidate = videoCall?.interview?.candidateId === id;

    const { appId, channel, token } = joinedVideoCall.config || {};
    const { id: uid } = activeProfile || {};

    // Joining the RTC channel
    await rtc.join(appId, channel, token, uid);

    // Creating the video and audio tracks
    const localTracks: ILocalTrack[] = [];

    const videoTrack = await AgoraRTC.createCameraVideoTrack()
      .then((track) => track)
      .catch(() => null);

    const audioTrack = await AgoraRTC.createMicrophoneAudioTrack()
      .then((track) => track)
      .catch(() => null);

    if (videoTrack) {
      localTracks.push(videoTrack);
      setLocalVideoTrack(videoTrack);
    }

    if (audioTrack) {
      localTracks.push(audioTrack);
      setLocalAudioTrack(audioTrack);
    }

    console.log("USER_JOINING", {
      rtc,
      appId,
      channel,
      token,
      uid,
      videoTrack,
      audioTrack,
      localTracks,
    });

    // Adding the participant
    const participant: IVideoCallParticipant = {
      id: profileId,
      firstName,
      avatar,
      accountId,
      isYou,
      isHost,
      isCandidate,
      videoTrack,
      audioTrack,
    };

    addParticipant(participant);

    // Sending the local tracks to RTC channel
    if (!!localTracks?.length) {
      rtc.publish(localTracks);
    }
  };

  const leave = async () => {
    const rtc = rtcRef.current;

    console.log("USER_LEAVING", { localVideoTrack, localAudioTrack });

    // Leaving the RTC channel
    rtc.leave();

    // Closing the local video track
    localVideoTrack?.close();
    setLocalVideoTrack(null);

    // Closing the local video track
    localAudioTrack?.close();
    setLocalAudioTrack(null);

    console.log("USER_LEAVING_END");
  };

  const addParticipant = (participant: IVideoCallParticipant) => {
    console.log("USER_ADDING_PARTICIPANT");

    setParticipants((participants) => [
      ...participants.filter(({ id }) => id !== participant?.id),
      participant,
    ]);
  };

  const updateParticipant = (
    participantId: string,
    payload: IVideoCallParticipant
  ) => {
    setParticipants((participants) => {
      const index = participants?.findIndex(({ id }) => id === participantId);
      const participant = participants[index];

      console.log("USER_UPDATING_PARTICIPANT", {
        participantId,
        participant,
        participants,
        participantCount: participants?.length || 0,
      });

      participants[index] = {
        ...participant,
        ...payload,
      };

      return participants;
    });
  };

  const removeParticipant = (participantId: string) => {
    console.log("USER_REMOVING_PARTICIPANT");

    setParticipants((participants) =>
      participants?.filter((participant) => participant?.id !== participantId)
    );
  };

  const sortParticipants = (participants: IVideoCallParticipant[]) => {
    const isHost = (userId?: string) => videoCall?.host?.id === userId;

    return (
      participants?.sort(
        (participantA, participantB) =>
          Number(isHost(participantB?.id)) - Number(isHost(participantA?.id))
      ) || []
    );
  };

  const handleLeave = () => {
    leave();
  };

  const handleVideoToggle = async () => {
    const rtc = rtcRef.current;
    const isEnabled = !!localVideoTrack?.enabled;

    if (!rtc || !profileId) return;

    console.log("USER_VIDEO_TOGGLE", { isEnabled, localVideoTrack });

    switch (isEnabled) {
      case true:
        console.log("USER_VIDEO_OFF", { localVideoTrack });

        if (!localVideoTrack) return;

        // Closing the local video track
        localVideoTrack?.close();

        // Unpublishing the video track
        await rtc.unpublish([localVideoTrack]);

        // Updating the participant
        updateParticipant(profileId, { videoTrack: null });

        // Updating the local tracks
        setLocalVideoTrack(null);

        break;
      case false:
        // Creating the local video track
        const videoTrack = await AgoraRTC.createCameraVideoTrack()
          .then((track) => track)
          .catch(() => null);

        console.log("USER_VIDEO_ON", { videoTrack });

        if (!videoTrack) return;

        // Publishing the video track
        await rtc.publish([videoTrack]);

        // Updating the participant
        updateParticipant(profileId, { videoTrack });

        // Updating the local tracks
        setLocalVideoTrack(videoTrack);

        break;
    }
  };

  const handleAudioToggle = async () => {
    const rtc = rtcRef.current;
    const isEnabled = !!localAudioTrack?.enabled;

    if (!rtc || !profileId) return;

    console.log("USER_AUDIO_TOGGLE", { isEnabled, localAudioTrack });

    switch (isEnabled) {
      case true:
        console.log("USER_AUDIO_OFF", { localAudioTrack });

        if (!localAudioTrack) return;

        // Closing the local audio track
        localAudioTrack?.close();

        // Unpublishing the audio track
        await rtc.unpublish([localAudioTrack]);

        // Updating the participant
        updateParticipant(profileId, { audioTrack: null });

        // Updating the local tracks
        setLocalAudioTrack(null);

        break;
      case false:
        // Creating the local audio track
        const audioTrack = await AgoraRTC.createMicrophoneAudioTrack()
          .then((track) => track)
          .catch(() => null);

        console.log("USER_AUDIO_ON", { audioTrack });

        if (!audioTrack) return;

        // Publishing the audio track
        await rtc.publish([audioTrack]);

        // Updating the participant
        updateParticipant(profileId, { audioTrack });

        // Updating the local tracks
        setLocalAudioTrack(audioTrack);

        break;
    }
  };

  const handleScreenSharingToggle = async () => {
    const rtc = rtcRef.current;
    const videoContainer = videoContainerRef.current;
    const isEnabled = !!localScreenTrack?.enabled;

    console.log("USER_SCREEN_SHARING", { isEnabled });

    switch (isEnabled) {
      case true:
        if (!localScreenTrack) return;

        // Closing the screen track
        localScreenTrack?.close();

        // Unpublishing the screen track
        await rtc.unpublish([localScreenTrack]);

        // Updating the local screen track
        setLocalScreenTrack(null);

        if (localVideoTrack) {
          // Publishing the video track
          await rtc.publish([localVideoTrack]);

          // Playing the video track
          if (videoContainer) {
            localVideoTrack?.play(videoContainer);
          }
        }

        break;
      case false:
        // Creating the local screen track
        const screenTrack = await AgoraRTC.createScreenVideoTrack({}, "disable")
          .then((track) => track)
          .catch(() => null);

        if (!screenTrack) return;

        if (localVideoTrack) {
          // Stopping the local video track
          localVideoTrack?.stop();

          // Unpublishing the video track
          await rtc.unpublish([localVideoTrack]);
        }

        // Publishing the screen track
        await rtc.publish([screenTrack]);

        console.log("USER_SCREEN_SHARING_MASS_SUBSCRIBE", {
          remoteUsers: rtc.remoteUsers,
          remoteUsersCount: rtc.remoteUsers?.length,
        });

        // Subscribing all remote users to the screen track
        await rtc.massSubscribe(
          rtc.remoteUsers?.map((user) => ({ user, mediaType: "video" }))
        );

        // Updating the local screen track
        setLocalScreenTrack(screenTrack);

        // Playing the screen track
        if (videoContainer) {
          screenTrack?.play(videoContainer);
        }

        break;
    }
  };

  const handleRecordingToggle = async () => {
    switch (recording) {
      case true:
        setRecording(!recording);
        dispatch(stopVideoCallRecording());

        break;
      case false:
        setRecording(!recording);
        dispatch(startVideoCallRecording());

        break;
    }
  };

  const handleVideoControllsToggle = (mouseIn: boolean) => {
    setVideoControls(mouseIn ? true : false);
  };

  useEffect(() => {
    if (!speaker?.id) return;

    const participant = participants?.find(({ id }) => speaker?.id === id);
    const { firstName } = participant || {};

    console.log("USER_SPEAKER_NAME_CHANGE", {
      participant,
      speakerId: speaker?.id,
      firstName,
    });

    setSpeaker({ ...speaker, firstName });
  }, [speaker?.id]);

  useEffect(() => {
    if (!participants) return;

    participantsRef.current = participants;
  }, [participants]);

  useEffect(() => {
    if (!host) return;

    hostRef.current = host;
  }, [host]);

  useEffect(() => {
    if (!videoCall) return;

    videoCallRef.current = videoCall;
  }, [videoCall]);

  useEffect(() => {
    if (!videoCallId) return;

    join();

    return () => {
      leave();
    };
  }, [videoCallId]);

  useEffect(() => {
    initiateRTC();

    return () => {
      leave();
    };
  }, []);

  return (
    <Container>
      <PageHeader back={false}>Video Call</PageHeader>
      <Container loading={videoCallLoading}>
        {videoCall?.id && (
          <div
            className={`
              relative w-full xxs:max-h-[50vh] xxs:aspect-verticalVideo md:aspect-video flex flex-row justify-center items-center bg-primaryMid rounded-lg overflow-hidden
              ${recording ? "border-[3px] border-solid border-danger" : ""}
            `}
            onMouseEnter={() => handleVideoControllsToggle(true)}
            onMouseLeave={() => handleVideoControllsToggle(false)}
          >
            <div
              ref={videoContainerRef}
              className="w-full h-full top-[0px] left-[0px] right-[0px] overflow-hidden z-50"
            >
              {speaker && (
                <div className="absolute w-[75px] flex flex-row justify-center items-center rounded-lg top-[10px] left-[10px] p-[5px] box-border backdrop-blur-xl backdrop-brightness-75 z-40">
                  <span className="text-white text-xs font-medium">
                    {textformat.shortenByChars(speaker?.firstName || "", 15)}
                  </span>
                </div>
              )}
            </div>
          </div>
        )}

        <div className="">
          <VideoCallControlBar
            video={videoEnabled}
            audio={audioEnabled}
            screenSharing={screenSharing}
            recording={recording}
            hidden={false}
            onLeave={handleLeave}
            onVideoToggle={handleVideoToggle}
            onAudioToggle={handleAudioToggle}
            onScreenSharingToggle={handleScreenSharingToggle}
            onRecordingToggle={handleRecordingToggle}
            // onSettingsToggle={handleSettingsToggle}
          />
        </div>

        <div
          className="
            transition-all grid gap-[10px] mt-[20px]
            xxs:grid-cols-4 md:grid-cols-5
        "
        >
          {sortParticipants(participants)?.map((participant) => (
            <VideoCallParticipantCard key={participant?.id} {...participant} />
          ))}
        </div>
      </Container>

      {/* <div className="mt-[50px] break-words">
        {JSON.stringify({ systemInfo })}
      </div> */}
    </Container>
  );
};
