import React, { useEffect } from "react";
import { useParams } from "react-router";

import { Container, PageHeader } from "../../components";
import { FriendList } from "./../../features";
import { useAppDispatch, useAppSelector, useAccount } from "../../hooks";
import { getFriendsByUserId } from "../../store";

export const FriendListContainer = () => {
  const dispatch = useAppDispatch();

  const { activeProfile } = useAccount();

  const {
    userFriends: {
      data: friends,
      results: friendCount,
      isLoading: friendLoading,
    },
  } = useAppSelector((state) => state.profile);

  useEffect(() => {
    if (!activeProfile?.id) return;

    dispatch(getFriendsByUserId(activeProfile?.id));
  }, [activeProfile?.id]);

  return (
    <Container>
      <PageHeader back={false}>Friends ({friendCount || 0})</PageHeader>
      <FriendList data={friends} loading={friendLoading} />
    </Container>
  );
};
