import React from "react";

import { Action, IProfile, ModalType, Role } from "../../shared";

import { ActionMenu, Avatar, Link, RBACSwitch } from "../../components";
import { useAccount, useModal } from "../../hooks";
import { ROUTER } from "../../routes";
import { date } from "../../utils";

interface IPostCardUserInfoBarProps {
  id: string;
  createdBy?: IProfile;
  createdById: string;
  createdAt?: Date;
  loading?: boolean;
  editing?: boolean;
  onEdit: (id?: string) => void;
  onDelete: (id?: string) => void;
}

export const PostCardUserInfoBar: React.FC<IPostCardUserInfoBarProps> = ({
  id,
  createdBy,
  createdById,
  createdAt,
  loading,
  editing,
  onEdit,
  onDelete,
}) => {
  const { activeProfile } = useAccount();
  const { setModal } = useModal();

  const isAuthor = activeProfile?.id === createdById || false;

  return (
    <div className="w-full flex flex-row justify-between items-start">
      <div className="flex flex-row justify-start items-center gap-x-[10px]">
        <Avatar
          size={40}
          to={ROUTER.USER_DETAIL(createdById)}
          src={createdBy?.avatar}
        >
          {createdBy?.lastName}
        </Avatar>
        <div className="flex flex-col gap-y-[5px]">
          <Link to={ROUTER.USER_DETAIL(createdById)}>
            <h3 className="text-sm text-white font-semibold leading-none">
              {createdBy?.firstName || ""} {createdBy?.lastName || ""}
            </h3>
          </Link>
          <span className="text-xs text-secondary font-normal leading-none">
            {date(createdAt).fromNow()}
          </span>
        </div>
      </div>
      <div>
        {RBACSwitch({
          elements: [
            {
              roles: [Role.Admin],
              render: !editing ? (
                <ActionMenu
                  loading={loading}
                  actions={[Action.Edit, Action.Delete]}
                  {...{ onEdit, onDelete }}
                />
              ) : (
                <></>
              ),
            },
            {
              roles: [Role.Owner, Role.TeamLead, Role.Employee, Role.User],
              render:
                isAuthor && !editing ? (
                  <ActionMenu
                    loading={loading}
                    actions={[Action.Edit, Action.Delete]}
                    {...{ onEdit, onDelete }}
                  />
                ) : (
                  <></>
                ),
            },
          ],
        })}
      </div>
    </div>
  );
};

PostCardUserInfoBar.defaultProps = {};
