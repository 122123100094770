import React, { useEffect, useRef, useState } from "react";

import {
  DigitalAssetType,
  ModalType,
  NotificationStatus,
} from "../../../shared";

import { Button, Container, Modal, TabSwitch } from "../../../components";
import { DigitalAssetPreviewCard } from "./../../../features";
import {
  useAppDispatch,
  useAppSelector,
  useLoading,
  useModal,
  useToast,
} from "../../../hooks";
import {
  getDigitalAssets,
  addDigitalAssetsToResume,
  removeDigitalAssetsFromResume,
  getGalleryFiles,
} from "../../../store";

interface ISelectedDigitalAsset {
  id: string;
  type?: DigitalAssetType;
  isGallery?: boolean;
}

export const ResumeDigitalAssetSelectAssetModal = () => {
  const dispatch = useAppDispatch();

  const { modal, isOpen, closeModal } = useModal({
    type: ModalType.RESUME_DIGITAL_ASSET_SELECT_ASSET,
  });

  const [loading, setLoading] = useLoading();
  const { toast } = useToast();

  const [addedAssets, setAddedAssets] = useState<string[]>([]);
  const [selectedAssets, setSelectedAssets] = useState<ISelectedDigitalAsset[]>(
    []
  );

  const {
    resume: { data: resume },
  } = useAppSelector((state) => state.resume);

  const { props } = modal;
  const { resumeId, createdById } = props || {};

  const disabled = !selectedAssets.length;

  const handleSubmit = async () => {
    if (!selectedAssets.length) return;

    setLoading(true);

    // Preparing data
    const data = {
      digitalAssets:
        selectedAssets?.filter((i) => !i?.isGallery)?.map(({ id }) => id) || [],
      galleryItems:
        selectedAssets?.filter((i) => !!i?.isGallery)?.map(({ id }) => id) ||
        [],
    };

    // Adding Digital Assets
    const digitalAssets = await dispatch(
      addDigitalAssetsToResume(resumeId, data)
    );
    if (!digitalAssets?.length) {
      setLoading(false);

      return toast({
        status: NotificationStatus.Error,
        message: `Digital assets haven't been added to your resume`,
      });
    }

    setLoading(false);
    closeModal();

    return toast({
      status: NotificationStatus.Success,
      message: `Digital assets have been successfully added to your resume`,
    });
  };

  const handleSelect = (selectedDigitalAsset?: ISelectedDigitalAsset): void => {
    if (!selectedDigitalAsset?.id) return;

    const { id } = selectedDigitalAsset;

    setSelectedAssets((selectedAssets) =>
      !!selectedAssets.find((i) => i?.id === id)
        ? selectedAssets?.filter((i) => i?.id !== id)
        : [...selectedAssets?.filter((i) => i?.id !== id), selectedDigitalAsset]
    );
  };

  const tabs = [
    {
      id: " digital-portfolio",
      label: "Digital Portfolio",
      component: () => (
        <ResumeDigitalAssetSelectDigitalPortfolioView
          addedAssets={addedAssets}
          selectedAssets={selectedAssets}
          createdById={createdById}
          onSelect={handleSelect}
        />
      ),
    },
    {
      id: "gallery",
      label: "Gallery",
      component: () => (
        <ResumeDigitalAssetSelectGalleryView
          addedAssets={addedAssets}
          selectedAssets={selectedAssets}
          createdById={createdById}
          onSelect={handleSelect}
        />
      ),
    },
  ];

  useEffect(() => {
    if (!resume.digitalAssets?.length) return;

    setAddedAssets(resume.digitalAssets?.map(({ id }) => id));
  }, [resume?.digitalAssets]);

  return (
    <Modal
      title="Add Digital Asset"
      description="Please select your digital assets to add into your resume."
      dialogClassName="max-w-[720px] max-h-[100vh]"
      open={isOpen}
      onClose={closeModal}
    >
      <Container className="mt-[15px]">
        <TabSwitch tabs={tabs} />
        <div
          className="
            w-full flex items-center gap-[10px] mt-[15px]
            xxs:flex-col-reverse md:flex-row md:justify-end
        "
        >
          <Button
            variant="outlined"
            color="secondary"
            className="xxs:w-full md:w-auto"
            onClick={closeModal}
          >
            Discard
          </Button>
          <Button
            variant="outlined"
            color="success"
            className="xxs:w-full md:w-auto min-w-[150px]"
            loading={loading}
            disabled={disabled}
            onClick={handleSubmit}
          >
            Add To Resume{" "}
            {!!selectedAssets?.length ? `(${selectedAssets.length})` : ""}
          </Button>
        </div>
      </Container>
    </Modal>
  );
};

interface IResumeDigitalAssetSelectViewProps {
  addedAssets: string[];
  selectedAssets: ISelectedDigitalAsset[];
  createdById: string;
  onSelect: (asset: ISelectedDigitalAsset) => void;
}

const ResumeDigitalAssetSelectDigitalPortfolioView: React.FC<
  IResumeDigitalAssetSelectViewProps
> = ({ addedAssets, selectedAssets, createdById, onSelect }) => {
  const dispatch = useAppDispatch();

  const {
    digitalAssets: { data },
  } = useAppSelector((state) => state.digitalPortfolio);

  const digitalAssets = data?.filter(
    (asset) => !addedAssets.find((id) => id === asset?.id)
  );

  const certificateAssets = digitalAssets?.filter(
    (asset) => asset.type === DigitalAssetType.Certificate
  );
  const photoAssets = digitalAssets?.filter(
    (asset) => asset.type === DigitalAssetType.Photo
  );

  const selected = (id?: string): boolean =>
    !!selectedAssets.find((i) => i?.id === id);

  const handleSelect = (id?: string) => {
    if (!id) return;

    onSelect({ id, isGallery: false });
  };

  useEffect(() => {
    if (!createdById) return;

    dispatch(
      getDigitalAssets({ where: { createdById }, sort: [{ createdAt: -1 }] })
    );
  }, [createdById]);

  return (
    <div className="flex flex-col gap-[30px] max-h-[400px] overflow-y-scroll">
      {!!certificateAssets?.length && (
        <div className="flex flex-col">
          <h3 className="text-base text-secondary font-semibold">
            Certificates
          </h3>
          <div className="grid gap-[2px] mt-[15px] xxs:grid-cols-3 sm:grid-cols-4 md:grid-cols-4">
            {certificateAssets?.map((digitalAsset) => (
              <DigitalAssetPreviewCard
                key={digitalAsset?.id}
                {...digitalAsset}
                selected={selected(digitalAsset?.id)}
                selecting
                onSelect={handleSelect}
              />
            ))}
          </div>
        </div>
      )}
      {!!photoAssets?.length && (
        <div className="flex flex-col">
          <h3 className="text-base text-secondary font-semibold">Photos</h3>
          <div className="grid gap-[2px] mt-[15px] xxs:grid-cols-3 sm:grid-cols-4 md:grid-cols-4">
            {photoAssets?.map((digitalAsset) => (
              <DigitalAssetPreviewCard
                key={digitalAsset?.id}
                {...digitalAsset}
                selected={selected(digitalAsset?.id)}
                selecting
                onSelect={handleSelect}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const ResumeDigitalAssetSelectGalleryView: React.FC<
  IResumeDigitalAssetSelectViewProps
> = ({ addedAssets, selectedAssets, createdById, onSelect }) => {
  const dispatch = useAppDispatch();

  const {
    galleryFiles: { data: galleryItems },
  } = useAppSelector((state) => state.gallery);

  const selected = (id?: string): boolean =>
    !!selectedAssets.find((i) => i?.id === id);

  const handleSelect = (id?: string) => {
    if (!id) return;

    onSelect({ id, isGallery: true });
  };

  useEffect(() => {
    if (!createdById) return;

    dispatch(
      getGalleryFiles({ where: { createdById }, sort: [{ createdAt: -1 }] })
    );
  }, [createdById]);

  return (
    <div className="flex flex-col gap-[30px] max-h-[400px] overflow-y-scroll">
      <div className="flex flex-col">
        <div className="grid gap-[2px] mt-[15px] xxs:grid-cols-3 sm:grid-cols-4 md:grid-cols-4">
          {galleryItems?.map((digitalAsset) => (
            <DigitalAssetPreviewCard
              key={digitalAsset?.id}
              {...digitalAsset}
              selected={selected(digitalAsset?.id)}
              selecting
              onSelect={handleSelect}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
