import React from "react";

import { lazyload } from "../../utils";
import { MainLayout } from "../../layouts";

const { UserSettingsContainer } = lazyload(() => import("./../../features"));

export const UserSettingsPage = () => (
  <MainLayout hideFooter>
    <UserSettingsContainer />
  </MainLayout>
);
