import React from "react";

import { ISidebar } from "@types";

import {
  SidebarSection,
  Sidebar,
  SidebarContent,
  Button,
  Searchbar,
} from "../../../components";
import { ROUTER } from "../../../routes";

export const NewsLeftSidebar: React.FC<ISidebar> = (props) => {
  return (
    <Sidebar {...props}>
      <SidebarContent>
        <SidebarSection heading="Search" className="border-none">
          <Searchbar
            placeholder="Search news.."
            onChange={() => {
              console.log("SEARCHING");
            }}
          />
        </SidebarSection>

        <SidebarSection heading="Filters" className="border-none">
          <h2>Filter #1</h2>
          <h2>Filter #2</h2>
          <h2>Filter #3</h2>
        </SidebarSection>
      </SidebarContent>
    </Sidebar>
  );
};
