import React from "react";

import { IResumeCover } from "../../../../shared";
import {
  ResumeCoverSectionDetail,
  IResumeSectionProps,
} from "../../../../features";

interface IResumeCoverSectionProps extends IResumeSectionProps<IResumeCover> {}

export const ResumeCoverSection: React.FC<IResumeCoverSectionProps> = ({
  data,
  loading,
}) => <ResumeCoverSectionDetail loading={loading} data={data} />;

ResumeCoverSection.defaultProps = {};
