import React, { useEffect } from "react";
import { useParams } from "react-router";

import { FiUpload as UploadIcon } from "react-icons/fi";

import { DigitalAssetType, ModalType } from "../../shared";

import { Button, Container, PageHeader } from "../../components";
import { DigitalAssetPreviewCard } from "../../features";
import { ROUTER } from "../../routes";
import {
  useAccount,
  useAppDispatch,
  useAppSelector,
  useModal,
} from "../../hooks";
import { getDigitalAssets, getDigitalPortfolioByUserId } from "../../store";

export const UserDigitalPortfolioDetailContainer = () => {
  const dispatch = useAppDispatch();

  const { userId } = useParams();

  const { setModal } = useModal();
  const { activeProfile } = useAccount();

  const {
    digitalAssets: {
      data: digitalAssets,
      results: digitalAssetsCount,
      isLoading: digitalAssetsLoading,
    },
  } = useAppSelector((state) => state.digitalPortfolio);

  const certificateAssets = digitalAssets?.filter(
    (asset) => asset?.type === DigitalAssetType.Certificate
  );
  const photoAssets = digitalAssets?.filter(
    (asset) => asset?.type === DigitalAssetType.Photo
  );
  const videoAssets = digitalAssets?.filter(
    (asset) => asset?.type === DigitalAssetType.Video
  );

  const digitalPortfolioId = activeProfile?.digitalPortfolio?.id;
  const isAuthor = activeProfile?.id === userId;

  const handleUpload = () => {
    setModal({ type: ModalType.DIGITAL_PORTFOLIO_UPLOAD });
  };

  useEffect(() => {
    if (!userId) return;

    dispatch(getDigitalAssets({ where: { createdById: userId } }));
  }, [userId]);

  return (
    <Container>
      <PageHeader
        backTo={ROUTER.USER_DETAIL(userId)}
        exceptions={[
          { from: ROUTER.RESUME_BUILDER, to: ROUTER.RESUME_BUILDER },
        ]}
        renderOnRight={
          isAuthor ? (
            <Button
              color="secondary"
              icon={<UploadIcon />}
              onClick={handleUpload}
            >
              Upload
            </Button>
          ) : (
            <></>
          )
        }
      >
        Digital Portfolio
      </PageHeader>
      <Container loading={digitalAssetsLoading}>
        <div className="flex flex-col gap-[30px]">
          {!!certificateAssets?.length && (
            <div className="flex flex-col">
              <h3 className="text-base text-secondary font-semibold">
                Certificates
              </h3>
              <div className="grid gap-[2px] mt-[15px] xxs:grid-cols-3 sm:grid-cols-4 md:grid-cols-4">
                {certificateAssets?.map((digitalAsset) => (
                  <DigitalAssetPreviewCard
                    key={digitalAsset?.id}
                    {...digitalAsset}
                  />
                ))}
              </div>
            </div>
          )}

          {!!photoAssets?.length && (
            <div className="flex flex-col">
              <h3 className="text-base text-secondary font-semibold">Photos</h3>
              <div className="grid gap-[2px] mt-[15px] xxs:grid-cols-3 sm:grid-cols-4 md:grid-cols-4">
                {photoAssets?.map((digitalAsset) => (
                  <DigitalAssetPreviewCard
                    key={digitalAsset?.id}
                    to={ROUTER.USER_DIGITAL_ASSET_DETAIL(
                      digitalAsset?.createdById,
                      digitalAsset?.id
                    )}
                    {...digitalAsset}
                  />
                ))}
              </div>
            </div>
          )}
          {!!videoAssets?.length && (
            <div className="flex flex-col">
              <h3 className="text-base text-secondary font-semibold">Videos</h3>
              <div className="grid gap-[2px] mt-[15px] xxs:grid-cols-3 sm:grid-cols-4 md:grid-cols-4">
                {videoAssets?.map((digitalAsset) => (
                  <DigitalAssetPreviewCard
                    key={digitalAsset?.id}
                    to={ROUTER.USER_DIGITAL_ASSET_DETAIL(
                      digitalAsset?.createdById,
                      digitalAsset?.id
                    )}
                    {...digitalAsset}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </Container>
    </Container>
  );
};
