import React from "react";

import {
  FaFacebook as FacebookIcon,
  FaTwitter as TwitterIcon,
  FaInstagram as InstagramIcon,
  FaTiktok as TiktokIcon,
} from "react-icons/fa";

import { IconButton, IIconButtonProps } from "./../../components";

interface ISocialMediaIconButtonProps extends IIconButtonProps {
  platform: string | "facebook" | "twitter" | "tiktok" | "instagram";
  username?: string;
}

export const SocialMediaIconButton: React.FC<ISocialMediaIconButtonProps> = ({
  platform,
  username,
  iconClassName,
  iconProps,
  onClick,
}) => {
  let icon, url;

  switch (platform) {
    case "facebook":
      icon = FacebookIcon;
      url = username ? `https://www.facebook.com/${username}` : null;
      break;
    case "twitter":
      icon = TwitterIcon;
      url = username ? `https://twitter.com/${username}` : null;

      break;
    case "instagram":
      icon = InstagramIcon;
      url = username ? `https://www.instagram.com/${username}` : null;
      break;
    case "tiktok":
      icon = TiktokIcon;
      url = username ? `https://www.tiktok.com/@${username}` : null;
      break;
    default:
      break;
  }

  return icon ? (
    <IconButton
      href={url}
      icon={icon}
      iconProps={iconProps}
      iconClassName={iconClassName}
      onClick={onClick || (() => {})}
    />
  ) : (
    <></>
  );
};

SocialMediaIconButton.defaultProps = {
  iconProps: { size: 24 },
  iconClassName: "fill-secondary",
};
