import React from "react";

import { IFriendRequest } from "../../shared";

import { Avatar, Button, Card, CardBody, Link } from "../../components";
import { useToast, useAppDispatch, useLoading } from "../../hooks";
import { ROUTER } from "../../routes";
import {
  approveFriendRequest,
  getUserFriendRequests,
  rejectFriendRequest,
} from "../../store";
import { date } from "../../utils";
import {
  NotificationStatus,
  NotificationType,
  NotificationPriority,
} from "./../../shared";

interface IFriendRequestCardProps extends IFriendRequest {}

export const FriendRequestCard: React.FC<IFriendRequestCardProps> = ({
  id,
  user,
  createdAt,
}) => {
  const dispatch = useAppDispatch();

  const { toast } = useToast();

  const [approveLoading, setApproveLoading] = useLoading();
  const [rejectLoading, setRejectLoading] = useLoading();

  const handleApprove = async () => {
    if (!id) return;

    setApproveLoading(true);

    const request = await dispatch(approveFriendRequest(id));
    if (!request?.id) {
      setApproveLoading(false);
      toast({
        status: NotificationStatus.Error,
        message: "Friend Request Hasn't Been Accepted!",
      });

      return;
    }

    setApproveLoading(false);

    dispatch(getUserFriendRequests());
  };

  const handleReject = async () => {
    if (!id) return;

    setRejectLoading(true);

    const request = await dispatch(rejectFriendRequest(id));
    if (!request?.id) {
      setRejectLoading(false);
      toast({
        status: NotificationStatus.Error,
        message: "Friend Request Hasn't Been Rejected!",
      });

      return;
    }

    setRejectLoading(false);

    dispatch(getUserFriendRequests());
  };

  return (
    <Card>
      <CardBody>
        <div className="flex flex-row justify-between items-center">
          <div className="w-full flex flex-col">
            <div className="w-full flex flex-col">
              <div className="flex flex-row justify-start items-start gap-x-[10px]">
                <Avatar
                  size={40}
                  src={user?.avatar}
                  to={ROUTER.USER_DETAIL(user?.id)}
                >
                  {user?.firstName}
                </Avatar>
                <div className="w-full flex flex-col justify-between items-start gap-y-[5px] mt-[4.5px]">
                  <Link to={ROUTER.USER_DETAIL(user?.id)}>
                    <h3 className="text-base font-semibold text-white leading-none">
                      {user?.firstName || ""} {user?.lastName || ""}
                    </h3>
                  </Link>
                  <span className="text-xs text-secondary leading-none">
                    {date(createdAt).fromNow()}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-between items-center gap-x-[10px]">
            <Button
              variant="outlined"
              color="success"
              className="min-w-[100px]"
              loading={approveLoading}
              onClick={handleApprove}
            >
              Accept
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              className="min-w-[100px]"
              loading={rejectLoading}
              onClick={handleReject}
            >
              Reject
            </Button>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

FriendRequestCard.defaultProps = {};
