import React, { useEffect, useReducer, useState } from "react";

import { Action, DigitalAssetType, ModalType } from "../../../../shared";

import { Button, Link } from "../../../../components";

import {
  ResumeDeleteModal,
  ResumeSectionCard,
  ResumeSectionCardBody,
  ResumeSectionCardFooter,
  DigitalAssetPreviewCard,
} from "../../../../features";

import { removeEducation, removeResumeEducation } from "../../../../store";
import {
  useAppDispatch,
  useAppSelector,
  useModal,
  useToast,
} from "../../../../hooks";
import { ROUTER } from "../../../../routes";

export const ResumeDigitalAssetSectionDetail = ({ loading, data }) => {
  const dispatch = useAppDispatch();

  const {
    resume: { data: resume },
  } = useAppSelector((state) => state.resume);

  const { modal, setModal } = useModal();

  const { id: resumeId, profileId: createdById } = resume || {};

  const digitalAssets = data || [];

  const certificateAssets = digitalAssets?.filter(
    (asset) => asset.type === DigitalAssetType.Certificate
  );
  const photoAssets = digitalAssets?.filter(
    (asset) => asset.type === DigitalAssetType.Photo
  );

  const handleClick = (id?: string) => {
    setModal({
      ...modal,
      type: ModalType.RESUME_DIGITAL_ASSET_PREVIEW,
      props: { id },
    });
  };

  const handleAdd = () => {
    setModal({
      type: ModalType.RESUME_DIGITAL_ASSET_ADD,
      props: { resumeId, createdById },
    });
  };

  const handleDelete = (id?: string) => {
    if (!id) return;

    setModal({
      ...modal,
      type: ModalType.RESUME_DIGITAL_ASSET_DELETE,
      props: { id, resumeId },
    });
  };

  return (
    <ResumeSectionCard
      heading="Digital Portfolio"
      navbar={
        <Link
          to={ROUTER.USER_DIGITAL_ASSETS(createdById)}
          className="text-sm font-medium text-primary"
        >
          View All
        </Link>
      }
    >
      <ResumeSectionCardBody loading={loading}>
        {!!digitalAssets?.length && (
          <div className="flex flex-col gap-[30px]">
            {!!certificateAssets?.length && (
              <div className="flex flex-col">
                <h3 className="text-base text-secondary font-semibold">
                  Certificates
                </h3>
                <div className="grid gap-[2px] mt-[15px] xxs:grid-cols-3 sm:grid-cols-4 md:grid-cols-4">
                  {certificateAssets?.map((digitalAsset) => (
                    <DigitalAssetPreviewCard
                      key={digitalAsset?.id}
                      {...digitalAsset}
                      editing
                      onClick={() => handleClick(digitalAsset?.id)}
                      onDelete={() => handleDelete(digitalAsset?.id)}
                    />
                  ))}
                </div>
              </div>
            )}
            {!!photoAssets?.length && (
              <div className="flex flex-col">
                <h3 className="text-base text-secondary font-semibold">
                  Photos
                </h3>
                <div className="grid gap-[2px] mt-[15px] xxs:grid-cols-3 sm:grid-cols-4 md:grid-cols-4">
                  {photoAssets?.map((digitalAsset) => (
                    <DigitalAssetPreviewCard
                      key={digitalAsset?.id}
                      {...digitalAsset}
                      editing
                      onClick={() => handleClick(digitalAsset?.id)}
                      onDelete={() => handleDelete(digitalAsset?.id)}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
      </ResumeSectionCardBody>
      <ResumeSectionCardFooter>
        <div className="w-full flex flex-row justify-center">
          <Button variant="text" className="text-white" onClick={handleAdd}>
            Add New
          </Button>
        </div>
      </ResumeSectionCardFooter>
    </ResumeSectionCard>
  );
};

ResumeDigitalAssetSectionDetail.defaultProps = {};
