import React, { useEffect, useState } from "react";

import { FiPlus as PlusIcon, FiFilter as FilterIcon } from "react-icons/fi";

import {
  Tag,
  Container,
  PageHeader,
  FilterTag,
  IconButton,
} from "../../components";
import { EmployeeList } from "./../../features";
import { getResumes } from "../../store";
import {
  useAppDispatch,
  useAppSelector,
  useDrawer,
  useFilter,
} from "../../hooks";
import { DrawerType } from "../../shared";
import { IFilterTag } from "@types";

export const EmployeeSearch = () => {
  const dispatch = useAppDispatch();

  const {
    filters,
    filterTags,
    removeFilter,
    removeFilterTag,
    isEmpty,
    isFilterEmpty,
    buildFilterQuery,
  } = useFilter();

  const { setDrawer, resetDrawer } = useDrawer();

  const {
    resumes: { data: resumes, results: resumeCount, isLoading: resumeLoading },
  } = useAppSelector((state) => state.resume);

  useEffect(() => {
    const {
      //
      jobTitle,
      company,
      country,
      state,
      city,
      university,
      degree,
    }: any = filters || {};

    const isVerified = filters?.isVerified === "true" ? true : false;

    const where = {
      isDefault: true,
      isApplied: false,
      $profile$: {
        isBusiness: false,
        isLegitbook: false,
        country: buildFilterQuery("country", country),
        state: buildFilterQuery("state", state),
        city: buildFilterQuery("city", city),
      },
      $experience$: {
        company: buildFilterQuery("company", company),
        role: buildFilterQuery("jobTitle", jobTitle),
        $verification$: {
          // status: isVerified ? VerificationStatus.Approved : undefined,
        },
      },
      $education$: {
        university: buildFilterQuery("university", university),
        degree: buildFilterQuery("degree", degree),
        $verification$: {
          // status: { $ne: null }, //isVerified ? VerificationStatus.Approved : undefined,
        },
      },
    };

    dispatch(getResumes({ where }));
  }, [filters]);

  useEffect(() => {
    setDrawer({ type: DrawerType.EMPLOYEES, open: false });

    return () => resetDrawer();
  }, []);

  return (
    <>
      <div className="fixed z-50 bottom-[95px] right-[20px] flex flex-col gap-y-[8px] md:hidden xxs:flex">
        <IconButton
          icon={FilterIcon}
          iconProps={{ size: 20 }}
          className="w-[40px] h-[40px] rounded-full bg-primary text-white hover:bg-primaryBlack"
          onClick={() => setDrawer({ type: DrawerType.EMPLOYEES, open: true })}
        />
      </div>
      <Container>
        <PageHeader
          back={false}
          renderOnRight={
            <span className="text-secondary">
              {resumeCount || 0} employees found
            </span>
          }
        >
          Employee Search
        </PageHeader>

        <div className="flex flex-row justify-start items-center flex-wrap gap-x-[10px] gap-y-[10px]">
          {filterTags?.map((tag) => (
            <FilterTag
              key={tag.id}
              {...tag}
              onDelete={() => removeFilterTag(tag.id)}
            />
          ))}
        </div>

        <EmployeeList
          data={resumes}
          loading={resumeLoading}
          className="mt-[15px]"
        />
      </Container>
    </>
  );
};
