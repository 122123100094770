import React, { useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { useFormik } from "formik";

import {
  Button,
  Form,
  TextField,
  BasicDatePicker,
  Container,
} from "../../../components";
import { useAppDispatch, useLoading, useURL } from "../../../hooks";
import { ROUTER } from "../../../routes";
import { RegisterContext } from "../../../context";
import { FormValidator } from "../../../utils";

export const RegisterPassportData = () => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const { registerState, setRegisterState } = useContext(RegisterContext);
  const { passport } = registerState;

  const [loading, setLoading] = useLoading();
  const [URL, setURL] = useURL();

  const form = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      dateOfBirth: new Date(),
    },
    onSubmit: async () => {
      setLoading(true);

      setTimeout(() => {
        setLoading(false);
        setRegisterState({ ...registerState, isPassportVerified: true });
        setURL({ path: ROUTER.REGISTER_EMAIL });
      }, 250);
    },
  });

  const {
    values,
    errors,
    touched,
    resetForm,
    setValues,
    handleChange,
    handleSubmit,
  } = form;

  const handleCancel = () => {
    resetForm();
    setRegisterState({});
    setURL({ path: ROUTER.REGISTER_PASSPORT_SCAN });
  };

  useEffect(() => {
    if (!passport) return;

    const { firstName, lastName, dob } = passport;

    setValues({ firstName, lastName, dateOfBirth: new Date(dob) });
  }, [passport]);

  return (
    <Container className="w-full min-h-[250px] py-[50px]">
      <div className="w-full text-center mb-[50px]">
        <h1 className="text-2xl font-semibold">Fetched Data</h1>
      </div>
      <Form onSubmit={handleSubmit}>
        <div className="w-full flex flex-col justify-start items-start gap-y-[10px] text-white">
          <TextField
            id="firstName"
            label="First Name"
            type="text"
            variant="filled"
            value={values?.firstName}
            disabled
            onChange={handleChange}
          />
          <TextField
            id="lastName"
            label="Last Name"
            type="text"
            variant="filled"
            value={values?.lastName}
            disabled
            onChange={handleChange}
          />
          <BasicDatePicker
            id="dob"
            label="Date of birth"
            value={values?.dateOfBirth}
            disabled
            onChange={() => {}}
          />

          <div className="w-full flex flex-row justify-between items-center">
            <div>
              <span className="text-base font-medium pr-5">
                Is this information correct?
              </span>
            </div>
            <div className="flex flex-row justify-start gap-x-[10px]">
              <Button
                color="secondary"
                variant="contained"
                className="min-h-[42px] bg-primaryLight text-secondary"
                onClick={handleCancel}
              >
                No
              </Button>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                className="min-w-[125px] min-h-[42px] bg-blue-500 text-blue-50"
                loading={loading}
              >
                Yes, Confirm
              </Button>
            </div>
          </div>
        </div>
      </Form>
    </Container>
  );
};
