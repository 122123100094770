import React from "react";

import { lazyload } from "../../utils";
import { MainLayout } from "../../layouts";

const { NewsContainer, NewsLeftSidebar } = lazyload(
  () => import("../../features")
);

export const NewsPage = () => (
  <MainLayout
  //  leftSidebar={NewsLeftSidebar}
  >
    <NewsContainer />
  </MainLayout>
);
