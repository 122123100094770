import React, { useEffect } from "react";
import { IconType } from "react-icons";

import { FiArrowLeft as BackIcon } from "react-icons/fi";
import { useLocation } from "react-router";
import { ROUTER } from "../../routes";

import { IconButton } from "./../../components";

interface IPageHeaderProps {
  back?: boolean;
  backTo?: string | null;
  backIcon?: IconType | any;
  backIconProps?: object;
  exceptions?: {
    from: string;
    to: string;
  }[];
  className?: string;
  renderOnRight?: JSX.Element;
}

export const PageHeader: React.FC<IPageHeaderProps> = ({
  children,
  back,
  backTo,
  backIcon,
  backIconProps,
  exceptions,
  className,
  renderOnRight,
}) => {
  const location = useLocation();
  const previous: string = location?.state?.previous || null;

  const exception =
    exceptions?.find(({ from, to }) => (previous === from ? to : null)) || null;

  return (
    <div
      className={`${className} flex flex-row justify-between items-center gap-x-[10px] min-h-[48px] mb-[10px]`}
    >
      <div className="flex flex-row justify-start items-center gap-x-[8px]">
        {back && (
          <IconButton
            to={exception ? exception?.to : backTo ? backTo : previous || ""}
            icon={backIcon}
            iconProps={backIconProps}
            className="rounded-full text-secondary p-[5px] hover:bg-primaryLight"
          />
        )}
        <span className="flex flex-row justify-center items-center text-white font-semibold text-md">
          {children}
        </span>
      </div>
      <div className="flex flex-row">{renderOnRight}</div>
    </div>
  );
};

PageHeader.defaultProps = {
  back: true,
  backTo: null,
  backIcon: BackIcon,
  backIconProps: { size: 18 },
  className: "",
  renderOnRight: <></>,
};
