import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  SidebarSection,
  Sidebar,
  SidebarAccordion,
  Searchbar,
  Form,
  TextField,
  Button,
  SidebarContent,
  Checkbox,
  AdvancedFilterInput,
} from "../../../components";

import { useAppDispatch, useFilter, useModal, useToast } from "../../../hooks";
import { date, FormValidator, Generator } from "../../../utils";
import { removeJob, updateJob } from "../../../store";
import { ROUTER } from "../../../routes";
import {
  ModalType,
  NotificationStatus,
  NotificationType,
  NotificationPriority,
} from "../../../shared";
import { useFormik } from "formik";
import { IEmployeeSearchFilter, ISidebar } from "@types";

export const EmployeeSearchFilterSidebar: React.FC<ISidebar> = (props) => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const { filters, setFilters, resetFilters, isEmpty, isFilterEmpty } =
    useFilter();

  const form = useFormik<IEmployeeSearchFilter>({
    initialValues: {
      // country: [],
      // state: [],
      // city: [],
      // company: [],
      // jobTitle: [],
      // university: [],
      // degree: [],
      // isVerified: false,
    },
    onSubmit: (values) => {
      setFilters({ ...values });
    },
  });

  const { values, setValues, setFieldValue, resetForm, handleSubmit } = form;

  const validator = new FormValidator<IEmployeeSearchFilter>(form);

  const handleReset = () => {
    resetForm();
    resetFilters();
  };

  useEffect(() => {
    setValues({ ...values, ...filters });
  }, [filters]);

  useEffect(() => {
    console.log({ values });
  }, [values]);

  return (
    <Sidebar {...props}>
      <SidebarContent className="pb-[75px]">
        <Form onSubmit={handleSubmit}>
          <SidebarSection
            heading="Filters"
            navbar={
              <div>
                {!isEmpty && (
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleReset}
                  >
                    Clear All
                  </Button>
                )}
              </div>
            }
          >
            <SidebarAccordion
              summary="Location"
              expanded={!isFilterEmpty(["country", "state", "city"])}
            >
              <div className="flex flex-col gap-y-[10px]">
                <AdvancedFilterInput
                  filterId="country"
                  labelClassName="text-secondary"
                  placeholder="Country"
                  values={values?.country}
                  errorMessage={validator.isInputValid("country")}
                  onChange={(value) => setFieldValue("country", value, false)}
                />
                <AdvancedFilterInput
                  filterId="state"
                  labelClassName="text-secondary"
                  placeholder="State"
                  values={values?.state}
                  errorMessage={validator.isInputValid("state")}
                  onChange={(value) => setFieldValue("state", value, false)}
                />
                <AdvancedFilterInput
                  filterId="city"
                  labelClassName="text-secondary"
                  placeholder="City"
                  values={values?.city}
                  errorMessage={validator.isInputValid("city")}
                  onChange={(value) => setFieldValue("city", value, false)}
                />
              </div>
            </SidebarAccordion>
            <SidebarAccordion
              summary="Experience"
              expanded={!isFilterEmpty(["company", "jobTitle"])}
            >
              <div className="flex flex-col gap-y-[10px]">
                <AdvancedFilterInput
                  filterId="company"
                  labelClassName="text-secondary"
                  placeholder="Company"
                  values={values?.company}
                  errorMessage={validator.isInputValid("company")}
                  onChange={(value) => setFieldValue("company", value, false)}
                />
                <AdvancedFilterInput
                  filterId="jobTitle"
                  labelClassName="text-secondary"
                  placeholder="Job Title"
                  values={values?.jobTitle}
                  errorMessage={validator.isInputValid("jobTitle")}
                  onChange={(value) => setFieldValue("jobTitle", value, false)}
                />
              </div>
            </SidebarAccordion>
            <SidebarAccordion
              summary="Education"
              expanded={!isFilterEmpty(["university", "degree"])}
            >
              <div className="flex flex-col gap-y-[10px]">
                <AdvancedFilterInput
                  filterId="university"
                  labelClassName="text-secondary"
                  placeholder="University"
                  values={values?.university}
                  errorMessage={validator.isInputValid("university")}
                  onChange={(value) =>
                    setFieldValue("university", value, false)
                  }
                />
                <AdvancedFilterInput
                  filterId="degree"
                  labelClassName="text-secondary"
                  placeholder="Degree"
                  values={values?.degree}
                  errorMessage={validator.isInputValid("degree")}
                  onChange={(value) => setFieldValue("degree", value, false)}
                />
              </div>
            </SidebarAccordion>

            {/* <SidebarAccordion
              summary="Verification"
              className="border-none"
              expanded={!isFilterEmpty(["isVerified"])}
            >
              <Checkbox
                name="isVerified"
                label="Only Verified"
                checked={values?.isVerified}
                onChange={() => {
                  setFieldValue("isVerified", !values.isVerified, false);
                }}
              />
            </SidebarAccordion> */}
          </SidebarSection>
        </Form>
      </SidebarContent>

      <div className="absolute w-full h-[75px] bottom-[0px] flex flex-col justify-center p-[20px] box-border">
        <div className="flex flex-row justify-between gap-x-[10px]">
          <Button
            variant="outlined"
            color="primary"
            className="w-full"
            onClick={() => handleSubmit()}
          >
            Apply Filters
          </Button>
        </div>
      </div>
    </Sidebar>
  );
};
