import { createSlice } from "@reduxjs/toolkit";

import { IProfile } from "../../../shared";

import { AppReducerState } from "../../store";

interface IProfileState {
  profile: AppReducerState<IProfile>;
  profiles: AppReducerState<IProfile[]>;
  userFriends: AppReducerState<IProfile[]>;
  userFriendRequests: AppReducerState<IProfile[]>;
}

const initialState: IProfileState = {
  profile: {
    isLoading: false,
    data: {},
    error: null,
  },
  profiles: {
    isLoading: false,
    data: [],
    error: null,
  },
  userFriends: {
    isLoading: false,
    data: [],
    error: null,
  },
  userFriendRequests: {
    isLoading: false,
    data: [],
    error: null,
  },
};

export const profileSlice = createSlice({
  name: "Profile",
  initialState,
  reducers: {
    setProfile: (state, action) => {
      if (!action.payload) return;

      state.profile.data = {
        ...state.profile.data,
        ...action.payload,
      };
    },

    getProfilesRequest: (state) => {
      state.profiles.results = 0;
      state.profiles.data = [];
      state.profiles.isLoading = true;
    },
    getProfilesSuccess: (state, action) => {
      const { data, results } = action.payload;

      state.profiles.isLoading = false;
      state.profiles.data = data;
      state.profiles.results = results;
    },
    getProfilesError: (state, action) => {
      state.profiles.isLoading = false;
      state.profiles.error = action.payload;
    },

    getProfileRequest: (state) => {
      state.profile.data = {};
      state.profile.isLoading = true;
    },
    getProfileSuccess: (state, action) => {
      state.profile.isLoading = false;
      state.profile.data = action.payload;
    },
    getProfileError: (state, action) => {
      const error = action.payload;

      state.profile.isLoading = false;
      state.profile.error = error;
    },

    updateUserAvatarRequest: () => {},
    updateUserAvatarSuccess: (state, action) => {
      state.profile.data.avatar = action.payload;
    },
    updateUserAvatarError: () => {},

    getFriendsRequest: (state) => {
      state.userFriends.isLoading = true;
      state.userFriends.results = 0;
      state.userFriends.data = [];
    },
    getFriendsSuccess: (state, action) => {
      const { data, results } = action.payload;

      state.userFriends.data = data;
      state.userFriends.results = results;
      state.userFriends.isLoading = false;
    },
    getFriendsError: (state, action) => {
      state.userFriends.isLoading = false;
      state.userFriends.error = action.payload;
    },

    addFriendRequest: (state) => {},
    addFriendSuccess: (state, action) => {},
    addFriendError: (state, action) => {},

    removeFriendRequest: (state) => {},
    removeFriendSuccess: (state, action) => {},
    removeFriendError: (state, action) => {},

    getFriendRequestsRequest: (state) => {
      state.userFriendRequests.isLoading = true;
      state.userFriendRequests.results = 0;
      state.userFriendRequests.data = [];
    },
    getFriendRequestsSuccess: (state, action) => {
      const { data, results } = action.payload;

      state.userFriendRequests.data = data;
      state.userFriendRequests.results = results;
      state.userFriendRequests.isLoading = false;
    },
    getFriendRequestsError: (state, action) => {
      state.userFriendRequests.isLoading = false;
      state.userFriendRequests.error = action.payload;
    },

    approveFriendRequestRequest: (state) => {},
    approveFriendRequestSuccess: (state) => {},
    approveFriendRequestError: (state) => {},

    rejectFriendRequestRequest: (state) => {},
    rejectFriendRequestSuccess: (state) => {},
    rejectFriendRequestError: (state) => {},
  },
});

export const profileReducer = profileSlice.reducer;
