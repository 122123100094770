import React, { useEffect } from "react";

import { InfoCard, Container } from "../../../components";
import { UserSettingsProfileLocationEditForm } from "../forms";

export const UserSettingsProfileViewContainer = () => {
  return (
    <Container className="flex flex-col py-[15px] gap-[15px]">
      <InfoCard>
        <div className="flex flex-col text-white text-base">
          <div>
            <h2 className="text-base font-semibold">Location</h2>
          </div>
          <div className="mt-[15px]">
            <UserSettingsProfileLocationEditForm />
          </div>
        </div>
      </InfoCard>
      <InfoCard>
        <div className="flex flex-col text-white text-base">
          <div>
            <h2 className="text-base font-semibold">Privacy</h2>
          </div>
          <div></div>
        </div>
      </InfoCard>
    </Container>
  );
};
