import { v4 as uuid } from "uuid";
import { faker } from "@faker-js/faker";
import { IBasicTableColumn } from "../components";

type FakeTableDataOptions = {
  columns: {
    field: string;
    headerName: string;
    generator: () => string;
  }[];
  rowCount: number;
};

type FakeTableDataResult = {
  columns: {
    field: string;
    headerName: string;
  }[];
  rows: any[];
};

export class Generator {
  static faker = faker;

  static uuid() {
    return uuid();
  }

  static randomImages() {}

  static fakeTableData(options: FakeTableDataOptions): FakeTableDataResult {
    const { columns, rowCount = 0 } = options || {};

    if (!columns?.length || !rowCount) return { columns: [], rows: [] };

    const rows: any[] = [];

    for (let i = 1; i <= rowCount; i++) {
      let row = {};

      for (const column of columns) {
        row["id"] = uuid();
        row[column?.field] = column.generator() || null;
      }

      rows.push(row);
    }

    const result = {
      columns: columns?.map((col) => ({ ...col, generator: undefined })),
      rows,
    };

    return result;
  }

  static renderTableColumnAndRows<T = any>(options: {
    columns: IBasicTableColumn[];
    rows: T[];
  }): {
    columns: IBasicTableColumn[];
    rows: T[];
  } {
    const { columns, rows } = options || {};

    if (!columns?.length || !rows?.length) return { columns: [], rows: [] };

    const result = {
      columns: columns?.map((col) => ({ ...col, flex: 2, minWidth: 100 })),
      rows,
    };

    return result;
  }
}
