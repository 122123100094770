import React from "react";
import { useNavigate } from "react-router";

import { ModalType } from "../../../shared";

import {
  useAppDispatch,
  useLoading,
  useModal,
  useAccount,
  useToast,
} from "../../../hooks";

import {
  Avatar,
  BasicDatePicker,
  BasicTimePicker,
  Button,
  Form,
  FormSection,
  Modal,
  TextField,
} from "../../../components";
import { textformat } from "../../../utils";
import { ROUTER } from "../../../routes";

export const VideoCallLeaveModal = () => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const { modal, isOpen, closeModal } = useModal({
    type: ModalType.VIDEO_CALL_LEAVE,
  });

  const { toast } = useToast();
  const [loading, setLoading] = useLoading();

  const { activeProfile } = useAccount();

  const {
    props: { jobApplicationId, userId, companyId },
  } = modal;

  return (
    <Modal title="Leave Video Call" description="Are you sure?" open={isOpen}>
      {/* <div className="flex flex-row justify-end gap-[10px] mt-[15px]">
        <Button variant="outlined" color="secondary" onClick={closeModal}>
          Discard
        </Button>
        <Button
          variant="outlined"
          color="success"
          className="min-w-[150px]"
          loading={loading}
          onClick={handleJoin}
        >
          Join Meeting
        </Button>
      </div> */}
    </Modal>
  );
};
