import React, { useEffect, useRef, useState } from "react";

import AgoraRTC, {
  IRemoteAudioTrack,
  IRemoteVideoTrack,
  ILocalAudioTrack,
  ILocalVideoTrack,
} from "agora-rtc-sdk-ng";

import {
  MdVideocam as VideoCameraIcon,
  MdVideocamOff as VideoCameraOffIcon,
  MdOutlineMic as MicIcon,
  MdOutlineMicOff as MicOffIcon,
  MdOutlineMonitor as MonitorIcon,
} from "react-icons/md";
import { TbCrown as HostIcon } from "react-icons/tb";
import { FiUser as UserIcon, FiSettings as SettingsIcon } from "react-icons/fi";

import { IVideoCallClient } from "@types";
import { IProfile, IVideoCall } from "../../shared";

import { IconButton, Link, Loader } from "../../components";
import { IVideoCallParticipant } from "./../../features";
import { useLoading } from "../../hooks";
import { ROUTER } from "../../routes";

interface IVideoCallParticipantCardProps extends IVideoCallParticipant {
  id?: string;
  firstName?: string;
  avatar?: string;
  isYou?: boolean;
  isHost?: boolean;
}

export const VideoCallParticipantCard: React.FC<
  IVideoCallParticipantCardProps
> = ({
  id,
  firstName,
  avatar,
  isYou,
  isHost,
  isCandidate,
  videoTrack,
  audioTrack,
  screenSharing,
}) => {
  const videoEnabled = !!videoTrack;
  const audioEnabled = !!audioTrack;

  const isAvatar = !!avatar && !!avatar?.length;

  const videoContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    console.log("USER_PLAYING_VIDEO", { videoTrack });

    const videoContainer = videoContainerRef.current;

    if (!videoTrack || !videoContainer) return;
    if (screenSharing) return;

    videoTrack.play(videoContainer);
  }, [videoTrack]);

  useEffect(() => {
    if (isYou) return;
    if (!audioTrack) return;

    console.log("USER_PLAYING_AUDIO", { audioTrack });

    audioTrack.play();
  }, [audioTrack]);

  useEffect(() => {
    return () => {
      videoTrack?.stop();
      audioTrack?.stop();
    };
  }, []);

  return (
    <div className="relative aspect-square bg-primaryMid rounded-lg overflow-hidden overflow-hidd  en select-none">
      {isHost && (
        <div className="absolute w-[20px] h-[20px] flex flex-row justify-center items-center rounded-full top-[10px] left-[10px] backdrop-blur-xl backdrop-brightness-75 z-40">
          <HostIcon size={14} className="text-white" />
        </div>
      )}
      <div className="absolute left-[5px] right-[5px] bottom-[5px] z-40">
        <div className="relative w-full h-[25px] flex flex-row justify-between items-center gap-[5px] backdrop-blur-xl backdrop-brightness-75 px-[5px] box-border rounded-lg overflow-hidden">
          <span className="w-full flex flex-row justify-center items-center text-xs text-white font-medium -mb-[1px] leading-none z-20">
            <Link to={ROUTER.USER_DETAIL(id)}>
              <span className="flex flex-row items-center gap-[3px]">
                {isYou ? "You" : firstName}
              </span>
            </Link>
          </span>
          <div className="transition-all cursor-pointer flex flex-row items-center gap-[2px] z-20">
            <IconButton
              className="cursor-default text-white"
              icon={!videoEnabled ? VideoCameraOffIcon : VideoCameraIcon}
              iconProps={{
                size: 18,
              }}
            />
            <IconButton
              className="cursor-default text-white"
              icon={!audioEnabled ? MicOffIcon : MicIcon}
              iconProps={{
                size: 16,
              }}
            />
          </div>
          <div className="absolute w-full h-full top-[0px] left-[0px] z-10 blur-xl"></div>
        </div>
      </div>
      <div
        ref={videoContainerRef}
        className="absolute w-[150%] h-[150%] -ml-[10%] -mt-[10%] z-20"
      ></div>
      {videoEnabled ? (
        screenSharing ? (
          isAvatar ? (
            <div className="absolute w-full h-full bg-primaryMid overflow-hidden z-30">
              <img className="w-full aspect-square" src={avatar} alt="" />
            </div>
          ) : (
            <div className="w-full h-full flex flex-row justify-center items-center">
              <UserIcon size={30} />
            </div>
          )
        ) : (
          <div className="absolute w-full h-full flex flex-row justify-center items-center bg-primaryMid z-10">
            <Loader size={20} className="text-secondary" />
          </div>
        )
      ) : isAvatar ? (
        <div className="absolute w-full h-full bg-primaryMid overflow-hidden z-30">
          <img className="w-full aspect-square" src={avatar} alt="" />
        </div>
      ) : (
        <div className="w-full h-full flex flex-row justify-center items-center">
          <UserIcon size={30} />
        </div>
      )}
    </div>
  );
};

VideoCallParticipantCard.defaultProps = {};
