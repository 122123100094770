import React from "react";

import { v4 as uuid } from "uuid";

import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  OutlinedInput,
  MenuItem,
  SelectProps,
} from "@mui/material";

import { MdExpandMore as ExpandMoreIcon } from "react-icons/md";

import { InputError } from "..";

interface IBasicSelectProps extends SelectProps {
  className?: string;
  labelClassName?: string;
  controlClassName?: string;
  placeholder?: string;
  variant?: "standard" | "outlined" | "filled";
  options?: { id: string | number; value: string }[];
  name?: string;
  defaultValue?: string | number | null;
  value?: string | number | null;
  errorMessage?: string | null;
  label?: string | null;
  labelId?: string;
  required?: boolean;
  disabled?: boolean;
  setValue?: (value: any) => void;
}

export const BasicSelect: React.FC<IBasicSelectProps> = ({
  className,
  controlClassName,
  placeholder,
  variant,
  options,
  name,
  defaultValue,
  value,
  error,
  errorMessage,
  setValue,
  label,
  labelClassName,
  required,
  disabled,
  ...props
}) => {
  const standardVariant = variant === "standard";

  const labelId = uuid();
  const labelHidden = !label || label?.length <= 0 || false;

  const inputProps = {
    className: standardVariant
      ? "w-full h-full flex flex-row justify-start items-center rounded-lg px-[12px] py-[10px] box-border"
      : "",
  };

  const handleChange = (e) => {
    if (setValue) setValue(e.target.value);
  };

  return (
    <FormControl variant={variant} className={`${controlClassName} w-full`}>
      {!labelHidden && (
        <InputLabel id={labelId} className="text-secondary" shrink={!!value}>
          {label}
        </InputLabel>
      )}

      <MuiSelect
        labelId={label ? labelId : ""}
        name={name}
        defaultValue={defaultValue}
        value={value}
        renderValue={
          value
            ? undefined
            : () => (
                <div
                  className={
                    labelClassName ? labelClassName : "text-base text-secondary"
                  }
                >
                  {placeholder}
                </div>
              )
        }
        className={`
            ${Boolean(error?.toString()?.length) ? "border border-danger" : ""}
            ${className ? className : "bg-primaryLight text-white"}
            w-full h-[55px] bg-primaryLight text-white rounded-lg
        `}
        inputProps={{ ...inputProps }}
        MenuProps={{
          PaperProps: {
            style: { width: "inherit", maxHeight: 280 },
            className: "text-white bg-primaryBlack",
          },
        }}
        IconComponent={({ className }) => (
          <ExpandMoreIcon
            size={20}
            className={`${className} flex h-[100%] top-0 right-[8px] text-secondary`}
          />
        )}
        onChange={handleChange}
        disableUnderline
        displayEmpty
        {...{ disabled }}
        // {...props}
      >
        {options?.map((option) => (
          <MenuItem key={option?.id} value={option?.id}>
            {option?.value}
          </MenuItem>
        ))}
      </MuiSelect>
      <InputError error={errorMessage} className="pt-[5px]" />
    </FormControl>
  );
};

BasicSelect.defaultProps = {
  label: null,
  labelId: "",
  variant: "filled",
  placeholder: "",
  controlClassName: "",
  value: null,
  options: [],
  disabled: false,
};
