import React from "react";

import {
  IAgoraRTCClient,
  ILocalAudioTrack,
  ILocalVideoTrack,
} from "agora-rtc-sdk-ng";

export interface IRtcContextState {
  client?: IAgoraRTCClient;
  localVideoTrack?: ILocalVideoTrack | null;
  localAudioTrack?: ILocalAudioTrack | null;
}

export interface IRtcContext {
  rtcContext: IRtcContextState;
  setRtcContext: (state: IRtcContextState) => void;
}

export const RtcContext = React.createContext<IRtcContext>({
  rtcContext: {},
  setRtcContext: () => {},
});

export const RtcProvider = RtcContext.Provider;
