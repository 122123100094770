import { createSlice } from "@reduxjs/toolkit";

import { IVerification } from "../../../shared";
import { AppReducerState } from "../../store";
import { appendToStore, popFromStore } from "../../storeManager";

interface ILicenseState {
  licenses: AppReducerState<any[]>;
  licenseCopies: AppReducerState<any[]>;
  license: AppReducerState<any>;
}

const initialState: ILicenseState = {
  licenses: {
    isLoading: false,
    data: [],
    page: 0,
    pageCount: 0,
    error: null,
  },
  licenseCopies: {
    isLoading: false,
    data: [],
    error: null,
  },
  license: {
    isLoading: false,
    data: {},
    error: null,
  },
};

export const licenseSlice = createSlice({
  name: "License",
  initialState,
  reducers: {
    setLicense: (state, action) => {
      state.license.data = action.payload;
    },

    getLicensesRequest: (state) => {
      state.licenses.isLoading = true;
      state.licenses.error = null;
    },
    getLicensesSuccess: (state, action) => {
      const { results, data, page = 0, pageCount = 0 } = action.payload;

      state.licenses.data = data;
      state.licenses.results = results;
      state.licenses.page = page;
      state.licenses.pageCount = pageCount;
      state.licenses.error = null;
      state.licenses.isLoading = false;
    },
    getLicensesError: (state, action) => {
      state.licenses.isLoading = false;
      state.licenses.error = action.payload;
    },

    getLicenseCopiesRequest: (state) => {
      state.licenseCopies.isLoading = true;
      state.licenseCopies.data = [];
      state.licenseCopies.error = null;
    },
    getLicenseCopiesSuccess: (state, action) => {
      const { results, data, page = 0, pageCount = 0 } = action.payload;

      state.licenseCopies.data = data;
      state.licenseCopies.results = results;
      state.licenseCopies.page = page;
      state.licenseCopies.pageCount = pageCount;
      state.licenseCopies.error = null;
      state.licenseCopies.isLoading = false;
    },
    getLicenseCopiesError: (state, action) => {
      state.licenseCopies.isLoading = false;
      state.licenseCopies.error = action.payload;
    },

    getLicenseRequest: (state) => {
      state.license.isLoading = true;
      state.license.error = null;
    },
    getLicenseSuccess: (state, action) => {
      state.license.data = action.payload;
      state.license.error = null;
      state.license.isLoading = false;
    },
    getLicenseError: (state, action) => {
      state.license.isLoading = false;
      state.license.error = action.payload;
    },

    calculateLicensePaymentTotalRequest: (state) => {},
    calculateLicensePaymentTotalSuccess: (state, action) => {},
    calculateLicensePaymentTotalError: (state, action) => {},

    licensePaymentRequest: (state) => {},
    licensePaymentSuccess: (state, action) => {},
    licensePaymentError: (state, action) => {},
  },
});

export const licenseReducer = licenseSlice.reducer;
