import React from "react";

import { RBAC, RBACSwitch } from "../../components";
import { UserCompanyActivitySidebar } from "../../features";
import { Role } from "../../shared";
import { MainLayout, MainExtendedLayout } from "../../layouts";
import { lazyload } from "../../utils";

const {
  UserDetailContainer,
  UserResumeDetailContainer,
  JobApplicationDetailSidebar,
} = lazyload(() => import("../../features"));

export const UserDetailPage = () =>
  RBACSwitch({
    elements: [
      {
        roles: [Role.Admin, Role.User],
        component: () => (
          <MainExtendedLayout>
            <UserDetailContainer />
          </MainExtendedLayout>
        ),
      },
      {
        roles: [Role.Owner, Role.TeamLead, Role.Employee],
        component: () => (
          <MainLayout
            leftSidebar={JobApplicationDetailSidebar}
            rightSidebar={UserCompanyActivitySidebar}
          >
            <UserResumeDetailContainer />
          </MainLayout>
        ),
      },
    ],
  });
