import React from "react";
import { useNavigate } from "react-router";

import { Avatar, Button, Link } from "../../../components";
import { useAppDispatch, useAppSelector, useModal } from "../../../hooks";
import { ROUTER } from "../../../routes";
import { ModalType } from "../../../shared";
import { date, textformat } from "../../../utils";

interface IScheduledInterviewWidgetProps {}

export const ScheduledInterviewWidget: React.FC<
  IScheduledInterviewWidgetProps
> = () => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const { setModal } = useModal();

  const {
    jobApplication: { data: jobApplication },
  } = useAppSelector((state) => state.jobApplication);

  const interview = jobApplication?.interviews?.[0] || null;
  const interviewId = interview?.id;

  const { title, startedAt, finishedAt } = interview || {};

  const handleInterviewDetails = () => {
    setModal({
      type: ModalType.INTERVIEW_DETAIL_MODAL,
      props: { interviewId },
    });
  };

  return (
    <div className="flex flex-col text-secondary text-xs">
      <div className="flex flex-col">
        <span className="text-white text-base font-medium">
          {textformat.shortenByChars(title, 25)}
        </span>
        <span className="">
          {date(startedAt).format("HH:mm")}-{date(finishedAt).format("HH:mm")}
        </span>
      </div>
      <div></div>
      <div className="w-full flex items-center gap-[10px] mt-[15px]">
        <Button
          color="secondary"
          variant="outlined"
          className="w-full"
          onClick={handleInterviewDetails}
        >
          Details
        </Button>
      </div>
    </div>
  );
};
