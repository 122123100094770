export enum LicenseType {
  Exclusive = "exclusive",
  NonExclusive = "non_exclusive",
}

export enum LicenseDurationType {
  Day = "day",
  Week = "week",
  Month = "month",
  Year = "year",
}
