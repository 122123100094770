import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { useFormik } from "formik";
import * as Yup from "yup";

import { ModalType, NotificationStatus } from "../../../shared";

import {
  useAppDispatch,
  useAppSelector,
  useLoading,
  useModal,
  useToast,
} from "../../../hooks";
import { getResumes } from "../../../store";

import {
  Button,
  Container,
  Form,
  Modal,
  RadioGroup,
  Radio,
  TextField,
} from "./../../../components";
import { ROUTER } from "../../../routes";
import { addLineBreaks, FormValidator } from "../../../utils";
import { createTicket } from "../../../store/reducers/ticket";

interface ITicketCreateForm {
  subject: string;
  details: string;
}

const validationSchema = Yup.object().shape({
  subject: Yup.string()
    .max(100, "Exceeded maximum character length of 100")
    .required("Subject is a required field"),
  details: Yup.string()
    .max(1000, "Exceeded maximum character length of 1000")
    .required("Details is a required field"),
});

export const TicketCreateModal = () => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const { modal, isOpen, closeModal } = useModal({
    type: ModalType.TICKET_CREATE,
  });

  const { toast } = useToast();
  const [loading, setLoading] = useLoading();

  const [defaultResumeId, setDefaultResumeId] = useState<string>();

  const {
    account: { data: account },
  } = useAppSelector((state) => state.account);
  const {
    resumes: { data: resumes, isLoading: resumesLoading },
  } = useAppSelector((state) => state.resume);

  const { props } = modal;
  const { jobId, onSubmit } = props || {};

  const profile = account?.profile;
  const profileId = profile?.id;

  const templates = resumes
    ?.map(({ id, name, isDefault }) => ({ id, name, isDefault }))
    ?.sort((a, b) => Number(b.isDefault) - Number(a.isDefault))
    ?.map(({ id, name, isDefault }) => ({
      id,
      name,
      isDefault,
      label: isDefault ? "Default Resume" : name,
    }));

  const form = useFormik<ITicketCreateForm>({
    validationSchema,
    initialValues: { subject: "", details: "" },
    onSubmit: async (values) => {
      const { subject, details } = values || {};

      const data = { subject, details: addLineBreaks(details || "") };

      setLoading(true);

      const ticket = await dispatch(createTicket(data));
      if (!ticket?.id) {
        setLoading(false);
        return toast({
          status: NotificationStatus.Error,
          message: "Report not sent!",
        });
      }

      toast({
        status: NotificationStatus.Success,
        message: "Repot has been sent to us! 👌",
      });

      setLoading(false);
      closeModal();
    },
  });

  const validator = new FormValidator<ITicketCreateForm>(form);

  const {
    values,
    errors,
    touched,
    resetForm,
    setFieldValue,
    handleChange,
    handleSubmit,
  } = form;

  const handleClose = () => {
    closeModal();
  };

  const handleCancel = () => {
    handleClose();
  };

  useEffect(() => {
    if (!defaultResumeId) return;

    setFieldValue("resumeId", defaultResumeId, false);
  }, [defaultResumeId]);

  useEffect(() => {
    if (!templates?.length) return;

    setDefaultResumeId(
      templates?.find((i) => i?.isDefault)?.id || templates?.[0]?.id
    );
  }, [templates]);

  useEffect(() => {
    if (!profileId) return;

    dispatch(getResumes({ where: { profileId, isApplied: false } }));
  }, [profileId]);

  return (
    <Modal
      title="Support"
      description="Please describe the problem you noticed. We'll fix shortly."
      open={isOpen}
      onClose={handleClose}
    >
      <Container loading={resumesLoading}>
        <Form onSubmit={handleSubmit}>
          <div className="flex flex-col mt-[15px]">
            <div className="flex flex-col gap-[10px]">
              <TextField
                id="subject"
                label="Subject"
                type="text"
                variant="filled"
                className="bg-primaryLight text-white border border-solid border-primaryMid"
                value={values?.subject}
                errorMessage={validator.isInputValid("subject")}
                onChange={handleChange}
              />
              <TextField
                id="details"
                label="Details"
                type="text"
                variant="filled"
                className="bg-primaryLight text-white border border-solid border-primaryMid"
                minRows={4}
                maxRows={4}
                multiline
                value={values?.details}
                errorMessage={validator.isInputValid("details")}
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-row justify-end gap-[5px] mt-[20px]">
              <Button variant="text" color="secondary" onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                type="submit"
                variant="outlined"
                color="success"
                loading={loading}
                disabled={loading}
              >
                Send Report
              </Button>
            </div>
          </div>
        </Form>
      </Container>
    </Modal>
  );
};
