import React, { useEffect } from "react";

import { useNavigate } from "react-router";

import {
  ModalType,
  NotificationStatus,
  NotificationType,
  NotificationPriority,
} from "../../../shared";
import {
  useAppDispatch,
  useAppSelector,
  useLoading,
  useModal,
  useToast,
  useURL,
} from "../../../hooks";
import { ROUTER } from "../../../routes";
import {
  removeResumeCover,
  removeProfileCover,
  removeDigitalAssetsFromResume,
  getDigitalAssetById,
} from "../../../store";
import {
  Button,
  Container,
  ImageResponsiveContainer,
  Modal,
} from "../../../components";
import { DigitalAssetPreviewCard } from "../../DigitalPortfolio";

export const ResumeDigitalAssetDeleteModal = () => {
  const dispatch = useAppDispatch();

  const { modal, isOpen, closeModal } = useModal({
    type: ModalType.RESUME_DIGITAL_ASSET_DELETE,
  });

  const [loading, setLoading] = useLoading();
  const { toast } = useToast();

  const {
    digitalAsset: { data: digitalAsset, isLoading: digitalAssetLoading },
  } = useAppSelector((state) => state.digitalPortfolio);

  const { props } = modal;
  const { id, resumeId } = props || {};
  const { name, previewSrc } = digitalAsset || {};

  const handleDelete = async () => {
    setLoading(true);

    const deletedAssets = await dispatch(
      removeDigitalAssetsFromResume(resumeId, { digitalAssets: [id] })
    );
    if (!deletedAssets?.length) {
      setLoading(false);
      closeModal();

      return toast({
        status: NotificationStatus.Error,
        message: "Digital asset hasn't been deleted from your resume",
      });
    }

    setLoading(false);
    closeModal();

    toast({
      status: NotificationStatus.Success,
      message: "Digital asset has been deleted from your resume!",
    });
  };

  const handleClose = () => {
    closeModal();
  };

  useEffect(() => {
    console.log({ id });

    if (!id) return;

    dispatch(getDigitalAssetById(id));
  }, [id]);

  return (
    <Modal
      title="Delete Digital Portfolio Asset"
      description="Please read below before taking action"
      titleClassName="text-danger"
      open={isOpen}
      onClose={handleClose}
    >
      <Container loading={digitalAssetLoading}>
        <div className="text-sm text-secondary break-words">
          <ImageResponsiveContainer src={previewSrc} alt="" />

          {/* <div className="w-full max-h-[150px] rounded-lg overflow-hidden">
            <img className="w-full h-auto" src={previewSrc} alt="" />
          </div> */}

          <div className="mt-[15px]">
            <p>
              Are you sure you want to delete the digital asset{" "}
              <span className="text-white font-semibold">{name}</span> from your
              resume?
            </p>
          </div>
        </div>
        <div className="flex flex-row justify-end items-center gap-x-[10px] mt-5">
          <div className="flex flex-row gap-x-[10px]">
            <Button
              variant="outlined"
              color="secondary"
              className="xxs:w-full md:w-auto"
              onClick={closeModal}
            >
              Discard
            </Button>
            <Button
              variant="outlined"
              color="danger"
              className="xxs:w-full md:w-auto md:min-w-[150px]"
              loading={loading}
              onClick={handleDelete}
            >
              Delete
            </Button>
          </div>
        </div>
      </Container>
    </Modal>
  );
};
