import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import { useSearchParams } from "react-router-dom";

import Calendar, { CalendarOptions } from "@fullcalendar/react";

import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";

import { ModalType } from "../../shared";

import { useModal } from "../../hooks";
import { date, Generator } from "../../utils";
import { Container } from "../Container";

interface IFullCalendarProps extends CalendarOptions {
  events: {
    id: string;
    type?: string;
    name: string;
    description: string;
    startedAt: Date;
    finishedAt: Date;
  }[];
  onEdit?: (id: string) => void;
  onDelete?: (id: string) => void;
}

export const FullCalendar: React.FC<IFullCalendarProps> = ({
  events,
  onEdit,
  onDelete,

  ...props
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const calendarRef = useRef<Calendar>(null);
  const calendar = calendarRef.current ? calendarRef.current.getApi() : null;

  const view = calendar ? searchParams.get("view") : "dayGridMonth";
  const year = calendar ? Number(searchParams.get("year")) : null;
  const month = calendar ? Number(searchParams.get("month")) : null;

  const [calendarEvents, setCalendarEvents] = useState<any[]>([]);

  const { setModal } = useModal();

  const handleEventDetail = (event) => {
    setModal({
      type: ModalType.CALENDAR_EVENT_DETAIL,
      props: { ...event, onEdit, onDelete },
    });
  };

  const handleEventCreate = ({ startedAt }) => {
    setModal({ type: ModalType.CALENDAR_EVENT_CREATE, props: { startedAt } });
  };

  const calendarConfig: CalendarOptions = {
    firstDay: 1,
    titleFormat: { year: "numeric", month: "short", day: "numeric" },
    slotDuration: "00:30:00",
    selectable: false,
    allDaySlot: false,
    nowIndicator: true,
    views: {
      dayGridMonth: {
        eventLimit: 2,
      },
    },
    dateClick: ({ date, allDay }) => {
      handleEventCreate({ startedAt: date });
    },
    datesSet: (dates) => {
      const { start } = dates;

      const view = dates.view?.type;
      const year = date(start).add(1, "week").format("YYYY");
      const month = date(start).add(1, "week").format("M");

      setSearchParams({ ...searchParams, view, year, month });
    },
    eventClick: ({ event }) => {
      const { id, title, start } = event;

      handleEventDetail({ id, title, startedAt: start });
    },
    select: ({ start, end, allDay }) => {
      console.log("Selecting..", { start, end, allDay });
    },
  };

  useEffect(() => {
    if (!view) return;

    calendar?.changeView(view || "");
  }, [view]);

  useEffect(() => {
    if (!year) return;

    const todayDate = calendar?.getDate() || new Date();

    if (year >= 2010 && year <= 2050) {
      calendar?.gotoDate(date(todayDate).set("year", year).toDate());
    }
  }, [year]);

  useEffect(() => {
    if (!month) return;

    const todayDate = calendar?.getDate() || new Date();

    if (month > 0 && month <= 12) {
      calendar?.gotoDate(
        date(todayDate)
          .set("month", month > 1 ? month - 1 : 0)
          .toDate()
      );
    }
  }, [month]);

  useEffect(() => {
    setCalendarEvents(
      !!events?.length
        ? events?.map((event) => ({
            ...event,
            title: event?.name,
            start: date(event?.startedAt).format("YYYY-MM-DD"),
            end: date(event?.finishedAt).format("YYYY-MM-DD"),
            color: event?.type === "interview" ? "#2F80ED" : "#7887A4",
            name: undefined,
            startedAt: undefined,
            finishedAt: undefined,
          }))
        : []
    );
  }, [events]);

  return (
    <Container>
      <Calendar
        loading={() => {
          console.log("Loading Calendar..");
        }}
        ref={calendarRef}
        initialView={view || "dayGridMonth"}
        plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin]}
        headerToolbar={{
          left: "title",
          right: "today prev,next",
          // right: "timeGridDay,timeGridWeek,dayGridMonth prev,next",
        }}
        buttonText={{
          today: "Today",
          day: "Day",
          week: "Week",
          month: "Month",
          list: "List",
        }}
        events={calendarEvents}
        {...calendarConfig}
        {...props}
      />
    </Container>
  );
};

FullCalendar.defaultProps = {};
