import * as Yup from "yup";

import { LicenseDurationType, LicenseType } from "../../../shared";

export interface ILicensePaymentForm {
  fullName?: string;
  companyName?: string;
  country?: string;
  state?: string;
  city?: string;
  cardNumber?: string;
  cardHolder?: string;
  cardExpirationDate?: Date;
  cvc?: string;
}

export const LicensePaymentFormInitialValues = {
  fullName: "MARK CUBAN",
  companyName: "DALLAS MAVERICS",
  country: "United States",
  state: "",
  city: "Dallas",
  cardNumber: "432888899994321",
  cardHolder: "MARK CUBAN",
  cardExpirationDate: new Date("2021-10-10"),
  cvc: "410",
};

export const LicensePaymentFormValidationSchema = Yup.object().shape({
  fullName: Yup.string().required("Full Name is a required field"),
  companyName: Yup.string().required("Company Name is a required field"),
  country: Yup.string().required("Country is a required field"),
  state: Yup.string().notRequired(),
  city: Yup.string().required("City is a required field"),
  cardNumber: Yup.string().required("Card Number is a required field"),
  cardHolder: Yup.string().required("Card Holder Name is a required field"),
  cardExpirationDate: Yup.string().required(
    "Card Expiration Date is a required field"
  ),
  cvc: Yup.string().required("CVC is a required field"),
});
