import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

import { MdVerified as VerifiedIcon } from "react-icons/md";

import { DigitalAssetType, ModalType } from "../../shared";

import {
  Container,
  ImageResponsiveContainer,
  PageHeader,
  VideoPlayer,
} from "../../components";
import { DigitalAssetCardNavbar } from "./../../features";

import {
  useAccount,
  useAppDispatch,
  useAppSelector,
  useModal,
} from "../../hooks";
import { ROUTER } from "../../routes";
import { getDigitalAssetById } from "../../store";
import { textformat } from "../../utils";
import { useSearchParams } from "react-router-dom";

export const UserDigitalAssetDetailContainer = () => {
  const dispatch = useAppDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const { userId, assetId } = useParams();
  const purchaseStatus = searchParams.get("purchase");

  const { activeProfile } = useAccount();
  const { setModal } = useModal();

  const {
    digitalAsset: {
      data: digitalAsset,
      isLoading: digitalAssetLoading,
      error: digitalAssetError,
    },
  } = useAppSelector((state) => state.digitalPortfolio);

  const [descriptionHidden, setDescriptionHidden] = useState(true);

  const { id, name, description, src, createdBy, createdById, createdAt } =
    digitalAsset || {};

  const isBusinessUser = activeProfile?.isBusiness || false;
  const isLicensePurchased =
    isBusinessUser && digitalAsset?.isLicensePurchased ? true : false;

  const handleDescriptionShow = () => {
    setDescriptionHidden(false);
  };

  const handleEdit = () => {
    setModal({
      type: ModalType.DIGITAL_ASSET_EDIT,
      props: { ...digitalAsset },
    });
  };

  const handleDelete = () => {
    setModal({
      type: ModalType.DIGITAL_ASSET_DELETE,
      props: { ...digitalAsset },
    });
  };

  useEffect(() => {
    if (!digitalAsset?.id) return;

    if (!purchaseStatus) return;

    switch (purchaseStatus) {
      case "success":
        setModal({
          type: ModalType.LICENSE_PURCHASE_SUCCESS,
          props: { ...digitalAsset },
        });
        break;
      case "error":
        setModal({
          type: ModalType.LICENSE_PURCHASE_ERROR,
          props: { ...digitalAsset },
        });
        break;
    }
  }, [digitalAsset]);

  useEffect(() => {
    if (!assetId) return;

    dispatch(getDigitalAssetById(assetId));

    if (!purchaseStatus) return;

    switch (purchaseStatus) {
      case "success":
        setModal({
          type: ModalType.LICENSE_PURCHASE_SUCCESS,
          props: { ...digitalAsset },
        });
        break;
      case "error":
        setModal({
          type: ModalType.LICENSE_PURCHASE_ERROR,
          props: { ...digitalAsset },
        });
        break;
    }
  }, [assetId]);

  return (
    <Container loading={digitalAssetLoading} error={digitalAssetError}>
      <PageHeader
        // backTo={ROUTER.USER_DIGITAL_ASSETS(userId)}
        exceptions={[
          { from: ROUTER.RESUME_BUILDER, to: ROUTER.RESUME_BUILDER },
          { from: ROUTER.USER_DETAIL(userId), to: ROUTER.USER_DETAIL(userId) },
        ]}
      >
        {name}
      </PageHeader>
      <Container className="bg-primaryLight rounded-lg box-border p-[15px]">
        <div className="relative flex flex-row">
          {[DigitalAssetType.Photo, DigitalAssetType.Certificate]?.some(
            (i) => i === digitalAsset.type
          ) && <ImageResponsiveContainer src={src} />}
          {digitalAsset.type === DigitalAssetType.Video && (
            <div className="relative w-[720px] h-auto rounded-lg overflow-hidden">
              <VideoPlayer url={src} />
            </div>
          )}
        </div>

        {createdById && (
          <div className="mt-[20px]">
            <DigitalAssetCardNavbar
              {...{
                id,
                createdBy,
                createdById,
                createdAt,
                ...digitalAsset,
                onEdit: handleEdit,
                onDelete: handleDelete,
              }}
            />
          </div>
        )}
        {!!description?.length && (
          <div className="w-full mt-[20px] text-white text-sm">
            {textformat.countChars(description) > 250 ? (
              descriptionHidden ? (
                <p>
                  {textformat.shortenByChars(description || "", 250)}{" "}
                  <span
                    className="transition-all text-secondary font-semibold cursor-pointer hover:brightness-110"
                    onClick={handleDescriptionShow}
                  >
                    Read more
                  </span>
                </p>
              ) : (
                <p>{description}</p>
              )
            ) : (
              <p>{description}</p>
            )}
          </div>
        )}
      </Container>
    </Container>
  );
};
