import React, { useEffect, useMemo } from "react";

import { useAppSelector } from "./../hooks";

export const useAccount = () => {
  const {
    account: { data: accountState },
  } = useAppSelector((state) => state.account);

  const {
    notifications: { data: notifications },
  } = useAppSelector((state) => state.notification);

  const account = useMemo(() => accountState, [accountState]);
  const activeProfile = account?.profile;

  const isAuthenticated = account?.id ? true : false;

  const notificationCount =
    notifications?.filter((i) => !i.isViewed)?.length || 0;

  const hasNotifications =
    notifications?.filter((i) => !i.isViewed)?.length >= 1 || 0;

  const chatMessageCount = 0;

  return {
    account,
    activeProfile,
    isAuthenticated,
    hasNotifications,
    notificationCount,
    chatMessageCount,
  };
};
