import React, { Suspense } from "react";

import { Loader, Navbar } from "../components";

interface IChatLayoutProps {}

export const ChatLayout: React.FC<IChatLayoutProps> = ({ children }) => (
  <div className="flex flex-col w-full min-h-screen h-auto bg-primaryDark overflow-x-hidden">
    <Navbar />
    <div className="flex flex-row w-full h-screen">
      <Suspense fallback={<Loader />}>{children}</Suspense>
    </div>
  </div>
);

ChatLayout.defaultProps = {
  leftSidebar: <></>,
  rightSidebar: <></>,
};
