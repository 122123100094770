import React from "react";

import { Dialog } from "@mui/material";

import { CloseIconButton } from "./../../components";

import { closeModal } from "../../store";
import { useAppDispatch } from "../../hooks";
import { textformat } from "./../../utils";

export interface IModalProps {
  title?: string;
  description?: string;
  open?: boolean;
  width?: number;
  className?: string;
  dialogClassName?: string;
  titleClassName?: string;
  disableBackdrop?: boolean;
  BottomFixedComponent?: React.JSXElementConstructor<React.ComponentProps<any>>;
  onClose?: () => void;
}

export const Modal: React.FC<IModalProps> = ({
  children,
  title,
  description,
  open,
  width,
  className,
  dialogClassName,
  titleClassName,
  disableBackdrop,
  BottomFixedComponent,
  onClose,
}) => {
  const dispatch = useAppDispatch();

  const handleClose = () => {
    if (!onClose) return dispatch(closeModal());

    dispatch(closeModal());
    onClose();
  };

  return (
    <Dialog
      className={`
        p-[20px] z-[90]
        ${disableBackdrop ? "" : "backdrop-blur-sm"}
      `}
      PaperProps={{
        className: `
          ${className}
          relative min-h-[75px] max-h-full flex flex-col rounded-lg bg-primaryBlack border-primaryLight border-[2px] overflow-hidden text-secondary m-[0px]`,
      }}
      open={open || false}
      disableAutoFocus
      disableEnforceFocus
      disableRestoreFocus
      hideBackdrop
      onClose={handleClose}
    >
      <div className="sticky h-[85px] top-[0px] left-[0px] right-[0px] flex flex-row justify-between items-center bg-inherit z-30 p-[25px] box-border">
        <div className="relative w-full">
          {title ? (
            <ModalTitle
              {...{ title, description, className: titleClassName }}
            />
          ) : (
            <div></div>
          )}
          <div className="transition-all absolute -top-[7.5px] -right-[7.5px] w-[24px] h-[24px] flex flex-row justify-center items-center box-border p-[5px] rounded-full cursor-pointer hover:bg-primaryLight ">
            <CloseIconButton iconProps={{ size: 14 }} onClick={handleClose} />
          </div>
        </div>
      </div>
      <div className="basis-full w-full p-[25px] pt-[0px] box-border z-20 bg-inherit overflow-y-scroll">
        {children}
      </div>
      {BottomFixedComponent && <BottomFixedComponent />}
    </Dialog>
  );
};

Modal.defaultProps = {
  open: false,
  className: "w-[480px]",
  dialogClassName: "",
  titleClassName: "",
  disableBackdrop: false,
  BottomFixedComponent: () => <></>,
};

interface IModalTitleProps {
  className?: string;
  title?: string;
  description?: string;
}

export const ModalTitle: React.FC<IModalTitleProps> = ({
  title,
  description,
  className,
}) => (
  <div className={`flex flex-col gap-y-[5px] break-words`}>
    <h2
      className={`${className} text-base text-white font-semibold leading-none`}
    >
      {textformat.shortenByChars(title || "", 50)}
    </h2>
    {description && (
      <span className="text-xs text-secondary font-medium">{description}</span>
    )}
  </div>
);

ModalTitle.defaultProps = {
  className: "",
  title: "Modal",
};
