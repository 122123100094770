import React from "react";

import { useNavigate } from "react-router";

import {
  ModalType,
  NotificationStatus,
  NotificationType,
  NotificationPriority,
} from "../../../shared";
import { useAppDispatch, useModal, useToast, useURL } from "../../../hooks";
import { ROUTER } from "../../../routes";
import { removeEducation, removeResumeEducation } from "../../../store";
import { Button, Modal } from "../../../components";

export const ResumeEducationDeleteModal = () => {
  const dispatch = useAppDispatch();
  const { toast } = useToast();

  const { modal, isOpen, setModal, closeModal } = useModal({
    type: ModalType.RESUME_EDUCATION_DELETE,
  });

  const { loading, props } = modal;
  const { id, university, degree, resumeId } = props || {};

  const handleDelete = async () => {
    setModal({ loading: { delete: true } });

    const removed = await dispatch(
      resumeId ? removeResumeEducation(id) : removeEducation(id)
    );
    if (!removed) {
      setModal({ loading: { delete: false } });
      closeModal();

      return toast({
        status: NotificationStatus.Error,
        message: "Education Not Deleted!",
      });
    }

    setModal({ loading: { delete: false } });
    closeModal();

    toast({
      status: NotificationStatus.Success,
      message: "Education Deleted!",
    });
  };

  const handleClose = () => {
    closeModal();
  };

  const handleCancel = () => {
    handleClose();
  };

  return (
    <Modal
      title={`Delete`}
      description="Please read below before taking action"
      titleClassName="text-danger"
      open={isOpen}
      onClose={handleClose}
    >
      <div>
        <div className="text-sm text-secondary break-words">
          <p>
            Are you sure you want to delete education{" "}
            <span className="text-white font-semibold">
              {degree} at {university}
            </span>
            ? This will remove all fields and data including any associations of
            verification that you or a company has paid for. An alternative is
            to delist the experience/job/education if you don't want others to
            know you are associated with it.
          </p>
        </div>
        <div className="flex flex-row justify-between items-center gap-x-[10px] mt-5">
          <div>
            <Button variant="text" color="secondary" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
          <div className="flex flex-row gap-x-[10px]">
            {/* <Button
              variant="outlined"
              color="warning"
              loading={loading?.delist}
              onClick={handleDelist}
            >
              Delist
            </Button> */}
            <Button
              variant="outlined"
              color="danger"
              loading={loading?.delete}
              onClick={handleDelete}
            >
              Yes, Delete
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
