import React, { useReducer } from "react";

import { Action, IEducation, ModalType } from "../../../../shared";

import { Button } from "../../../../components";

import {
  ResumeSectionCard,
  ResumeSectionCardBody,
  ResumeSectionCardFooter,
  ResumeEducationCreateForm,
  ResumeEducationEditForm,
  ResumeEducationCard,
  IResumeSectionProps,
} from "../../../../features";

import {
  useAppDispatch,
  useAppSelector,
  useModal,
  useToast,
} from "../../../../hooks";

export interface IResumeEducationSectionDetailProps
  extends IResumeSectionProps<IEducation> {}

export const ResumeEducationSectionDetail = ({ loading, data }) => {
  const dispatch = useAppDispatch();
  const { toast } = useToast();

  const { resume } = useAppSelector((state) => state.resume);

  const { modal, setModal } = useModal();

  const resumeId = resume?.data?.id;

  const reducer = (state, action) => {
    const { type, payload } = action;

    switch (type) {
      case "START_CREATE_EXPERIENCE":
        return { ...state, creating: true };
      case "STOP_CREATE_EXPERIENCE":
        return { ...state, creating: false };
      case "START_EDIT_EXPERIENCE":
        return {
          ...state,
          editing: [
            ...state.editing.filter(({ id }) => id !== payload?.id),
            payload,
          ],
        };
      case "STOP_EDIT_EXPERIENCE":
        return {
          ...state,
          editing: state.editing.filter(({ id }) => id !== payload?.id),
        };
      default:
        return state;
    }
  };

  const [state, localDispatch] = useReducer(reducer, {
    data: [],
    creating: false,
    editing: [],
  });

  const handleDelete = (props) => {
    setModal({
      ...modal,
      type: ModalType.RESUME_EDUCATION_DELETE,
      props,
    });
  };

  const isEditing = (id: string) =>
    state.editing?.filter((el) => el?.id === id)?.length >= 1;

  const handleCreateStart = () => {
    localDispatch({ type: "START_CREATE_EXPERIENCE" });
  };

  const handleCreateCancel = () => {
    localDispatch({ type: "STOP_CREATE_EXPERIENCE" });
  };

  const handleEditStart = (id: string) => {
    localDispatch({ type: "START_EDIT_EXPERIENCE", payload: { id } });
  };

  const handleEditCancel = (id: string) => {
    localDispatch({ type: "STOP_EDIT_EXPERIENCE", payload: { id } });
  };

  return (
    <>
      <ResumeSectionCard
        heading="Education"
        // navbar={
        //   <Button color="success" variant="text">
        //     Verify
        //   </Button>
        // }
      >
        <ResumeSectionCardBody loading={loading}>
          {data?.map((education) => (
            <div key={education?.id}>
              {isEditing(education?.id) ? (
                // @ts-ignore
                <ResumeEducationEditForm
                  data={education}
                  onCancel={() => handleEditCancel(education?.id)}
                />
              ) : (
                <ResumeEducationCard
                  {...education}
                  actions={[Action.Edit, Action.Delete]}
                  onEdit={() => handleEditStart(education?.id)}
                  onDelete={() => handleDelete({ ...education, resumeId })}
                />
              )}
            </div>
          ))}
        </ResumeSectionCardBody>

        <ResumeSectionCardFooter>
          {state.creating ? (
            <ResumeEducationCreateForm onCancel={handleCreateCancel} />
          ) : (
            <div className="w-full flex flex-row justify-center">
              <Button
                variant="text"
                className="text-white"
                onClick={handleCreateStart}
              >
                Add New
              </Button>
            </div>
          )}
        </ResumeSectionCardFooter>
      </ResumeSectionCard>
    </>
  );
};

ResumeEducationSectionDetail.defaultProps = {};
