import React, { useEffect } from "react";
import { useNavigate } from "react-router";

import { ModalType, NotificationStatus } from "../../../shared";

import {
  useAppDispatch,
  useAppSelector,
  useLoading,
  useModal,
  useAccount,
  useToast,
} from "../../../hooks";

import {
  Avatar,
  BasicDatePicker,
  BasicTimePicker,
  Button,
  Container,
  Form,
  FormSection,
  Link,
  Modal,
  TextField,
} from "../../../components";
import { date, textformat } from "../../../utils";
import { ROUTER } from "../../../routes";
import { getInterviewById, startInterview } from "../../../store";

export const InterviewStartModal = () => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const { modal, isOpen, closeModal } = useModal({
    type: ModalType.INTERVIEW_START_MODAL,
  });

  const { toast } = useToast();
  const [loading, setLoading] = useLoading();

  const {
    interview: { data: interview, isLoading: interviewLoading },
  } = useAppSelector((state) => state.interview);

  const {
    props: { interviewId },
  } = modal;

  const {
    title,
    startedAt,
    finishedAt,
    interviewer,
    candidate,
    videoCallId,
    calendarEventId,
  } = interview || {};

  const participants = [interviewer, candidate]?.filter((i) => !!i) || [];

  const handleStart = async () => {
    setLoading(true);

    const startedInterview = await dispatch(startInterview(interviewId));
    if (!startedInterview?.id) {
      toast({
        status: NotificationStatus.Error,
        message: "Interview Not Started.",
      });
      setLoading(false);

      return;
    }

    setTimeout(() => {
      setLoading(false);
      closeModal();

      redirect(`${ROUTER.MEET}?id=${videoCallId}`);
    }, 500);
  };

  useEffect(() => {
    if (!interviewId) return;

    dispatch(getInterviewById(interviewId));
  }, [interviewId]);

  return (
    <Modal
      title="Join Interview"
      description="Please confirm if you're ready to start the interview process."
      open={isOpen}
    >
      <Container loading={interviewLoading}>
        <div className="flex flex-col text-sm gap-[10px]">
          <div className="flex flex-col justify-start items-start">
            <h3 className="text-sm font-semibold mb-[5px]">Info</h3>
            <div className="flex flex-col gap-[5px]">
              <div className="flex flex-col">
                <span className="text-xs text-white font-medium">Title: </span>
                <span className="text-white font-medium text-sm">
                  {title || ""}
                </span>
              </div>
              <div className="flex flex-col">
                <span className="text-xs text-white font-medium">Time:</span>
                <p className="text-white text-sm">
                  {date(startedAt).format("HH:")}-
                  {date(finishedAt).format("HH:")}
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-col justify-start items-start mt-[15px]">
            <h3 className="text-sm font-semibold">Participants</h3>
            <div className="w-full flex flex-col gap-[5px] mt-[10px]">
              {!!participants?.length &&
                participants?.map(
                  ({ id, firstName, lastName, role, avatar, isHost }) => (
                    <div className="transition-all w-full flex flex-row justify-between items-center gap-[10px] w-full rounded-lg p-[10px] bg-primaryDark hover:bg-primaryLight">
                      <div className="flex flex-row gap-[8px]">
                        <Avatar
                          size={28}
                          src={avatar}
                          className="text-xs font-medium capitalize"
                        >
                          {firstName}
                        </Avatar>
                        <div className="flex flex-col justify-center">
                          <span className="text-white text-xs font-semibold capitalize">
                            {firstName} {lastName}
                          </span>
                          <span
                            className={`
                        ${isHost ? "text-warning" : "text-secondary"}
                        text-xxs font-medium capitalize
                      `}
                          >
                            {role}
                          </span>
                        </div>
                      </div>
                    </div>
                  )
                )}
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-end gap-[10px] mt-[15px]">
          <Button variant="outlined" color="secondary" onClick={closeModal}>
            Discard
          </Button>
          <Button
            variant="outlined"
            color="success"
            className="min-w-[150px]"
            loading={loading}
            onClick={handleStart}
          >
            Join Interview
          </Button>
        </div>
      </Container>
    </Modal>
  );
};
