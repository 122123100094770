import React from "react";

import { HiOutlineDocumentDownload as DocumentIcon } from "react-icons/hi";

import { ICompanyVerification, VerificationStatus } from "../../shared";

import {
  Button,
  Avatar,
  CardFooter,
  Card,
  CardBody,
  StatusBadge,
} from "../../components";
import { VerificationRequestActionLog } from "../../features";
import { ROUTER } from "../../routes";
import { date, textformat } from "../../utils";

interface IBusinessVerificationCardProps extends ICompanyVerification {}

export const BusinessVerificationCard: React.FC<
  IBusinessVerificationCardProps
> = ({ id, status, name, tin, email, phone, size, requestedBy, createdAt }) => (
  <Card>
    <CardBody>
      <div className="flex justify-between items-center">
        <div className="w-full flex flex-col">
          <div className="w-full flex flex-row justify-between items-center">
            <div className="flex flex-row justify-start items-center gap-x-[10px]">
              <Avatar size={40}>{name}</Avatar>
              <div className="w-full flex flex-col justify-between items-start gap-y-[5px] mt-[2px]">
                <div className="flex flex-row items-center gap-x-[10px] gap-y-[10px]">
                  <h3 className="text-base font-semibold text-white leading-none">
                    {textformat.shortenByChars(name, 25)}
                  </h3>
                  <StatusBadge status={status} />
                </div>

                <span className="text-xs leading-none">
                  {date(createdAt).fromNow()}
                </span>
              </div>
            </div>

            <div className="flex justify-between gap-x-[10px]">
              <Button
                color="primary"
                variant="outlined"
                to={ROUTER.ADMIN_BUSINESS_VERIFICATION_DETAIL(id)}
              >
                Details
              </Button>
            </div>
          </div>

          <div className="flex flex-col mx-[20px] mt-[10px]">
            <div className="flex flex-col mt-[10px] mx-[30px]">
              <div className="flex flex-row justify-start items-start gap-x-[40px]">
                <div className="flex flex-col justify-start items-start gap-y-[5px] font-medium">
                  <span className="text-xs text-secondary">Size</span>
                  <span className="text-sm text-white capitalize">{size}</span>
                </div>
                <div className="flex flex-col justify-start items-start gap-y-[5px] font-medium">
                  <span className="text-xs text-secondary">Tax Number</span>
                  <span className="text-sm text-white">{tin}</span>
                </div>
                <div className="flex flex-col justify-start items-start gap-y-[5px] font-medium">
                  <span className="text-xs text-secondary">Phone</span>
                  <span className="text-sm text-white">{phone}</span>
                </div>
                <div className="flex flex-col justify-start items-start gap-y-[5px] font-medium">
                  <span className="text-xs text-secondary">Email</span>
                  <span className="text-sm text-white">{email}</span>
                </div>
                {/* <div className="flex flex-col justify-start items-start gap-y-[5px] font-medium">
                  <span className="text-xs text-secondary">Document</span>
                  <div className="flex flex-row justify-center items-center gap-x-[3px] p-[5px] box-border max-w-[120px] h-[25px] bg-primaryMid text-[10px] text-secondary rounded-[5px]">
                    <DocumentIcon size={12} />
                    <span>Registration.pdf</span>
                  </div>
                </div> */}
              </div>
            </div>

            {/* {experience ? (
              <div className="flex flex-col">
                <span className="text-base text-white font-semibold">
                  {experience?.role} at {experience?.company}
                </span>
                <span className="text-xs">
                  {date(experience?.startedAt).format("MMM YYYY")} -{" "}
                  {date(experience?.finishedAt).format("MMM YYYY")}
                </span>
              </div>
            ) : (
              <div className="flex flex-col mt-[10px]">
                <span className="text-sm text-white font-medium">
                  {education?.degree} at {education?.university}
                </span>
                <span className="text-xs">
                  {date(education?.startedAt).format("MMM YYYY")} -{" "}
                  {date(education?.finishedAt).format("MMM YYYY")}
                </span>
              </div>
            )} */}

            {/* {experience && (
              <div className="flex flex-col mt-[20px]">
                <div className="flex flex-row justify-start items-center gap-x-[20px] text-white">
                  <div className="flex flex-row justify-start items-center gap-x-[5px]">
                    <span className="text-sm text-secondary font-medium">
                      Requested By:
                    </span>
                    <div className="flex flex-row justify-start items-center gap-x-[5px]">
                      <Link to={ROUTER.USER_DETAIL(user?.id)}>
                        <span className="text-sm font-semibold text-white">
                          {createdBy?.firstName} {createdBy?.lastName}
                        </span>
                      </Link>
                    </div>
                  </div>

                  <div className="flex flex-row items-center gap-x-[5px] text-base stroke-secondary fill-transparent">
                    <MessageIcon size={20} />
                    <span>{experience?.companyEmail}</span>
                  </div>
                  <div className="flex flex-row items-center gap-x-[5px] text-base stroke-secondary fill-transparent">
                    <PhoneCallIcon size={20} />
                    <span>{experience?.companyPhone}</span>
                  </div>
                </div>
              </div>
            )} */}
          </div>
        </div>
      </div>

      {/* <div className="flex justify-between items-center">
        <div className="w-full flex flex-col">
          <div className="w-full flex flex-row justify-between items-center">
            <div className="flex flex-row">
              <Avatar>{name}</Avatar>

              <div className="flex flex-row justify-start items-center ml-2 mr-4 gap-x-1.5">
                <Link to={ROUTER.BUSINESS_VERIFICATION_DETAIL(id)}>
                  <h3 className="text-base font-semibold text-white">{name}</h3>
                </Link>
                <span className="text-sm text-secondary">
                  • {date(createdAt).fromNow()}
                </span>
              </div>
              <StatusBadge status={status} />
            </div>
            <div className="flex justify-between">
              <Button
                color="primary"
                variant="outlined"
                to={ROUTER.BUSINESS_VERIFICATION_DETAIL(id)}
              >
                Details
              </Button>
            </div>
          </div>

          <div className="flex flex-col mt-[10px] mx-[30px]">
            <div className="flex flex-row justify-start items-start gap-x-[40px]">
              <div className="flex flex-col justify-start items-start gap-y-[5px] font-medium">
                <span className="text-xs text-secondary">Size</span>
                <span className="text-sm text-white capitalize">{size}</span>
              </div>
              <div className="flex flex-col justify-start items-start gap-y-[5px] font-medium">
                <span className="text-xs text-secondary">Tax Number</span>
                <span className="text-sm text-white">{tin}</span>
              </div>
              <div className="flex flex-col justify-start items-start gap-y-[5px] font-medium">
                <span className="text-xs text-secondary">Document</span>
                <div className="flex flex-row justify-center items-center gap-x-[3px] p-[5px] box-border max-w-[120px] h-[25px] bg-primaryMid text-[10px] text-secondary rounded-[5px]">
                  <DocumentIcon size={12} />
                  <span>Registration.pdf</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </CardBody>

    {false ? (
      <>
        <CardFooter className="w-full flex flex-col py-[20px]">
          <VerificationRequestActionLog
            verificationId={id}
            actionLogId={null}
          />
        </CardFooter>
        <CardFooter>
          <Button
            variant="text"
            color="secondary"
            // onClick={() => setActionLog(false)}
          >
            Close
          </Button>
        </CardFooter>
      </>
    ) : (
      <CardFooter>
        <Button
          variant="text"
          color="secondary"
          // onClick={() => setActionLog(true)}
        >
          Action Log
        </Button>
      </CardFooter>
    )}

    {/* <CardFooter>
      <div className="w-full flex flex-row justify-start items-center gap-x-[20px] mx-[30px] text-sm text-white">
        {requestedBy && (
          <div className="flex flex-row justify-start items-center gap-x-[5px]">
            <span className="text-sm text-secondary font-medium">
              Requested By:
            </span>
            <div className="flex flex-row justify-start items-center gap-x-[5px]">
              <Avatar>{requestedBy?.firstName}</Avatar>
              <span className="text-sm font-semibold text-white">
                {requestedBy?.firstName?.slice(0, 1)}. {requestedBy?.lastName}
              </span>
            </div>
          </div>
        )}
        <div className="flex flex-row items-center gap-x-[5px] stroke-secondary fill-transparent">
          <MessageIcon size={18} />
          <span>{email}</span>
        </div>
        <div className="flex flex-row items-center gap-x-[5px] stroke-secondary fill-transparent">
          <PhoneCallIcon size={18} />
          <span>{phone}</span>
        </div>
      </div>
    </CardFooter> */}
  </Card>
);

BusinessVerificationCard.defaultProps = {
  status: VerificationStatus.Pending,
  tin: "******",
};
