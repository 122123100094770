import React from "react";

import { IExperience } from "../../../../shared";
import {
  IResumeSectionProps,
  ResumeExperienceDetailSection,
} from "../../../../features";

interface IResumeExperienceSectionProp
  extends IResumeSectionProps<IExperience> {}

export const ResumeExperienceSection: React.FC<
  IResumeExperienceSectionProp
> = ({ data, loading }) => (
  <ResumeExperienceDetailSection loading={loading} data={data} />
);

ResumeExperienceSection.defaultProps = {};
