import React, { useState } from "react";
import { useNavigate } from "react-router";

import { FiCheck as TickIcon } from "react-icons/fi";

import { Action, IDigitalAsset } from "../../shared";
import { ROUTER } from "../../routes";
import { useAccount } from "../../hooks";
import { ActionMenu, DeleteIconButton, Link } from "../../components";

interface IDigitalAssetPreviewCardProps extends IDigitalAsset {
  className?: string;
  to?: string;
  alt?: string;
  selected?: boolean;
  selecting?: boolean;
  editing?: boolean;
  onClick?: (id?: string) => void;
  onSelect?: (id?: string) => void;
  onEdit?: (id?: string) => void;
  onDelete?: (id?: string) => void;
}

export const DigitalAssetPreviewCard: React.FC<
  IDigitalAssetPreviewCardProps
> = ({
  id,
  to,
  previewSrc,
  alt,
  createdById,
  selected: _selected,
  selecting,
  editing,
  className,
  onClick,
  onSelect,
  onDelete,
}) => {
  const redirect = useNavigate();

  const { activeProfile } = useAccount();

  const [selected, setSelected] = useState(_selected);

  const isAuthor = activeProfile?.id === createdById;

  const handleClick = () => {
    // Selecting
    if (selecting) {
      setSelected(!selected);

      if (!id) return;

      if (onSelect) {
        onSelect(id);
        setSelected(!selected);
      }

      return;
    }

    // Clicking
    if (onClick) {
      onClick(id);

      return;
    }

    // Redirecting
    if (!id || !createdById) return;

    redirect(ROUTER.USER_DIGITAL_ASSET_DETAIL(createdById, id));
  };

  const handleSelect = () => {
    setSelected(!selected);

    if (!id) return;

    if (onSelect) {
      onSelect(id);
      setSelected(!selected);
    }
  };

  const handleRedirect = () => {
    if (!id || !createdById) return;

    redirect(ROUTER.USER_DIGITAL_ASSET_DETAIL(createdById, id));
  };

  return (
    <div
      className={`
        ${className}
        relative flex flex-row justify-center items-center aspect-square bg-primaryMid transition-all select-none hover:bg-gray-700
        xxs:basis-full xs:basis-6/12 sm:basis-4/12 md:basis-3/12
      `}
    >
      {selecting && (
        <div
          className={`
          transition-all absolute top-[10px] left-[10px] w-[24px] h-[24px] flex flex-row justify-center items-center border-[1px] border-solid rounded-lg z-40 
          ${
            selected
              ? "border-primary bg-primary"
              : "border-primaryMid bg-primaryLight"
          }
        `}
        >
          {selected ? <TickIcon size={16} className="text-white" /> : <></>}
        </div>
      )}

      <div
        className={`
          transition-all absolute w-full h-full z-[70] opacity-0 hover:opacity-100`}
      >
        {to && (
          <Link
            to={ROUTER.USER_DIGITAL_ASSET_DETAIL(createdById, id)}
            className="w-full h-full"
          ></Link>
        )}
        <div
          className={`
            ${onClick ? "cursor-pointer" : ""}
            cursor-pointer z-50 absolute w-full h-full bg-primaryLight opacity-0 hover:opacity-10`}
          onClick={handleClick}
        ></div>
        {isAuthor && editing && (
          <div className="absolute top-[10px] right-[10px] w-[24px] h-[24px] flex flex-row justify-center items-center rounded-full bg-primaryLight border-[1px] border-solid border-primaryMid hover:bg-primaryMid z-50">
            <DeleteIconButton
              iconProps={{ size: 14 }}
              iconClassName="text-secondary"
              onClick={onDelete}
            />
          </div>
        )}
      </div>

      {id && (
        <div
          className={`
            transition-all absolute w-full h-full z-50 bg-gray-900 cursor-pointer select-none hover:opacity-0
            ${selected ? "opacity-0" : "opacity-20"}
        `}
          onClick={selecting ? handleSelect : handleRedirect}
        ></div>
      )}
      {previewSrc ? (
        <img
          src={previewSrc}
          className="transition-all w-full h-full select-none z-30"
          alt={alt}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

DigitalAssetPreviewCard.defaultProps = {
  className: "",
  selected: false,
  selecting: false,
  editing: false,
};
