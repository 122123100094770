import React, { useEffect } from "react";

import { ModalType } from "../../shared";

import { FullCalendar, Container, PageHeader } from "../../components";
import { useAppDispatch, useAppSelector, useModal } from "../../hooks";
import { getCalendarEvents } from "../../store";

export const CalendarContainer = () => {
  const dispatch = useAppDispatch();

  const { setModal } = useModal();

  const {
    calendarEvents: { data: calendarEvents, isLoading: calendarEventsLoading },
  } = useAppSelector((state) => state.calendarEvent);

  const handleEventDelete = (id: string) => {
    setModal({ type: ModalType.CALENDAR_EVENT_DELETE, props: { id } });
  };

  useEffect(() => {
    dispatch(getCalendarEvents());
  }, []);

  return (
    <Container>
      <PageHeader>Events</PageHeader>
      <Container loading={calendarEventsLoading}>
        <FullCalendar events={calendarEvents} onDelete={handleEventDelete} />
      </Container>
    </Container>
  );
};
