import React from "react";

import { AuthLayout } from "../../layouts";
import { lazyload } from "../../utils";

const { PasswordResetContainer } = lazyload(() => import("./../../features"));

export const PasswordResetPage = () => (
  <AuthLayout>
    <PasswordResetContainer />
  </AuthLayout>
);
