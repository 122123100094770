import React from "react";

import { IImageProps } from "./Image";

export const ImageResponsiveContainer: React.FC<IImageProps> = ({
  src,
  alt,
}) => {
  return (
    <div
      className="
      w-full h-[50vh] max-h-[540px] bg-primaryDark backdrop-brightness-150 flex flex-row justify-center items-center rounded-lg overflow-hidden"
    >
      <img className="w-auto h-auto max-h-full" src={src} alt={alt} />
    </div>
  );
};
