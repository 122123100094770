import React, { useEffect, useState } from "react";

import { MdInfo as InfoIcon } from "react-icons/md";

import { useFormik } from "formik";

import { ModalType, NotificationStatus } from "../../../shared";

import {
  Button,
  Container,
  Form,
  Modal,
  BasicSelect,
  Switch,
  TextField,
  Checkbox,
} from "../../../components";
import {
  ILicenseOptionsForm,
  LicenseOptionsFormValidationSchema,
  LicenseOptionsFormInitialValues,
} from "../../../features";

import {
  useAppDispatch,
  useAppSelector,
  useDebounce,
  useLoading,
  useModal,
  useToast,
} from "../../../hooks";
import {
  createDigitalAssetLicense,
  updateDigitalAssetLicense,
  enableDigitalAssetLicensing,
  disableDigitalAssetLicensing,
  calculateLicense,
  getLicenseById,
  setLicense,
  getDigitalAssetById,
} from "../../../store";
import { LICENSE_TYPE_SELECT } from "../../../constants";
import { FormValidator, textformat } from "../../../utils";

interface ILicensePostLimit {
  id: string;
  platform: string;
  limit: number;
  enabled: boolean;
}

const DEFAULT_POST_LIMITS = [
  {
    id: "facebook",
    platform: "facebook",
    enabled: false,
    limit: 0,
  },
  {
    id: "twitter",
    platform: "twitter",
    enabled: false,
    limit: 0,
  },
  {
    id: "instagram",
    platform: "instagram",
    enabled: false,
    limit: 0,
  },
  {
    id: "tiktok",
    platform: "tiktok",
    enabled: false,
    limit: 0,
  },
];

export const LicenseOptionsModal = () => {
  const dispatch = useAppDispatch();

  const { modal, closeModal, isOpen } = useModal({
    type: ModalType.LICENSE_OPTIONS,
  });

  const { marketplaceItemId, licenseId, onSubmit } = modal?.props;

  const { toast } = useToast();
  const [loading, setLoading] = useLoading();

  const [licensing, setLicensing] = useState(false);

  const [serviceFee, setServiceFee] = useState(0);
  const [total, setTotal] = useState(0);
  const [postLimits, setPostLimits] =
    useState<ILicensePostLimit[]>(DEFAULT_POST_LIMITS);

  const {
    license: { data: license, isLoading: licenseLoading },
  } = useAppSelector((state) => state.license);

  const {
    digitalAsset: { data: digitalAsset },
  } = useAppSelector((state) => state.digitalPortfolio);

  const { id: digitalAssetId, isLicense, isLicensable } = digitalAsset || {};

  const form = useFormik<ILicenseOptionsForm>({
    validationSchema: LicenseOptionsFormValidationSchema,
    initialValues: { ...LicenseOptionsFormInitialValues },
    onSubmit: async (values) => {
      if (!digitalAssetId) return;

      const data = {
        ...values,
        postLimits: postLimits?.map(({ platform, limit, enabled }) => ({
          platform,
          limit,
          enabled,
        })),
      };

      // Updating the license
      setLoading(true);

      const license = licenseId
        ? await dispatch(updateDigitalAssetLicense(digitalAssetId, data))
        : await dispatch(createDigitalAssetLicense(digitalAssetId, data));
      if (!license?.id) {
        setLoading(false);
        closeModal();

        onSubmit({ isLicense: false });

        toast({
          status: NotificationStatus.Error,
          message: "License not updated",
        });
        return;
      }

      setLoading(false);
      closeModal();

      onSubmit({ isLicense: licensing });
    },
  });

  const validator = new FormValidator<ILicenseOptionsForm>(form);

  const {
    values,
    setFieldValue,
    setValues,
    resetForm,
    handleChange,
    handleSubmit,
  } = form;
  const { duration, price, isPostControl } = values;

  const calculateTotal = useDebounce(
    async (options: { duration: number; price: number }) => {
      // Calculating
      const result = await dispatch(calculateLicense(options));
      if (!result) return;

      const { serviceFee, total } = result;

      setTotal(total);
      setServiceFee(serviceFee);
    }
  );

  const handleLicensingToggle = async (enabled?: boolean) => {
    if (!digitalAssetId) return;

    if (!licenseId) {
      setLicensing(enabled || false);
      return;
    }

    console.log({ enabled, licensing });

    const updated = enabled
      ? await dispatch(enableDigitalAssetLicensing(digitalAssetId))
      : await dispatch(disableDigitalAssetLicensing(digitalAssetId));
    if (!updated) {
      toast({
        status: NotificationStatus.Error,
        message: "License not updated.",
      });
    }
  };

  const handlePostControlToggle = (enabled?: boolean) => {
    setFieldValue("isPostControl", !!enabled, true);
  };

  const handlePostLimitToggle = (e, id: string, enabled: boolean) => {
    const limits = [...postLimits];

    const index = limits?.findIndex((i) => i.id === id);
    const limit = limits?.[index];

    if (!limit || !limits?.[index]) return;

    limits[index] = { ...limit, enabled, limit: 0 };

    setPostLimits([...limits]);
  };

  const handlePostLimitChange = (e, id) => {
    const limits = [...postLimits];

    const value = parseInt(e.target?.value) || 0;
    const index = limits?.findIndex((i) => i.id === id);
    const limit = limits?.[index];

    if (!limit) return;

    limits[index] = { ...limit, limit: value };

    setPostLimits([...limits]);
  };

  useEffect(() => {
    if (!duration || !price) return;

    calculateTotal({ duration, price });
  }, [duration, price]);

  useEffect(() => {
    setLicensing(digitalAsset?.isLicense || false);
  }, [digitalAsset?.isLicense]);

  useEffect(() => {
    if (!license?.postLimits?.length) return;

    setPostLimits(license?.postLimits || []);
  }, [license?.postLimits]);

  useEffect(() => {
    if (!license?.id) return;

    setValues({ ...values, ...license });
  }, [license]);

  useEffect(() => {
    if (!marketplaceItemId) return;

    dispatch(getDigitalAssetById(marketplaceItemId));
  }, [marketplaceItemId]);

  useEffect(() => {
    if (!licenseId) {
      resetForm();
      setPostLimits(DEFAULT_POST_LIMITS);
      dispatch(setLicense(null));

      return;
    }

    dispatch(getLicenseById(licenseId));
  }, [licenseId]);

  return (
    <Modal
      title="License Options"
      description="Set up the license options below"
      className="!w-full !max-w-[480px]"
      open={isOpen}
    >
      <Container loading={licenseLoading}>
        <div className="relative w-full h-auto">
          <div className="flex flex-row gap-[20px]">
            <div className="basis-4/12">
              <div className="w-full w-[120px] h-[120px] select-none rounded-lg overflow-hidden">
                <img
                  className="w-full h-auto aspect-square"
                  src={digitalAsset?.previewSrc}
                  alt=""
                />
              </div>
            </div>
            <div className="basis-8/12 flex flex-col justify-center items-start gap-[10px]">
              <div className="w-full flex flex-col">
                <span className="text-white text-base font-semibold">Name</span>
                <p className="text-secondary text-sm">
                  {textformat.shortenByChars(digitalAsset?.name || "", 15)}
                </p>
              </div>

              <div className="w-full flex flex-col">
                <span className="text-white text-sm font-semibold">Status</span>
                <div className="flex flex-row justify-between items-center">
                  <div className="flex flex-col gap-[5px] text-base">
                    {licensing ? (
                      <span className="text-success">Licensing</span>
                    ) : (
                      <span className="text-secondary">No licensing</span>
                    )}
                  </div>
                  <Switch value={licensing} onChange={handleLicensingToggle} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {licensing ? (
          <div>
            <Form onSubmit={handleSubmit}>
              <div className="relative mt-[25px] w-full">
                <div className="flex flex-col gap-[10px]">
                  <BasicSelect
                    id="type"
                    label="License Type"
                    options={LICENSE_TYPE_SELECT}
                    value={values?.type}
                    errorMessage={validator.isInputValid("type")}
                    setValue={(value) => setFieldValue("type", value, true)}
                  />
                  <div className="flex gap-[10px] xxs:flex-col md:flex-row">
                    <TextField
                      id="duration"
                      label="Duration (Days)"
                      type="number"
                      variant="filled"
                      className="bg-primaryLight text-white border border-solid border-primaryMid"
                      labelClassName="text-secondary"
                      maxLength={3}
                      value={values?.duration}
                      errorMessage={validator.isInputValid("duration")}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="flex flex-row justify-start items-start gap-[10px] border-[1px] border-dashed border-primary rounded-lg p-[10px] box-border text-xs text-white mt-[25px]">
                  <InfoIcon size={30} className="text-white" />
                  <p>
                    By default you’re consenting to allow the licensee to post
                    on any social platform, unless you use Post Control.
                  </p>
                </div>

                <div className="w-full mt-[25px]">
                  <div className="flex flex-col gap-[10px]">
                    <div className="flex flex-row justify-between items-center gap-[5px]">
                      <span className="text-base text-white font-medium">
                        Post control
                      </span>
                      <Switch
                        value={values?.isPostControl}
                        onChange={handlePostControlToggle}
                      />
                    </div>

                    {isPostControl && (
                      <div className="w-full flex flex-col gap-[10px] mb-[20px]">
                        {postLimits?.map(({ id, platform, limit, enabled }) => (
                          <div className="w-full flex flex-row items-center">
                            <div className="basis-9/12">
                              <div className="flex flex-row items-center">
                                <Checkbox
                                  id={`post_control_${id}`}
                                  name={`post_control_${id}`}
                                  checked={enabled}
                                  customLabel={
                                    <span className="text-base font-medium text-secondary capitalize">
                                      {platform}
                                    </span>
                                  }
                                  onChange={(e, checked) =>
                                    handlePostLimitToggle(e, id, checked)
                                  }
                                />
                              </div>
                            </div>
                            <div className="basis-3/12">
                              <TextField
                                id={`post_control_${id}`}
                                type="number"
                                variant="standard"
                                className="bg-primaryLight text-white border border-solid border-primaryMid h-[45px]"
                                labelClassName="text-secondary"
                                maxLength={3}
                                value={limit}
                                disabled={!enabled}
                                onChange={(e) => handlePostLimitChange(e, id)}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                    <div className="flex flex-row justify-between items-center gap-[5px]">
                      <span className="text-base text-white font-medium">
                        Commercial usage
                      </span>
                      <Switch
                        value={values?.isCommercialUsage}
                        onChange={(value) =>
                          setFieldValue("isCommercialUsage", value, true)
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="w-full mt-[30px]">
                  <span className="text-base text-white font-medium">
                    Pricing
                  </span>
                  <div className="flex flex-col gap-[10px] mt-[10px]">
                    <TextField
                      id="price"
                      label="Price (USD)"
                      type="number"
                      variant="filled"
                      className="bg-primaryLight text-white border border-solid border-primaryMid"
                      labelClassName="text-secondary"
                      maxLength={5}
                      value={values?.price}
                      errorMessage={validator.isInputValid("price")}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="w-full mt-[30px]">
                  <span className="text-base text-white font-medium">
                    Terms
                  </span>

                  <div className="flex flex-col gap-[10px] mt-[10px]">
                    <TextField
                      id="terms"
                      label="Additional Terms"
                      type="text"
                      variant="filled"
                      className="bg-primaryLight text-white border border-solid border-primaryMid"
                      labelClassName="text-secondary"
                      multiline
                      minRows={4}
                      maxRows={4}
                      value={values?.terms}
                      errorMessage={validator.isInputValid("terms")}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="flex flex-col gap-[5px] mt-[30px]">
                  <div className="flex flex-row justify-between items-center gap-[5px]">
                    <span className="text-base text-secondary font-medium">
                      Service Fee
                    </span>
                    <span className="text-white font-medium">
                      ${serviceFee || 0}
                    </span>
                  </div>
                  <div className="flex flex-row justify-between items-center gap-[5px]">
                    <span className="text-base text-secondary font-medium">
                      Total
                    </span>
                    <span className="text-white font-semibold">
                      ${total || 0}
                    </span>
                  </div>
                </div>

                <div className="w-full flex flex-row justify-end mt-[30px]">
                  <Button
                    variant="outlined"
                    color="success"
                    type="submit"
                    className="min-w-[150px]"
                    loading={loading}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        ) : (
          <></>
        )}
      </Container>
    </Modal>
  );
};
