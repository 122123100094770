import React from "react";

import { FiTrash as DeleteIcon } from "react-icons/fi";

import { IconButton, IIconButtonProps } from "./../../components";

interface IDeleteIconButton extends IIconButtonProps {}

export const DeleteIconButton: React.FC<IDeleteIconButton> = ({
  iconClassName,
  iconProps,
  onClick,
}) => (
  <IconButton
    icon={DeleteIcon}
    iconProps={iconProps}
    iconClassName={iconClassName}
    onClick={onClick || (() => {})}
  />
);

DeleteIconButton.defaultProps = {
  iconProps: { size: 24 },
  iconClassName: "fill-secondary",
};
