import React from "react";

import { Loader } from "../../components";

interface ILoadMoreButtonProps {
  label?: string;
  loading?: boolean;
  onClick: (e?: React.MouseEvent<any>) => void;
}

export const LoadMoreButton: React.FC<ILoadMoreButtonProps> = ({
  label,
  loading,
  onClick,
}) => (
  <div
    className="w-full h-[50px] flex flex-row justify-center items-center rounded-lg cursor-pointer bg-primaryLight text-secondary hover:brightness-110"
    onClick={onClick}
  >
    {loading ? (
      <Loader size={18} className="text-secondary" />
    ) : (
      <span className="text-xs font-medium">{label}</span>
    )}
  </div>
);

LoadMoreButton.defaultProps = {
  label: "Load More",
  loading: false,
};
