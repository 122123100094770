import React from "react";

import { IEducation } from "../../../../shared";
import {
  IResumeSectionProps,
  ResumeEducationSectionDetail,
} from "../../../../features";

interface IResumeEducationSectionProps
  extends IResumeSectionProps<IEducation> {}

export const ResumeEducationSection: React.FC<IResumeEducationSectionProps> = ({
  data,
  loading,
}) => <ResumeEducationSectionDetail loading={loading} data={data} />;

ResumeEducationSection.defaultProps = {};
