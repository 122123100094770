import React from "react";

import ReactPlayer from "react-player/file";

interface IVideoPlayerProps {
  url?: string;
}

export const VideoPlayer: React.FC<IVideoPlayerProps> = ({ url }) => {
  return (
    <ReactPlayer
      url={url}
      wrapper={({ children }) => (
        <div className="w-full h-auto">{children}</div>
      )}
      controls
      loop
    />
  );
};
