import React from "react";

import { ScrollTopButton, TicketIconButton } from "../../features";
import { Link, Logo } from "../../components";

interface IFooterProps {}

export const Footer: React.FC<IFooterProps> = () => (
  <footer className="w-full flex flex-col box-border">
    <div className="flex flex-row justify-between items-center">
      <div className="flex flex-col justify-start">
        <div className="flex flex-col justify-start">
          <Logo className="text-lg" />
          <span className="text-xs mt-[3px]">
            © 2022 Legitbook Inc. All rights reserved.
          </span>
        </div>

        <div className="flex flex-col justify-start">
          <div className="flex flex-row flex-wrap gap-[15px] text-sm mt-[20px]">
            <Link to="#">About Legitbook</Link>
            <Link to="#">Privacy Policy</Link>
            <Link to="#">Terms of Use</Link>
            <Link to="#">Contact Us</Link>
          </div>
        </div>
      </div>
      <div className="flex flex-row gap-[10px]">
        <ScrollTopButton />
        <TicketIconButton />
      </div>
    </div>
  </footer>
);

Footer.defaultProps = {};
