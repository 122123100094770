import React from "react";

import { AuthLayout } from "../../layouts";
import { lazyload } from "../../utils";

const { LoginContainer } = lazyload(() => import("./../../features"));

export const LoginPage = () => (
  <AuthLayout>
    <LoginContainer />
  </AuthLayout>
);
