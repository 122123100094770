import React from "react";
import { useNavigate } from "react-router";

import { Button, IButtonProps } from "../../components";
import { useAppDispatch, useLoading, useModal } from "../../hooks";
import { ROUTER } from "../../routes";
import { ModalType } from "../../shared";
import { checkChatWithUser } from "../../store";

interface IChatMessageButtonProps {
  className?: string;
  userId?: string;
  text?: string;
  buttonProps?: IButtonProps;
}

export const ChatMessageButton: React.FC<IChatMessageButtonProps> = ({
  className,
  userId,
  text,
  buttonProps,
}) => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const { setModal } = useModal();
  const [loading, setLoading] = useLoading();

  const handleMessage = async () => {
    if (!userId) return;

    setLoading(true);

    // Checking Chat
    const chat = await dispatch(checkChatWithUser(userId));

    // Creating Chat
    if (!chat?.id) {
      setLoading(false);

      setModal({
        type: ModalType.CHAT_MESSAGE,
        props: {
          userId,
          onClose: () => {
            setLoading(false);
          },
        },
      });

      return;
    }

    redirect(ROUTER.CHAT_DETAIL(chat.id));
  };

  return (
    <Button
      color="success"
      variant="outlined"
      className={className}
      loading={loading}
      onClick={handleMessage}
      {...buttonProps}
    >
      {text}
    </Button>
  );
};

ChatMessageButton.defaultProps = {
  buttonProps: {},
  className: "min-w-[125px]",
  text: "Message",
};
