import React from "react";

import { lazyload } from "../../utils";
import { MainLayout } from "../../layouts";
import {
  ResumeBuilderSidebar,
  ResumeBuilderRightSidebar,
} from "../../features";

const { ResumeBuilderContainer } = lazyload(() => import("../../features"));

export const ResumeBuilderPage = () => (
  <MainLayout
    leftSidebar={ResumeBuilderSidebar}
    rightSidebar={ResumeBuilderRightSidebar}
  >
    <ResumeBuilderContainer />
  </MainLayout>
);
