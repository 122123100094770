import React, { Suspense, useEffect, useRef, useState } from "react";

import { useNavigate, useParams } from "react-router";

import { useFormik } from "formik";
import * as Yup from "yup";

import { MdSend as SendIcon } from "react-icons/md";

import { ModalType, WebsocketEvents, IChatMessage } from "../../../shared";

import { Form, TextField } from "../../../components";
import { useAppDispatch, useAppSelector } from "../../../hooks";

import { ROUTER } from "../../../routes";
import { getChatById, sendChatMessage } from "../../../store";
import { addLineBreaks, Generator } from "../../../utils";

interface IChatMessageFormProps {
  ref?: React.Ref<HTMLInputElement>;
  onSubmit?: (message?: IChatMessage) => void;
}

const validationSchema = Yup.object().shape({
  message: Yup.string()
    .trim()
    .max(1500, "Exceeded maximum character length of 70")
    .required("Message is a required field"),
});

export const ChatMessageForm: React.FC<IChatMessageFormProps> =
  React.forwardRef((props, ref: React.Ref<HTMLInputElement>) => {
    const { onSubmit } = props;

    const dispatch = useAppDispatch();
    const redirect = useNavigate();

    const { chatId } = useParams();

    const {
      account: { data: account },
    } = useAppSelector((state) => state.account);

    const profileId = account?.profileId;

    const { values, resetForm, handleChange, handleSubmit } = useFormik({
      initialValues: { message: "" },
      validationSchema,
      onSubmit: async (values) => {
        const text = addLineBreaks(values.message);

        const data: IChatMessage = {
          text,
          authorId: profileId,
          chatId,
          isIncoming: false,
          tid: Generator.uuid(),
        };

        await dispatch(sendChatMessage(data));

        resetForm();

        if (onSubmit) {
          onSubmit(data);
        }
      },
    });

    const handleKeyDown = (e) => {
      const key = e.keyCode;

      const enterKey = key === 13;
      const shiftKey = e.shiftKey;

      if (!shiftKey && enterKey) {
        e.preventDefault();
        handleSubmit();
      }
    };

    useEffect(() => {
      if (!ref) return;

      // @ts-ignore
      ref.current.style.backround = "red";
    }, []);

    return (
      <div className="w-full h-auto max-h-[220px]">
        <Form onSubmit={handleSubmit}>
          <div className="w-full flex flex-row justify-start items-center">
            <TextField
              ref={ref}
              name="message"
              type="text"
              variant="standard"
              className="h-auto min-h-[75px] !text-white !bg-transparent !rounded-none !border-none px-[25px] py-[10px]"
              placeholder="Typing a message.."
              multiline
              maxRows={8}
              value={values?.message}
              rightIcon={() => <SendIcon size={24} />}
              rightIconProps={{
                className: `transition-all absolute w-[40px] h-auto flex flex-col justify-end right-[10px] bottom-[20px] box-border px-[10px] py-[5px] cursor-pointer text-secondary hover:text-white`,
                onClick: () => handleSubmit(),
              }}
              autoFocus
              onChange={handleChange}
              onKeyDown={handleKeyDown}
            />
          </div>
        </Form>
      </div>
    );
  });
