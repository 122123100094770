import React, { Suspense } from "react";

import { BottomNavbar, Loader, Navbar } from "../components";
import { ILayout } from "@types";

interface IMainExtendedLayoutProps extends ILayout {
  hideFooter?: boolean;
}

export const MainExtendedLayout: React.FC<IMainExtendedLayoutProps> = ({
  children,
  leftSidebar: LeftSidebar,
  rightSidebar: RightSidebar,
}) => (
  <div className="flex flex-col w-full min-h-screen h-auto bg-primaryDark overflow-x-hidden font-gilroy">
    <Navbar />

    {LeftSidebar && (
      <div
        className="fixed h-screen w-auto flex flex-col box-border border-r border-solid border-primaryLight bg-primaryDark z-30
                  xxs:hidden xl:flex 
    "
      >
        <Suspense fallback={<Loader />}>
          <LeftSidebar
            position="left"
            className="md:hidden lg:flex lg:w-[120px] xl:w-[200px] 2xl:w-[220px] 3xl:w-[300px]"
          />
        </Suspense>
      </div>
    )}

    <div className="w-full lg:max-w-[960px] 3xl:max-w-[1024px] flex flex-col h-auto min-h-[500px] mt-[75px] mx-auto py-[30px] box-border text-secondary xxs:p-[20px]">
      <Suspense fallback={<Loader />}>{children}</Suspense>
    </div>

    {RightSidebar && (
      <div
        className="fixed w-auto h-screen right-[0px] flex flex-col box-border border-l border-solid border-primaryLight bg-primaryDark z-30
                  xxs:hidden xl:flex          
    "
      >
        <Suspense fallback={<Loader />}>
          <RightSidebar
            position="right"
            className="xl:w-[280px] 2xl:w-[350px]"
          />
        </Suspense>
      </div>
    )}

    <div className="fixed z-50 bottom-0 w-full h-[70px] flex md:hidden xxs:flex">
      <BottomNavbar />
    </div>
    <div className="w-full h-[75px]"></div>
  </div>
);

MainExtendedLayout.defaultProps = { hideFooter: false };
