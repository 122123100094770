import React from "react";
import { useLocation } from "react-router";

import { FiArrowLeft as BackIcon } from "react-icons/fi";

import { IconButton } from "./../../components";
import { ROUTER } from "../../routes";

interface IBackButtonProps {
  backTo?: string | null;
  exception?: {
    from: string;
    backTo: string;
  };
}

export const BackButton: React.FC<IBackButtonProps> = ({
  backTo,
  exception,
}) => {
  const location = useLocation();

  // @ts-ignore
  const previous: string = location?.state?.previous || null;

  const exceptBackTo = exception
    ? previous?.startsWith(exception.from) || previous?.endsWith(exception.from)
      ? exception.backTo
      : null
    : null;

  return (
    <IconButton
      to={
        exceptBackTo ? exceptBackTo : backTo ? backTo : previous || ROUTER.HOME
      }
      icon={BackIcon}
      iconProps={{ size: 18 }}
      className="rounded-full text-secondary p-[5px] hover:bg-primaryLight"
    />
  );
};

BackButton.defaultProps = {};
