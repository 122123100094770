import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  RegisterStart,
  RegisterPassportData,
  RegisterEmail,
  RegisterEmailConfirm,
  RegisterComplete,
} from "../..";

import { ROUTER } from "../../../routes";
import { PageSwitch } from "../../../components";
import { IRegisterContextState, RegisterContext } from "../../../context";
import { useAppSelector } from "../../../hooks";

export const RegisterContainer = () => {
  const redirect = useNavigate();

  const [registerState, setRegisterState] = useState<IRegisterContextState>({
    email: "",
    password: "",
    passport: {},
    profile: {},
    isPassportVerified: false,
    isEmailVerified: false,
  });

  const pages = [
    {
      path: ROUTER.REGISTER_PASSPORT_SCAN,
      component: <RegisterStart />,
    },
    {
      path: ROUTER.REGISTER_PASSPORT_DATA,
      component: <RegisterPassportData />,
    },
    {
      path: ROUTER.REGISTER_EMAIL,
      component: <RegisterEmail />,
    },
    {
      path: ROUTER.REGISTER_EMAIL_CONFIRM,
      component: <RegisterEmailConfirm />,
    },
    {
      path: ROUTER.REGISTER_PASSWORD,
      component: <RegisterComplete />,
    },
  ];

  return (
    <RegisterContext.Provider
      value={{
        registerState,
        setRegisterState: (values) => setRegisterState(values),
      }}
    >
      <div className="w-full max-w-[640px] text-white flex flex-col gap-y-5">
        <PageSwitch pages={pages} defaultPage={pages[0]} />
      </div>
    </RegisterContext.Provider>
  );
};
