import React, { useState } from "react";

import { ModalType, VerificationStatus } from "../../shared";

import { StatusBadge, VerifiedBadgeIcon } from "./../../components";
import { useModal } from "../../hooks";

interface IVerificationStatusBadgeProps {
  className?: string;
  status?: string | null;
  experience?: any;
  education?: any;
  createdById?: string;
  onVerify?: () => void;
}

export const VerificationStatusBadge: React.FC<
  IVerificationStatusBadgeProps
> = ({ className, experience, education, createdById, onVerify, ...props }) => {
  const { modal, setModal } = useModal();

  const [status, setStatus] = useState(props?.status);

  let label, color;

  switch (status) {
    case VerificationStatus.Pending:
      label = "pending";
      color = "warning";
      break;
    case VerificationStatus.Approved:
      label = "verified";
      color = "success";
      break;
    case VerificationStatus.Rejected:
      label = "rejected";
      color = "danger";
      break;
    default:
      label = "pending";
      color = "primary";
      break;
  }

  const isApproved = status === VerificationStatus.Approved; // && status !== VerificationStatus.Rejected;
  const isRejected = status === VerificationStatus.Rejected;

  const handleVerify = () => {
    setModal({
      type: ModalType.VERIFICATION_REQUEST_CREATE,
      props: {
        experience,
        education,
        createdById,
        onVerify: () => {
          setStatus(VerificationStatus.Pending);

          if (onVerify) {
            onVerify();
          }
        },
      },
    });
  };

  return (
    <>
      {status ? (
        isApproved ? (
          <div
            className={`${className} flex flex-row justify-start items-center gap-x-[3px] text-sm text-secondary font-medium stroke-greenLight fill-transparent`}
          >
            <VerifiedBadgeIcon />
            <span className="text-greenLight text-xs font-semibold">
              Verified
            </span>
          </div>
        ) : isRejected ? (
          <span
            className={`${className} text-xs font-semibold text-warning underline cursor-pointer transition-all hover:brightness-125`}
            onClick={handleVerify}
          >
            Get Verified
          </span>
        ) : (
          <StatusBadge className={className} status={status} />
        )
      ) : (
        <span
          className={`${className} text-xs font-semibold text-warning underline cursor-pointer transition-all hover:brightness-125`}
          onClick={handleVerify}
        >
          Get Verified
        </span>
      )}
    </>
  );
};

VerificationStatusBadge.defaultProps = {
  status: null,
};
