import React from "react";
import { useLocation } from "react-router";

import { PasswordResetEmail, PasswordResetConfirm } from "../../../features";
import { PageSwitch } from "../../../components";

import { ROUTER } from "../../../routes";

export const PasswordResetContainer = () => {
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const token = searchParams.get("token");

  const pages = [
    {
      path: ROUTER.PASSWORD_RESET,
      component: token ? <PasswordResetConfirm /> : <PasswordResetEmail />,
    },
  ];

  return (
    <div className="w-full max-w-[640px] text-white flex flex-col gap-y-5">
      <PageSwitch pages={pages} defaultPage={pages[0]} />
    </div>
  );
};
