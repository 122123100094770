import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { useFormik } from "formik";
import * as Yup from "yup";

import {
  ModalType,
  NotificationStatus,
  NotificationType,
  NotificationPriority,
} from "../../../shared";

import {
  useAppDispatch,
  useAppSelector,
  useLoading,
  useModal,
  useToast,
} from "../../../hooks";
import { applyJob, createGalleryFile, getResumes } from "../../../store";

import {
  Button,
  Container,
  Form,
  Modal,
  FileDragDropPicker,
  Loader,
  TextField,
  UploadProgressLoader,
} from "./../../../components";
import { ROUTER } from "../../../routes";
import { FormValidator, toBase64 } from "../../../utils";

interface IGalleryUploadForm {
  name?: string;
  description?: string;
  file?: string;
}

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .max(500, "Exceeded maximum character length of 500")
    .required("Title is a required field"),
  description: Yup.string()
    .max(3000, "Exceeded maximum character length of 3000")
    .notRequired(),
});

export const GalleryUploadModal = () => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const { toast } = useToast();
  const { modal, isOpen, closeModal } = useModal({
    type: ModalType.GALLERY_UPLOAD,
  });

  const [loading, setLoading] = useLoading();
  const [processing, setProcessing] = useLoading();
  const [progress, setProgress] = useState(0);
  const [progressLabel, setProgressLabel] = useState<string>();

  const [files, setFiles] = useState<File[]>([]);
  const [filePreviews, setFilePreviews] = useState<string[]>([]);

  const form = useFormik<IGalleryUploadForm>({
    initialValues: { name: "", description: "" },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      // Preparing Data
      const form = new FormData();
      const file = files?.[0];

      for (const key of Object.keys(values)) {
        if (!key) continue;

        form.append(key, values[key]);
      }

      form.append("file", file);

      setLoading(true);
      setProgressLabel("Uploading the file..");

      // Uploading the file
      const galleryFile = await dispatch(
        createGalleryFile(form, handleUploadProgress)
      );
      if (!galleryFile?.id) {
        setLoading(false);
        closeModal();

        toast({
          status: NotificationStatus.Error,
          message: `File hasn't been upload to gallery.`,
        });

        return;
      }

      setLoading(false);
      closeModal();

      handleReset();

      toast({
        status: NotificationStatus.Success,
        message: `File has been successfully uploaded to gallery.`,
      });
    },
  });

  const { values, handleChange, handleSubmit } = form;

  const validator = new FormValidator(form);

  const handleFileChange = (files?: File[]) => {
    if (!files?.length) return;

    setFiles(files);
  };

  const handleFilePreviews = async (files?: File[]) => {
    if (files?.length) {
      const previews: string[] = [];

      for await (let file of files) {
        const buffer = await toBase64(file);
        const preview = buffer?.toString();

        if (!preview) continue;

        previews.push(preview);
      }

      setFilePreviews(previews);
    }
  };

  const handleUploadProgress = (e) => {
    const { loaded, total } = e;

    const percent = Math.round((loaded * 100) / total);

    setProgress(() => percent);
  };

  const handleReset = () => {
    setFiles([]);
  };

  const handleClose = () => {
    closeModal();
  };

  useEffect(() => {
    if (!processing) return;

    setProgressLabel("Processing the file..");
  }, [processing]);

  useEffect(() => {
    if (progress >= 100 || false) {
      setProcessing(true);
    }
  }, [progress]);

  useEffect(() => {
    handleFilePreviews(files);
  }, [files]);

  return (
    <Modal
      title="Upload to gallery"
      description="Please select a file you want to upload"
      className="w-[480px]"
      open={isOpen}
      onClose={handleClose}
    >
      {loading ? (
        <UploadProgressLoader value={progress} label={progressLabel} />
      ) : (
        <Container>
          {!files?.length ? (
            <Form onSubmit={handleSubmit}>
              <FileDragDropPicker
                className="h-[240px]"
                onChange={handleFileChange}
              />
            </Form>
          ) : (
            <div className="flex flex-col">
              <div className="flex flex-row flex-wrap gap-x-[5px] gap-y-[5px] mt-[5px]">
                {filePreviews?.map((preview, index) => (
                  <div
                    className={`
                        ${
                          !!filePreviews?.length && filePreviews?.length === 1
                            ? "basis-full h-auto"
                            : "basis-2/12 h-[75px]"
                        }
                        relative flex flex-row rounded-lg overflow-hidden select-none
                    `}
                  >
                    {!!preview ? (
                      <img
                        className="w-[150%] h-auto select-none pointer-events-none"
                        src={preview}
                        alt="File Preview"
                      />
                    ) : (
                      <Loader />
                    )}
                  </div>
                ))}
              </div>

              <div className="flex flex-col gap-y-[10px] mt-[20px]">
                <TextField
                  id="name"
                  label="Title"
                  type="text"
                  variant="filled"
                  className="!bg-primaryDark !text-white"
                  controlClassName="basis-1/2"
                  labelClassName="text-secondary"
                  inputClassName="text-inherit"
                  required
                  value={values?.name}
                  errorMessage={validator.isInputValid("name")}
                  onChange={handleChange}
                />
                <TextField
                  id="description"
                  label="Description"
                  type="text"
                  variant="filled"
                  className="!bg-primaryDark !text-white"
                  controlClassName="basis-1/2"
                  labelClassName="text-secondary"
                  inputClassName="text-inherit"
                  minRows={2}
                  maxRows={5}
                  multiline
                  value={values?.description}
                  errorMessage={validator.isInputValid("description")}
                  onChange={handleChange}
                />
              </div>

              <div className="flex flex-row justify-end items-center gap-x-[10px] mt-[20px]">
                <Button
                  color="secondary"
                  disabled={loading}
                  onClick={handleReset}
                >
                  Change
                </Button>
                <Button
                  color="success"
                  className="min-w-[150px]"
                  loading={loading}
                  onClick={() => handleSubmit()}
                >
                  Upload
                </Button>
              </div>
            </div>
          )}
        </Container>
      )}
    </Modal>
  );
};
