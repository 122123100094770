import React from "react";

import {
  ResumeDigitalAssetSectionDetail,
  ResumeEducationSectionSelect,
} from "../../..";

interface IResumeDigitalAssetSectionProps {
  data?: any;
  loading?: boolean;
  editing?: boolean;
}

export const ResumeDigitalAssetSection: React.FC<
  IResumeDigitalAssetSectionProps
> = ({ loading, editing, data }) => (
  <ResumeDigitalAssetSectionDetail loading={loading} data={data} />
);

ResumeDigitalAssetSection.defaultProps = {};
