import React from "react";
import { Button } from "../../components";
import { ROUTER } from "../../routes";

export const VideoCallNotFoundError = () => (
  <div className="flex flex-col box-border py-[15px]">
    <div className="flex flex-col">
      <h2 className="text-white text-md font-semibold">
        Meeting is not found 🫤
      </h2>
      <span className="text-secondary text-base">
        Sorry, but the meeting you're looking for doesn't exist!
      </span>
    </div>
    <div className="mt-[15px]">
      <Button to={ROUTER.HOME} variant="outlined" color="secondary">
        To Home Page
      </Button>
    </div>
  </div>
);
