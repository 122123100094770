import React from "react";

import { MainExtendedLayout, MainLayout } from "../../layouts";
import { lazyload } from "../../utils";

const { MarketplaceItemDetailContainer } = lazyload(
  () => import("../../containers")
);

export const MarketplaceItemDetailPage = () => (
  <MainExtendedLayout hideFooter>
    <MarketplaceItemDetailContainer />
  </MainExtendedLayout>
);
