import React, { useEffect } from "react";
import { useNavigate } from "react-router";

import { ModalType } from "../../../shared";

import { Container, Modal } from "../../../components";
import { useAppDispatch, useModal } from "../../../hooks";

export const TermsAndConditionsModal = () => {
  const { isOpen, closeModal } = useModal({
    type: ModalType.TERMS_AND_CONDITIONS,
  });

  return (
    <Modal
      title="Terms and conditions"
      open={isOpen}
      dialogClassName="max-w-[300px]"
    >
      <Container>
        <p>
          By registering for Legitbook services, you are giving consent to
          having any of your resume data verified by prospective employers.
        </p>
      </Container>
    </Modal>
  );
};
