import { ApiService } from "../../../services";
import { AppDispatch, GetRootState } from "../../store";

import { gallerySlice } from "./gallery.slice";

export const getGalleryFiles =
  (options?: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(gallerySlice.actions.getGalleryFilesRequest());

      const response = await ApiService.post(`gallery-files/query`, options);
      const { data, results } = response.data;

      dispatch(gallerySlice.actions.getGalleryFilesSuccess({ data, results }));

      return data;
    } catch (e: any) {
      dispatch(
        gallerySlice.actions.getGalleryFilesError(e.response?.data?.error)
      );
    }
  };

export const getGalleryById = (id: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(gallerySlice.actions.getGalleryByIdRequest());

    const response = await ApiService.get(`galleries/${id}`);
    const { data, results } = response.data;

    dispatch(gallerySlice.actions.getGalleryByIdSuccess({ data, results }));

    return data;
  } catch (e: any) {
    dispatch(gallerySlice.actions.getGalleryByIdError(e.response?.data?.error));
  }
};

export const getGalleryByUserId =
  (userId: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(gallerySlice.actions.getGalleryByUserIdRequest());

      const response = await ApiService.get(`profiles/${userId}/gallery`);
      const { data, results } = response.data;

      dispatch(
        gallerySlice.actions.getGalleryByUserIdSuccess({ data, results })
      );

      return data;
    } catch (e: any) {
      dispatch(
        gallerySlice.actions.getGalleryByUserIdError(e.response?.data?.error)
      );
    }
  };

export const getGalleryFileById =
  (fileId: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(gallerySlice.actions.getGalleryFileByIdRequest());

      const response = await ApiService.get(`gallery-files/${fileId}`);
      const { data, results } = response.data;

      dispatch(
        gallerySlice.actions.getGalleryFileByIdSuccess({ data, results })
      );

      return data;
    } catch (e: any) {
      dispatch(
        gallerySlice.actions.getGalleryFileByIdError(e.response?.data?.error)
      );
    }
  };

export const createGalleryFile =
  (
    payload: any,
    onUploadProgress: (e: { loaded: number; total: number }) => void
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(gallerySlice.actions.createGalleryFileRequest());

      const response = await ApiService.post(`gallery-files`, payload, {
        timeout: 15 * 60 * 1000,
        onUploadProgress,
      });

      const { data, results } = response.data;

      dispatch(
        gallerySlice.actions.createGalleryFileSuccess({ data, results })
      );

      return data;
    } catch (e: any) {
      console.log({ e });

      if (!!e?.response)
        dispatch(
          gallerySlice.actions.createGalleryFileError(e.response?.data?.error)
        );

      return e;
    }
  };

export const updateGalleryFileById =
  (fileId: string, payload: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(gallerySlice.actions.updateGalleryFileByIdRequest());

      const response = await ApiService.put(`gallery-files/${fileId}`, payload);
      const { data } = response.data;

      dispatch(gallerySlice.actions.updateGalleryFileByIdSuccess({ data }));

      await dispatch(getGalleryFileById(fileId));

      return data;
    } catch (e: any) {
      dispatch(
        gallerySlice.actions.updateGalleryFileByIdError(e.response?.data?.error)
      );
    }
  };

export const removeGalleryFileById =
  (fileId: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(gallerySlice.actions.removeGalleryFileByIdRequest());

      const response = await ApiService.delete(`gallery-files/${fileId}`);
      const { data, results } = response.data;

      dispatch(
        gallerySlice.actions.removeGalleryFileByIdSuccess({ data, results })
      );

      return { id: fileId };
    } catch (e: any) {
      dispatch(
        gallerySlice.actions.removeGalleryFileByIdError(e.response?.data?.error)
      );
    }
  };
