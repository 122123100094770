export const stringifyQueryParams = (params: object | any) => {
  if (typeof params !== "object") return "";

  const keys = Object.keys(params);
  const values = Object.values(params);

  const result = keys?.map((key, i) => `${key}=${values[i]}`)?.join("&") || "";

  return result;
};
