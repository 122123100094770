import React from "react";

import { Button as MuiButton } from "@mui/material";

import { Link, Loader } from "./../../components";

export interface IButtonProps {
  loading?: boolean;
  color?: "primary" | "secondary" | "success" | "danger" | "warning";
  variant?: "text" | "contained" | "outlined";
  className?: string;
  icon?: any;
  to?: string | null;
  onClick?: (
    e?: React.MouseEvent | React.FormEvent<HTMLFormElement> | undefined
  ) => void;
  [x: string]: any;
}

export const Button: React.FC<IButtonProps> = ({ color, ...props }) => {
  switch (color) {
    case "primary":
      return <PrimaryButton {...props} />;
    case "secondary":
      return <SecondaryButton {...props} />;
    case "success":
      return <SuccessButton {...props} />;
    case "danger":
      return <DangerButton {...props} />;
    case "warning":
      return <WarningButton {...props} />;
    default:
      return <PrimaryButton {...props} />;
  }
};

Button.defaultProps = {
  color: "primary",
  variant: "outlined",
  loading: false,
};

interface IBaseButtonProps extends IButtonProps {
  [x: string]: any;
}

export const BaseButton: React.FC<IBaseButtonProps> = ({
  children,
  to,
  loading,
  disabled,
  variant,
  className,
  underlayClassName,
  icon,
  ...props
}) => {
  let extraClassName = "";
  let extraUnderlayClassName = "";

  switch (variant) {
    case "contained":
      extraClassName = "";
      extraUnderlayClassName = "hidden";
      break;
    case "outlined":
      extraClassName = "border border-solid rounded-md";
      break;
    case "text":
      extraClassName = "";
      extraUnderlayClassName = "hidden";
      break;
    default:
      break;
  }

  return (
    // @ts-ignore
    <MuiButton
      className={`${extraClassName} ${className} ${
        disabled && "grayscale text-secondary"
      } h-[36px] capitalize text-xs font-medium font-gilroy px-[20px] overflow-hidden`}
      disabled={disabled || loading}
      {...props}
    >
      <div
        className={`absolute opacity-10 inset-0 z-0 ${underlayClassName} ${extraUnderlayClassName}`}
      ></div>

      {to && <Link to={to} className="absolute w-full h-full z-20" />}

      {icon ? <span className="mr-[5px]">{icon}</span> : <></>}

      {loading ? (
        <Loader size={18} className="text-white" />
      ) : (
        <span className="z-10 text-inherit">{children}</span>
      )}
    </MuiButton>
  );
};

export const PrimaryButton: React.FC<IBaseButtonProps> = ({
  children,
  className,
  color,
  ...props
}) => (
  <BaseButton
    className={className || "border-primary text-primary"}
    underlayClassName="bg-primary"
    {...props}
  >
    {children}
  </BaseButton>
);

export const SecondaryButton: React.FC<IBaseButtonProps> = ({
  children,
  className,
  color,
  ...props
}) => (
  <BaseButton
    className={`border-secondary text-secondary ${className}`}
    underlayClassName="bg-secondary"
    {...props}
  >
    {children}
  </BaseButton>
);

export const SuccessButton: React.FC<IBaseButtonProps> = ({
  children,
  className,
  color,
  ...props
}) => (
  <BaseButton
    className={`border-success text-success ${className}`}
    underlayClassName="bg-success"
    {...props}
  >
    {children}
  </BaseButton>
);

export const DangerButton: React.FC<IBaseButtonProps> = ({
  children,
  className,
  color,
  ...props
}) => (
  <BaseButton
    className={`border-danger text-danger ${className}`}
    underlayClassName="bg-danger"
    {...props}
  >
    {children}
  </BaseButton>
);

export const WarningButton: React.FC<IBaseButtonProps> = ({
  children,
  className,
  color,
  ...props
}) => (
  <BaseButton
    className={`border-warning text-warning ${className}`}
    underlayClassName="bg-warning"
    {...props}
  >
    {children}
  </BaseButton>
);
