import { createSlice } from "@reduxjs/toolkit";

import { IVideoCallClient } from "@types";
import { IVideoCall } from "../../../shared";
import { AppReducerState } from "../../store";

interface IVideoCallState {
  videoCallClient: IVideoCallClient;
  videoCall: AppReducerState<IVideoCall>;
  videoCalls: AppReducerState<IVideoCall[]>;
}

const initialState: IVideoCallState = {
  videoCallClient: {},
  videoCall: {
    isLoading: false,
    data: {},
    error: null,
  },
  videoCalls: {
    isLoading: false,
    results: 0,
    data: [],
    error: null,
  },
};

export const videoCallSlice = createSlice({
  name: "Video Call",
  initialState,
  reducers: {
    setVideoCallClient: (state, action) => {
      state.videoCallClient = { ...state.videoCallClient, ...action.payload };
    },
    setVideoCall: (state, action) => {
      state.videoCall.data = { ...state.videoCall.data, ...action.payload };
    },

    generateToken: (state) => {},

    getVideoCallByIdRequest: (state) => {
      state.videoCall.isLoading = true;
      state.videoCall.data = {};
    },
    getVideoCallByIdSuccess: (state, action) => {
      const { data } = action.payload;

      state.videoCall.data = data;
      state.videoCall.isLoading = false;
    },
    getVideoCallByIdError: (state, action) => {
      state.videoCall.error = action.payload;
      state.videoCall.isLoading = false;
    },

    joinVideoCallByIdRequest: (state) => {},
    joinVideoCallByIdSuccess: (state, action) => {},
    joinVideoCallByIdError: (state, action) => {},

    startVideoCallRecordingRequest: (state) => {},
    startVideoCallRecordingSuccess: (state, action) => {},
    startVideoCallRecordingError: (state, action) => {},

    stopVideoCallRecordingRequest: (state) => {},
    stopVideoCallRecordingSuccess: (state, action) => {},
    stopVideoCallRecordingError: (state, action) => {},
  },
});

export const videoCallReducer = videoCallSlice.reducer;
