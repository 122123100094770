import React, { useEffect, useState } from "react";

import { CloseIconButton } from "./../../components";
import { textformat } from "./../../utils";

export interface IBasicModalProps {
  title?: string;
  description?: string;
  open?: boolean;
  width?: number;
  className?: string;
  dialogClassName?: string;
  titleClassName?: string;
  disableBackdrop?: boolean;
  BottomFixedComponent?: React.JSXElementConstructor<React.ComponentProps<any>>;
  onClose?: () => void;
}

export const BasicModal: React.FC<IBasicModalProps> = ({
  children,
  title,
  description,
  open: isOpen,
  className,
  disableBackdrop,
  BottomFixedComponent,
  onClose,
}) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);

    if (onClose) {
      onClose();
    }
  };

  useEffect(() => {
    setOpen(isOpen || false);
  }, [isOpen]);

  return open ? (
    <div
      className={`
        fixed top-[0px] left-[0px] right-[0px] w-screen h-screen flex flex-row justify-center items-center z-[100] p-[25px] box-border
      `}
    >
      <div
        className={`${className} w-full h-auto min-h-[100px] max-h-full flex flex-col bg-primaryDark text-white border-[2px] border-primaryMid rounded-lg overflow-hidden z-50`}
      >
        <div className="sticky h-[85px] top-[0px] left-[0px] right-[0px] flex flex-row justify-between items-center bg-inherit z-30 p-[25px] box-border">
          <div className="relative w-full">
            {title ? (
              <div className={`flex flex-col gap-y-[5px] break-words`}>
                <h2
                  className={`text-base text-white font-semibold leading-none`}
                >
                  {textformat.shortenByChars(title || "", 50)}
                </h2>
                {description && (
                  <span className="text-xs text-secondary font-medium">
                    {description}
                  </span>
                )}
              </div>
            ) : (
              <div></div>
            )}
            <div className="transition-all absolute -top-[7.5px] -right-[7.5px] w-[24px] h-[24px] flex flex-row justify-center items-center box-border p-[5px] rounded-full cursor-pointer hover:bg-primaryLight ">
              <CloseIconButton iconProps={{ size: 14 }} onClick={handleClose} />
            </div>
          </div>
        </div>
        <div className="basis-full w-full p-[25px] pt-[0px] box-border z-20 bg-primaryDark overflow-y-scroll">
          {children}
        </div>
        {BottomFixedComponent && <BottomFixedComponent />}
      </div>

      <div
        className={`
            ${disableBackdrop ? "" : "backdrop-blur-sm"}
            absolute w-full h-full z-40`}
        onClick={handleClose}
      ></div>
    </div>
  ) : (
    <></>
  );
};

BasicModal.defaultProps = {
  open: false,
  className: "max-w-[480px]",
  dialogClassName: "",
  titleClassName: "",
  disableBackdrop: false,
  BottomFixedComponent: () => <></>,
};
