import { createSlice } from "@reduxjs/toolkit";
import { AppReducerState } from "../../store";
import {
  appendToStore,
  appendToStoreEntity,
  popFromStore,
  StoreManager,
} from "../../storeManager";

interface IJobApplicationState {
  jobApplications: AppReducerState<any[]>;
  jobApplication: AppReducerState<any>;
  userJobApplications: AppReducerState<any[]>;
}

const initialState: IJobApplicationState = {
  jobApplications: {
    isLoading: false,
    results: 0,
    data: [],
    error: null,
  },
  jobApplication: {
    isLoading: false,
    data: {},
    error: null,
  },
  userJobApplications: {
    isLoading: false,
    results: 0,
    data: [],
    error: null,
  },
};

export const jobApplicationSlice = createSlice({
  name: "JobApplication",
  initialState,
  reducers: {
    appendJobApplications: appendToStore("jobApplications"),
    popJobApplications: popFromStore("jobApplications"),

    getJobApplicationsRequest: (state) => {
      state.jobApplications.isLoading = true;
    },
    getJobApplicationsSuccess: (state, action) => {
      const { results, data } = action.payload || {};

      state.jobApplications.isLoading = false;
      state.jobApplications.results = results;
      state.jobApplications.data = data;
    },
    getJobApplicationsError: (state, action) => {
      state.jobApplications.isLoading = false;
      state.jobApplications.error = action.payload;
    },

    getJobApplicationRequest: (state) => {
      state.jobApplication.isLoading = true;
    },
    getJobApplicationSuccess: (state, action) => {
      state.jobApplication.isLoading = false;
      state.jobApplication.data = action.payload;
    },
    getJobApplicationError: (state, action) => {
      state.jobApplication.isLoading = false;
      state.jobApplication.error = action.payload;
    },

    createJobApplicationRequest: (state) => {
      state.jobApplication.isLoading = true;
    },
    createJobApplicationSuccess: (state, action) => {
      state.jobApplication.isLoading = false;
    },
    createJobApplicationError: (state, action) => {
      state.jobApplication.isLoading = false;
      state.jobApplication.error = action.payload;
    },

    updateJobApplicationRequest: (state) => {
      state.jobApplication.isUpdating = true;
    },
    updateJobApplicationSuccess: (state, action) => {
      state.jobApplication.isUpdating = false;
    },
    updateJobApplicationError: (state, action) => {
      state.jobApplication.isUpdating = false;
      state.jobApplication.error = action.payload;
    },

    removeJobApplicationRequest: (state) => {
      state.jobApplication.isLoading = true;
    },
    removeJobApplicationSuccess: (state, action) => {
      state.jobApplication.data = null;
      state.jobApplication.isLoading = false;
    },
    removeJobApplicationError: (state, action) => {
      state.jobApplication.error = action.payload;
      state.jobApplication.isLoading = false;
    },

    appendJobApplicationNote: appendToStoreEntity("jobApplication", "notes"),

    getJobApplicationNotesRequest: (state) => {},
    getJobApplicationNotesSuccess: (state, action) => {},
    getJobApplicationNotesError: (state, action) => {},

    addJobApplicationNoteRequest: () => {},
    addJobApplicationNoteSuccess: () => {},
    addJobApplicationNoteError: () => {},

    updateJobApplicationNoteRequest: (state) => {},
    updateJobApplicationNoteSuccess: StoreManager.insertChild({
      parent: "jobApplications",
      child: "notes",
      parentKey: "jobApplicationId",
    }),
    updateJobApplicationNoteError: (state, action) => {},

    removeJobApplicationNoteRequest: (state) => {},
    removeJobApplicationNoteSuccess: (state, action) =>
      StoreManager.pop(state, action.payload),

    // StoreManager.removeChild({
    //   parent: "jobApplications",
    //   child: "notes",
    //   parentKey: "jobApplicationId",
    // }),
    removeJobApplicationNoteError: (state, action) => {},

    getUserRecentJobApplicationsRequest: (state) => {
      state.userJobApplications.isLoading = true;
    },
    getUserRecentJobApplicationsSuccess: (state, action) => {
      const { results, data } = action.payload || {};

      state.userJobApplications.results = results;
      state.userJobApplications.data = data;
      state.userJobApplications.isLoading = false;
    },
    getUserRecentJobApplicationsError: (state, action) => {
      state.jobApplication.error = action.payload;
      state.userJobApplications.isLoading = false;
    },

    setInterviewToJobApplication: (state, action) => {
      const interview = action.payload;

      state.jobApplication.data = {
        ...state.jobApplication.data,
        interviews: [interview]?.filter((i) => !!i),
      };
    },
  },
});

export const jobApplicationReducer = jobApplicationSlice.reducer;
