import {} from "react-redux";

export const useScroll = () => {
  const scrollTop = () => {
    window?.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const scrollToRef = (
    ref: React.RefObject<HTMLDivElement>,
    options?: ScrollIntoViewOptions
  ) => {
    if (!ref?.current) return;

    ref.current.scrollIntoView(options);
  };

  return { scrollTop, scrollToRef };
};
