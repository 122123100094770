import React from "react";

import { FiSearch as SearchIcon } from "react-icons/fi";

import { ITextFieldProps, TextField } from "./../../components";
import { useDebounce } from "../../hooks";

interface ISearchbarProps extends ITextFieldProps {
  className?: string;
  iconClassName?: string;
  id?: string;
  name?: string;
  value?: string | null;
  placeholder?: string;
  onSubmit?: () => void;
}

export const Searchbar: React.FC<ISearchbarProps> = ({
  className,
  iconClassName,
  id,
  name,
  value,
  errorMessage,
  placeholder,
  onChange,
  onSubmit,
}) => {
  // const handleChange = useDebounce(
  //   onChange
  //     ? onChange
  //     : () => {
  //         console.log("empty");
  //       }
  // );

  // const handleSubmit = useDebounce(onSubmit ? onSubmit : () => {});

  return (
    <div
      className={`${className} relative w-full h-[45px] flex flex-row bg-primaryLight rounded-lg overflow-hidden`}
    >
      <TextField
        type="text"
        variant="standard"
        className="!h-full"
        inputClassName="w-full !h-full flex flex-row items-center justify-center rounded-lg text-sm !bg-transparent !text-white border-[0px] border-box pr-[40px]"
        {...{ id, name, value, errorMessage, placeholder }}
        onChange={onChange}
      />
      <div className="absolute w-[40px] h-full bg-transparent top-[0px] right-[0px] flex flex-row justify-center items-center cursor-pointer">
        <SearchIcon
          size={16}
          className={`${iconClassName} transition-all text-secondary hover:text-white`}
          onClick={onSubmit ? () => onSubmit() : () => {}}
        />
      </div>
    </div>
  );
};

Searchbar.defaultProps = {
  placeholder: "Search something...",
};
