import React, { useEffect } from "react";

import { Container, DataList, PageHeader } from "../../components";
import { PostCard, PostCreateForm } from "./../../features";

import { getPosts } from "../../store";

import {
  useAppDispatch,
  useAppSelector,
  useDrawer,
  useQueryParams,
} from "../../hooks";

export const NewsContainer = () => {
  const dispatch = useAppDispatch();

  const { queryParams, setQueryParams, isQueryParams } = useQueryParams();

  const { page } = queryParams;

  const {
    posts: { data, results, isLoading, isLoadingMore, pageSize, pageCount },
  } = useAppSelector((state) => state.post);

  const handleLoad = async () => {
    const nextPage = page ? page + 1 : 1;

    setQueryParams({ page: nextPage });
  };

  useEffect(() => {
    console.log({ queryParams });

    if (!isQueryParams) {
      setQueryParams({ ...queryParams, page: 1 });
      return;
    }

    if (!page) return;

    // if (!page) {
    //   setQueryParams({ ...queryParams, page: 1 });
    //   return;
    // }

    const actionMode = page > 1 ? "append" : "get";

    dispatch(getPosts(queryParams, actionMode));
  }, [queryParams]);

  return (
    <Container loading={isLoading}>
      <PageHeader back={false}>News</PageHeader>
      <PostCreateForm isPublic={true} />
      <div className="mt-[15px]">
        <DataList
          data={data}
          loading={isLoadingMore}
          results={results}
          page={page}
          pageSize={pageSize}
          pageCount={pageCount}
          ElementComponent={PostCard}
          onLoad={handleLoad}
        />
      </div>
    </Container>
  );
};
