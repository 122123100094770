import { createSlice } from "@reduxjs/toolkit";

import { IVerification } from "../../../shared";
import { AppReducerState } from "../../store";
import { appendToStore, popFromStore } from "../../storeManager";

interface IInterviewState {
  interviews: AppReducerState<any[]>;
  interview: AppReducerState<any>;
}

const initialState: IInterviewState = {
  interviews: {
    isLoading: false,
    results: 0,
    data: [],
    error: null,
  },
  interview: {
    isLoading: false,
    data: {},
    error: null,
  },
};

export const interviewSlice = createSlice({
  name: "Interview",
  initialState,
  reducers: {
    getInterviewsRequest: (state) => {
      state.interviews.isLoading = true;
    },
    getInterviewsSuccess: (state, action) => {
      const { data, results } = action.payload;

      state.interviews.isLoading = false;
      state.interviews.data = data;
      state.interviews.results = results;
    },
    getInterviewsError: (state, action) => {
      state.interviews.isLoading = false;
      state.interviews.error = action.payload;
    },

    getInterviewByIdRequest: (state) => {
      state.interview.isLoading = true;
    },
    getInterviewByIdSuccess: (state, action) => {
      const { data } = action.payload;

      state.interview.isLoading = false;
      state.interview.data = data;
    },
    getInterviewByIdError: (state, action) => {
      state.interview.isLoading = false;
      state.interview.error = action.payload;
    },

    createInterviewRequest: (state) => {
      state.interviews.isLoading = true;
    },
    createInterviewSuccess: (state, action) => {
      const { data } = action.payload;

      state.interviews.isLoading = false;
      state.interviews.data = [...state.interviews.data, data];
    },
    createInterviewError: (state, action) => {
      state.interviews.isLoading = false;
      state.interviews.error = action.payload;
    },

    updateInterviewRequest: (state) => {
      state.interview.isLoading = true;
    },
    updateInterviewSuccess: (state, action) => {
      state.interview.isLoading = false;
      state.interview.data = action.payload;
    },
    updateInterviewError: (state, action) => {
      state.interview.isLoading = false;
      state.interview.error = action.payload;
    },

    removeInterviewRequest: (state) => {
      state.interview.isLoading = true;
    },
    removeInterviewSuccess: (state, action) => {
      const { data } = action.payload;

      state.interview.isLoading = false;
      state.interview.data = {};

      state.interviews.isLoading = false;
      state.interviews.data =
        state.interviews.data?.length > 0
          ? state.interviews.data?.filter(({ id }) => id !== data?.id)
          : [];
    },
    removeInterviewError: (state, action) => {
      state.interview.isLoading = false;
      state.interview.error = action.payload;
    },

    startInterviewRequest: (state) => {},
    startInterviewSuccess: (state, action) => {},
    startInterviewError: (state, action) => {},
  },
});

export const interviewReducer = interviewSlice.reducer;
