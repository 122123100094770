import React from "react";

import {
  MdVideocam as VideoCameraIcon,
  MdVideocamOff as VideoCameraOffIcon,
  MdOutlineMic as MicIcon,
  MdOutlineMicOff as MicOffIcon,
  MdOutlineMonitor as MonitorIcon,
} from "react-icons/md";
import { FiSettings as SettingsIcon } from "react-icons/fi";
import { RiRecordCircleFill as RecordIcon } from "react-icons/ri";

import { Button, IconButton } from "../../components";

interface IVideoCallControlBarProps {
  video?: boolean;
  audio?: boolean;
  screenSharing?: boolean;
  recording?: boolean;
  hidden?: boolean;
  onLeave?: () => void;
  onVideoToggle?: () => void;
  onAudioToggle?: () => void;
  onScreenSharingToggle?: () => void;
  onRecordingToggle?: () => void;
  onSettingsToggle?: () => void;
}

export const VideoCallControlBar: React.FC<IVideoCallControlBarProps> = ({
  video,
  audio,
  screenSharing,
  recording,
  hidden,
  onLeave,
  onVideoToggle,
  onAudioToggle,
  onScreenSharingToggle,
  onRecordingToggle,
  onSettingsToggle,
}) => {
  return (
    <div
      className={`
      transition-all flex-row justify-center items-center p-[5px] box-border z-30
      ${hidden ? "hidden" : "flex"}
    `}
    >
      <div className="w-full max-w-[360px] h-[55px] flex flex-row justify-between items-center rounded-lg p-[10px] box-border">
        <div className="w-full flex flex-row justify-center items-center gap-[15px] select-none">
          <IconButton
            className={`
              cursor-pointer
              ${
                !video
                  ? "text-secondary hover:brightness-125"
                  : "text-white hover:brightness-75"
              }
            `}
            icon={!video ? VideoCameraOffIcon : VideoCameraIcon}
            iconProps={{
              size: 22,
            }}
            onClick={onVideoToggle}
          />
          <IconButton
            className={`
                cursor-pointer
                ${
                  !audio
                    ? "text-secondary hover:brightness-125"
                    : "text-white hover:brightness-75"
                }
            `}
            icon={!audio ? MicOffIcon : MicIcon}
            iconProps={{
              size: 22,
            }}
            onClick={onAudioToggle}
          />
          <IconButton
            className={`
                cursor-pointer
                ${
                  screenSharing
                    ? "text-primary hover:brightness-125"
                    : "text-white hover:brightness-75"
                }
            `}
            icon={screenSharing ? MonitorIcon : MonitorIcon}
            iconProps={{
              size: 22,
            }}
            onClick={onScreenSharingToggle}
          />
          <IconButton
            className={`
                cursor-pointer
                ${
                  recording
                    ? "text-danger hover:brightness-125"
                    : "text-white hover:brightness-75"
                }
            `}
            icon={RecordIcon}
            iconProps={{
              size: 22,
            }}
            onClick={onRecordingToggle}
          />

          <SettingsIcon
            size={18}
            className="text-white cursor-pointer hover:brightness-75"
            onClick={onSettingsToggle}
          />
        </div>
        <div className="flex flex-row items-center gap-[10px]">
          <Button
            variant="outlined"
            color="danger"
            className="min-w-[150px]"
            onClick={onLeave}
          >
            Leave
          </Button>
        </div>
      </div>
    </div>
  );
};

VideoCallControlBar.defaultProps = {
  video: false,
  audio: false,
  screenSharing: false,
  hidden: false,
};
