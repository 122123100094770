import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Fade } from "@mui/material";

import { getAccount, syncAccount } from "../../store";
import { ROUTER } from "../../routes";
import { FallbackLoader } from "..";
import { useAppDispatch, useLoading } from "../../hooks";

export const AuthProvider = ({ children }) => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const location = useLocation();
  const [loading, setLoading] = useLoading();

  const PUBLIC_ROUTES = [
    ROUTER.ROOT,
    ROUTER.FAQ,
    ROUTER.LOGIN,
    ROUTER.PASSWORD_RESET,
    ROUTER.INVITE_DETAIL(),
  ];

  const matchRoutes = (routes) =>
    routes?.some((route) => location.pathname === route);

  const validateAccount = async () => {
    try {
      setLoading(true);

      // Checking Account
      const account = await dispatch(getAccount());
      const isAuthenticated = account?.id ? true : false;

      // Checking Access
      const isLoginRoute = matchRoutes([ROUTER.LOGIN]);
      const isPublicRoute = matchRoutes(PUBLIC_ROUTES);

      if (isAuthenticated && isPublicRoute)
        return setTimeout(() => redirect(ROUTER.HOME), 0);

      if (isAuthenticated && isLoginRoute)
        return setTimeout(() => redirect(ROUTER.HOME), 0);

      if (!isAuthenticated && !isPublicRoute)
        return setTimeout(() => redirect(ROUTER.ROOT), 0);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    validateAccount();
  }, []);

  return loading ? (
    <FallbackLoader className="text-red-400" />
  ) : (
    <Fade in={false}>{children}</Fade>
  );
};
