import { createSlice } from "@reduxjs/toolkit";

import {
  AppActionState,
  appendToStore,
  AppReducerState,
  popFromStore,
} from "../../../store";
import { IPost } from "../../../shared";

interface IPostState {
  posts: AppReducerState<IPost[]>;
  post: AppReducerState<IPost>;
}

const initialState: IPostState = {
  posts: {
    isLoading: false,
    data: [],
    results: 0,
    page: 0,
    pageSize: 0,
    pageCount: 0,
    error: null,
  },
  post: {
    isLoading: false,
    isUpdating: false,
    data: {},
    error: null,
  },
};

export const postSlice = createSlice({
  name: "Post",
  initialState,
  reducers: {
    appendPosts: appendToStore("posts"),
    popPosts: popFromStore("posts"),

    getPostsRequest: (state, action: AppActionState) => {
      const { mode } = action.payload;

      switch (mode) {
        case "get":
          state.posts.isLoading = true;
          state.posts.data = [];
          state.posts.results = 0;
          break;
        case "append":
          state.posts.isLoadingMore = true;
          break;
      }
    },
    getPostsSuccess: (state, action: AppActionState<IPost[]>) => {
      const {
        mode = "get",
        data,
        results = 0,
        page = 1,
        pageSize,
        pageCount,
      } = action.payload;

      switch (mode) {
        case "get":
          state.posts.isLoading = false;
          state.posts.data = data || [];
          state.posts.results = results;
          state.posts.page = page;
          state.posts.pageSize = pageSize;
          state.posts.pageCount = pageCount;

          break;
        case "append":
          state.posts.isLoadingMore = false;
          state.posts.results = (state.posts.results || 0) + results;
          state.posts.page = page;
          state.posts.data = [...state.posts.data, ...(data || [])];

          break;
      }
    },
    getPostsError: (state, action: AppActionState) => {
      const { mode, error } = action.payload;

      state.posts.error = error;

      state.posts.isLoading = false;
      state.posts.isLoadingMore = false;
    },

    // getPostsRequest: (state) => {
    //   state.posts.isLoading = true;
    //   state.posts.data = [];
    //   state.posts.results = 0;
    // },
    // getPostsSuccess: (state, action) => {
    //   const { data, results } = action.payload;

    //   state.posts.isLoading = false;
    //   state.posts.data = data;
    //   state.posts.results = results;
    // },
    // getPostsError: (state, action) => {
    //   state.posts.isLoading = false;
    //   state.posts.data = [];
    //   state.posts.results = 0;
    //   state.posts.error = action.payload;
    // },

    getPostFeedRequest: (state) => {
      state.posts.isLoading = true;
    },
    getPostFeedSuccess: (state, action) => {
      const { data, results } = action.payload;

      state.posts.isLoading = false;
      state.posts.data = data;
      state.posts.results = results;
    },
    getPostFeedError: (state, action) => {
      state.posts.isLoading = false;
      state.posts.error = action.payload;
    },

    getPostRequest: (state) => {
      state.post.isLoading = true;
    },
    getPostSuccess: (state, action) => {
      state.post.isLoading = false;
      state.post.data = action.payload;
    },
    getPostError: (state, action) => {
      state.post.isLoading = false;
      state.post.error = action.payload;
    },

    createPostRequest: (state) => {
      state.post.isLoading = true;
    },
    createPostSuccess: (state, action) => {
      state.post.isLoading = false;
    },
    createPostError: (state, action) => {
      state.post.isLoading = false;
      state.post.error = action.payload;
    },

    updatePostRequest: (state) => {
      state.post.isUpdating = true;
    },
    updatePostSuccess: (state, action) => {
      state.post.isUpdating = false;
    },
    updatePostError: (state, action) => {
      state.post.isUpdating = false;
      state.post.error = action.payload;
    },

    removePostRequest: (state) => {
      state.post.isLoading = true;
    },
    removePostSuccess: (state, action) => {
      state.post.data = {};
      state.post.isLoading = false;
    },
    removePostError: (state, action) => {
      state.post.error = action.payload;
      state.post.isLoading = false;
    },

    likePostRequest: (state) => {},
    likePostSuccess: (state, action) => {},
    likePostError: (state, action) => {},

    dislikePostRequest: (state) => {},
    dislikePostSuccess: (state, action) => {},
    dislikePostError: (state, action) => {},

    getPostCommentsRequest: (state) => {},
    getPostCommentsSuccess: (state, action) => {},
    getPostCommentsError: (state, action) => {},

    addPostCommentRequest: (state) => {},
    addPostCommentSuccess: (state, action) => {},
    addPostCommentError: (state, action) => {},

    // removePostCommentRequest: (state) => {},
    // removePostCommentSuccess: (state, action) => {},
    // removePostCommentError: (state, action) => {},
  },
});

export const postReducer = postSlice.reducer;
