import React, { useRef, useEffect } from "react";

import { ModalType } from "../../../shared";
import {
  Container,
  FullScreenLoader,
  Loader,
  Modal,
} from "../../../components";

import { useLoading, useModal } from "../../../hooks";

export const LicenseAgreementModal = () => {
  const { modal, isOpen } = useModal({
    type: ModalType.LICENSE_AGREEMENT,
  });

  const agreementRef = useRef<HTMLObjectElement>(null);
  const [agreementLoading, setAgreementLoading] = useLoading(true);

  const { license, onSubmit } = modal?.props;

  const agreementSrc = license?.agreementSrc;

  return (
    <Modal
      title="License Agreement"
      className="!w-full !max-w-[760px]"
      open={isOpen}
    >
      <Container className="h-[640px]">
        {agreementLoading && (
          <div className="absolute top-[0px] w-full h-full bg-primaryDark flex flex-row justify-center items-center">
            <Loader className="text-secondary" />
          </div>
        )}
        <object
          ref={agreementRef}
          data={agreementSrc}
          className="w-full h-[640px]"
          onLoad={() => {
            setTimeout(() => {
              setAgreementLoading(false);
            }, 500);
          }}
        >
          Agreement Document
        </object>
      </Container>
    </Modal>
  );
};
