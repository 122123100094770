import { LicenseDurationType, LicenseType } from "./../shared";

export const LICENSE_TYPE_SELECT = [
  { id: LicenseType.Exclusive, value: "Exclusive" },
  { id: LicenseType.NonExclusive, value: "Non-exclusive" },
];

export const LICENSE_DURATION_TYPE_SELECT = [
  { id: LicenseDurationType.Day, value: "Days" },
  { id: LicenseDurationType.Week, value: "Weeks" },
  { id: LicenseDurationType.Month, value: "Months" },
  { id: LicenseDurationType.Year, value: "Years" },
];
