import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { FieldArray, useFormik } from "formik";
import Markdown from "react-markdown";

import { TbInfinity as InfinityIcon } from "react-icons/tb";

import { LicenseType, ModalType, NotificationStatus } from "../../../shared";

import {
  Button,
  Checkbox,
  Container,
  Form,
  Link,
  Modal,
  TextField,
} from "../../../components";
import {
  ILicenseOptionsForm,
  LicensePurchaseFormValidationSchema,
  LicensePurchaseFormInitialValues,
} from "../../../features";

import {
  useAccount,
  useAppDispatch,
  useAppSelector,
  useBasicModal,
  useDebounce,
  useLoading,
  useModal,
  useToast,
} from "../../../hooks";
import {
  calculateLicensePurchaseTotal,
  getDigitalAssetById,
  getLicenseById,
  purchaseLicense,
} from "../../../store";
import { FormValidator, textformat } from "../../../utils";
import { ROUTER } from "../../../routes";
import { getDocumentByType } from "store/reducers/document";

export const LicensePurchaseModal = () => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const { modal, closeModal, isOpen } = useModal({
    type: ModalType.LICENSE_PURCHASE,
  });

  const { toast } = useToast();
  const { activeProfile } = useAccount();

  const [loading, setLoading] = useLoading();
  const [calculating, setCalculating] = useLoading(false);

  const [fees, setFees] = useState({
    price: 0,
    serviceFee: 0,
    paymentFee: 0,
    total: 0,
  });
  const [total, setTotal] = useState(0);
  const [licensing, setLicensing] = useState(true);
  const [termsAccepted, setTermsAccepted] = useState(false);

  const [termsDocument, setTermsDocument] = useState({
    name: "",
    markdown: "",
  });
  const [termsDocumentLoading, setTermsDocumenLoading] = useLoading();

  const { Component: TermsModal, setModal: setTermsModal } = useBasicModal();

  const {
    digitalAsset: { data: digitalAsset, isLoading: digitalAssetLoading },
  } = useAppSelector((state) => state.digitalPortfolio);

  const {
    license: { data: license, isLoading: licenseLoading },
  } = useAppSelector((state) => state.license);

  const { marketplaceItem } = modal.props;
  const { id, name, description } = digitalAsset;

  const licenseId = digitalAsset?.license?.id;
  const profileId = activeProfile?.id;

  const form = useFormik<ILicenseOptionsForm>({
    validationSchema: LicensePurchaseFormValidationSchema,
    initialValues: { ...LicensePurchaseFormInitialValues },
    onSubmit: async (values) => {
      const { duration, postLimits } = values;
      if (!duration) return;

      setLoading(true);

      // Preparing the redirect links
      const href = window.location.href;
      const successUrl = `${href}?license_status=success`;
      const cancelUrl = `${href}?license_status=error`;

      // Creating the payment checkout
      const paymentCheckout = await dispatch(
        purchaseLicense({
          licenseId,
          duration,
          postLimits,
          payment: {
            successUrl,
            cancelUrl,
          },
        })
      );
      if (!paymentCheckout?.url) {
        setLoading(false);
        toast({ status: NotificationStatus.Error, message: "Payment failed." });

        return;
      }

      window.location.replace(paymentCheckout.url);
      setLoading(false);
    },
  });

  const validator = new FormValidator<ILicenseOptionsForm>(form);

  const {
    values,
    errors,
    touched,
    resetForm,
    isValid,
    setFieldValue,
    handleChange,
    handleSubmit,
  } = form;

  const { duration } = values || {};
  const { createdById } = digitalAsset || {};
  const { isPostControl, isCommercialUsage, postLimits } = license || {};
  const paymentDisabled = !termsAccepted || !duration;

  const calculateTotal = useDebounce(async ({ licenseId, duration }) => {
    if (!licenseId || !duration) return;

    setCalculating(true);

    const result = await dispatch(
      calculateLicensePurchaseTotal(licenseId, { duration })
    );
    if (!result) return;

    const { price, serviceFee, paymentFee, total } = result;

    setFees({ ...total, price, serviceFee, paymentFee, total });
    setCalculating(false);
  });

  const handleTermsModal = async () => {
    setTermsModal(true);

    const licenseeName = `${license?.owner?.firstName} ${license?.owner?.lastName} `;
    const licensorName = `${activeProfile?.firstName} ${activeProfile?.lastName}`;
    const licensePrice = fees.price || 0;
    const licenseDuration = duration || 0;

    let type: string | null = "";

    switch (license?.type) {
      case LicenseType.Exclusive:
        if (isPostControl) {
          type = "EXCLUSIVE_LICENSE_SOCIAL_MEDIA_AGREEMENT";
        }

        if (isCommercialUsage) {
          type = "EXCLUSIVE_LICENSE_COMMERCIAL_AGREEMENT";
        }

        if (!isPostControl && !isCommercialUsage) {
          type = "EXCLUSIVE_LICENSE_SOCIAL_MEDIA_AGREEMENT";
        }

        break;
      case LicenseType.NonExclusive:
        if (isPostControl) {
          type = "NON_EXCLUSIVE_LICENSE_SOCIAL_MEDIA_AGREEMENT";
        }

        if (isCommercialUsage) {
          type = "NON_EXCLUSIVE_LICENSE_COMMERCIAL_AGREEMENT";
        }

        if (!isPostControl && !isCommercialUsage) {
          type = "NON_EXCLUSIVE_LICENSE_SOCIAL_MEDIA_AGREEMENT";
        }

        console.log({
          isPostControl,
          isCommercialUsage,
          type,
          test: Date.now(),
        });

        break;
      default:
        type = null;
        break;
    }

    if (!type) {
      setTermsModal(false);
      return;
    }

    setTermsDocumenLoading(true);

    const document = await dispatch(
      getDocumentByType(type, {
        licensee: licenseeName,
        licensor: licensorName,
        license_price: licensePrice,
        license_duration: licenseDuration,
      })
    );
    if (!document) {
      setTermsModal(false);
      setTermsDocumenLoading(false);
      return;
    }

    setTermsDocument(document);
    setTermsDocumenLoading(false);
  };

  useEffect(() => {
    calculateTotal({ licenseId, duration });
  }, [duration]);

  useEffect(() => {
    setFieldValue(
      "postLimits",
      license?.postLimits?.filter((i) => !!i?.enabled) || [],
      true
    );
  }, [license?.postLimits]);

  useEffect(() => {
    if (!marketplaceItem) return;

    dispatch(getDigitalAssetById(marketplaceItem));
  }, [marketplaceItem]);

  useEffect(() => {
    if (!licenseId) return;

    dispatch(getLicenseById(licenseId));
  }, [licenseId]);

  return (
    <>
      <Modal
        title="License Content"
        description="Fill in the form below to license this digital asset"
        className="!w-full !max-w-[480px]"
        open={isOpen}
        onClose={closeModal}
      >
        <Container loading={licenseLoading}>
          <div className="relative w-full h-auto">
            <div className="flex flex-row gap-[20px]">
              <div className="basis-4/12">
                <div className="w-full w-[120px] h-[120px] select-none rounded-lg overflow-hidden">
                  <img
                    className="w-full h-auto aspect-square"
                    src={digitalAsset.previewSrc}
                    alt=""
                  />
                </div>
              </div>

              <div className="basis-8/12 flex flex-col justify-center items-start gap-[10px]">
                <div className="w-full flex flex-col">
                  <span className="text-white text-base font-semibold">
                    Name
                  </span>
                  <p className="text-secondary text-sm">
                    {textformat.shortenByChars(name || "", 15)}
                  </p>
                </div>
                <div className="w-full flex flex-col">
                  <span className="text-white text-base font-semibold">
                    License Type
                  </span>
                  <p className="text-secondary text-sm capitalize">
                    {license?.type}
                  </p>
                </div>
              </div>
            </div>

            {licensing ? (
              <div className="flex flex-row w-full">
                <Form formik={form} onSubmit={handleSubmit}>
                  <div className="mt-[25px] w-full">
                    <div className="flex flex-col gap-[10px]">
                      <div className="flex gap-[10px] xxs:flex-col md:flex-row">
                        <TextField
                          id="duration"
                          label="Duration (Days)"
                          type="number"
                          variant="filled"
                          className="bg-primaryLight text-white border border-solid border-primaryMid"
                          labelClassName="text-secondary"
                          maxLength={3}
                          value={values?.duration}
                          errorMessage={validator.isInputValid("duration")}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    {isPostControl && (
                      <div className="mt-[25px]">
                        <h2 className="text-white text-base font-medium">
                          Post Limits
                        </h2>
                        <div className="flex flex-col mt-[10px] gap-[10px]">
                          <FieldArray
                            name="postLimits"
                            render={(helpers) => {
                              const { postLimits } = values || {};

                              return !!postLimits?.length ? (
                                postLimits?.map((limit, i) => (
                                  <div className="flex flex-row gap-[10px] justify-between">
                                    <TextField
                                      key={id}
                                      id={`post_limit_${limit?.platform}`}
                                      name={`postLimits[${i}].username`}
                                      label={`${limit?.platform}`}
                                      type="text"
                                      className="bg-primaryLight text-white border border-solid border-primaryMid"
                                      labelClassName="text-secondary"
                                      maxLength={50}
                                      value={values.postLimits?.[i]?.username}
                                      errorMessage={
                                        errors.postLimits?.[i] &&
                                        !!touched.postLimits?.[i]
                                          ? `${limit?.platform} is a required field`
                                          : null
                                      }
                                      onChange={handleChange}
                                    />
                                    <div className="w-[75px] h-[55px] flex flex-col justify-center items-center rounded-lg bg-primaryLight">
                                      <span className="text-sm font-semibold">
                                        {(limit?.limit <= 0 ? (
                                          <InfinityIcon size={18} />
                                        ) : (
                                          limit?.limit
                                        )) || <InfinityIcon size={18} />}
                                      </span>
                                      <span className="text-center text-xxs">
                                        posts
                                      </span>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <></>
                              );
                            }}
                          />
                        </div>
                      </div>
                    )}

                    <div className="flex flex-row justify-start items-center gap-[5px] mt-[25px]">
                      <Checkbox
                        id="terms_accepted"
                        className="mr-[0px]"
                        checked={termsAccepted}
                        customLabel={
                          <div>
                            <span className="text-sm text-secondary">
                              I agree to legitbook{" "}
                              <span
                                className="transition-all text-secondary font-semibold cursor-pointer hover:brightness-125"
                                onClick={handleTermsModal}
                              >
                                term & services
                              </span>
                            </span>
                          </div>
                        }
                        disabled={!isValid}
                        onChange={() => {
                          setTermsAccepted(!termsAccepted);
                        }}
                      />
                    </div>

                    <div className="flex flex-col gap-[5px] mt-[30px]">
                      <div className="flex flex-row justify-between items-center gap-[5px]">
                        <span className="text-base text-secondary font-medium">
                          Price
                        </span>
                        <span className="text-white font-medium">
                          ${fees.price}
                        </span>
                      </div>
                      <div className="flex flex-row justify-between items-center gap-[5px]">
                        <span className="text-base text-secondary font-medium">
                          Service Fee
                        </span>
                        <span className="text-white font-medium">
                          ${fees.serviceFee}
                        </span>
                      </div>
                      <div className="flex flex-row justify-between items-center gap-[5px]">
                        <span className="text-base text-secondary font-medium">
                          Payment Fee
                        </span>
                        <span className="text-white font-medium">
                          ${fees.paymentFee}
                        </span>
                      </div>
                      <div className="flex flex-row justify-between items-center gap-[5px] mt-[10px]">
                        <span className="text-base text-secondary font-medium">
                          Total
                        </span>
                        <span className="text-white font-semibold">
                          ${fees.total}
                        </span>
                      </div>
                    </div>

                    <div className="w-full flex flex-row justify-end mt-[15px]">
                      <Button
                        variant="outlined"
                        color="success"
                        type="submit"
                        className="min-w-[150px]"
                        loading={loading}
                        disabled={paymentDisabled}
                      >
                        Pay Now
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            ) : (
              <></>
            )}
          </div>
        </Container>
      </Modal>
      <TermsModal
        title="Terms of use"
        description="Please read carefully and accept."
        className="max-w-[760px]"
      >
        <Container loading={termsDocumentLoading}>
          <Markdown className="markdown w-full">
            {termsDocument?.markdown}
          </Markdown>
        </Container>
      </TermsModal>
    </>
  );
};
