import React from "react";

import { FiCalendar as CalendarIcon } from "react-icons/fi";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextFieldProps } from "@mui/material";
import { FormikErrors } from "formik";

import { TextField, InputError } from "./../../components";
import { date } from "../../utils";
import { DatePickerProps } from "@mui/lab";

interface IBasicDatePickerProps extends DatePickerProps<any> {
  id?: string;
  label?: string;
  value?: Date | string | null;
  error?:
    | string
    | string[]
    | boolean
    | FormikErrors<any>
    | FormikErrors<any>[]
    | null
    | undefined;
  className?: string;
  renderInput?: (
    props: TextFieldProps
  ) => React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  minDate?: Date;
  maxDate?: Date;
  required?: boolean;
  disabled?: boolean;
  disableFuture?: boolean;
  disablePast?: boolean;
  onChange: (
    date: string | Date | null | undefined,
    keyboardInputValue?: string | undefined
  ) => void;
}

export const BasicDatePicker: React.FC<IBasicDatePickerProps> = ({
  id,
  label,
  value,
  error,
  className,
  minDate,
  maxDate,
  disabled,
  disablePast,
  disableFuture,
  onChange,
  ...props
}) => (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <DatePicker
      // inputFormat="MM-DD-YYYY"
      renderInput={(props: any) => (
        <TextField
          {...props}
          variant="filled"
          errorMessage={error}
          className={className}
          controlClassName="basis-1/2"
          labelClassName="text-secondary"
        />
      )}
      components={{
        OpenPickerIcon: !disabled ? CalendarIcon : () => <></>,
      }}
      {...{
        label,
        value,
        minDate: disablePast ? new Date() : minDate ? minDate : undefined,
        maxDate: disableFuture ? new Date() : maxDate ? maxDate : undefined,
        disabled,
        onChange,
        ...props,
      }}
    />
  </LocalizationProvider>
);

BasicDatePicker.defaultProps = {
  minDate: date().subtract(50, "year").toDate(),
  maxDate: date().add(10, "year").toDate(),
  disabled: false,
  disablePast: false,
  disableFuture: false,
};
