import React, { useEffect, useState } from "react";

import { INotification } from "../../shared";

import { NotificationStatus, WebsocketEvents } from "../../shared";
import { useAccount, useAppDispatch, useToast } from "../../hooks";
import { EventService } from "../../services";
import { setNotifications } from "../../store";

export const EventProvider = () => {
  const dispatch = useAppDispatch();

  const { toast } = useToast();
  const { isAuthenticated, account } = useAccount();

  const handleEvents = async () => {
    await EventService.connect();

    EventService.on("message", (message) => {
      const { event, data, results, refreshedAt } = message || {};

      switch (event) {
        case WebsocketEvents.GET_USER_NOTIFICATIONS:
          dispatch(setNotifications({ data, results, refreshedAt }));
          break;
        case WebsocketEvents.NEW_USER_NOTIFICATION:
          const { status, title, details: message } = data as INotification;
          toast({
            status: status || NotificationStatus.Info,
            title,
            message,
          });
          break;
        default:
          break;
      }
    });
  };

  useEffect(() => {
    if (isAuthenticated) {
      handleEvents();
    }
  }, [account]);

  return <></>;
};
