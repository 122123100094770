import React, { useEffect, useState } from "react";

import { MdInsertDriveFile as AgreementIcon } from "react-icons/md";

import { IDigitalAsset, ILicenseCopy, IProfile, ModalType } from "../../shared";

import {
  Avatar,
  BasicTable,
  Button,
  Container,
  IBasicTable,
  IBasicTableColumn,
  IBasicTableRenderCellParams,
  IBasicTableRow,
  Link,
  PageHeader,
  SocialMediaIconButton,
} from "../../components";
import { Generator, date, textformat, toDecimal } from "../../utils";
import {
  useAppDispatch,
  useAppSelector,
  useModal,
  useQueryParams,
} from "../../hooks";
import { ROUTER } from "../../routes";
import {
  getUserLicenseCopies,
  getUserLicenses,
  getUserSoldLicenseCopies,
} from "../../store";

export const UserLicensesSoldContainer = () => {
  const dispatch = useAppDispatch();

  const { isQueryParams, queryParams, setQueryParams } = useQueryParams();
  const { setModal } = useModal();

  const [table, setTable] = useState<IBasicTable>({ columns: [], rows: [] });

  const {
    licenseCopies: {
      data: licenses,
      results: licensesCount,
      isLoading: licensesLoading,
      page: licensesPage,
      pageCount: licensesPageCount,
    },
  } = useAppSelector((state) => state.license);

  const renderTable = () => {
    const columns: IBasicTableColumn[] = [
      {
        field: "license_licensee",
        headerName: "Licensee",
        sortable: false,
        minWidth: 180,
        valueGetter: (data) => data.row?.licensee,
        renderCell: ({ value }: IBasicTableRenderCellParams<IProfile>) => (
          <Link to={value?.id ? ROUTER.USER_DETAIL(value?.id) : null}>
            <div className="flex flex-row justify-start items-center gap-[10px]">
              <Avatar src={value?.avatar} size={28} />
              <span>
                {value?.firstName} {value?.lastName}
              </span>
            </div>
          </Link>
        ),
      },
      {
        field: "digital_asset",
        headerName: "Asset",
        minWidth: 180,
        valueGetter: (data) => data.row?.digitalAsset,
        renderCell: ({ value }: IBasicTableRenderCellParams<IDigitalAsset>) => (
          <Link to={value?.id ? ROUTER.MARKETPLACE_ITEM(value?.id) : null}>
            <div className="flex flex-row justify-start items-center gap-[10px]">
              <Avatar src={value?.previewSrc} size={28} />
              <span>{textformat.shorten(value?.name, 30)}</span>
            </div>
          </Link>
        ),
      },
      {
        field: "status",
        headerName: "Status",
        sortable: true,
        valueGetter: (data) => data.row?.isActive,
        renderCell: ({
          value: isActive,
        }: IBasicTableRenderCellParams<boolean>) => (
          <span
            className={`capitalize ${
              isActive ? "text-success" : "text-danger"
            }`}
          >
            {isActive ? "Active" : "Expired"}
          </span>
        ),
      },
      {
        field: "revenue",
        headerName: "Revenue (USD)",
        minWidth: 150,
        valueGetter: (data) => data.row?.order?.price,
        renderCell: ({ value }: IBasicTableRenderCellParams<number>) => (
          <span className="">${toDecimal(value) || 0}</span>
        ),
      },
      {
        field: "license_agreement",
        headerName: "Agreement",
        sortable: false,
        valueGetter: (data) => data.row,
        renderCell: ({ value }: IBasicTableRenderCellParams<ILicenseCopy>) =>
          !!value?.agreementSrc ? (
            <AgreementIcon
              size={20}
              className="transition-all text-secondary cursor-pointer hover:brightness-125"
              onClick={() => handleAgreementModal(value)}
            />
          ) : (
            <span>-</span>
          ),
      },
      {
        field: "platforms",
        headerName: "Platforms",
        sortable: false,
        minWidth: 150,
        valueGetter: (data) => data.row?.postLimits,
        renderCell: ({ value }: IBasicTableRenderCellParams) => (
          <div className="flex flex-row justify-start items-center gap-[5px]">
            {!!value?.length ? (
              value?.map(({ platform, username }) => (
                <SocialMediaIconButton
                  platform={platform}
                  username={username}
                  iconProps={{ size: 18 }}
                />
              ))
            ) : (
              <>-</>
            )}
          </div>
        ),
      },
      {
        field: "license_duration",
        headerName: "Duration (Days)",
        sortable: true,
        minWidth: 150,
        valueGetter: (data) => data.row,
        renderCell: ({ value }: IBasicTableRenderCellParams<ILicenseCopy>) =>
          date(value?.finishedAt).diff(value?.startedAt, "d"),
      },
      {
        field: "started_at",
        headerName: "Start Date",
        minWidth: 150,
        valueGetter: (data) => data.row?.startedAt,
        renderCell: ({ value }: IBasicTableRenderCellParams<Date>) =>
          date(value).format("DD/MM/YYYY"),
      },
      {
        field: "finished_at",
        headerName: "Expiration Date",
        minWidth: 150,
        valueGetter: (data) => data.row?.finishedAt,
        renderCell: ({ value }: IBasicTableRenderCellParams<Date>) =>
          date(value).format("DD/MM/YYYY"),
      },
    ]?.map((i) => ({ flex: 2, minWidth: 100, ...i } as IBasicTableColumn));

    setTable({ ...table, columns, rows: licenses });
  };

  const handleAgreementModal = (license) => {
    setModal({ type: ModalType.LICENSE_AGREEMENT, props: { license } });
  };

  const handlePageChange = (page) => {
    if (page <= 0) return;

    setQueryParams({ page });
  };

  useEffect(() => {
    renderTable();
  }, [licenses]);

  useEffect(() => {
    if (!isQueryParams) {
      setQueryParams({ page: 1 });
      return;
    }

    dispatch(getUserSoldLicenseCopies(queryParams));
  }, [queryParams]);

  return (
    <Container>
      <PageHeader backTo={ROUTER.HOME}>
        Sold Licenses ({licensesCount || 0})
      </PageHeader>
      <Container>
        <BasicTable
          columns={table?.columns}
          rows={table?.rows}
          loading={licensesLoading}
          page={licensesPage}
          pageCount={licensesPageCount}
          onPageChange={handlePageChange}
        />
      </Container>
    </Container>
  );
};
