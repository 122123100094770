import React, { useEffect } from "react";
import { useNavigate } from "react-router";

import { useFormik } from "formik";
import * as Yup from "yup";

import {
  ModalType,
  NotificationStatus,
  NotificationType,
  NotificationPriority,
} from "../../../shared";

import {
  useAppDispatch,
  useAppSelector,
  useLoading,
  useModal,
  useToast,
} from "../../../hooks";
import { applyJob, getResumes, inviteEmployee } from "../../../store";

import {
  Button,
  Container,
  Form,
  TextField,
  Modal,
  RadioGroup,
  Radio,
} from "../../../components";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email")
    .required("Email is a required field"),
});

export const InviteEmployeeModal = () => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const { toast } = useToast();
  const { modal, isOpen, setModal, closeModal } = useModal({
    type: ModalType.COMPANY_EMPLOYEE_INVITE,
  });
  const [loading, setLoading] = useLoading();

  const {
    account: { data: account },
  } = useAppSelector((state) => state.account);

  const companyId = account?.ownership?.id;

  const { props } = modal;
  const { onSubmit } = props || {};

  const {
    values,
    touched,
    errors,
    setFieldValue,
    setFieldError,
    resetForm,
    handleChange,
    handleSubmit,
  } = useFormik({
    validationSchema,
    initialValues: {
      email: "",
    },
    onSubmit: async (values) => {
      const { email } = values;

      const data = {
        email,
        companyId,
      };

      setLoading(true);

      const invite = await dispatch(inviteEmployee(data));
      if (!invite) {
        setLoading(false);
        setFieldError("email", `The following user can't be invited!`);
        return;
      }

      if (onSubmit) onSubmit();

      setLoading(false);
      resetForm();
      closeModal();

      toast({
        status: NotificationStatus.Success,
        message: "User Has Been Invited To Your Company!",
      });
    },
  });

  const handleClose = () => {
    closeModal();
  };

  const handleCancel = () => {
    handleClose();
  };

  return (
    <Modal
      title="Invite Member"
      description="You can invite your team members using email."
      open={isOpen}
      onClose={handleClose}
    >
      <Container>
        <Form onSubmit={handleSubmit}>
          <TextField
            id="email"
            label="Email"
            type="text"
            variant="filled"
            className="bg-primaryLight text-white border border-solid border-primaryMid"
            labelClassName="text-secondary"
            value={values?.email}
            errorMessage={touched?.email ? errors?.email : null}
            onChange={handleChange}
          />
          <div className="flex flex-row justify-end items-center gap-x-[10px] mt-5">
            <div className="flex flex-row gap-x-[5px]">
              <Button variant="text" color="secondary" onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                type="submit"
                variant="outlined"
                color="success"
                loading={loading}
                disabled={loading}
              >
                Send Invite
              </Button>
            </div>
          </div>
        </Form>
      </Container>
    </Modal>
  );
};
