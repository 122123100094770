import { ApiService } from "../../../services";
import { AppDispatch, GetRootState, RootState } from "../../store";

import { jobApplicationSlice } from "./job-application.slice";

export const appendJobApplications =
  (payload: any) => async (dispatch: AppDispatch) =>
    dispatch(jobApplicationSlice.actions.appendJobApplications(payload));

export const popJobApplications =
  (payload: any) => async (dispatch: AppDispatch) =>
    dispatch(jobApplicationSlice.actions.popJobApplications(payload));

export const getJobApplications =
  (options: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(jobApplicationSlice.actions.getJobApplicationsRequest());

      const response = await ApiService.post("job-applications/query", options);
      const { results, data } = response.data;

      dispatch(
        jobApplicationSlice.actions.getJobApplicationsSuccess({ results, data })
      );

      return data;
    } catch (e: any) {
      dispatch(
        jobApplicationSlice.actions.getJobApplicationsError(
          e.response?.data?.error
        )
      );
    }
  };

export const getUserRecentJobApplications =
  () => async (dispatch: AppDispatch, getState: GetRootState) => {
    try {
      dispatch(
        jobApplicationSlice.actions.getUserRecentJobApplicationsRequest()
      );

      const state = getState();
      const createdById = state.account.account.data.profileId;

      const response = await ApiService.post(`job-applications/query`, {
        where: { isWithdrawn: false, createdById },
        pagination: {
          limit: 3,
        },
      });
      const { data, results } = response.data;

      dispatch(
        jobApplicationSlice.actions.getUserRecentJobApplicationsSuccess({
          data,
          results,
        })
      );
    } catch (e: any) {
      dispatch(
        jobApplicationSlice.actions.getUserRecentJobApplicationsError(
          e.response?.data?.error
        )
      );
    }
  };

export const getJobApplication =
  (id: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(jobApplicationSlice.actions.getJobApplicationRequest());

      const response = await ApiService.get(`job-applications/${id}`);
      const { data } = response.data;

      dispatch(jobApplicationSlice.actions.getJobApplicationSuccess(data));

      console.log({ data });

      return data;
    } catch (e: any) {
      dispatch(
        jobApplicationSlice.actions.getJobApplicationError(
          e.response?.data?.error
        )
      );
    }
  };

export const getJobApplicationById =
  (jobApplicationId: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(jobApplicationSlice.actions.getJobApplicationRequest());

      const response = await ApiService.get(
        `job-applications/${jobApplicationId}`
      );
      const { data } = response.data;

      dispatch(jobApplicationSlice.actions.getJobApplicationSuccess(data));

      console.log({ data });

      return data;
    } catch (e: any) {
      dispatch(
        jobApplicationSlice.actions.getJobApplicationError(
          e.response?.data?.error
        )
      );
    }
  };

export const createJobApplication =
  (payload: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(jobApplicationSlice.actions.createJobApplicationRequest());

      const response = await ApiService.post("job-applications", payload);
      const { data } = response.data;

      dispatch(jobApplicationSlice.actions.createJobApplicationSuccess(data));
      dispatch(jobApplicationSlice.actions.appendJobApplications(data));

      return data;
    } catch (e: any) {
      dispatch(
        jobApplicationSlice.actions.createJobApplicationError(
          e.response?.data?.error
        )
      );
    }
  };

export const updateJobApplication =
  (id: string, payload: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(jobApplicationSlice.actions.updateJobApplicationRequest());

      // Updating
      const response = await ApiService.put(`job-applications/${id}`, payload);
      const { data } = response.data;

      // Refreshing
      // const refreshed = await dispatch(getJobApplication(id));

      dispatch(jobApplicationSlice.actions.updateJobApplicationSuccess(data));
      // dispatch(jobApplicationSlice.actions.appendJobApplications(refreshed));

      return id;
    } catch (e: any) {
      console.log({ e });
      dispatch(
        jobApplicationSlice.actions.updateJobApplicationError(
          e.response?.data?.error
        )
      );
    }
  };

export const removeJobApplication =
  (id: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(jobApplicationSlice.actions.removeJobApplicationRequest());

      const response = await ApiService.delete(`job-applications/${id}`);
      const { data } = response.data;

      dispatch(jobApplicationSlice.actions.removeJobApplicationSuccess(data));
      dispatch(jobApplicationSlice.actions.popJobApplications({ id }));

      return id;
    } catch (e: any) {
      dispatch(
        jobApplicationSlice.actions.removeJobApplicationError(
          e.response?.data?.error
        )
      );
    }
  };

export const setInterviewToJobApplication =
  (payload: any) => async (dispatch: AppDispatch) => {
    dispatch(jobApplicationSlice.actions.setInterviewToJobApplication(payload));
  };
