import React, { useState } from "react";
import { useNavigate } from "react-router";

import { FiCheck as TickIcon } from "react-icons/fi";

import { IMarketplaceItem } from "../../shared";
import { Card, DeleteIconButton, Link } from "../../components";
import { ROUTER } from "../../routes";
import { useAccount } from "../../hooks";

interface IMarketplaceItemCardProps extends IMarketplaceItem {
  className?: string;
  to?: string;
  alt?: string;
  selected?: boolean;
  selecting?: boolean;
  editing?: boolean;
  onClick?: (id?: string) => void;
  onSelect?: (id?: string) => void;
  onEdit?: (id?: string) => void;
  onDelete?: (id?: string) => void;
}

export const MarketplaceItemCard: React.FC<IMarketplaceItemCardProps> = ({
  id,
  to,
  previewSrc,
  alt,
  createdById,
  selected: _selected,
  selecting,
  editing,
  className,
  onClick,
  onSelect,
  onDelete,
}) => {
  const redirect = useNavigate();

  const { activeProfile } = useAccount();

  const [selected, setSelected] = useState(_selected);

  const isAuthor = activeProfile?.id === createdById;

  return (
    <div className="relative w-full aspect-square bg-primaryLight overflow-hidden">
      {to && (
        <Link
          to={to}
          className="transition-all absolute w-full h-full z-40 cursor-pointer hover:backdrop-brightness-125"
        ></Link>
      )}
      <div className="absolute aspect-square overflow-hidden z-30">
        <img src={previewSrc} className="aspect-square" alt="" />
      </div>
    </div>
  );
};

MarketplaceItemCard.defaultProps = {
  className: "",
  selected: false,
  selecting: false,
  editing: false,
};
