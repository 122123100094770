import React from "react";

import { IFriendRequest } from "../../shared";

import { FriendRequestCard } from "./../../features";
import { CardList, ICardListProps } from "../../components";

interface IFriendRequestListProps extends ICardListProps {
  data?: IFriendRequest[];
}

export const FriendRequestList: React.FC<IFriendRequestListProps> = ({
  className,
  data,
  loading,
}) => (
  <CardList className={className} loading={loading}>
    {data?.map((request) => (
      <FriendRequestCard key={request?.id} {...request} />
    ))}
  </CardList>
);

FriendRequestList.defaultProps = {
  className: "",
  data: [],
  loading: false,
};
