import React from "react";

import { lazyload } from "../../utils";
import { MainExtendedLayout } from "../../layouts";

const { UserLicensesPurchasedContainer, LicenseSidebar } = lazyload(
  () => import("../../features")
);

export const UserLicensesPurchasedPage = () => (
  <MainExtendedLayout leftSidebar={LicenseSidebar}>
    <UserLicensesPurchasedContainer />
  </MainExtendedLayout>
);
