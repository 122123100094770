export enum ModalType {
  EDIT = "EDIT",
  DELETE = "DELETE",

  TERMS_AND_CONDITIONS = "TERMS_AND_CONDITIONS",
  INFO = "INFO",
  FAQ_AVAILABLE_COUNTRIES = "FAQ_AVAILABLE_COUNTRIES",

  // Company
  COMPANY_EMPLOYEE_INVITE = "COMPANY_EMPLOYEE_INVITE",

  // Company Comment
  COMPANY_COMMENT_CREATE = "COMPANY_COMMENT_CREATE",

  // Resume
  RESUME_TEMPLATE_DELETE = "RESUME_TEMPLATE_DELETE",
  RESUME_COVER_DELETE = "RESUME_COVER_DELETE",
  RESUME_EDUCATION_DELETE = "RESUME_EDUCATION_DELETE",
  RESUME_EXPERIENCE_DELETE = "RESUME_EXPERIENCE_DELETE",
  RESUME_DIGITAL_ASSET_ADD = "RESUME_DIGITAL_ASSET_ADD",
  RESUME_DIGITAL_ASSET_PREVIEW = "RESUME_DIGITAL_ASSET_PREVIEW",
  RESUME_DIGITAL_ASSET_DELETE = "RESUME_DIGITAL_ASSET_DELETE",
  RESUME_DIGITAL_ASSET_SELECT_ASSET = "RESUME_DIGITAL_ASSET_SELECT_ASSET",

  // Job
  JOB_CREATE = "JOB_DELETE",
  JOB_DELETE = "JOB_DELETE",
  JOB_APPLY = "JOB_APPLY",
  JOB_WITHDRAW = "JOB_WITHDRAW",

  // Job / Application
  JOB_APPLICATION_MESSAGE = "JOB_APPLICATION_MESSAGE",
  JOB_APPLICATION_CREATE = "",
  JOB_APPLICATION_REMOVE = "",

  // Verification
  VERIFICATION_REQUEST_CREATE = "VERIFICATION_REQUEST_CREATE",

  // Chat
  CHAT_MESSAGE = "CHAT_MESSAGE",
  CHAT_DELETE = "CHAT_DELETE",

  // Post
  POST_CREATE = "POST_CREATE",
  POST_DELETE = "POST_DELETE",

  // Calendar Event
  CALENDAR_EVENT_DETAIL = "CALENDAR_EVENT_DETAIL",
  CALENDAR_EVENT_CREATE = "CALENDAR_EVENT_CREATE",
  CALENDAR_EVENT_EDIT = "CALENDAR_EVENT_EDIT",
  CALENDAR_EVENT_DELETE = "CALENDAR_EVENT_DELETE",

  // Gallery
  GALLERY_UPLOAD = "GALLERY_UPLOAD",
  GALLERY_FILE_EDIT = "GALLERY_FILE_EDIT",
  GALLERY_FILE_DELETE = "GALLERY_FILE_DELETE",

  // Digital Portfolio
  DIGITAL_PORTFOLIO_UPLOAD = "DIGITAL_PORTFOLIO_UPLOAD",
  DIGITAL_ASSET_EDIT = "DIGITAL_ASSET_EDIT",
  DIGITAL_ASSET_DELETE = "DIGITAL_ASSET_DELETE",

  // Interview
  INTERVIEW_SCHEDULE_MODAL = "INTERVIEW_SCHEDULE_MODAL",
  INTERVIEW_DETAIL_MODAL = "INTERVIEW_DETAIL_MODAL",
  INTERVIEW_START_MODAL = "INTERVIEW_START_MODAL",

  TICKET_CREATE = "TICKET_CREATE",

  // Video Call
  VIDEO_CALL_JOIN = "VIDEO_CALL_JOIN",
  VIDEO_CALL_LEAVE = "VIDEO_CALL_LEAVE",
  VIDEO_CALL_SETTINGS = "VIDEO_CALL_SETTINGS",

  // License
  LICENSE_OPTIONS = "LICENSE_OPTIONS",
  LICENSE_PURCHASE = "LICENSE_PURCHASE",
  LICENSE_AGREEMENT = "LICENSE_AGREEMENT",
  LICENSE_PURCHASE_SUCCESS = "LICENSE_PURCHASE_SUCCESS",
  LICENSE_PURCHASE_ERROR = "LICENSE_PURCHASE_ERROR",
  LICENSE_PAYMENT = "LICENSE_PAYMENT",
}
