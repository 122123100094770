import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import { useFormik } from "formik";
import * as Yup from "yup";

import { FiCheckCircle as CheckIcon } from "react-icons/fi";

import { ROUTER } from "../../routes";
import {
  Form,
  TextField,
  Button,
  FormSection,
  Container,
  Avatar,
} from "../../components";
import {
  useAppDispatch,
  useAppSelector,
  useLoading,
  useToast,
} from "../../hooks";
import {
  acceptCompanyEmployeeInvite,
  getInvite,
  rejectCompanyEmployeeInvite,
} from "../../store";
import {
  NotificationStatus,
  NotificationType,
  NotificationPriority,
} from "./../../shared";

export const InviteDetail = () => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const params = useParams();
  const location = useLocation();
  const query = new URLSearchParams(location.search) || {};

  const { inviteId } = params;
  const token = query.get("token");

  const [isAccepted, setAccepted] = useState(false);
  const [isRejected, setRejected] = useState(false);

  const { toast } = useToast();
  const [acceptLoading, setAcceptLoading] = useLoading();
  const [rejectLoading, setRejectLoading] = useLoading();

  const {
    account: { data: account },
  } = useAppSelector((state) => state.account);

  const {
    invite: { data: invite, isLoading },
  } = useAppSelector((state) => state.invite);

  const { role, company } = invite || {};
  const companyId = company?.id;

  const isOwner = account?.ownership?.id ? true : false;
  const isEmployee = account?.employment?.id ? true : false;

  const isEmployed = isOwner || isEmployee;
  const isAvailable =
    !invite?.isAccepted &&
    !invite?.isRejected &&
    invite?.accountId === account?.id;

  const handleAccept = async () => {
    setAcceptLoading(true);

    const invite = await dispatch(
      acceptCompanyEmployeeInvite({ token, inviteId, companyId })
    );
    if (!invite) {
      toast({
        status: NotificationStatus.Error,
        message: "Invite Not Accepted!",
      });
      return setAcceptLoading(false);
    }

    setAccepted(true);
    setAcceptLoading(false);
  };

  const handleReject = async () => {
    setRejectLoading(true);

    await dispatch(rejectCompanyEmployeeInvite({ token, inviteId, companyId }));

    setRejected(true);
    setRejectLoading(false);
  };

  useEffect(() => {
    if (!inviteId) return;

    dispatch(getInvite(inviteId));
  }, [inviteId]);

  return (
    <Container
      loading={isLoading}
      className="max-w-[400px]"
      loadingClassName="flex justify-center items-center"
    >
      {isAvailable ? (
        !isAccepted && !isRejected ? (
          <div className="w-full flex flex-col items-center text-white bg-primaryLight px-[35px] py-[50px] rounded-xl">
            <Avatar size={90} className="bg-primaryMid font-semibold text-3xl">
              {company?.profile?.name}
            </Avatar>
            <div className="w-full max-w-[300px] flex flex-col items-center mt-[30px]">
              <span className="text-lg text-center font-medium">
                Hey,{" "}
                <span className="font-semibold">
                  {account?.profile?.firstName}
                </span>
                ! You've been invited by{" "}
                <span className="font-semibold capitalize">
                  {company?.profile?.name}
                </span>
              </span>

              <p className="text-base text-center mt-[10px]">
                {company?.profile?.name} is inviting you for the position of{" "}
                <span className="font-semibold capitalize">{role?.type}</span>.
                Please accept the invintation to join the team on Legitbook.
              </p>
            </div>

            {isEmployed && (
              <div className="mt-[15px]">
                <p className="text-center text-danger text-sm font-semibold">
                  You can't accept the invite because
                  <br /> you already work for another company.
                </p>
              </div>
            )}
            <div className="flex flex-row justify-center gap-x-[10px] mt-[25px]">
              {isEmployed ? (
                <>
                  <Button
                    color="danger"
                    className="w-[125px]"
                    variant="outlined"
                    onClick={handleReject}
                    loading={rejectLoading}
                  >
                    Reject
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    color="secondary"
                    variant="outlined"
                    onClick={handleReject}
                    loading={rejectLoading}
                  >
                    Reject
                  </Button>
                  <Button
                    color="success"
                    variant="outlined"
                    className="w-[125px]"
                    onClick={handleAccept}
                    loading={acceptLoading}
                  >
                    Accept
                  </Button>
                </>
              )}
            </div>
          </div>
        ) : isRejected ? (
          <InviteRejected {...{ invite, account }} />
        ) : (
          <InviteAccepted {...{ invite, account }} />
        )
      ) : (
        <InviteNotFound {...{ invite }} />
      )}
    </Container>
  );
};

const InviteAccepted = ({ invite }) => (
  <div className="w-full flex flex-col items-center text-white bg-primaryLight px-[35px] py-[50px] rounded-xl">
    <Avatar size={90} className="bg-primaryMid font-semibold text-3xl">
      {invite?.company?.profile?.name}
    </Avatar>
    <div className="w-full max-w-[300px] flex flex-col items-center mt-[30px]">
      <span className="text-lg text-center font-medium">
        Congratulations! Your invite to{" "}
        <span className="font-semibold">{invite?.company?.profile?.name}</span>{" "}
        has been accepted. 🥳
      </span>
    </div>
    <div className="mt-[30px]">
      <Button
        type="submit"
        color="secondary"
        variant="outlined"
        className="min-w-[150px]"
        to={ROUTER.HOME}
      >
        Back to Legitbook
      </Button>
    </div>
  </div>
);

const InviteRejected = ({ invite }) => (
  <div className="w-full flex flex-col items-center text-white bg-primaryLight px-[35px] py-[50px] rounded-xl">
    <Avatar size={90} className="bg-primaryMid font-semibold text-3xl">
      {invite?.company?.profile?.name}
    </Avatar>
    <div className="w-full max-w-[300px] flex flex-col items-center mt-[30px]">
      <span className="text-lg text-center font-medium">
        Your invite to{" "}
        <span className="font-semibold">{invite?.company?.profile?.name}</span>{" "}
        has been rejected. 😥
      </span>
    </div>
    <div className="mt-[30px]">
      <Button
        type="submit"
        color="secondary"
        variant="outlined"
        className="min-w-[150px]"
        to={ROUTER.HOME}
      >
        Back to Legitbook
      </Button>
    </div>
  </div>
);

const InviteNotFound = ({ invite }) => (
  <div className="w-full flex flex-col items-center text-white bg-primaryLight px-[35px] py-[50px] rounded-xl">
    {/* <Avatar size={90} className="bg-primaryMid font-semibold text-3xl">
      {invite?.company?.profile?.name}
    </Avatar> */}
    <div className="w-full max-w-[300px] flex flex-col items-center mt-[30px]">
      <span className="text-lg text-center font-medium">
        The invite you're looking for is invalid or expired. 😥
      </span>
    </div>
    <div className="mt-[30px]">
      <Button
        type="submit"
        color="secondary"
        variant="outlined"
        className="min-w-[150px]"
        to={ROUTER.HOME}
      >
        Back to Legitbook
      </Button>
    </div>
  </div>
);
