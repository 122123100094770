import React, { useEffect, useMemo } from "react";

import { IExperience } from "../../../../shared";

import { Checkbox } from "../../../../components";
import {
  IResumeSectionSelectProps,
  ResumeExperienceCard,
  ResumeSectionCard,
  ResumeSectionCardBody,
} from "../../../../features";
import { getExperiences } from "../../../../store";
import { useAppDispatch, useAppSelector } from "../../../../hooks";

export interface IResumeExperienceSectionSelectProps
  extends IResumeSectionSelectProps<IExperience> {}

export const ResumeExperienceSectionSelect: React.FC<
  IResumeExperienceSectionSelectProps
> = ({ selected, setSelected, isChecked, onChange }) => {
  const dispatch = useAppDispatch();

  const {
    account: { data: account },
  } = useAppSelector((state) => state.account);
  const { resume } = useAppSelector((state) => state.resume);
  const { experiences } = useAppSelector((state) => state.experience);

  const profile = account?.profile;
  const profileId = profile?.id;
  const resumeId = resume?.data?.id;
  const resumeExperiences = resume?.data?.experiences || [];

  const archivedExperiences = experiences?.data?.filter(
    ({ id }) =>
      !resumeExperiences
        ?.map(({ originId }) => originId)
        ?.filter((originId: string) => originId)
        ?.includes(id)
  );
  const data = [...resumeExperiences, ...archivedExperiences];

  useEffect(() => {
    setSelected(resumeExperiences?.map(({ id }) => id));
  }, [resumeExperiences]);

  useEffect(() => {
    if (!profileId || !resumeId) return;
    dispatch(getExperiences({ where: { profileId, isArchived: true } }));
  }, []);

  return (
    <ResumeSectionCard heading="Work Experience">
      <ResumeSectionCardBody loading={experiences?.isLoading}>
        {data?.map((experience) => (
          <div
            key={experience?.id}
            className="flex flex-row justify-start items-start gap-x-[10px]"
          >
            <Checkbox
              value={experience?.id}
              checked={isChecked ? isChecked(experience?.id) : false}
              onChange={onChange}
            />
            <ResumeExperienceCard isActionMenu={false} {...experience} />
          </div>
        ))}
      </ResumeSectionCardBody>
    </ResumeSectionCard>
  );
};

ResumeExperienceSectionSelect.defaultProps = {};
