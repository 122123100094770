import { ApiService } from "../../../services";
import { AppDispatch } from "../../store";

import { marketplaceSlice } from "./marketplace.slice";

export const getMarketplaceItems =
  (options?: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(marketplaceSlice.actions.getMarketplaceItemsRequest());

      const response = await ApiService.get(`marketplace/items`);
      const payload = response.data;

      dispatch(marketplaceSlice.actions.getMarketplaceItemsSuccess(payload));

      return payload;
    } catch (e: any) {
      const error = e.response?.data?.error;

      dispatch(marketplaceSlice.actions.getMarketplaceItemsError(error));
    }
  };

export const getMarketplaceItemById =
  (id: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(marketplaceSlice.actions.getMarketplaceItemRequest());

      const response = await ApiService.get(`marketplace/items/${id}`);
      const payload = response.data.data;

      dispatch(marketplaceSlice.actions.getMarketplaceItemSuccess(payload));

      return payload;
    } catch (e: any) {
      const error = e.response?.data?.error;

      dispatch(marketplaceSlice.actions.getMarketplaceItemError(error));
    }
  };
