import React, { Ref, useEffect } from "react";

import { TextField as MuiTextField, BaseTextFieldProps } from "@mui/material";
import { FiLock as LockIcon } from "react-icons/fi";
import { InputError } from "../Input/InputError";

export interface ITextFieldProps extends BaseTextFieldProps {
  ref?: React.Ref<any>;
  variant?: "standard" | "outlined" | "filled";
  value?: string | number | Date | undefined | null;
  // error?:
  //   | string
  //   | string[]
  //   | boolean
  //   | FormikErrors<any>
  //   | FormikErrors<any>[]
  //   | null
  //   | undefined;
  errorMessage?: string | null;
  label?: string;
  inputClassName?: string;
  className?: string;
  controlClassName?: string;
  labelClassName?: string;
  rows?: number;
  maxRows?: number;
  minRows?: number;
  min?: number;
  max?: number;
  maxLength?: number;
  autoComplete?: "on" | "off";
  required?: boolean;
  blocked?: boolean;
  multiline?: boolean;
  placeholder?: string;
  InputProps?: any;
  InputLabelProps?: any;
  rightIcon?: () => JSX.Element;
  rightIconProps?: {
    className?: string;
    onClick?: (e: React.MouseEvent) => void;
  };
  autocomplete?: boolean | string;
  autoFocus?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  // [x: string]: any;
}

export const TextField: React.FC<ITextFieldProps> = React.forwardRef(
  (
    {
      value,
      error,
      errorMessage,
      label,
      variant,
      required,
      className,
      inputClassName,
      controlClassName,
      labelClassName,
      rows,
      multiline,
      maxRows,
      minRows,
      min,
      max,
      maxLength,
      disabled,
      blocked,
      autoComplete,
      placeholder,
      InputProps,
      InputLabelProps,
      rightIcon: RightIcon,
      rightIconProps,
      onChange,
      ...props
    },
    ref: React.Ref<HTMLElement>
  ) => {
    let variantStyles = "";

    const isError = Boolean(error?.toString()?.length);

    switch (variant) {
      case "standard":
        variantStyles = "px-[12px] py-[10px]";
        className = `${className}`;
        break;
      default:
        variantStyles = "";
        className = `${className} ${multiline ? "min-h-[55px]" : "h-[55px]"}`;
        break;
    }

    return (
      <div className={`${controlClassName} w-full relative overflow-hidden`}>
        <MuiTextField
          ref={ref}
          label={label ? `${label} ${required ? "*" : ""}` : null}
          className={`
            w-full flex flex flex-row items-center bg-primaryLight text-white rounded-lg overflow-hidden
            ${className}
            ${isError ? "border-[1px] border-solid !border-danger" : ""}
            ${RightIcon ? "pr-[50px]" : ""}
            ${disabled ? "brightness-75" : "brightness-100"} 
        `}
          InputProps={{
            className: `${inputClassName} ${variantStyles} w-full h-full flex flex-row text-inherit box-border`,
            disableUnderline: true,
            ...InputProps,
          }}
          InputLabelProps={{
            className: `${labelClassName} capitalize`,
            ...InputLabelProps,
          }}
          sx={{
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "inherit",
            },
          }}
          value={value || ""}
          autoComplete="off"
          required={false}
          onInput={(e) => {
            if (!maxLength) return;
            //@ts-ignore
            e.target.value = e.target.value?.slice(0, maxLength);
          }}
          onChange={onChange}
          {...{
            variant,
            placeholder,
            disabled,
            rows,
            multiline,
            maxRows,
            minRows,
            ...props,
          }}
        />

        {blocked ? (
          <div className="absolute top-[12.5px] right-[6px] w-[28px] h-[28px] flex flex-row justify-center items-center text-secondary">
            <LockIcon size={16} />
          </div>
        ) : RightIcon ? (
          <div
            className={`${
              rightIconProps?.className ||
              "flex flex-row justify-center items-center absolute top-[12.5px] right-[6px] w-[28px] h-[28px] text-secondary"
            }`}
            onClick={rightIconProps?.onClick || ((e) => {})}
          >
            <RightIcon />
          </div>
        ) : (
          <></>
        )}
        <InputError
          error={errorMessage?.toString() || null}
          className="mt-[5px]"
        />
      </div>
    );
  }
);

TextField.defaultProps = {
  variant: "filled",
  controlClassName: "",
  inputClassName: "",
  className: "bg-primaryLight text-white",
  labelClassName: "text-secondary",
  InputProps: {},
  label: "",
  errorMessage: null,
  autoComplete: "off",
  multiline: false,
  required: false,
};
