import React from "react";

import { Container, InfoCard } from "../../../components";
import { UserSettingsAccountPasswordEditForm } from "../forms";

export const UserSettingsAccountViewContainer = () => {
  return (
    <Container className="flex flex-col py-[15px] gap-[15px]">
      <InfoCard>
        <div className="flex flex-col text-white text-base">
          <div>
            <h2 className="text-base font-semibold">Identity</h2>
          </div>
          <div></div>
        </div>
      </InfoCard>
      <InfoCard>
        <div className="flex flex-col text-white text-base">
          <div>
            <h2 className="text-base font-semibold">Password</h2>
          </div>
          <div className="mt-[15px]">
            <UserSettingsAccountPasswordEditForm />
          </div>
        </div>
      </InfoCard>
    </Container>
  );
};
