import React from "react";

import { useFormik } from "formik";
import * as Yup from "yup";

import { Form, TextField, Button } from "../../../components";
import {
  useAccount,
  useAppDispatch,
  useLoading,
  useToast,
} from "../../../hooks";
import { FormValidator } from "../../../utils";
import {
  updatePasswordByAccountId,
  validatePasswordByAccountId,
} from "../../../store";
import { NotificationStatus } from "../../../shared";

interface IAccountPasswordEditForm {
  currentPassword: string;
  newPassword: string;
  newPasswordConfirmation: string;
}

const validationSchema = Yup.object().shape({
  currentPassword: Yup.string().required(
    "Current Password is a required field"
  ),
  newPassword: Yup.string()
    .min(8, "New Password must consist of 8 characters minimum")
    .required("New Password is a required field"),
  newPasswordConfirmation: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
    .trim()
    .required("Confirm New Password is a required field"),
});

export const UserSettingsAccountPasswordEditForm = () => {
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useLoading();
  const { toast } = useToast();
  const { account } = useAccount();

  const form = useFormik<IAccountPasswordEditForm>({
    validationSchema,
    initialValues: {
      currentPassword: "",
      newPassword: "",
      newPasswordConfirmation: "",
    },
    onSubmit: async (values) => {
      if (!account?.id) return;

      const { currentPassword, newPassword } = values;

      setLoading(true);

      // Validating the password
      const validated = await dispatch(
        validatePasswordByAccountId(account.id, { password: currentPassword })
      );
      if (!validated) {
        setFieldError("currentPassword", "Current Password is invalid.");
        setLoading(false);

        return;
      }

      // Updating the password
      const updated = await dispatch(
        updatePasswordByAccountId(account.id, { password: newPassword })
      );
      if (!updated?.id) {
        setLoading(false);

        toast({
          status: NotificationStatus.Error,
          message: `Settings haven't been saved.`,
        });

        return;
      }

      setLoading(false);
      resetForm();

      toast({
        status: NotificationStatus.Success,
        message: `Settings have been saved.`,
      });
    },
  });

  const validator = new FormValidator<IAccountPasswordEditForm>(form);

  const { values, resetForm, setFieldError, handleChange, handleSubmit } = form;

  return (
    <Form onSubmit={handleSubmit}>
      <div className="w-full flex flex-col gap-[10px]">
        <TextField
          id="currentPassword"
          label="Current Password"
          type="password"
          variant="filled"
          className="!bg-primaryDark !text-white"
          value={values?.currentPassword}
          errorMessage={validator.isInputValid("currentPassword")}
          onChange={handleChange}
        />
        <TextField
          id="newPassword"
          label="New Password"
          type="password"
          variant="filled"
          className="!bg-primaryDark !text-white"
          labelClassName="text-secondary"
          value={values?.newPassword}
          errorMessage={validator.isInputValid("newPassword")}
          onChange={handleChange}
        />
        <TextField
          id="newPasswordConfirmation"
          label="Confirm New Password"
          type="password"
          variant="filled"
          className="!bg-primaryDark !text-white"
          labelClassName="text-secondary"
          value={values?.newPasswordConfirmation}
          errorMessage={validator.isInputValid("newPasswordConfirmation")}
          onChange={handleChange}
        />
      </div>
      <div className="w-full flex flex-row justify-end items-center mt-[15px]">
        <Button
          variant="outlined"
          color="success"
          className="min-w-[150px]"
          loading={loading}
          onClick={() => handleSubmit()}
        >
          Save Changes
        </Button>
      </div>
    </Form>
  );
};
