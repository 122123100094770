import React from "react";

import {
  MdOutlineShoppingCart as BuyLicenseIcon,
  MdVerified as VerifiedIcon,
} from "react-icons/md";

import { Action, IDigitalAsset, IProfile, ModalType, Role } from "../../shared";

import { ActionMenu, Avatar, Button, Link, RBACSwitch } from "../../components";
import { useAccount, useModal } from "../../hooks";
import { ROUTER } from "../../routes";
import { date } from "../../utils";

interface IDigitalAssetCardNavbarProps extends IDigitalAsset {
  id?: string;
  createdBy?: IProfile;
  createdById: string;
  createdAt?: Date;
  loading?: boolean;
  editing?: boolean;
  onEdit: (id?: string) => void;
  onDelete: (id?: string) => void;
}

export const DigitalAssetCardNavbar: React.FC<IDigitalAssetCardNavbarProps> = ({
  id,
  isPublic,
  isLicense,
  isLicenseAvailable,
  isLicensable,
  license,
  createdBy,
  createdById,
  createdAt,
  loading,
  editing,
  onEdit,
  onDelete,
  ...props
}) => {
  const { activeProfile } = useAccount();
  const { setModal } = useModal();

  const licenseId = license?.id;

  const isOwner = activeProfile?.id === createdById || false;
  const isBusinessUser = activeProfile?.isBusiness || false;

  const isLicensing = isLicense;
  const isLicensePurchased =
    isBusinessUser && props?.isLicensePurchased ? true : false;

  const handleLicenseOptionsModal = () => {
    setModal({
      type: ModalType.LICENSE_OPTIONS,
      props: { licenseId },
    });
  };

  const handleLicensePurchaseModal = () => {
    setModal({
      type: ModalType.LICENSE_PURCHASE,
      props: { digitalAssetId: id },
    });
  };

  const handleLicenseInfoModal = () => {};

  return (
    <div className="w-full flex flex-row justify-between items-start">
      <div className="flex flex-row justify-start items-center gap-x-[10px]">
        <Avatar
          size={40}
          to={ROUTER.USER_DETAIL(createdById)}
          src={createdBy?.avatar}
        >
          {createdBy?.lastName}
        </Avatar>
        <div className="flex flex-col gap-y-[5px]">
          <Link to={ROUTER.USER_DETAIL(createdById)}>
            <h3 className="text-sm text-white font-semibold leading-none">
              {createdBy?.firstName || ""} {createdBy?.lastName || ""}
            </h3>
          </Link>
          <span className="text-xs text-secondary font-normal leading-none">
            {date(createdAt).fromNow()}
          </span>
        </div>

        <div className="ml-[20px]">
          {isOwner ? (
            isLicensable ? (
              isLicensing ? (
                isLicenseAvailable ? (
                  <Button
                    variant="outlined"
                    color="success"
                    icon={<VerifiedIcon size={14} className="" />}
                    onClick={handleLicenseOptionsModal}
                  >
                    Licensed
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    color="secondary"
                    icon={<VerifiedIcon size={14} className="" />}
                  >
                    Licensed
                  </Button>
                )
              ) : (
                <Button
                  variant="outlined"
                  color="success"
                  className="min-w-[125px]"
                  onClick={handleLicenseOptionsModal}
                >
                  License
                </Button>
              )
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
        </div>
      </div>
      <div>
        {isOwner ? (
          RBACSwitch({
            elements: [
              {
                roles: [Role.Admin],
                render: !editing ? (
                  <ActionMenu
                    loading={loading}
                    actions={[Action.Edit, Action.Delete]}
                    {...{ onEdit, onDelete }}
                  />
                ) : (
                  <></>
                ),
              },
              {
                roles: [Role.Owner, Role.TeamLead, Role.Employee, Role.User],
                render:
                  isOwner && !editing ? (
                    <ActionMenu
                      loading={loading}
                      actions={[Action.Edit, Action.Delete]}
                      {...{ onEdit, onDelete }}
                    />
                  ) : (
                    <></>
                  ),
              },
            ],
          })
        ) : isLicensePurchased ? (
          <div className="flex flex-row gap-[10px]">
            <Button
              variant="outlined"
              color="secondary"
              icon={<VerifiedIcon size={14} className="" />}
              onClick={handleLicenseInfoModal}
            >
              Licensed
            </Button>
          </div>
        ) : isLicensing ? (
          <div className="flex flex-row gap-[10px]">
            <Button
              variant="outlined"
              color="success"
              className="min-w-[125px]"
              icon={<BuyLicenseIcon size={14} className="" />}
              onClick={handleLicensePurchaseModal}
            >
              License
            </Button>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

DigitalAssetCardNavbar.defaultProps = {};
