import React from "react";

import { Avatar, VerificationStatusBadge } from "../../components";
import { date } from "../../utils";

export const EducationDetailSection = ({
  id,
  degree,
  university,
  grade,
  verification,
  profileId,
  isDraft,
  isEnrolled,
  isVerified,
  startedAt,
  finishedAt,
}) => (
  <div className="flex justify-start">
    <div className="w-full flex flex-col justify-start items-start text-sm text-secondary gap-x-[10px]">
      <div className="flex flex-row justify-start items-center text-base text-white font-semibold gap-x-[8px]">
        <Avatar size={40}>{university}</Avatar>
        <div className="flex flex-col items-start justify-center gap-y-[3px]">
          <div className="flex flex-row justify-start items-center gap-x-[10px]">
            <h4 className="flex flex-row justify-start items-center flex-wrap gap-x-[5px] gap-y-[3px] text-base text-white font-semibold leading-none">
              <div>
                <span className="break-words">{degree} at </span>
                <span className="text-primary">{university}</span>
              </div>
              {isDraft ? (
                <span className="text-sm text-secondary">(Draft)</span>
              ) : (
                <VerificationStatusBadge
                  className="text-sm"
                  status={verification?.status}
                  education={{ id, degree, university, profileId }}
                />
              )}
            </h4>
          </div>

          <span className="text-xs font-medium text-secondary leading-none">
            {startedAt && finishedAt ? (
              `${date(startedAt).format("MMM YYYY")} - ${
                isEnrolled ? "Present" : date(finishedAt).format("MMM YYYY")
              }`
            ) : (
              <></>
            )}
          </span>
        </div>
      </div>
    </div>
  </div>
);

EducationDetailSection.defaultProps = {
  grade: 0,
  isEnrolled: false,
  isVerified: false,
};
