import React from "react";

import { Link } from "./../../components";

interface IMenuLinkProps {
  className?: string;
  to?: string;
  active?: boolean;
  activeClassName?: string;
}

export const MenuLink: React.FC<IMenuLinkProps> = ({
  children,
  to,
  active,
  className,
  activeClassName,
}) => {
  const activeStyles = active
    ? `${activeClassName} text-white font-semibold hover:text-white`
    : `text-secondary font-medium hover:text-white`;

  return (
    <div
      className={`
            ${className}
            ${activeStyles}
            relative flex flex-row justify-start items-center cursor-pointer text-base box-border
      `}
    >
      <Link {...{ to }}>{children}</Link>
    </div>
  );
};

MenuLink.defaultProps = {
  className: "",
};
