import { Role } from "../shared";

import * as Pages from "./../pages";
import { ROUTER } from "./../routes";

interface IRoute {
  path: string | Function;
  exact?: boolean;
  component: () => JSX.Element;
  roles?: Role[];
}

export const routes: IRoute[] = [
  // Errors
  {
    path: "*",
    exact: true,
    component: Pages.NotFoundPage,
  },
  {
    path: ROUTER.TEST,
    exact: true,
    component: Pages.TestPage,
  },
  {
    path: ROUTER.ROOT,
    exact: true,
    component: Pages.RegisterPage,
  },
  {
    path: ROUTER.FAQ,
    exact: true,
    component: Pages.FAQPage,
  },
  {
    path: ROUTER.HOME,
    exact: true,
    component: Pages.HomePage,
  },

  // User
  {
    path: ROUTER.FRIENDS,
    exact: true,
    component: Pages.FriendsPage,
  },
  {
    path: ROUTER.USER_SETTINGS,
    exact: true,
    component: Pages.UserSettingsPage,
  },

  // Auth
  {
    path: ROUTER.LOGIN,
    exact: true,
    component: Pages.LoginPage,
  },
  {
    path: ROUTER.PASSWORD_RESET,
    exact: true,
    component: Pages.PasswordResetPage,
  },
  {
    path: ROUTER.INVITE_DETAIL(""),
    component: Pages.InviteDetailPage,
  },

  // Admin
  {
    path: ROUTER.ADMIN,
    exact: true,
    component: Pages.AdminHomePage,
  },
  {
    path: ROUTER.ADMIN_BUSINESS_VERIFICATION_DETAIL(),
    component: Pages.AdminBusinessVerificationDetailPage,
  },
  {
    path: ROUTER.ADMIN_BUSINESS_AUDIT_DETAIL(),
    component: Pages.AdminBusinessAuditDetailPage,
  },

  // Business
  {
    path: ROUTER.BUSINESS_ADMINISTRATION,
    exact: true,
    component: Pages.BusinessAdministrationPage,
  },
  {
    path: ROUTER.BUSINESS_AUDIT_DETAIL(),
    component: Pages.BusinessAuditDetailPage,
  },

  // User
  {
    path: ROUTER.USER_DETAIL(),
    component: Pages.UserDetailPage,
  },
  {
    path: ROUTER.USER_FRIENDS(),
    exact: true,
    component: Pages.UserFriendsPage,
  },
  {
    path: ROUTER.USER_GALLERY(),
    exact: true,
    component: Pages.UserGalleryPage,
  },
  {
    path: ROUTER.USER_GALLERY_ITEM_DETAIL(),
    component: Pages.UserGalleryFileDetailPage,
  },

  // License
  {
    path: ROUTER.USER_LICENSES,
    exact: true,
    component: Pages.UserLicensesPage,
  },
  {
    path: ROUTER.USER_LICENSES_PURCHASED,
    exact: true,
    component: Pages.UserLicensesPurchasedPage,
  },
  {
    path: ROUTER.USER_LICENSES_SOLD,
    exact: true,
    component: Pages.UserLicensesSoldPage,
  },

  // Company
  {
    path: ROUTER.COMPANY_DETAIL_ALIAS(),
    component: Pages.CompanyDetailPage,
  },
  {
    path: ROUTER.COMPANY_DETAIL(),
    component: Pages.CompanyDetailPage,
  },
  {
    path: ROUTER.BUSINESS_REGISTRATION,
    exact: true,
    component: Pages.BusinessRegistrationPage,
  },

  // Job
  {
    path: ROUTER.JOBS,
    roles: [Role.Admin, Role.Owner, Role.TeamLead, Role.Employee, Role.User],
    component: Pages.JobRootPage,
  },
  {
    path: ROUTER.JOB_DETAIL(),
    exact: true,
    roles: [Role.Admin, Role.Owner, Role.TeamLead, Role.Employee, Role.User],
    component: Pages.JobDetailPage,
  },
  {
    path: ROUTER.JOB_CREATE,
    exact: true,
    roles: [Role.Owner, Role.TeamLead, Role.Employee],
    component: Pages.JobCreatePage,
  },
  {
    path: ROUTER.JOB_EDIT(),
    exact: true,
    roles: [Role.Admin, Role.Owner, Role.TeamLead, Role.Employee],
    component: Pages.JobEditPage,
  },
  {
    path: ROUTER.JOB_APPLICATION_LIST(),
    exact: true,
    roles: [Role.Owner, Role.TeamLead, Role.Employee],
    component: Pages.JobApplicationListPage,
  },
  {
    path: ROUTER.JOB_APPLICATION_DETAIL(),
    exact: true,
    roles: [Role.Owner, Role.TeamLead, Role.Employee, Role.User],
    component: Pages.JobApplicationDetailPage,
  },

  // Resume Builder
  {
    path: ROUTER.RESUME_BUILDER,
    component: Pages.ResumeBuilderPage,
  },

  // Digital Portfolio
  {
    path: ROUTER.USER_DIGITAL_ASSETS(),
    component: Pages.UserDigitalPortfolioDetailPage,
  },
  {
    path: ROUTER.USER_DIGITAL_ASSET_DETAIL(),
    component: Pages.UserDigitalAssetDetailPage,
  },

  // Employee
  {
    path: ROUTER.EMPLOYEE_SEARCH,
    exact: true,
    roles: [Role.Owner, Role.TeamLead, Role.Employee],
    component: Pages.EmployeeListPage,
  },
  {
    path: ROUTER.EMPLOYEE_DETAIL(),
    roles: [Role.Owner, Role.TeamLead, Role.Employee],
    component: Pages.EmployeeDetailPage,
  },

  // Events
  {
    path: ROUTER.EVENTS,
    exact: true,
    component: Pages.CalendarPage,
  },

  // Chat
  {
    path: ROUTER.CHATS,
    component: Pages.ChatPage,
  },
  {
    path: ROUTER.CHAT_DETAIL(),
    component: Pages.ChatPage,
  },

  // News
  {
    path: ROUTER.NEWS,
    exact: true,
    component: Pages.NewsPage,
  },

  // Search
  {
    path: ROUTER.SEARCH(),
    exact: true,
    component: Pages.SearchPage,
  },

  // Notifications
  {
    path: ROUTER.NOTIFICATIONS,
    exact: true,
    component: Pages.NotificationsPage,
  },

  // Video Call
  {
    path: ROUTER.MEET,
    exact: true,
    component: Pages.VideoCallPage,
  },

  // Marketplace
  {
    path: ROUTER.MARKETPLACE,
    exact: true,
    component: Pages.MarketplacePage,
  },
  {
    path: ROUTER.MARKETPLACE_ITEM(),
    component: Pages.MarketplaceItemDetailPage,
  },

  // Others
  {
    path: ROUTER.EDUCATION,
    exact: true,
    component: Pages.EducationPage,
  },
  {
    path: ROUTER.CREATORS,
    exact: true,
    component: Pages.CreatorsPage,
  },
];
