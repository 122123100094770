import React, { useEffect } from "react";

import { Button, Checkbox } from "../../../../components";
import {
  ResumeEducationCard,
  ResumeSectionCard,
  ResumeSectionCardBody,
} from "../../..";

import { date } from "../../../../utils";
import { getEducations } from "../../../../store";
import { useAppDispatch, useAppSelector } from "../../../../hooks";

export const ResumeDigitalAssetSectionSelect = ({
  selected,
  setSelected,
  isChecked,
  onChange,
}) => {
  const dispatch = useAppDispatch();

  const {
    account: { data: account },
  } = useAppSelector((state) => state.account);
  const { resume } = useAppSelector((state) => state.resume);
  const { educations } = useAppSelector((state) => state.education);

  const profile = account?.profile;
  const profileId = profile?.id;
  const resumeId = resume?.data?.id;
  const resumeEducations = resume?.data?.educations || [];

  const archivedEducations = educations?.data?.filter(
    ({ id }) =>
      !resumeEducations
        ?.map(({ originId }) => originId)
        ?.filter((originId: string) => originId)
        ?.includes(id)
  );
  const data = [...resumeEducations, ...archivedEducations];

  useEffect(() => {
    setSelected(resumeEducations?.map(({ id }) => id));
  }, [resumeEducations]);

  useEffect(() => {
    if (!profileId || !resumeId) return;
    dispatch(getEducations({ where: { profileId, isArchived: true } }));
  }, []);

  return (
    <ResumeSectionCard
      heading="Education"
      // navbar={
      //   <Button color="success" variant="text">
      //     Verify
      //   </Button>
      // }
    >
      <ResumeSectionCardBody loading={educations?.isLoading}>
        {data?.map((education) => (
          <div
            key={education?.id}
            className="flex flex-row justify-start items-start gap-x-[10px]"
          >
            <Checkbox
              value={education?.id}
              checked={isChecked(education?.id)}
              onChange={onChange}
            />
            <ResumeEducationCard actions={[]} {...education} />
          </div>
        ))}
      </ResumeSectionCardBody>
    </ResumeSectionCard>
  );
};

ResumeDigitalAssetSectionSelect.defaultProps = {};
