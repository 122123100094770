import React, { useState } from "react";

import {
  MdMenu as BurgerMenuIcon,
  MdInsertDriveFile as ResumeIcon,
} from "react-icons/md";

import { BiSupport as SupportIcon } from "react-icons/bi";

import {
  Loader,
  Navbar,
  NavbarLink,
  EmployeeSearchIcon,
  CreatorsIcon,
  EducationIcon,
  HomeIcon,
  JobSearchIcon,
  NewsIcon,
  BottomNavbarLink,
  IconButton,
  Drawer,
  RBACSwitch,
} from "../../components";
import { ModalType, Role } from "../../shared";
import { useActivePage, useModal } from "../../hooks";
import { ROUTER } from "../../routes";

export const BottomNavbar = () => {
  const { isActive } = useActivePage();
  const { setModal } = useModal();

  const handleSupport = () => {
    setModal({ type: ModalType.TICKET_CREATE });
  };

  return (
    <>
      <div className="w-full flex flex-row justify-between items-center h-[70px] text-white fill-white box-border bg-primaryDark border-t-[1px] border-primaryLight px-[20px]">
        <BottomNavbarLink
          to={ROUTER.HOME}
          icon={() => <HomeIcon size={24} viewBox="-2 -2 24 24" />}
          isActive={isActive(ROUTER.HOME)}
        />
        <BottomNavbarLink
          to={ROUTER.NEWS}
          icon={() => <NewsIcon size={24} viewBox="-2.5 -2 24 24" />}
          isActive={isActive(ROUTER.NEWS)}
        />

        {RBACSwitch({
          elements: [
            {
              roles: [Role.Owner, Role.TeamLead, Role.Employee],
              render: (
                <>
                  <BottomNavbarLink
                    to={ROUTER.EMPLOYEE_SEARCH}
                    icon={() => (
                      <EmployeeSearchIcon size={24} viewBox="-2.5 -1.5 24 24" />
                    )}
                    isActive={isActive(ROUTER.EMPLOYEE_SEARCH)}
                  />
                </>
              ),
            },
            {
              roles: [Role.User],
              render: (
                <>
                  <BottomNavbarLink
                    to={ROUTER.JOBS}
                    icon={() => (
                      <JobSearchIcon size={24} viewBox="-3.5 -2.5 24 24" />
                    )}
                    isActive={isActive(ROUTER.JOBS)}
                  />
                  <BottomNavbarLink
                    to={ROUTER.RESUME_BUILDER}
                    icon={() => <ResumeIcon size={16} />}
                    isActive={isActive(ROUTER.RESUME_BUILDER)}
                  />
                </>
              ),
            },
          ],
        })}

        <BottomNavbarLink
          icon={() => <SupportIcon size={20} />}
          onClick={handleSupport}
        />

        {/* <BottomNavbarLink
          to={ROUTER.EDUCATION}
          icon={() => <EducationIcon size={24} viewBox="-3 -3.5 24 24" />}
          isActive={isActive(ROUTER.EDUCATION)}
        />
        <BottomNavbarLink
          to={ROUTER.CREATORS}
          icon={() => <CreatorsIcon size={24} viewBox="-2.5 -2.5 24 24" />}
          isActive={isActive(ROUTER.CREATORS)}
        /> */}
        {/* <IconButton
          icon={BurgerMenuIcon}
          iconProps={{ size: 24 }}
          className="w-[40px] h-[40px]"
          iconClassName="fill-white"
          onClick={handleToggleSidebar}
        /> */}
      </div>
    </>
  );
};
