import React, { useEffect, useState } from "react";

import { Tab, Tabs } from "@mui/material";

export const PageContainerSwitch = ({ tabs, showTabs, indicatorClassName }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleChange = (e, value) => setActiveTab(value);

  const onRight = tabs?.filter((t, index) => activeTab === index)?.[0]?.onRight;

  return (
    <div className="flex flex-col">
      <div className="flex flex-row justify-between items-center">
        <Tabs
          className="flex flex-row justify-start"
          TabIndicatorProps={{
            className: `${indicatorClassName} bg-primary h-[2px]`,
          }}
          value={activeTab}
          onChange={handleChange}
        >
          {tabs?.map(({ label }, index) => {
            const active = activeTab === index || false;

            return (
              <Tab
                key={label}
                label={label}
                className={`flex flex-row justify-center capitalize text-base font-gilroy min-w-0 min-h-0 px-0 mx-0 mr-5 ${
                  active
                    ? "text-white font-semibold"
                    : "text-secondary font-semibold"
                }`}
                disableRipple
              />
            );
          })}
        </Tabs>
        <div>{onRight ? onRight : <></>}</div>
      </div>
      <div className="w-full mt-[15px]">
        <TabViews activeTab={activeTab} tabs={tabs} />
      </div>
    </div>
  );
};

PageContainerSwitch.defaultProps = {
  tabs: [],
  showTabs: true,
  indicatorClassName: "",
  onRight: <></>,
};

const TabViews = ({ activeTab, tabs }) => {
  const [view, setView] = useState(() => <></>);

  useEffect(() => {
    if (!tabs.length || !tabs[activeTab]) return;

    const { component: Component } = tabs[activeTab];

    setView(() => <Component />);
  }, [activeTab]);

  return <div className="w-full">{view ? view : <></>}</div>;
};

TabViews.defaultProps = {
  activeTab: 0,
  tabs: [],
};
