import { createSlice } from "@reduxjs/toolkit";

import { IGallery, IGalleryFile } from "../../../shared";
import { AppReducerState } from "../../store";

interface IGalleryState {
  gallery: AppReducerState<IGallery>;
  galleryAlbum: AppReducerState<IGallery>;
  galleryFile: AppReducerState<IGalleryFile>;
  galleryFiles: AppReducerState<IGalleryFile[]>;
}

const initialState: IGalleryState = {
  gallery: {
    isLoading: false,
    data: {},
    error: null,
  },
  galleryAlbum: {
    isLoading: false,
    data: {},
    error: null,
  },
  galleryFile: {
    isLoading: false,
    data: {},
    error: null,
  },
  galleryFiles: {
    isLoading: false,
    data: [],
    error: null,
  },
};

export const gallerySlice = createSlice({
  name: "Gallery",
  initialState,
  reducers: {
    getGalleryByIdRequest: (state) => {
      state.gallery.isLoading = true;
      state.gallery.data = {};
    },
    getGalleryByIdSuccess: (state, action) => {
      const { data } = action.payload;

      state.gallery.isLoading = false;
      state.gallery.data = data;
    },
    getGalleryByIdError: (state, action) => {
      const { error } = action.payload;

      state.gallery.isLoading = false;
      state.gallery.error = error;
    },

    getGalleryByUserIdRequest: (state) => {
      state.gallery.isLoading = true;
      state.gallery.data = {};
    },
    getGalleryByUserIdSuccess: (state, action) => {
      const { data } = action.payload;

      state.gallery.isLoading = false;
      state.gallery.data = data;
    },
    getGalleryByUserIdError: (state, action) => {
      const { error } = action.payload;

      state.gallery.isLoading = false;
      state.gallery.error = error;
    },

    getGalleryAlbumByIdRequest: (state) => {
      state.galleryAlbum.isLoading = true;
      state.galleryAlbum.data = {};
    },
    getGalleryAlbumByIdSuccess: (state, action) => {
      const { data } = action.payload;

      state.galleryAlbum.isLoading = false;
      state.galleryAlbum.data = data;
    },
    getGalleryAlbumByIdError: (state, action) => {
      const { error } = action.payload;

      state.galleryAlbum.isLoading = false;
      state.galleryAlbum.error = error;
    },

    getGalleryFilesRequest: (state) => {
      state.galleryFiles.isLoading = true;
      state.galleryFiles.results = 0;
      state.galleryFiles.data = [];
    },
    getGalleryFilesSuccess: (state, action) => {
      const { data } = action.payload;

      state.galleryFiles.isLoading = false;
      state.galleryFiles.data = data;
    },
    getGalleryFilesError: (state, action) => {
      const { error } = action.payload;

      state.galleryFiles.isLoading = false;
      state.galleryFiles.error = error;
    },

    getGalleryFileByIdRequest: (state) => {
      state.galleryFile.isLoading = true;
      state.galleryFile.data = {};
    },
    getGalleryFileByIdSuccess: (state, action) => {
      const { data } = action.payload;

      state.galleryFile.isLoading = false;
      state.galleryFile.data = data;
    },
    getGalleryFileByIdError: (state, action) => {
      const { error } = action.payload;

      state.galleryFile.isLoading = false;
      state.galleryFile.error = error;
    },

    createGalleryFileRequest: (state) => {},
    createGalleryFileSuccess: (state, action) => {
      const { data } = action.payload;

      state.galleryFiles.isLoading = false;
      state.galleryFiles.data = [data, ...state.galleryFiles.data];
    },
    createGalleryFileError: (state, action) => {
      const error = action.payload;

      state.galleryFiles.isLoading = false;
      state.galleryFiles.error = error;
    },

    updateGalleryFileByIdRequest: (state) => {
      state.galleryFile.isUpdating = true;
    },
    updateGalleryFileByIdSuccess: (state, action) => {
      const { data } = action.payload;

      state.galleryFile.isUpdating = false;
      // state.galleryFile.data = {}
    },
    updateGalleryFileByIdError: (state, action) => {
      const { error } = action.payload;

      state.galleryFile.isUpdating = false;
      state.galleryFile.error = error;
    },

    removeGalleryFileByIdRequest: (state) => {},
    removeGalleryFileByIdSuccess: (state, action) => {
      state.galleryFile.isLoading = false;
      state.galleryFile.data = {};
    },
    removeGalleryFileByIdError: (state, action) => {
      const { error } = action.payload;

      state.galleryFile.isLoading = false;
      state.galleryFile.error = error;
    },
  },
});

export const galleryReducer = gallerySlice.reducer;
