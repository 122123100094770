import React from "react";

import { MainLayout, MainExtendedLayout } from "../../layouts";
import { lazyload } from "../../utils";

const { CalendarContainer } = lazyload(() => import("../../features"));

export const CalendarPage = () => (
  <MainExtendedLayout>
    <CalendarContainer />
  </MainExtendedLayout>
);
