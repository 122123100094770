import { ApiService } from "../../../services";
import { AppDispatch } from "../../store";

import { calendarEventSlice } from "./calendar-event.slice";

export const getCalendarEvents =
  (options?: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(calendarEventSlice.actions.getCalendarEventsRequest());

      const response = await ApiService.post(
        "calendar-events/query",
        options || {}
      );
      const { results, data } = response.data;

      dispatch(
        calendarEventSlice.actions.getCalendarEventsSuccess({
          results,
          data,
        })
      );

      return data;
    } catch (e: any) {
      dispatch(
        calendarEventSlice.actions.getCalendarEventsError(
          e.response?.data?.error
        )
      );
    }
  };

export const getCalendarEventById =
  (id: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(calendarEventSlice.actions.getCalendarEventByIdRequest());

      const response = await ApiService.get(`calendar-events/${id}`);
      const { data } = response.data;

      dispatch(
        calendarEventSlice.actions.getCalendarEventByIdSuccess({
          data,
        })
      );

      return data;
    } catch (e: any) {
      dispatch(
        calendarEventSlice.actions.getCalendarEventByIdError(
          e.response?.data?.error
        )
      );
    }
  };

export const createCalendarEvent =
  (payload: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(calendarEventSlice.actions.createCalendarEventRequest());

      const response = await ApiService.post("calendar-events", payload);
      const { data } = response.data;

      dispatch(calendarEventSlice.actions.createCalendarEventSuccess(data));

      return data;
    } catch (e: any) {
      console.log({ e });
      dispatch(
        calendarEventSlice.actions.createCalendarEventError(
          e.response?.data?.error
        )
      );
    }
  };

export const updateCalendarEventById =
  (id: string, payload: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(calendarEventSlice.actions.updateCalendarEventRequest());

      // Updating
      await ApiService.put(`calendar-events/${id}`, payload);

      // Fetching
      const response = await ApiService.get(`calendar-events/${id}`);
      const { data } = response.data;

      await dispatch(
        calendarEventSlice.actions.updateCalendarEventSuccess(data)
      );

      return data;
    } catch (e: any) {
      console.log({ e });
      dispatch(
        calendarEventSlice.actions.updateCalendarEventError(
          e.response?.data?.error
        )
      );
    }
  };

export const removeCalendarEventById =
  (id: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(calendarEventSlice.actions.removeCalendarEventRequest());

      await ApiService.delete(`calendar-events/${id}`);

      dispatch(calendarEventSlice.actions.removeCalendarEventSuccess({ id }));

      return { id };
    } catch (e: any) {
      console.log({ e });
      dispatch(
        calendarEventSlice.actions.removeCalendarEventError(
          e.response?.data?.error
        )
      );
    }
  };
