import React, { useEffect, useState } from "react";

import {
  DataGrid,
  DataGridProps,
  GridColDef,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import {
  LinearProgress,
  Pagination,
  PaginationItem,
  PaginationProps,
  Skeleton,
} from "@mui/material";
import {
  MdArrowBack as ArrowPrevIcon,
  MdArrowForward as ArrowNextIcon,
} from "react-icons/md";

import { LinearLoader, Loader } from "../../components";
import { useLoading } from "../../hooks";

export interface IBasicTable<T = any> {
  columns: IBasicTableColumn[];
  rows: T[];
}

export interface IBasicTableColumn extends GridColDef {}

export interface IBasicTableRow {}

export interface IBasicTableRenderCellParams<T = any>
  extends GridRenderCellParams<T> {}

interface IBasicTableProps<T = any> extends DataGridProps {
  rows: T[];
  page?: number;
  pageCount?: number;
  onPageChange?: (page: number) => void;
}

export const BasicTable: React.FC<IBasicTableProps> = ({
  loading,
  page,
  pageCount,
  pageSize,
  columns,
  rows,
  rowCount,
  onPageChange,
  ...props
}) => {
  const rowsPerPage = rowCount
    ? rowCount > 0 && rowCount <= 10
      ? rowCount
      : 10
    : 10;

  const density: "standard" | "compact" | "comfortable" = "standard";

  // @ts-ignore
  const compactMode = density === "compact" || false;

  const headerHeight = 40;
  const height = rowsPerPage * (compactMode ? 35 : 52) + headerHeight + 100;
  const rowContainerHeight = height - headerHeight - 100;

  const handlePageChange = (e, pageIndex) => {
    if (pageIndex <= 0 || page === pageIndex) return;

    if (onPageChange) {
      onPageChange(pageIndex);
    }
  };

  return (
    <div className="relative w-full h-auto">
      <DataGrid
        className="relative text-secondary border-[1px] border-primaryLight z-40 "
        sx={{ height }}
        loading={loading}
        classes={{
          root: "text-white",
          columnHeaders: "border-none",
          columnHeader:
            "transition-all text-sm text-secondary cursor-pointe select-none cursor-pointer outline-none bg-primaryLight hover:brightness-110",
          columnSeparator: "hidden",
          sortIcon: "text-secondary",
          row: "transition-all w-full text-sm text-white font-medium h-[50px] bg-primaryDark hover:brightness-110",
          cell: "border-primaryMid border-x-[0px] border-t-[0px] outline-none",
          cellContent: "",
          footerContainer:
            "flex flex-row justify-end max-h-[100px] p-[15px] box-border border-none",
          selectedRowCount: "hidden",
        }}
        headerHeight={headerHeight}
        rows={rows}
        columns={columns}
        density={density}
        paginationMode="server"
        pageSize={rowsPerPage}
        rowsPerPageOptions={[]}
        disableColumnMenu
        components={{
          LoadingOverlay: () => (
            <div
              className="w-full h-full flex flex-row justify-center items-center bg-primaryDark brightness-110"
              style={{ height }}
            >
              <Loader size={20} className="text-secondary" />
            </div>
          ),
          NoResultsOverlay: () => (
            <NoResultsOverlay height={rowContainerHeight} />
          ),
          NoRowsOverlay: () => <NoResultsOverlay height={rowContainerHeight} />,
          Pagination: (props) => (
            <BasicTablePagination
              {...props}
              page={page}
              count={pageCount}
              disabled={loading}
              onChange={handlePageChange}
            />
          ),
        }}
        {...props}
      />
    </div>
  );
};

const NoResultsOverlay = ({ height }) => (
  <div
    className="w-full flex flex-row justify-center items-center bg-primaryDark brightness-110"
    style={{ height }}
  >
    <span>No data found</span>
  </div>
);

BasicTable.defaultProps = {
  loading: false,
  rows: [],
  columns: [],
  page: 0,
  pageCount: 0,
};

export const BasicTablePagination: React.FC<PaginationProps> = ({
  count,
  onChange,
  ...props
}) => {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  const handleChange = async (e, value) => {
    if (onChange) {
      await onChange(e, value);
    }

    apiRef.current.setPage(value - 1);
  };

  return (
    <Pagination
      page={page + 1}
      count={count}
      renderItem={(props) => (
        <PaginationItem
          classes={{
            root: "w-[36px] h-[36px] flex flex-row justify-center rounded-full items-center text-secondary bg-primaryMid hover:brightness-110",
            ellipsis: "!w-[15px] m-[0px] text-secondary bg-transparent",
            selected: "text-white brightness-125",
            disabled: "text-secondary bg-primaryLight",
          }}
          slots={{
            previous: () => <ArrowPrevIcon size={18} />,
            next: () => <ArrowNextIcon size={18} />,
          }}
          {...props}
        />
      )}
      onChange={handleChange}
      {...props}
    />
  );
};

BasicTablePagination.defaultProps = { page: 0, count: 0 };
