import { FormikErrors } from "formik";
import React, { useEffect, useRef, useState } from "react";

import { FiUploadCloud as UploadIcon } from "react-icons/fi";

import { Form, IconButton, InputError } from "..";
import { HTMLFileTypes } from "../../shared";

interface IFileDragDropPickerProps {
  className?: string;
  id?: string;
  name?: string;
  error?:
    | string
    | string[]
    | boolean
    | FormikErrors<any>
    | FormikErrors<any>[]
    | null
    | undefined;
  extensions?: HTMLFileTypes[];
  multiple?: boolean;
  onChange?: (files?: File[]) => void;
}

export const FileDragDropPicker: React.FC<IFileDragDropPickerProps> = ({
  className,
  id,
  name,
  error,
  extensions,
  multiple,
  onChange,
}) => {
  const filePickerRef = useRef<HTMLInputElement>(null);
  const filePicker = filePickerRef.current;

  const [dragActive, setDragActive] = useState(false);
  const [files, setFiles] = useState<File[]>([]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || [])?.slice(0, 1);

    if (files?.length > 10) if (!files?.length) return;

    setFiles(files);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e?.preventDefault();
  };

  const handleDrag = (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const eventType = e?.type;

    switch (eventType) {
      case "dragenter" || "dragover":
        setDragActive(true);
        break;
      case "dragleave":
        setDragActive(false);
        break;
      default:
        break;
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const files = Array.from(e.dataTransfer.files).slice(0, 1) || [];
    if (!files.length) return;

    setFiles(files);
    setDragActive(false);
  };

  const handleBrowse = () => {
    if (!filePickerRef.current) return;

    filePickerRef.current.value = "";
    filePickerRef.current?.click();
  };

  useEffect(() => {
    if (onChange) {
      onChange(files);
    }
  }, [files]);

  return (
    <Form
      className={`
        ${className}
        relative w-full flex flex-row justify-center items-center border-[2px] border-dashed rounded-lg overflow-hidden
        ${
          dragActive
            ? "bg-primaryLight border-primary"
            : "bg-primaryLight border-primaryMid"
        }
      `}
      onSubmit={handleSubmit}
    >
      <div
        className="absolute w-full h-full z-20 cursor-pointer"
        onClick={handleBrowse}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
      ></div>
      <input
        ref={filePickerRef}
        type="file"
        multiple={multiple}
        hidden={true}
        onChange={handleChange}
      />
      <div className="flex flex-col justify-center items-center text-secondary z-10">
        <UploadIcon size={36} />
        <div className="mt-[10px]">
          <span className="flex flex-row justify-center text-sm font-medium text-center">
            Drag & Drop
            <br />
            OR Select your files
          </span>
        </div>
      </div>
    </Form>
  );
};

FileDragDropPicker.defaultProps = {
  className: "h-[180px]",
  extensions: [HTMLFileTypes.jpg, HTMLFileTypes.png],
  multiple: false,
};
