import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { useFormik } from "formik";

import {
  ModalType,
  NotificationStatus,
  NotificationType,
  NotificationPriority,
} from "../../../shared";

import {
  useAppDispatch,
  useAppSelector,
  useLoading,
  useModal,
  useToast,
} from "../../../hooks";
import {
  applyJob,
  createGalleryFile,
  getResumes,
  updateDigitalAssetById,
  updateGalleryFileById,
} from "../../../store";

import {
  Button,
  Container,
  Form,
  Modal,
  RadioGroup,
  Radio,
  FilePicker,
  FileDragDropPicker,
  CloseIcon,
  IconButton,
  Loader,
  TextField,
} from "../../../components";
import { ROUTER } from "../../../routes";
import { FormValidator, textformat, toBase64 } from "../../../utils";

interface IDigitalAssetEditForm {
  name?: string;
  description?: string;
}

export const DigitalAssetEditModal = () => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const { toast } = useToast();
  const { modal, isOpen, closeModal } = useModal({
    type: ModalType.DIGITAL_ASSET_EDIT,
  });

  const { props } = modal;
  const { id, name, description } = props;

  const [loading, setLoading] = useLoading();

  const form = useFormik<IDigitalAssetEditForm>({
    initialValues: { name, description },
    onSubmit: async (values, { resetForm }) => {
      if (!id) return;

      // Preparing Data
      const { name, description } = values;

      const data = {
        name,
        description: textformat.addLineBreaks(description || ""),
      };

      setLoading(true);

      // Updating the digital asset
      const digitalAsset = await dispatch(updateDigitalAssetById(id, data));
      if (!digitalAsset?.id) {
        setLoading(false);

        toast({
          status: NotificationStatus.Error,
          message: `Digital asset hasn't been updated!`,
        });

        return;
      }

      setLoading(false);
      closeModal();
      resetForm();

      toast({
        status: NotificationStatus.Success,
        message: `Digital asset has been successfully updated.`,
      });
    },
  });

  const { values, handleChange, handleSubmit } = form;

  const validator = new FormValidator(form);

  return (
    <Modal
      title="Edit Digital Asset"
      description="You can change some digital asset details here."
      open={isOpen}
      onClose={closeModal}
    >
      <Container>
        <div className="flex flex-col">
          <div className="flex flex-col gap-y-[10px]">
            <TextField
              id="name"
              label="Title"
              type="text"
              variant="filled"
              className="!bg-primaryDark !text-white"
              controlClassName="basis-1/2"
              labelClassName="text-secondary"
              inputClassName="text-inherit"
              required
              value={values?.name}
              errorMessage={validator.isInputValid("name")}
              onChange={handleChange}
            />
            <TextField
              id="description"
              label="Description"
              type="text"
              variant="filled"
              className="!bg-primaryDark !text-white"
              controlClassName="basis-1/2"
              labelClassName="text-secondary"
              inputClassName="text-inherit"
              minRows={2}
              maxRows={5}
              multiline
              value={values?.description}
              errorMessage={validator.isInputValid("description")}
              onChange={handleChange}
            />
          </div>

          <div className="flex flex-row justify-end items-center gap-x-[10px] mt-[20px]">
            <Button color="secondary" disabled={loading} onClick={closeModal}>
              Discard
            </Button>
            <Button
              color="success"
              className="min-w-[150px]"
              loading={loading}
              onClick={() => handleSubmit()}
            >
              Save Changes
            </Button>
          </div>
        </div>
      </Container>
    </Modal>
  );
};
