import { ApiService } from "../../../services";
import { AppDispatch } from "../../store";

import { verificationSlice } from "./verification.slice";

const appendVerificationRequests =
  (payload: any) => async (dispatch: AppDispatch) => {
    dispatch(
      verificationSlice.actions.appendVerificationRequestsRequests(payload)
    );
  };

const popVerificationRequests =
  (payload: any) => async (dispatch: AppDispatch) => {
    dispatch(
      verificationSlice.actions.popVerificationRequestsRequests(payload)
    );
  };

export const countVerifications =
  (options?: any) => async (dispatch: AppDispatch) => {
    try {
      const { where } = options || {};

      dispatch(verificationSlice.actions.countVerificationsRequest());

      const response = await ApiService.post(
        "verifications/count",
        options || {}
      );
      const { data } = response.data;

      dispatch(verificationSlice.actions.countVerificationsSuccess(data));

      return data;
    } catch (e: any) {
      dispatch(
        verificationSlice.actions.countVerificationsError(
          e.response?.data?.error
        )
      );
    }
  };

export const getVerificationRequests =
  (options: any) => async (dispatch: AppDispatch) => {
    try {
      const { where, include } = options || {};

      dispatch(verificationSlice.actions.getVerificationRequestsRequest());

      const response = await ApiService.post("verifications/query", {
        where,
      });
      const { results, data } = response.data;

      dispatch(
        verificationSlice.actions.getVerificationRequestsSuccess({
          results,
          data,
        })
      );

      return data;
    } catch (e: any) {
      dispatch(
        verificationSlice.actions.getVerificationRequestsError(
          e.response?.data?.error
        )
      );
    }
  };

export const createVerificationRequest =
  (payload: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(verificationSlice.actions.createVerificationRequestRequest());

      const response = await ApiService.post("verifications", payload);
      const { data } = response.data;

      dispatch(
        verificationSlice.actions.createVerificationRequestSuccess(data)
      );

      return data;
    } catch (e: any) {
      console.log({ e });
      dispatch(
        verificationSlice.actions.createVerificationRequestError(
          e.response?.data?.error
        )
      );
    }
  };

export const approveVerificationRequest =
  (id: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(verificationSlice.actions.approveVerificationRequestRequest());

      const response = await ApiService.put(`verifications/${id}/approve`);
      const { data } = response.data;

      dispatch(
        verificationSlice.actions.approveVerificationRequestSuccess(data)
      );
      dispatch(
        verificationSlice.actions.popVerificationRequestsRequests({ id })
      );

      return data;
    } catch (e: any) {
      console.log({ e });
      dispatch(
        verificationSlice.actions.approveVerificationRequestError(
          e.response?.data?.error
        )
      );
    }
  };

export const rejectVerificationRequest =
  (id: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(verificationSlice.actions.rejectVerificationRequestRequest());

      const response = await ApiService.put(`verifications/${id}/reject`);
      const { data } = response.data;

      dispatch(
        verificationSlice.actions.rejectVerificationRequestSuccess(data)
      );
      dispatch(
        verificationSlice.actions.popVerificationRequestsRequests({ id })
      );

      return data;
    } catch (e: any) {
      console.log({ e });
      dispatch(
        verificationSlice.actions.rejectVerificationRequestError(
          e.response?.data?.error
        )
      );
    }
  };

export const getVerificationRequestActions =
  (id: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(
        verificationSlice.actions.getVerificationRequestActionsRequest()
      );

      const response = await ApiService.get(`verifications/${id}/actions`);
      const { results, data } = response.data.data;

      console.log({ data });

      dispatch(
        verificationSlice.actions.getVerificationRequestActionsSuccess({
          results,
          data,
        })
      );

      return data;
    } catch (e: any) {
      console.log({ e });
      dispatch(
        verificationSlice.actions.getVerificationRequestActionsError(
          e.response?.data?.error
        )
      );
    }
  };

export const addVerificationRequestAction =
  (id: string, payload: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(verificationSlice.actions.addVerificationRequestActionRequest());

      const response = await ApiService.post(
        `verifications/${id}/actions`,
        payload
      );
      const { data } = response.data;

      dispatch(
        verificationSlice.actions.addVerificationRequestActionSuccess(data)
      );

      return data;
    } catch (e: any) {
      console.log({ e });
      dispatch(
        verificationSlice.actions.addVerificationRequestActionError(
          e.response?.data?.error
        )
      );
    }
  };

export const removeVerificationRequestAction =
  (id: string, { actionId }: any) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(
        verificationSlice.actions.removeVerificationRequestActionRequest()
      );

      const response = await ApiService.delete(
        `verifications/${id}/actions/${actionId}`
      );
      const { data } = response.data;

      dispatch(
        verificationSlice.actions.removeVerificationRequestActionSuccess(data)
      );

      return data;
    } catch (e: any) {
      console.log({ e });
      dispatch(
        verificationSlice.actions.removeVerificationRequestActionError(
          e.response?.data?.error
        )
      );
    }
  };
