import React, { useEffect } from "react";
import { useParams } from "react-router";

import { FiUpload as UploadIcon } from "react-icons/fi";

import { ModalType } from "../../shared";

import { Button, Container, Link, PageHeader } from "../../components";
import { GalleryFilePreviewCard } from "./../../features";
import { ROUTER } from "../../routes";
import {
  useAccount,
  useAppDispatch,
  useAppSelector,
  useModal,
} from "../../hooks";
import { getGalleryByUserId, getGalleryFiles } from "../../store";

export const UserGalleryContainer = () => {
  const dispatch = useAppDispatch();

  const { userId } = useParams();

  const { setModal } = useModal();
  const { activeProfile } = useAccount();

  const {
    galleryFiles: {
      data: galleryFiles,
      results: galleryFilesCount,
      isLoading: galleryFilesLoading,
    },
  } = useAppSelector((state) => state.gallery);

  const isAuthor = activeProfile?.id === userId;

  const handleUpload = () => {
    setModal({ type: ModalType.GALLERY_UPLOAD });
  };

  useEffect(() => {
    if (!userId) return;

    dispatch(getGalleryFiles({ where: { createdById: userId } }));
  }, [userId]);

  return (
    <Container>
      <PageHeader
        backTo={ROUTER.USER_DETAIL(userId)}
        renderOnRight={
          isAuthor ? (
            <Button
              color="secondary"
              icon={<UploadIcon />}
              onClick={handleUpload}
            >
              Upload
            </Button>
          ) : (
            <></>
          )
        }
      >
        Gallery
      </PageHeader>
      <Container loading={galleryFilesLoading}>
        <div className="grid gap-[2px] xxs:grid-cols-3 sm:grid-cols-3 md:grid-cols-4">
          {galleryFiles?.map((galleryFile) => (
            <GalleryFilePreviewCard {...galleryFile} />
          ))}
        </div>
      </Container>
    </Container>
  );
};
