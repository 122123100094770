import React from "react";

import { useNavigate } from "react-router";
import { ModalType, NotificationStatus } from "../../../shared";

import {
  useAppDispatch,
  useAppSelector,
  useLoading,
  useModal,
  useToast,
} from "../../../hooks";
import { removeCalendarEventById } from "../../../store";

import { Button, Modal } from "../../../components";
import { ROUTER } from "../../../routes";

export const CalendarEventDeleteModal = () => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const { modal, isOpen, closeModal } = useModal({
    type: ModalType.CALENDAR_EVENT_DELETE,
  });

  const { toast } = useToast();
  const [loading, setLoading] = useLoading();

  const { props } = modal;
  const { id, name } = props || {};

  const handleDelete = async () => {
    if (!id) return;

    setLoading(true);

    const removed = await dispatch(removeCalendarEventById(id));
    if (!removed?.id) {
      setLoading(false);
      closeModal();

      return toast({
        status: NotificationStatus.Error,
        message: "Calendar event hasn't been deleted!",
      });
    }

    setLoading(false);
    closeModal();
  };

  return (
    <Modal
      title="Delete Calendar Event"
      description="Please read below before taking action"
      titleClassName="text-danger"
      open={isOpen}
      onClose={closeModal}
    >
      <div>
        <div className="text-sm text-secondary break-words">
          <p>
            Are you sure you want to delete the following calendar event:{" "}
            <span className="text-white font-semibold">{name}</span>?
          </p>
        </div>
        <div className="flex flex-row justify-end items-center gap-x-[10px] mt-5">
          <div className="flex flex-row gap-x-[10px]">
            <Button variant="outlined" color="secondary" onClick={closeModal}>
              Discard
            </Button>

            <Button
              variant="outlined"
              color="danger"
              className="min-w-[125px]"
              loading={loading}
              onClick={handleDelete}
            >
              Delete
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
