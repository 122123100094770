import { createSlice } from "@reduxjs/toolkit";

import {
  AppActionState,
  appendToStore,
  AppReducerState,
  popFromStore,
} from "../..";

interface IDocumentState {
  documents: AppReducerState<any[]>;
  document: AppReducerState<any>;
}

const initialState: IDocumentState = {
  documents: {
    isLoading: false,
    data: [],
    results: 0,
    page: 0,
    pageSize: 0,
    pageCount: 0,
    error: null,
  },
  document: {
    isLoading: false,
    isUpdating: false,
    data: {},
    error: null,
  },
};

export const documentSlice = createSlice({
  name: "Document",
  initialState,
  reducers: {
    getDocumentRequest: (state) => {
      state.document.isLoading = true;
      state.document.data = [];
      state.document.results = 0;
    },
    getDocumentSuccess: (state, action) => {
      const { data, results } = action.payload;

      state.document.isLoading = false;
      state.document.data = data;
      state.document.results = results;
    },
    getDocumentError: (state, action) => {
      state.document.isLoading = false;
      state.document.data = [];
      state.document.results = 0;
      state.document.error = action.payload;
    },
  },
});

export const documentReducer = documentSlice.reducer;
