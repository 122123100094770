import React from "react";

import { ISidebar } from "@types";

import {
  SidebarSection,
  Sidebar,
  SidebarContent,
  Button,
} from "../../../components";
import { ROUTER } from "../../../routes";

export const UserFeedRightSidebar: React.FC<ISidebar> = (props) => (
  <Sidebar {...props}>
    <SidebarContent>
      <SidebarSection heading="Resumes" className="border-none">
        <Button to={ROUTER.RESUME_BUILDER} color="primary" className="w-full">
          Go to Resume Builder
        </Button>
      </SidebarSection>
    </SidebarContent>
  </Sidebar>
);
