import { createSlice } from "@reduxjs/toolkit";

import { IMarketplaceItem } from "../../../shared";
import { AppReducerState } from "../../store";

interface IMarketplaceState {
  items: AppReducerState<IMarketplaceItem[]>;
  item: AppReducerState<IMarketplaceItem>;
}

const initialState: IMarketplaceState = {
  items: {
    isLoading: false,
    data: [],
    error: null,
  },
  item: {
    isLoading: false,
    data: {},
    error: null,
  },
};

export const marketplaceSlice = createSlice({
  name: "Marketplace",
  initialState,
  reducers: {
    getMarketplaceItemsRequest: (state) => {
      state.items.isLoading = true;
      state.items.results = 0;
      state.items.data = [];
    },
    getMarketplaceItemsSuccess: (state, action) => {
      const { data, results, page, pageSize, pages } = action.payload;

      state.items.isLoading = false;
      state.items.data = data;
      state.items.results = results;
      state.items.page = page;
      state.items.pageSize = pageSize;
      state.items.pageCount = pages;
    },
    getMarketplaceItemsError: (state, action) => {
      const { error } = action.payload;

      state.items.isLoading = false;
      state.items.error = error;
    },

    getMarketplaceItemRequest: (state) => {
      state.item.isLoading = true;
      state.item.data = {};
    },
    getMarketplaceItemSuccess: (state, action) => {
      const data = action.payload;

      state.item.isLoading = false;
      state.item.data = data;
    },
    getMarketplaceItemError: (state, action) => {
      const error = action.payload;

      state.items.isLoading = false;
      state.items.error = error;
    },
  },
});

export const marketplaceReducer = marketplaceSlice.reducer;
