import React, { useEffect } from "react";
import { useNavigate } from "react-router";

import {
  MdExpandMore as ExpandMoreIcon,
  MdSettings as SettingsIcon,
} from "react-icons/md";

import { FiLogOut as LogOutIcon } from "react-icons/fi";

import {
  Avatar,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  IconButton,
  Loader,
} from "../../components";
import { ROUTER } from "../../routes";
import {
  logout,
  switchToPersonalProfile,
  switchToBusinessProfile,
} from "../../store";
import {
  useAppDispatch,
  useAppSelector,
  useLoading,
  useAccount,
} from "../../hooks";
import { textformat } from "./../../utils";

export const ProfileNavbar = () => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const {
    account: { data: account, isLoading: accountLoading },
  } = useAppSelector((state) => state.account);

  const { activeProfile, hasNotifications, notificationCount } = useAccount();

  const [loading, setLoading] = useLoading();
  const [logoutLoading, setLogoutLoading] = useLoading();

  const isLoading = loading || accountLoading;

  const { firstName, lastName } = activeProfile || {};

  const fullName = textformat.shorten(
    `${firstName || ""} ${lastName || ""}`,
    25
  );

  const accountId = account?.id;
  const profileId = account?.profileId;

  const isBusiness = activeProfile?.isBusiness || false;
  const isOwner = account?.ownership?.id ? true : false;
  const isOwnerVerification = account?.ownershipVerification?.id ? true : false;
  const isEmployee = account?.employment?.id ? true : false;
  const isEmployed = isOwner || isEmployee;

  const handleSwitchToPersonal = async (e) => {
    if (!accountId) return;

    setLoading(true);

    await dispatch(switchToPersonalProfile(accountId));

    setLoading(false);
    redirect(ROUTER.HOME);
  };

  const handleSwitchToBusiness = async (e) => {
    if (!accountId) return;

    setLoading(true);

    await dispatch(switchToBusinessProfile(accountId));

    setLoading(false);
    redirect(ROUTER.HOME);
  };

  const handleLogout = async () => {
    setLogoutLoading(true);

    const loggedOut = await dispatch(logout());
    if (!loggedOut) {
      return setLogoutLoading(false);
    }

    setLogoutLoading(false);
    redirect(ROUTER.LOGIN);
  };

  return (
    <div className="flex justify-between items-center">
      {isLoading ? (
        <div className="transition-all w-[75px] flex flex-row justify-between items-center px-[6px] py-[6px] box-border rounded-full bg-primaryLight cursor-pointer">
          <Loader size={18} className="text-secondary" />
        </div>
      ) : profileId ? (
        <DropdownMenu
          open={true}
          className="max-w-[200px] px-[20px] py-[20px] mt-[17px] -ml-[5px]"
          button={
            <div className="transition-all w-[75px] flex flex-row justify-between items-center px-[6px] py-[6px] box-border rounded-full bg-primaryLight cursor-pointer hover:brightness-110">
              <Avatar
                to={ROUTER.USER_DETAIL(profileId)}
                size={24}
                src={activeProfile?.avatar}
                className={
                  isBusiness ? "border-[2px] border-solid border-warning" : ""
                }
              >
                {isBusiness ? firstName?.slice(0, 1) : firstName?.slice(0, 1)}
              </Avatar>

              <div className="flex flex-row justify-start items-center gap-x-[5px]">
                <div className="md:flex xxs:hidden">
                  <IconButton icon={ExpandMoreIcon} size={28} />
                </div>
              </div>
            </div>
          }
        >
          <div className="mt-[5px] mb-[5px]">
            <h2 className="font-semibold text-base -mb-[8px]">
              <span className="capitalize">{fullName}</span>
            </h2>

            {isBusiness ? (
              <span className="text-warning uppercase text-xxs font-medium">
                Business
              </span>
            ) : (
              <span className="text-secondary uppercase text-xxs font-medium">
                Personal
              </span>
            )}
          </div>

          <div className="flex flex-col">
            <div className="flex flex-col gap-[2.5px]">
              <DropdownItem to={ROUTER.USER_DETAIL(profileId)}>
                Profile
              </DropdownItem>
              <DropdownItem to={ROUTER.USER_SETTINGS}>Settings</DropdownItem>
            </div>

            <div className="border-t border-b border-solid border-primaryLight my-[10px] box-border py-[10px]">
              <DropdownItem>
                {isBusiness ? (
                  <Button
                    color="secondary"
                    variant="outlined"
                    onClick={handleSwitchToPersonal}
                  >
                    Switch to Personal
                  </Button>
                ) : isEmployed ? (
                  <div className="flex flex-col gap-y-[10px]">
                    <Button
                      color="warning"
                      variant="outlined"
                      onClick={handleSwitchToBusiness}
                    >
                      Switch to Business
                    </Button>
                  </div>
                ) : isOwnerVerification ? (
                  <div className="text-xs text-secondary cursor-default">
                    <span>Company registration pending...</span>
                  </div>
                ) : (
                  <Button
                    color="success"
                    variant="outlined"
                    to={ROUTER.BUSINESS_REGISTRATION}
                  >
                    Register as Business
                  </Button>
                )}
              </DropdownItem>
            </div>

            <div>
              <DropdownItem
                icon={LogOutIcon}
                iconClassName="text-danger"
                className="hover:text-danger"
                onClick={handleLogout}
              >
                Logout
              </DropdownItem>
            </div>
          </div>
        </DropdownMenu>
      ) : (
        <Button color="primary" variant="outlined" to={ROUTER.LOGIN}>
          Login
        </Button>
      )}
    </div>
  );
};

ProfileNavbar.defaultProps = {};
