import React, { useEffect, useState } from "react";

import { Tab, Tabs } from "@mui/material";

interface ITabSwitchProps {
  tabs: ITab[];
  activeTabId?: string;
  indicatorClassName?: string;
  onChange?: (tabId: string) => void;
}

export const TabSwitch: React.FC<ITabSwitchProps> = ({
  tabs,
  activeTabId,
  indicatorClassName,
  onChange,
}) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleChange = (e, index: number) => {
    setActiveTab(index);

    if (onChange) {
      const tabId = tabs?.[index]?.id;
      if (!tabId) return;

      onChange(tabId);
    }
  };

  useEffect(() => {
    const activeTabIndex = tabs?.findIndex((i) => i?.id === activeTabId) || 0;
    console.log({ activeTabIndex });

    if (activeTabIndex < 0) {
      setActiveTab(0);
      return;
    }

    setActiveTab(activeTabIndex);
  }, [activeTabId]);

  return (
    <div className="flex flex-col">
      <div className="flex flex-row justify-between items-center">
        <Tabs
          className="flex flex-row justify-start"
          TabIndicatorProps={{
            className: `${indicatorClassName} bg-primary h-[2px]`,
          }}
          value={activeTab}
          onChange={handleChange}
        >
          {tabs?.map(({ label }, index) => {
            const active = activeTab === index || false;

            return (
              <Tab
                key={label}
                label={label}
                className={`flex flex-row justify-center capitalize text-base font-gilroy min-w-0 min-h-0 px-0 mx-0 mr-5 ${
                  active
                    ? "text-white font-semibold"
                    : "text-secondary font-semibold"
                }`}
                disableRipple
              />
            );
          })}
        </Tabs>
      </div>
      <div className="w-full mt-[15px]">
        <TabViews activeTab={activeTab} tabs={tabs} />
      </div>
    </div>
  );
};

TabSwitch.defaultProps = {
  tabs: [],
  indicatorClassName: "",
};

interface ITabViewsProps {
  activeTab: number;
  tabs?: ITab[];
  onRight?: () => JSX.Element;
}

interface ITab {
  id: string;
  label: string;
  component: () => JSX.Element;
}

export const TabViews: React.FC<ITabViewsProps> = ({ activeTab, tabs }) => {
  const [view, setView] = useState(() => <></>);

  useEffect(() => {
    if (!tabs?.length || !tabs?.[activeTab]) return;

    const { component: Component } = tabs[activeTab];

    setView(() => <Component />);
  }, [activeTab]);

  return <div className="w-full">{view ? view : <></>}</div>;
};

TabViews.defaultProps = {
  activeTab: 0,
  tabs: [],
};
