import { combineReducers, configureStore } from "@reduxjs/toolkit";

import {
  appReducer,
  accountReducer,
  profileReducer,
  profileCoverReducer,
  verificationReducer,
  resumeReducer,
  experienceReducer,
  educationReducer,
  jobReducer,
  jobApplicationReducer,
  postReducer,
  companyReducer,
  inviteReducer,
  chatReducer,
  notificationReducer,
  auditReducer,
  commentReducer,
  videoCallReducer,
  calendarEventReducer,
  interviewReducer,
  galleryReducer,
  digitalPortfolioReducer,
  licenseReducer,
  marketplaceReducer,
  documentReducer,
} from "./reducers";

export const rootReducer = combineReducers({
  app: appReducer,
  account: accountReducer,
  notification: notificationReducer,

  profile: profileReducer,
  profileCover: profileCoverReducer,

  company: companyReducer,
  verification: verificationReducer,
  audit: auditReducer,

  experience: experienceReducer,
  education: educationReducer,

  resume: resumeReducer,
  chat: chatReducer,

  job: jobReducer,
  jobApplication: jobApplicationReducer,

  post: postReducer,
  comment: commentReducer,
  invite: inviteReducer,

  videoCall: videoCallReducer,
  calendarEvent: calendarEventReducer,
  interview: interviewReducer,
  gallery: galleryReducer,
  digitalPortfolio: digitalPortfolioReducer,

  license: licenseReducer,
  marketplace: marketplaceReducer,
  document: documentReducer,
});

export const setupStore = () =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
    devTools:
      process.env?.NODE_ENV !== "production"
        ? {
            trace: true,
            traceLimit: 25,
            actionsDenylist: ["Notification"],
          }
        : false,
  });

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
export type GetRootState = () => RootState;

export interface AppReducerState<T = any> {
  data: T;
  results?: number;
  page?: number;
  pageSize?: number;
  pageCount?: number;
  error?: string | null;
  refreshedAt?: Date;

  isLoading: boolean;
  isLoadingMore?: boolean;
  isCreating?: boolean;
  isUpdating?: boolean;
}

export type AppActionModeType = "get" | "append";

export interface AppActionOptions {
  mode?: AppActionModeType;
}

export interface AppActionState<T = any> {
  type: string;
  payload: AppActionStatePayload<T>;
}

export interface AppActionStatePayload<T = any> {
  mode?: AppActionModeType;
  data?: T | null;
  results?: number;
  page?: number;
  pageSize?: number;
  pageCount?: number;
  error?: string | null;

  isLoading?: boolean;
  isLoadingMore?: boolean;
  isCreating?: boolean;
  isUpdating?: boolean;
}
