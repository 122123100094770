import React from "react";

import { Link } from "./../../components";

interface INavbarIconLink {
  className?: string;
  to?: string | null;
  counter?: number;
  counterClassName?: string;
  counterComponent?: () => JSX.Element;
  icon: React.FunctionComponent;
  iconSize?: number;
  iconProps?: object;
  iconClassName?: string;
  isActive?: boolean;
  onClick?: (e?: React.MouseEvent) => void;
}

export const NavbarIconLink: React.FC<INavbarIconLink> = ({
  children,
  counter,
  counterClassName,
  className,
  to,
  onClick,
  icon: Icon,
  iconSize,
  iconProps,
  iconClassName,
  isActive,
}) => (
  <div
    className={`${className} ${
      isActive ? "text-white fill-white" : "text-secondary fill-secondary"
    } transition-all relative flex flex-row justify-start items-center font-semibold text-sm cursor-pointer hover:fill-white hover:text-white`}
  >
    {to && <Link to={to} className="absolute w-full h-full z-40" />}

    <div
      className="relative flex flex-row justify-start items-center text-inherit py-[2px] px-[2px] z-30"
      onClick={onClick}
    >
      {Icon ? (
        <div className="w-auto h-[20px] overflow-hidden">
          <Icon
            // @ts-ignore
            size={iconSize}
            className="fill-inherit text-inherit"
            {...iconProps}
          />
        </div>
      ) : (
        <></>
      )}

      <NavbarCounterIcon
        count={counter || 0}
        className="absolute -top-[5px] -right-[2px] text-white text-sm bg-coral text-white"
      />
    </div>
  </div>
);

NavbarIconLink.defaultProps = {
  className: "",
  counter: 0,
  counterClassName: "",
  iconClassName: "",
  isActive: false,
};

interface INavbarCounterIconProps {
  count: number;
  className?: string;
}

export const NavbarCounterIcon: React.FC<INavbarCounterIconProps> = ({
  count,
  className,
}) => {
  return count >= 1 ? (
    <div
      className={`
        ${className}
        w-[16px] h-[16px] flex flex-row justify-center items-center bg-inherit rounded-full
    `}
    >
      <span className="text-[0.6em] z-20">
        {count >= 1 && count <= 99 ? count : "99"}
      </span>
    </div>
  ) : (
    <></>
  );
};

NavbarCounterIcon.defaultProps = {
  count: 0,
  className: "",
};
