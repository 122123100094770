import { createSlice } from "@reduxjs/toolkit";

import { IDigitalAsset, IDigitalPortfolio } from "../../../shared";
import { AppReducerState } from "../../store";

interface IDigitalPortfolioState {
  digitalPortfolio: AppReducerState<IDigitalPortfolio>;
  digitalAssets: AppReducerState<IDigitalAsset[]>;
  digitalAsset: AppReducerState<IDigitalAsset>;
}

const initialState: IDigitalPortfolioState = {
  digitalPortfolio: {
    isLoading: false,
    data: {},
    error: null,
  },
  digitalAssets: {
    isLoading: false,
    data: [],
    error: null,
  },
  digitalAsset: {
    isLoading: false,
    data: {},
    error: null,
  },
};

export const digitalPortfolioSlice = createSlice({
  name: "Digital Portfolio",
  initialState,
  reducers: {
    setDigitalAsset: (state, action) => {
      if (!action?.payload) return;

      state.digitalAsset.data = {
        ...state.digitalAsset.data,
        ...action.payload,
      };
    },

    getDigitalPortfolioByUserIdRequest: (state) => {
      state.digitalPortfolio.isLoading = true;
      state.digitalPortfolio.data = {};
    },
    getDigitalPortfolioByUserIdSuccess: (state, action) => {
      const { data } = action.payload;

      state.digitalPortfolio.isLoading = false;
      state.digitalPortfolio.data = data;
    },
    getDigitalPortfolioByUserIdError: (state, action) => {
      const { error } = action.payload;

      state.digitalPortfolio.isLoading = false;
      state.digitalPortfolio.error = error;
    },

    getDigitalAssetsRequest: (state) => {
      state.digitalAssets.isLoading = true;
      state.digitalAssets.results = 0;
      state.digitalAssets.data = [];
    },
    getDigitalAssetsSuccess: (state, action) => {
      const { data } = action.payload;

      state.digitalAssets.isLoading = false;
      state.digitalAssets.data = data;
    },
    getDigitalAssetsError: (state, action) => {
      const { error } = action.payload;

      state.digitalAssets.isLoading = false;
      state.digitalAssets.error = error;
    },

    getDigitalAssetRequest: (state) => {
      state.digitalAsset.isLoading = true;
      state.digitalAsset.data = {};
    },
    getDigitalAssetSuccess: (state, action) => {
      const { data } = action.payload;

      state.digitalAsset.isLoading = false;
      state.digitalAsset.data = data;
    },
    getDigitalAssetError: (state, action) => {
      const error = action.payload;

      state.digitalAsset.isLoading = false;
      state.digitalAsset.error = error;
    },

    createDigitalAssetRequest: (state) => {},
    createDigitalAssetSuccess: (state, action) => {
      const { data } = action.payload;

      state.digitalAssets.isLoading = false;
      state.digitalAssets.data = [data, ...state.digitalAssets.data];
    },
    createDigitalAssetError: (state, action) => {
      const { error } = action.payload;

      state.digitalAssets.isLoading = false;
      state.digitalAssets.error = error;
    },

    updateDigitalAssetRequest: (state) => {
      state.digitalAsset.isUpdating = true;
    },
    updateDigitalAssetSuccess: (state, action) => {
      state.digitalAsset.isUpdating = false;
      state.digitalAsset.data = {
        ...state.digitalAsset.data,
        ...action.payload,
      };
    },
    updateDigitalAssetError: (state, action) => {
      state.digitalAsset.isUpdating = false;
      state.digitalAsset.error = action.payload;
    },

    removeDigitalAssetByIdRequest: (state) => {},
    removeDigitalAssetByIdSuccess: (state, action) => {
      state.digitalAsset.isLoading = false;
      state.digitalAsset.data = {};
    },
    removeDigitalAssetByIdError: (state, action) => {
      const error = action.payload;

      state.digitalAsset.isLoading = false;
      state.digitalAsset.error = error;
    },

    createDigitalAssetLicenseRequest: (state) => {},
    createDigitalAssetLicenseSuccess: (state, action) => {},
    createDigitalAssetLicenseError: (state, action) => {},

    updateDigitalAssetLicenseRequest: (state) => {},
    updateDigitalAssetLicenseSuccess: (state, action) => {
      const license = {
        ...state.digitalAsset.data?.license,
        ...action.payload,
      };

      state.digitalAsset.data = {
        ...state.digitalAsset.data,
        isLicense: true,
        isPublic: true,
        license,
      };
    },
    updateDigitalAssetLicenseError: (state, action) => {},
  },
});

export const digitalPortfolioReducer = digitalPortfolioSlice.reducer;
