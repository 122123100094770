import React, { useEffect, useMemo, useState } from "react";

import { BasicModal, Modal } from "./../components";
import { closeModal, updateModal } from "../store";
import { useAppDispatch, useAppSelector } from "./useRedux";

interface IUseModalProps {
  type?: string;
}

export const useModal = (data?: IUseModalProps) => {
  const dispatch = useAppDispatch();

  const { modal } = useAppSelector((state) => state.app);

  const [isOpen, setOpen] = useState(false);

  const setModal = (state: any) => {
    dispatch(updateModal(state));
  };

  const handleClose = () => {
    dispatch(closeModal());
  };

  useEffect(() => {
    setOpen(modal?.type === data?.type || false);
  }, [modal]);

  return { modal, isOpen, setModal, closeModal: handleClose };
};

interface IUseBasicModalProps {
  component?: React.ComponentType<IBasicModalComponentProps>;
}

interface IBasicModalComponentProps {
  title?: string;
  description?: string;
  open?: boolean;
  className?: string;
  width?: number;
  onClose?: () => void;
}

export const useBasicModal = (config?: IUseBasicModalProps) => {
  const [open, setOpen] = useState(false);

  const setModal = (open: boolean) => {
    setOpen(open);
  };

  const Component: React.ComponentType<IBasicModalComponentProps> = useMemo(
    () =>
      ({ children, ...props }) =>
        (
          <BasicModal
            open={open}
            disableBackdrop
            onClose={() => setOpen(false)}
            {...props}
          >
            {children}
          </BasicModal>
        ),
    [open]
  );

  Component.defaultProps = {
    className: "",
    width: 500,
  };

  return { modal: open, setModal, Component };
};
