import * as Yup from "yup";

import { LicenseType } from "../../../shared";

export interface ILicenseOptionsForm {
  type?: LicenseType;
  price?: number;
  duration?: number;
  isActive?: boolean;
  isPostControl?: boolean;
  postLimits?: any[];
  isCommercialUsage?: boolean;
  terms?: string;
}

export const LicenseOptionsFormInitialValues = {
  type: LicenseType.NonExclusive,
  price: 0,
  duration: 0,
  isActive: true,
  isPostControl: false,
  postLimits: [] as any[],
  isCommercialUsage: false,
  terms: "",
};

export const LicenseOptionsFormValidationSchema = Yup.object().shape({
  type: Yup.string().required("Type is a required field"),
  price: Yup.number()
    .typeError("Invalid format")
    .min(5, "Price must be 5 USD at least")
    .max(99999, "Price must be less than 99999 USD")
    .required("Price is a required field"),
  duration: Yup.number()
    .typeError("Invalid format")
    .min(365, "Duration must be 365 days at least")
    .max(1000, "Duration must be less than 1000 days")
    .required("Duration is a required field"),
  isActive: Yup.boolean().notRequired(),
  isPostControl: Yup.boolean().notRequired(),
  isCommercialUsage: Yup.boolean().notRequired(),
  terms: Yup.string()
    .max(1500, "Exceeded maximum character length of 1500")
    .notRequired(),
});
