import React from "react";

interface IGridProps {
  className?: string;
  col?: number;
}

export const Grid: React.FC<IGridProps> = ({ children, col, className }) => {
  let cols = "";

  switch (col) {
    case 1:
      cols = "grid-cols-1";
      break;
    case 2:
      cols = "grid-cols-2";
      break;
    case 3:
      cols = "grid-cols-3";
      break;
    case 4:
      cols = "grid-cols-4";
      break;
    case 5:
      cols = "grid-cols-5";
      break;
    case 6:
      cols = "grid-cols-6";
      break;
    default:
      cols = "xxs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4";
      break;
  }

  return (
    <div className={`${className} w-full h-auto grid ${cols} gap-[5px]`}>
      {children}
    </div>
  );
};

Grid.defaultProps = {
  className: "",
};
