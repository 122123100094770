import { createContext } from "react";

export interface IRegisterContextState {
  email?: string;
  password?: string;
  passport?: any;
  profile?: any;
  isPassportVerified?: boolean;
  isEmailVerified?: boolean;
}

interface IRegisterContext {
  registerState: IRegisterContextState;
  setRegisterState: (values: IRegisterContextState) => void;
}

export const RegisterContext = createContext<IRegisterContext>({
  registerState: {},
  setRegisterState: () => {},
});
