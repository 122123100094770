import React from "react";

import {
  Avatar,
  BulletPointList,
  VerificationStatusBadge,
} from "../../components";
import { date, textformat } from "../../utils";

export const ExperienceDetailSection = ({
  id,
  role,
  company,
  details,
  verification,
  profileId,
  isWorking,
  isDraft,
  startedAt,
  finishedAt,
}) => {
  const isTimestamp = !!startedAt || !!finishedAt;

  return (
    <div className="flex flex-col justify-start">
      <div className="flex flex-row justify-start items-start gap-x-[10px] break-words">
        <Avatar size={40}>{company}</Avatar>
        <div
          className={`flex flex-col items-start justify-center gap-y-[3px] mt-[2px]`}
        >
          <div className="flex flex-row justify-start items-center gap-x-[10px]">
            <h4 className="flex flex-row justify-start items-center flex-wrap gap-x-[8px] gap-y-[3px] text-white font-semibold leading-none">
              <span className="break-words">{role} at </span>
              <span className="text-primary">{company}</span>
              {isDraft ? (
                <span className="text-sm text-secondary">(Draft)</span>
              ) : (
                <VerificationStatusBadge
                  className="text-sm"
                  status={verification?.status}
                  experience={{ id, role, company, profileId }}
                />
              )}
            </h4>
          </div>

          {isTimestamp && (
            <span className="text-xs font-medium text-secondary leading-none">
              {startedAt && finishedAt ? (
                `${date(startedAt).format("MMM YYYY")} - ${
                  isWorking ? "Present" : date(finishedAt).format("MMM YYYY")
                }`
              ) : (
                <></>
              )}
            </span>
          )}
        </div>
      </div>
      <div className="text-base text-secondary font-medium md:ml-[50px] xxs:mx-[0px]">
        <BulletPointList data={details} className="mt-[15px] break-words" />
      </div>
    </div>
  );
};

ExperienceDetailSection.defaultProps = {
  shorten: false,
  isWorking: false,
  isVerified: false,
};
