import React, { useEffect, useState } from "react";

import { MdInfo as InfoIcon } from "react-icons/md";

import { useFormik } from "formik";

import { ModalType } from "../../../shared";

import {
  BasicDatePicker,
  Button,
  Container,
  Form,
  Loader,
  Modal,
  TextField,
} from "../../../components";
import {
  ILicensePaymentForm,
  LicensePaymentFormInitialValues,
  LicensePaymentFormValidationSchema,
} from "../../../features";

import {
  useAppDispatch,
  useAppSelector,
  useLoading,
  useModal,
} from "../../../hooks";
import { FormValidator, textformat } from "../../../utils";

export const LicensePaymentModal = () => {
  const dispatch = useAppDispatch();

  const { modal, setModal, isOpen } = useModal({
    type: ModalType.LICENSE_PAYMENT,
  });

  const [loading, setLoading] = useLoading();
  const [calculating, setCalculating] = useLoading();

  const [fee, setFee] = useState(0);
  const [total, setTotal] = useState(0);

  const {
    digitalAsset: { data: digitalAsset, isLoading: digitalAssetLoading },
  } = useAppSelector((state) => state.digitalPortfolio);

  const { duration } = modal.props || {};
  const licenseId = digitalAsset?.license?.id;

  const form = useFormik<ILicensePaymentForm>({
    validationSchema: LicensePaymentFormValidationSchema,
    initialValues: { ...LicensePaymentFormInitialValues },
    onSubmit: async (values) => {
      console.log({ values });

      setLoading(true);

      setTimeout(() => {
        setLoading(false);
      }, 2000);
    },
  });

  const validator = new FormValidator<ILicensePaymentForm>(form);

  const { values, setFieldValue, handleChange, handleSubmit } = form;

  const handleGoBack = () => {
    setModal({
      type: ModalType.LICENSE_PURCHASE,
    });
  };

  return (
    <Modal
      title="Payment Details"
      description="Please specify your payment details"
      open={isOpen}
      className="h-[760px]"
      dialogClassName="max-w-[480px] max-h-[90vh]"
    >
      <Container
        loading={digitalAssetLoading}
        className="w-full h-full overflow-y-scroll pr-[15px]"
      >
        {calculating ? (
          <div className="w-full h-full flex flex-row justify-center items-center">
            <div className="flex flex-col justify-center items-center gap-[10px]">
              <Loader size={20} className="text-secondary" />
              <span className="text-sm text-secondary">Calculating...</span>
            </div>
          </div>
        ) : (
          <Form onSubmit={handleSubmit}>
            <div className="w-full">
              {/* <div className="flex flex-col gap-[10px]">
                <div className="flex flex-col gap-[10px]">
                  <TextField
                    id="fullName"
                    label="Full Name"
                    type="text"
                    variant="filled"
                    className="!bg-primaryDark !text-white"
                    labelClassName="text-secondary"
                    value={values?.fullName}
                    errorMessage={validator.isInputValid("fullName")}
                    onChange={handleChange}
                  />
                  <TextField
                    id="companyName"
                    label="Company Name"
                    type="string"
                    variant="filled"
                    className="!bg-primaryDark !text-white"
                    labelClassName="text-secondary"
                    value={values?.companyName}
                    errorMessage={validator.isInputValid("companyName")}
                    onChange={handleChange}
                  />
                  <TextField
                    id="country"
                    label="Country"
                    type="text"
                    variant="filled"
                    className="!bg-primaryDark !text-white"
                    labelClassName="text-secondary"
                    value={values?.country}
                    errorMessage={validator.isInputValid("country")}
                    onChange={handleChange}
                  />
                  <TextField
                    id="state"
                    label="State"
                    type="text"
                    variant="filled"
                    className="!bg-primaryDark !text-white"
                    labelClassName="text-secondary"
                    value={values?.state}
                    errorMessage={validator.isInputValid("state")}
                    onChange={handleChange}
                  />
                  <TextField
                    id="city"
                    label="City"
                    type="text"
                    variant="filled"
                    className="!bg-primaryDark !text-white"
                    labelClassName="text-secondary"
                    value={values?.city}
                    errorMessage={validator.isInputValid("city")}
                    onChange={handleChange}
                  />
                  <TextField
                    id="cardNumber"
                    label="Card Number"
                    type="text"
                    variant="filled"
                    className="!bg-primaryDark !text-white"
                    labelClassName="text-secondary"
                    value={values?.cardNumber}
                    errorMessage={validator.isInputValid("cardNumber")}
                    onChange={handleChange}
                  />
                  <TextField
                    id="cardHolder"
                    label="Card Holder Name"
                    type="text"
                    variant="filled"
                    className="!bg-primaryDark !text-white"
                    labelClassName="text-secondary"
                    value={values?.cardHolder}
                    errorMessage={validator.isInputValid("cardHolder")}
                    onChange={handleChange}
                  />
                  <div className="flex flex-row gap-[10px]">
                    <div className="basis-8/12">
                      <BasicDatePicker
                        id="cardExpirationDate"
                        label="Card Expiration Date"
                        value={values?.cardExpirationDate}
                        errorMessage={validator.isInputValid("cardExpirationDate")}
                        className="!bg-primaryDark !text-white"
                        disablePast
                        disableOpenPicker
                        onChange={(value) =>
                          setFieldValue("startDate", value, true)
                        }
                      />
                    </div>
                    <div className="basis-4/12">
                      <TextField
                        id="cvc"
                        label="CVC"
                        type="password"
                        variant="filled"
                        className="!bg-primaryDark !text-white"
                        labelClassName="text-secondary"
                        maxLength={3}
                        value={values?.cvc}
                        errorMessage={validator.isInputValid("cvc")}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-row justify-start items-start gap-[10px] border-[1px] border-dashed border-primary rounded-lg p-[10px] box-border text-xs mt-[25px]">
                <InfoIcon size={24} className="text-white" />
                <p>
                  Legitbook doesn't store any of your payment details. It's
                  fully processed by an external payment gateway.
                </p>
              </div>

              <div className="flex flex-col gap-[5px] mt-[30px]">
                <div className="flex flex-row justify-between items-center gap-[5px]">
                  <span className="text-base text-secondary font-medium">
                    Total
                  </span>
                  <span className="text-white font-semibold">${total}</span>
                </div>
              </div> */}

              <div className="w-full flex flex-row justify-end mt-[25px] gap-[10px]">
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleGoBack}
                >
                  Back
                </Button>
                <Button
                  variant="outlined"
                  color="success"
                  type="submit"
                  className="min-w-[150px]"
                  loading={loading}
                >
                  Pay Now
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Container>
    </Modal>
  );
};
