import React, { useEffect, useRef } from "react";

import { CardList, Container, LoadMoreButton, Loader } from "../../components";
import { PostCard, PostCreateForm } from "../../features";

import {
  closeModal,
  getJobs,
  getPosts,
  removeJob,
  updateJob,
  postSlice,
  getPostFeed,
} from "../../store";
import { Action, ModalType } from "../../shared";
import {
  useAppDispatch,
  useAppSelector,
  useLoading,
  useModal,
  useScroll,
  useToast,
} from "../../hooks";

interface IPostListProps {
  results?: number;
  page?: number;
  pageSize?: number;
  pageCount?: number;
  onLoad?: (...args) => Promise<void>;
}

export const PostList: React.FC<IPostListProps> = ({ onLoad, ...props }) => {
  const { results = 0, page = 1, pageSize = 0, pageCount = 0 } = props;

  const [loading, setLoading] = useLoading();

  const {
    posts: { data, isLoading },
  } = useAppSelector((state) => state.post);

  const isFound = pageCount >= 1;
  const isLastPage = pageCount >= 1 ? page >= pageCount : true;

  const handleLoad = async () => {
    if (!onLoad) return;

    setLoading(true);

    await onLoad();

    setLoading(false);
  };

  return (
    <Container loading={isLoading}>
      <CardList>
        {data?.map((post) => (
          <PostCard
            key={post?.id}
            {...post}
            actions={[Action.Edit, Action.Delete]}
          />
        ))}

        {isFound ? (
          isLastPage ? (
            <></>
          ) : (
            <LoadMoreButton loading={loading} onClick={handleLoad} />
          )
        ) : (
          <></>
        )}

        {/* <div className="w-full h-[50px] flex flex-row justify-center items-center">
          <span className="text-xs text-secondary">
            That's everything we have for you so far.
          </span>
        </div> */}
      </CardList>
    </Container>
  );
};

PostList.defaultProps = {
  page: 1,
  pageSize: 0,
  pageCount: 0,
};
