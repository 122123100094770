import React from "react";

import { lazyload } from "../../utils";
import { MainExtendedLayout, MainLayout } from "../../layouts";

const { UserLicensesContainer, LicenseSidebar } = lazyload(
  () => import("../../features")
);

export const UserLicensesPage = () => (
  <MainExtendedLayout leftSidebar={LicenseSidebar}>
    <UserLicensesContainer />
  </MainExtendedLayout>
);
