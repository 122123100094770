import { ApiService } from "../../../services";
import { AppDispatch } from "../../store";

import { interviewSlice } from "./interview.slice";

export const getInterviews =
  (options?: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(interviewSlice.actions.getInterviewsRequest());

      const response = await ApiService.post("interviews/query", options || {});
      const { results, data } = response.data;

      dispatch(
        interviewSlice.actions.getInterviewsSuccess({
          results,
          data,
        })
      );

      return data;
    } catch (e: any) {
      dispatch(
        interviewSlice.actions.getInterviewsError(e.response?.data?.error)
      );
    }
  };

export const getInterviewById =
  (id: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(interviewSlice.actions.getInterviewByIdRequest());

      const response = await ApiService.get(`interviews/${id}`);
      const { data } = response.data;

      dispatch(
        interviewSlice.actions.getInterviewByIdSuccess({
          data,
        })
      );

      return data;
    } catch (e: any) {
      dispatch(
        interviewSlice.actions.getInterviewByIdError(e.response?.data?.error)
      );
    }
  };

export const createInterview =
  (payload: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(interviewSlice.actions.createInterviewRequest());

      const response = await ApiService.post("interviews", payload);
      const { data } = response.data;

      dispatch(interviewSlice.actions.createInterviewSuccess({ data }));

      return data;
    } catch (e: any) {
      console.log({ e });
      dispatch(
        interviewSlice.actions.createInterviewError(e.response?.data?.error)
      );
    }
  };

export const updateInterview =
  (id: string, payload: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(interviewSlice.actions.updateInterviewRequest());

      const response = await ApiService.put(`interviews/${id}`, payload);
      const { data } = response.data;

      dispatch(interviewSlice.actions.updateInterviewSuccess(data));

      return data;
    } catch (e: any) {
      console.log({ e });
      dispatch(
        interviewSlice.actions.updateInterviewError(e.response?.data?.error)
      );
    }
  };

export const removeInterview =
  (id: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(interviewSlice.actions.removeInterviewRequest());

      await ApiService.delete(`interviews/${id}`);

      dispatch(interviewSlice.actions.removeInterviewSuccess({ data: { id } }));
    } catch (e: any) {
      console.log({ e });
      dispatch(
        interviewSlice.actions.removeInterviewError(e.response?.data?.error)
      );
    }
  };

export const startInterview = (id: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(interviewSlice.actions.startInterviewRequest());

    const response = await ApiService.put(`interviews/${id}/start`);
    const { data } = response.data;

    dispatch(interviewSlice.actions.startInterviewSuccess(data));

    console.log({ data });

    return data;
  } catch (e: any) {
    console.log({ e });
    dispatch(
      interviewSlice.actions.startInterviewError(e.response?.data?.error)
    );
  }
};
