import React, { Suspense } from "react";

import { Loader } from "../components";

export const InfoLayout: React.FC = ({ children }) => (
  <div className="w-full max-w-[760px] flex flex-col justify-center items-center h-auto mx-auto bg-primaryDark overflow-x-hidden box-border md:p-[0px] xxs:p-[20px]">
    <Suspense fallback={<Loader />}>{children}</Suspense>
  </div>
);

InfoLayout.defaultProps = {};
