import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";

import { ModalType } from "../../../shared";

import {
  useAppDispatch,
  useLoading,
  useModal,
  useAppSelector,
} from "../../../hooks";

import { Button, Container, Modal, BasicSelect } from "../../../components";
import { setVideoCallClient } from "../../../store";

interface IMediaDevice extends MediaDeviceInfo {}

export const VideoCallSettingsModal = () => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const { modal, isOpen, closeModal } = useModal({
    type: ModalType.VIDEO_CALL_SETTINGS,
  });

  const [loading, setLoading] = useLoading();

  const videoContainerRef = useRef<HTMLVideoElement>(null);

  const [devices, setDevices] = useState<IMediaDevice[]>([]);

  const [videoInputDevice, setVideoInputDevice] = useState<IMediaDevice>();
  const [audioInputDevice, setAudioInputDevice] = useState<IMediaDevice>();

  const { videoCallClient } = useAppSelector((state) => state.videoCall);

  const VIDEO_INPUT_DEVICE_SELECT = devices
    ?.filter(({ kind }) => kind === "videoinput")
    ?.map(({ deviceId, label }) => ({
      id: deviceId,
      value: label,
    }));

  const AUDIO_INPUT_DEVICE_SELECT = devices
    ?.filter(({ kind }) => kind === "audioinput")
    ?.map(({ deviceId, label }) => ({
      id: deviceId,
      value: label,
    }));

  const {
    props: { videoCallId },
  } = modal;

  const getDevices = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();

    setDefaultDevices(devices);
    setDevices(devices);
  };

  const setDefaultDevices = async (devices: IMediaDevice[]) => {
    const DEFAULT_VIDEO_INPUT_DEVICE_ID = localStorage.getItem(
      "DEFAULT_VIDEO_INPUT_DEVICE_ID"
    );
    const DEFAULT_AUDIO_INPUT_DEVICE_ID = localStorage.getItem(
      "DEFAULT_AUDIO_INPUT_DEVICE_ID"
    );

    const videoInputDevices =
      devices?.filter(({ kind }) => kind === "videoinput") || [];
    const audioInputDevices =
      devices?.filter(({ kind }) => kind === "audioinput") || [];

    const videoInputDevice = DEFAULT_VIDEO_INPUT_DEVICE_ID
      ? videoInputDevices?.find(
          ({ deviceId }) => deviceId === DEFAULT_VIDEO_INPUT_DEVICE_ID
        )
      : videoInputDevices?.[0];

    const audioInputDevice = DEFAULT_AUDIO_INPUT_DEVICE_ID
      ? audioInputDevices?.find(
          ({ deviceId }) => deviceId === DEFAULT_AUDIO_INPUT_DEVICE_ID
        )
      : audioInputDevices?.[0];

    setVideoInputDevice(videoInputDevice);
    setAudioInputDevice(audioInputDevice);

    // Streaming the video
    if (videoInputDevice) {
      streamVideo(videoInputDevice?.deviceId);
    }
  };

  const streamVideo = async (deviceId: string) => {
    try {
      if (!deviceId) return;

      const mediaDevices = navigator.mediaDevices;

      const videoStream = await mediaDevices
        .getUserMedia({ video: { deviceId } })
        .catch(() => null);

      const videoContainer = videoContainerRef.current;

      if (!videoContainer) return;

      videoContainer.srcObject = videoStream;
      videoContainer.play();
    } catch (e) {
      console.log({ e });
    }
  };

  const handleSubmit = () => {
    setLoading(true);

    setTimeout(() => {
      if (videoInputDevice?.deviceId) {
        const { deviceId, label } = videoInputDevice;

        // Updating the video call client state
        dispatch(
          setVideoCallClient({
            devices: {
              ...(videoCallClient.devices || {}),
              video: {
                deviceId,
                label,
              },
            },
          })
        );

        // Updating the default values in LocalStorage
        localStorage.setItem(
          "DEFAULT_VIDEO_INPUT_DEVICE_ID",
          videoInputDevice.deviceId
        );
      }

      if (audioInputDevice?.deviceId) {
        const { deviceId, label } = audioInputDevice;

        // Updating the video call client state
        dispatch(
          setVideoCallClient({
            devices: {
              ...(videoCallClient.devices || {}),
              audio: {
                deviceId,
                label,
              },
            },
          })
        );

        // Updating the default values in LocalStorage
        localStorage.setItem(
          "DEFAULT_AUDIO_INPUT_DEVICE_ID",
          audioInputDevice.deviceId
        );
      }

      setLoading(false);
      closeModal();
    }, 500);
  };

  const handleChange = async (deviceId: string) => {
    if (!deviceId) return;

    // Checking the devices
    const device = devices.find((device) => device.deviceId === deviceId);
    if (!device) return;

    // Updating the devices
    switch (device.kind) {
      case "videoinput":
        setVideoInputDevice(device);
        streamVideo(deviceId);

        break;
      case "audioinput":
        setAudioInputDevice(device);

        break;
    }
  };

  useEffect(() => {
    getDevices();
  }, []);

  return (
    <Modal
      title="Video Call Settings"
      description="You can set your webcam and mic here."
      dialogClassName="max-w-[760px]"
      open={isOpen}
    >
      <Container>
        <div className="w-full h-auto flex flex-col text-sm gap-[10px]">
          <div className="w-full h-[480px] bg-gray-800 rounded-lg overflow-hidden">
            <video
              ref={videoContainerRef}
              className="w-full h-full"
              autoPlay={true}
            ></video>
          </div>
          <div className="mt-[15px]">
            <div className="flex flex-row gap-[10px]">
              <div className="basis-6/12">
                <BasicSelect
                  id="camera"
                  label="Camera"
                  options={VIDEO_INPUT_DEVICE_SELECT}
                  value={videoInputDevice?.deviceId}
                  setValue={handleChange}
                />
              </div>
              <div className="basis-6/12">
                <BasicSelect
                  id="microphone"
                  label="Microphone"
                  options={AUDIO_INPUT_DEVICE_SELECT}
                  value={audioInputDevice?.deviceId}
                  setValue={handleChange}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-row justify-end gap-[10px] mt-[20px]">
          <Button variant="outlined" color="secondary" onClick={closeModal}>
            Discard
          </Button>
          <Button
            variant="outlined"
            color="success"
            className="min-w-[150px]"
            loading={loading}
            onClick={handleSubmit}
          >
            Save Changes
          </Button>
        </div>
      </Container>
    </Modal>
  );
};
