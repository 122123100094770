import React, { useEffect } from "react";

import { useNavigate } from "react-router";

import { useFormik } from "formik";
import * as Yup from "yup";

import {
  Button,
  Form,
  TextField,
  StarRating,
  PrivateModeSwitch,
} from "../../../components";
import {
  useAppDispatch,
  useAppSelector,
  useEmployee,
  useLoading,
  useToast,
  useURL,
} from "../../../hooks";
import { FormValidator, addLineBreaks } from "../../../utils";
import { appendCompanyComment, createCompanyComment } from "../../../store";
import {
  NotificationStatus,
  NotificationType,
  NotificationPriority,
} from "./../../../shared";

interface ICompanyCommentCreateForm {
  className?: string;
  inputClassName?: string;
  companyId?: string | null;
  jobApplicationId?: string;
  userId?: string;
  onCreate?: (value: any) => void;
  onClose?: (value?: any) => void;
}

const validationSchema = Yup.object().shape({
  details: Yup.string()
    .max(1000, "Exceeded maximum character length of 1000")
    .required("Comment is a required field"),
  rating: Yup.number().notRequired().default(0),
  isPrivate: Yup.boolean().notRequired().default(false),
});

export const CompanyCommentCreateForm: React.FC<ICompanyCommentCreateForm> = ({
  className,
  inputClassName,
  jobApplicationId,
  userId,
  onCreate,
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();

  const [loading, setLoading] = useLoading();
  const [URL, setURL] = useURL();
  const { toast } = useToast();

  const { company } = useEmployee();

  const {
    account: { data: account },
  } = useAppSelector((state) => state.account);

  const profile = account?.profile;
  const companyId = company?.id;
  const createdById = profile?.id;

  const handleClose = () => {
    resetForm();

    if (onClose) onClose();
  };

  const form = useFormik({
    initialValues: {
      details: "",
      rating: 0,
      isPrivate: false,
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      if (!companyId || !createdById) return;

      const { details } = values;

      const data = {
        ...values,
        details: addLineBreaks(details),
        companyId,
        userId,
        jobApplicationId,
        createdById,
      };

      setLoading(true);

      const created = await dispatch(createCompanyComment(companyId, data));
      if (!created?.id) {
        setLoading(false);

        return toast({
          status: NotificationStatus.Error,
          message: "Note Not Added!",
        });
      }

      if (onCreate) onCreate(created);

      dispatch(appendCompanyComment(created));

      setLoading(false);
      resetForm();
    },
  });

  const validator = new FormValidator(form);
  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
    handleSubmit,
    resetForm,
  } = form;

  return (
    <Form onSubmit={handleSubmit}>
      <TextField
        id="details"
        type="text"
        variant="standard"
        placeholder="Writing a note.."
        className={`${inputClassName}`}
        controlClassName="basis-full"
        labelClassName="text-secondary"
        required
        multiline
        minRows={5}
        maxRows={5}
        value={values?.details}
        errorMessage={validator.isInputValid("details")}
        onChange={handleChange}
      />

      <div className="flex flex-row justify-between items-center mt-[15px]">
        <PrivateModeSwitch
          leftLabel="Public"
          rightLabel="Private"
          value={values?.isPrivate}
          onChange={(value) => setFieldValue("isPrivate", value, true)}
        />
        <StarRating
          value={values?.rating}
          onChange={(value) => setFieldValue("rating", value, true)}
        />
      </div>

      <div className="w-full flex mt-[15px] gap-[20px] xxs:flex-col-reverse md:flex-row md:justify-start md:items-center">
        <div className="w-full flex flex-row justify-end gap-[10px]">
          <Button
            variant="outlined"
            color="secondary"
            className="xxs:w-full md:w-auto"
            onClick={handleClose}
          >
            Discard
          </Button>
          <Button
            type="submit"
            variant="outlined"
            color="success"
            className="min-w-[150px] xxs:w-full md:w-auto"
            loading={loading}
          >
            Create Note
          </Button>
        </div>
      </div>
    </Form>
  );
};

CompanyCommentCreateForm.defaultProps = {
  inputClassName: "!bg-primaryDark !text-white",
};
