import React from "react";

import {
  FormGroup,
  FormControlLabel,
  Checkbox as MuiCheckbox,
} from "@mui/material";

interface ICheckboxPros {
  id?: string;
  name?: string;
  label?: string;
  customLabel?: JSX.Element;
  value?: string | null;
  checked?: boolean;
  className?: string;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent, v: boolean) => void;
}

export const Checkbox: React.FC<ICheckboxPros> = ({
  id,
  name,
  label,
  value,
  checked,
  className,
  customLabel,
  disabled,
  onChange,
  ...props
}) => (
  <div
    className={`relative flex flex-row justify-start items-center text-secondary text-base font-medium`}
  >
    {disabled && (
      <div className="absolute w-full h-full backdrop-brightness-50 z-20"></div>
    )}
    <MuiCheckbox
      id={id}
      name={name}
      className={`${className} text-secondary p-[0px] mr-[5px] z-10`}
      value={value}
      checked={checked}
      onChange={onChange}
      disableRipple
      disabled={disabled}
      {...props}
    />
    {label && (
      <label htmlFor={id} className="cursor-pointer py-[3px] select-none">
        {label}
      </label>
    )}
    {customLabel ? (
      <label htmlFor={id} className="cursor-pointer ml-[5px]">
        {customLabel}
      </label>
    ) : (
      <></>
    )}
  </div>
);

Checkbox.defaultProps = {
  id: "",
  label: "",
  value: null,
  checked: false,
  disabled: false,
  onChange: () => {},
};
