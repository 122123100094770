import React, { useEffect, useReducer, useState } from "react";

import { PageSwitch } from "../../components";
import {
  ResumeTemplateDetailContainer,
  ResumeTemplateEditContainer,
  ResumeTemplateHistoryContainer,
} from "..";

import { ROUTER } from "../../routes";

import {
  appendResumes,
  getDefaultResume,
  getResumes,
  setURL,
} from "../../store";
import {
  useAppDispatch,
  useAppSelector,
  useDrawer,
  useScreen,
  useURL,
} from "../../hooks";
import { DrawerType } from "../../shared";

export const ResumeBuilderContainer = () => {
  const dispatch = useAppDispatch();

  const [URL, setURL] = useURL();
  const { setDrawer, resetDrawer } = useDrawer();
  const { breakpoint, width } = useScreen();

  const {
    account: { data: account },
  } = useAppSelector((state) => state?.account);

  const {
    resumes: { data: resumes },
  } = useAppSelector((state) => state?.resume);

  const profile = account?.profile;
  const profileId = profile?.id;
  const resumeId = profile?.resumeId;

  const pages = [
    {
      path: ROUTER.RESUME_TEMPLATE_HISTORY,
      component: <ResumeTemplateHistoryContainer />,
    },
    {
      path: ROUTER.RESUME_TEMPLATE_DETAIL(),
      component: <ResumeTemplateDetailContainer />,
    },
    {
      path: ROUTER.RESUME_TEMPLATE_EDIT(),
      component: <ResumeTemplateEditContainer />,
    },
  ];

  useEffect(() => {
    if (!profileId) return;

    dispatch(getResumes({ where: { isApplied: false, profileId } }));
  }, [profileId]);

  useEffect(() => {
    if (!resumeId) return;

    setURL({
      path: ROUTER.RESUME_TEMPLATE_DETAIL(),
      params: { id: resumeId },
    });
  }, [resumeId]);

  useEffect(() => {
    setDrawer({ type: DrawerType.RESUME_BUILDER, open: false });

    return () => resetDrawer();
  }, []);

  return <PageSwitch pages={pages} />;
};
