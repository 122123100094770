import React, { useEffect, useState } from "react";

import { IDigitalAsset } from "../../shared";

import {
  Avatar,
  BasicTable,
  Button,
  Container,
  IBasicTable,
  IBasicTableColumn,
  IBasicTableRenderCellParams,
  IBasicTableRow,
  Link,
  PageHeader,
  SocialMediaIconButton,
} from "../../components";
import { Generator, date, textformat } from "../../utils";
import { useAppDispatch, useAppSelector, useQueryParams } from "../../hooks";
import { ROUTER } from "./../../routes";
import { getUserLicenses } from "../../store";

export const UserLicensesContainer = () => {
  const dispatch = useAppDispatch();

  const { isQueryParams, queryParams, setQueryParams } = useQueryParams();

  const [table, setTable] = useState<IBasicTable>({ columns: [], rows: [] });

  const {
    licenses: {
      data: licenses,
      results: licensesCount,
      isLoading: licensesLoading,
      page: licensesPage,
      pageCount: licensesPageCount,
    },
  } = useAppSelector((state) => state.license);

  const renderTable = () => {
    const columns: IBasicTableColumn[] = [
      {
        field: "digital_asset_name",
        headerName: "Digital Asset",
        sortable: false,
        minWidth: 200,
        valueGetter: (data) => data.row?.digitalAsset,
        renderCell: ({ value }: IBasicTableRenderCellParams<IDigitalAsset>) => (
          <Link to={value?.id ? ROUTER.MARKETPLACE_ITEM(value?.id) : null}>
            <div className="flex flex-row justify-start items-center gap-[10px]">
              <Avatar src={value?.previewSrc} size={28} />
              <span>{textformat.shorten(value?.name, 30)}</span>
            </div>
          </Link>
        ),
      },
      {
        field: "type",
        headerName: "License Type",
        renderCell: ({ value }) => value,
      },
      {
        field: "price",
        headerName: "Price (USD)",
        valueGetter: (data) => data.row?.price,
        renderCell: ({ value }) => <span>${value || 0}</span>,
      },
      {
        field: "sold_copies",
        headerName: "Sold Copies",
        valueGetter: (data) => data.row?.copiesCount,
        renderCell: ({ value }: IBasicTableRenderCellParams<number>) =>
          value || 0,
      },
      { field: "duration", headerName: "Duration (Days)" },
      {
        field: "platforms",
        headerName: "Platforms",
        sortable: false,
        valueGetter: (data) => data.row?.postLimits,
        renderCell: ({ value }: IBasicTableRenderCellParams) => (
          <div className="flex flex-row justify-start items-center gap-[5px]">
            {!!value?.length ? (
              value?.map(({ platform, username }) => (
                <SocialMediaIconButton
                  platform={platform}
                  username={username}
                  iconProps={{ size: 18 }}
                />
              ))
            ) : (
              <>-</>
            )}
          </div>
        ),
      },
      {
        field: "createdAt",
        headerName: "Created",
        renderCell: ({ value }: IBasicTableRenderCellParams<Date>) =>
          date(value).format("MMM DD, hh:mm"),
      },
    ]?.map((i) => ({ flex: 2, minWidth: 100, ...i } as IBasicTableColumn));

    setTable({ ...table, columns, rows: licenses });
  };

  const handlePageChange = (page) => {
    if (page <= 0) return;

    setQueryParams({ page });
  };

  useEffect(() => {
    renderTable();
  }, [licenses]);

  useEffect(() => {
    if (!isQueryParams) {
      setQueryParams({ page: 1 });
      return;
    }

    dispatch(getUserLicenses(queryParams));
  }, [queryParams]);

  return (
    <Container loading={licensesLoading}>
      <PageHeader backTo={ROUTER.HOME}>
        Licenses ({licensesCount || 0})
      </PageHeader>
      <Container>
        <BasicTable
          columns={table?.columns}
          rows={table?.rows}
          loading={licensesLoading}
          page={licensesPage}
          pageCount={licensesPageCount}
          onPageChange={handlePageChange}
        />
      </Container>
    </Container>
  );
};
